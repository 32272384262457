import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;

    span {
        display: inline;
        color: white;
        margin-right: 6px;
        font-size: 0.7em;
    }
    background-color: #121D2E;
   
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;

    padding: 3px 15px;
    margin-right: 5px;
    margin-bottom: 5px;
   
`;


export const Content = styled.div`
    display: flex;

    
`;