import styled from "styled-components";

export const Container = styled.div`
    form {
        width: 100%;
    }
`;

export const Row = styled.div`
    display: flex;
    margin-bottom: 20px;

    .third-size {
        width: 30%;
    }

    .marginRight {
        margin-right: 5%;
    }
`;

export const Title = styled.h1`
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 1em;
`;

export const UploadMessage = styled.span`
    color: ${props => props.state ? "green": "red"};
`;


export const SaveButton = styled.button`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;


export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;