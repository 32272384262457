import { useAuth } from "../../../Hooks/useAuth";
import { transformPhone } from "../../../Support/Util";
import { Container, EmailInfo, PhoneInfo } from "./style";

const ProfileContactInfo = () => {

    const { currentUser } = useAuth();

    let email = "";
    let phone = null;
    
    if(currentUser.contact){
        if(currentUser.contact.contact_email) {
            email = currentUser.contact.contact_email;
        }

        if(currentUser.contact.phones && currentUser.contact.phones.length >= 1 ){
            phone  = transformPhone(currentUser.contact.phones[0].number);
        }
    }

    return (
       <Container>
           <EmailInfo>
               {email}
           </EmailInfo>
           <PhoneInfo>
               {phone}
           </PhoneInfo>
       </Container> 
    );
}

export default ProfileContactInfo;