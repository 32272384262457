import styled from "styled-components";

export const Content = styled.div`
    width: 94%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: #131E2E;

    @media(min-width: 700px){
        width: 700px;
    }
`;

export const Text = styled.div`
    color: ${props => props.color};
    padding: 2px 0px;
    font-weight: ${props => props.font};
    font-size: 0.9em;
    display: inline-block;
`;

export const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
`;

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
`;

export const Button = styled.a`
    display: flex;
    align-items: center;
    border-style: none;
    text-decoration: none;
    width: max-content;
    
    color: white;
    background-color: #FF843D;
    border-radius: 15px;
    
    padding: 2px 20px;
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: pointer;
`;
