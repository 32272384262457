import GenericCardArtistView from "../GenericCardArtistView";
import Paginator from "../Paginator";
import Card from "./Card";
import { Container } from "./style";

const ProfileList = ({profiles, pagination, getProfiles}) => {

    return (
        <Container>
            {profiles.map((profile, index) => {
                return (
                    <GenericCardArtistView key = {index} profile = {profile} />
                );
            })}
            <Paginator pagination = {pagination} getResources = {getProfiles} />
        </Container>
    );
}

export default ProfileList;