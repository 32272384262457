import { useEffect, useState } from 'react';
import axios from 'axios';
import { characterCounter, hasFree } from '../../Support/Util';
import useWindowSize from '../../Hooks/useWindowSize';
import Modal from '../Modal';
import ProfileViewerStatisticBar from '../ProfileViewerStatisticBar';
import Interactions from './Interactions';
import ProfileContactInfo from './ProfileContactInfo';
import ProfilePhoto from './ProfilePhoto';
import ProfileShortInfo from './ProfileShortInfo';
import { Container, TopHeader, LeftSide, RightSide, About, SeeMore, Line, AboutTitle, OkButton, TopHeaderDesktop, FavoriteButton } from './style';
import SocialMedia from '../SocialMedia';
import { useAuth } from '../../Hooks/useAuth';
import { addNewFavorite, removeFavorite } from '../../Services/FavoriteSerive';
import { useToast } from "../../Hooks/useToast";

const ProfileViewerHeader = ({profile}) => {

    const [windowWidth, windowHeight] = useWindowSize();
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const isFree = hasFree(profile);
    const {addToast} = useToast();
    

    const {getProducerToken, producerCurrentUser} = useAuth();
    const [isProducer, setIsProducer] = useState(false);
    const [isFavorite, setIsFavorite] = useState(profile.isFavorite ? profile.isFavorite: false);

    const handleContactOpen =async() => {
        setIsContactOpen(true);
        let apiUrl = `${process.env.REACT_APP_BASE_API}/phone/access`;
        const producerToken = getProducerToken();

        const headers = {
            'Content-Type': 'application/json',
        }

        if(producerToken !== false){
            headers.Authorization =  `Bearer ${producerToken.token}`;
        }

        const response = await axios.post(apiUrl,{profile_id: profile.id},{headers:headers});
        console.log(response);
        
    }

    const handleRemoveFavorite = async () => {
        const response =  await removeFavorite(getProducerToken(), profile.id);
        if(response.status === 200){
            setIsFavorite(false);
            addToast("Sucesso", "Favorito removido com sucesso!", 1);
        } else if(response.status === 400){
            let erroMessage = "";
            if(response.data.message==="Favorito não encontrado!"){
                erroMessage = "Atualize a página e tente novamente.";
            } else if(response.data.message === "Dados incompletos."){
                erroMessage = "Faça o login novamente e tente outra vez.";
            } else{
                erroMessage = "Entre em contato com o suporte.";
            }
            addToast("Falha", erroMessage , 0);
        } 
        
        else{
            addToast("Falha", 'Entre em contato com o suporte.', 0);
        }

    }

    const handleAddFavorite = async () => {
        const response =  await addNewFavorite(getProducerToken(), profile.id);
        if(response.status === 200){
            setIsFavorite(true);
            addToast("Sucesso", "Favorito adicionado com sucesso!", 1);
        } else if(response.status === 400){
            let messageError = response.data.message === "O favorito já foi adicionado!" ?
                "O artista já foi adicionado como favorito! Atualize a página e verifique novamente.": "Entre em contato com o suporte.";
            if(response.data.message === "O favorito já foi adicionado!" ? false: true){
                messageError = response.data.message === "Dados incompletos." ?
                "Faça o login novamente e tente outra vez.": "Entre em contato com o suporte.";
            }
            addToast("Falha", messageError , 0);
        } 
        
        else{
            addToast("Falha", 'Entre em contato com o suporte.', 0);
        }
    }

    const handleCloseAbout = () => {
        setIsAboutOpen(false);
    };

    const handleOpenAbout = () => {
        setIsAboutOpen(true);
    }

    const aboutSize = windowWidth > 700 ? 350 : 150;
    
    const [about, counter] = characterCounter(profile.characteristic.about, aboutSize);
    const completeAbout = profile.characteristic.about;

    useEffect(() => {
        if(producerCurrentUser){
            setIsProducer(producerCurrentUser.userable_type === "producer");
        } else{
            setIsProducer(false);
        }
        
    }, []);
    
    if(windowWidth > 700) {
        return(
            <Container>
                <TopHeaderDesktop>
                    <LeftSide>
                        <ProfilePhoto profile = {profile} />
                        <ProfileShortInfo profile = {profile} />
                    </LeftSide>
                    <RightSide>
                        <About>
                            {about}
                        </About>
                        { counter > aboutSize && 
                        <SeeMore onClick={handleOpenAbout}>
                            Ver mais
                        </SeeMore>
                        }

                        <Line />
                        <ProfileContactInfo 
                            profile = {profile} 
                            isContactOpen = {isContactOpen} 
                            handleContactOpen = {handleContactOpen} 
                        />

                        { isProducer &&
                            <FavoriteButton onClick={isFavorite? handleRemoveFavorite: handleAddFavorite} isFavorite={isFavorite}>
                                {isFavorite &&
                                    <span>
                                        Favoritado
                                    </span>
                                }

                                {!isFavorite &&
                                    <span>
                                        Favoritar
                                    </span>
                                }
                            </FavoriteButton>
                        }

                        <SocialMedia profile={profile} isFree={isFree} isViewer={true}/>
                        <ProfileViewerStatisticBar profile = {profile} />
                    </RightSide>
                </TopHeaderDesktop>

                <Modal isOpen={isAboutOpen} handleClose = {handleCloseAbout}>
                    <About>
                        <AboutTitle>
                            Sobre o artista
                        </AboutTitle>
                        {completeAbout}
                        <OkButton onClick={handleCloseAbout}>Ok</OkButton>

                    </About>
                </Modal>
            </Container>
        );
    }

    return (
        <Container>
            <TopHeader>
                <LeftSide>
                    <ProfilePhoto profile = {profile} />
                    <ProfileShortInfo profile = {profile} />
                </LeftSide>
                <RightSide>
                    <About>
                        {about}
                    </About>
                    { counter > aboutSize && 
                    <SeeMore onClick={handleOpenAbout}>
                        Ver mais
                    </SeeMore>
                    }

                    <Line />

                    <ProfileContactInfo 
                        profile = {profile} 
                        isContactOpen = {isContactOpen} 
                        handleContactOpen = {handleContactOpen} 
                    />

                    { isProducer &&
                        <FavoriteButton onClick={isFavorite? handleRemoveFavorite: handleAddFavorite} isFavorite={isFavorite}>
                            {isFavorite &&
                                <span>
                                    Favoritado
                                </span>
                            }

                            {!isFavorite &&
                                <span>
                                    Favoritar
                                </span>
                            }
                        </FavoriteButton>
                    }

                    <SocialMedia profile={profile} isFree={isFree} isViewer={true}/>
                </RightSide>
            </TopHeader>
            
            <Modal isOpen={isAboutOpen} handleClose = {handleCloseAbout}>
               <About>
                   <AboutTitle>
                       Sobre o artista
                   </AboutTitle>
                   {completeAbout}
                   <OkButton onClick={handleCloseAbout}>Ok</OkButton>

               </About>
            </Modal>
            <ProfileViewerStatisticBar profile = {profile} />
            
        </Container>
    );
}

export default ProfileViewerHeader