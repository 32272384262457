import { Container, Content, Space, Text, TextList } from "./style";

const AcceptTerms = () => {
    return(
        <Container>
            <Content>
                <Text>
                    Bem vindo à Ooppah, sua plataforma de integração artística. 
                    Agradecemos sua adesão e desejamos sucesso e satisfação na utilização de nossa ferramenta.
                    
                </Text>

                <Text>
                    <span>
                        Ao utilizar ou acessar nossa plataforma e/ou serviços, você <u>concorda</u> com os termos aqui apresentados, 
                        motivo pelo qual sugerimos a leitura completa e atenta de todo o texto.
                        Nossa empresa tem denominação social de OOPPAH SOLUÇÕES ARTISTICAS LTDA. – EPP, 
                        sede na cidade do Rio de Janeiro/RJ e está cadastrada no CNPJ sob o nº 23.825.830/0001-53.
                    </span>
                </Text>

                <Text>
                    Lembramos que você é livre para não concordar com os termos aqui estabelecidos, bastando não acessar nosso site e, caso já o tenha feito,
                    poderá solicitar sua exclusão nos moldes aqui descritos.
                </Text>

                <Text>
                    O objetivo deste Termo é estabelecer as regras (i) de contratação da Ooppah, (ii) de conduta dos Usuários e (iii) de utilização da plataforma Ooppah,
                    a fim de promover a correta e harmônica utilização da plataforma, o respeito entre os Usuários, favorecendo a interação e promoção dos objetivos da plataforma Ooppah.
                </Text>

                <Text>
                    Considerando que a plataforma Ooppah possui diferentes serviços, poderá ser solicitado ao Usuário a concordância com termos adicionais ou 
                    exigências restritivas, que integrarão o presente contrato.
                </Text>

                <Text>
                    <span>
                        Para ter uma conta na Ooppah é necessário ter mais de 18 anos e/ou ser legalmente capaz de contratar e aceitar os Termos aqui descritos.
                        Caso tenha qualquer dúvida ou reclamação nos contate através do e-mail <a href="mailto:contato@ooppah.com.br">contato@ooppah.com.br</a>.
                    </span>
                </Text>

                <TextList>
                    <span>
                        .1.
                    </span>

                    <span>
                        DEFINIÇÕES
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.1.
                    </span>

                    <span>
                        &emsp;Ooppah: Empresa responsável pela plataforma Ooppah, cuja denominação 
                        social é OOPPAH SOLUÇÕES ARTISTICAS LTDA. – EPP.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.2. 
                    </span>

                    <span>
                        &emsp;Plataforma Ooppah: Plataforma virtual de busca e organização para produtores e 
                        artistas que se utiliza de um banco de dados de perfis cadastrados por artistas e/ou agentes, 
                        que voluntariamente inserem suas informações, fotos, vídeos e experiências para serem encontrados 
                        por produtores, cujo endereço eletrônico é <a href="https://www.ooppah.com.br" target="_blank">www.ooppah.com.br</a>.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.3. 
                    </span>

                    <span>
                        &emsp;Usuário: É toda pessoa física ou jurídica que utiliza os serviços prestados pela Ooppah, com ou sem destinação final,
                        pagamento, interesse profissional ou vínculo contratual, de forma onerosa ou gratuita.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.4.
                    </span>

                    <span>
                        &emsp;Conteúdos do Usuário: Todos os dados de cadastro, imagens, vídeos, textos, conexões, interações e afins.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.5.
                    </span>
                    <span>
                        &emsp;Compartilhamento de informações: Toda divulgação passiva ou ativa do conteúdo fornecido ou gerado pelo usuário na plataforma Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.6.
                    </span>
                    <span>
                        &emsp;Acesso: Navegação restrita com ou sem assinatura, preenchimento de cadastro ou filiação, 
                        limitada às partes consideradas abertas da plataforma Ooppah, sem acesso aos canais, produtos 
                        e/ou serviços destinados aos assinantes e/ou usuários cadastrados.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.7.
                    </span>
                    <span>
                        &emsp;Acesso qualificado: Navegação mediante cadastro, assinatura e/ou filiação que, de acordo 
                        com cada perfil de usuário pode ser paga ou gratuita.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.8.
                    </span>
                    <span>
                        &emsp;Cadastro: Conjunto de informações fornecidas pelo usuário, cujo acesso se dará mediante uso de login e 
                        senha, de caráter pessoal e intransferível. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.9.
                    </span>
                    <span>
                        &emsp;Planos: A Ooppah pode oferecer o plano de assinatura de forma gratuita mas, para utilização dos 
                        serviços de modo completo, o usuário classificado como artista deverá fazer a opção de contratação 
                        mediante pagamento, cujos valores serão descritos no ato da contratação, estando à disposição para prévio conhecimento na plataforma Ooppah. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .1.10.
                    </span>

                    <span>
                        &emsp;Navegador: Programa que permite acesso e navegabilidade em sites na internet, os mais conhecidos são: Google Chrome, Internet Explorer e Mozila Firefox.
                    </span>
                </TextList>


                <TextList>
                    <span>
                        .1.11.
                    </span>
                    <span>
                        &emsp;Perfil Gratuito: Plano de assinatura oferecido de forma gratuita pela Ooppah, com a finalidade de democratizar o acesso à plataforma, com 
                        limitações na quantidade de Conteúdos do Usuário e na utilização dos serviços.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .2.
                    </span>
                    <span>
                        A OOPPAH E SEU COMPROMISSO
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .2.1.
                    </span>
                    <span>
                        &emsp;A plataforma Ooppah consiste em uma plataforma virtual de busca e 
                        organização para produtores e artistas que se utiliza de um banco de dados de perfis cadastrados 
                        por artistas e/ou agentes, que voluntariamente inserem suas informações, 
                        fotos, vídeos e experiências para serem encontrados por produtores, 
                        cujo endereço eletrônico é <a href="https://www.ooppah.com.br" target="_blank">www.ooppah.com.br</a>.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .2.2.
                    </span>
                    <span>
                        &emsp;Nosso compromisso se baseia em realizar os melhores esforços, sob a ótica do 
                        comercialmente razoável, para a criação e manutenção de ferramentas técnicas que 
                        visam à integração de artistas e demais profissionais do meio artístico. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .2.3.
                    </span>
                    <span>
                        &emsp;A Ooppah não garante a realização de testes, fechamento de trabalhos ou afins.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .2.4. 
                    </span>
                    <span>
                        &emsp;A Ooppah não realiza testes, nem é responsável por nenhum teste que 
                        venha ser convocado pela visualização do perfil do Usuário na plataforma ou 
                        por seleções que a plataforma divulgue para que seus Usuários tenham conhecimento. 
                        A Ooppah poderá divulgar seleções de testes, quando solicitados pelos profissionais 
                        de produção de elenco que utilizam a plataforma e/ou pelos Usuários, mas a Ooppah não
                        terá nenhuma ingerência por esses trabalhos, não sendo interveniente em nenhum momento 
                        de nenhuma dessas convocações que venham a surgir. 
                    </span>
                </TextList>
                
                <TextList>
                    <span>
                        .2.5.
                    </span>
                    <span>
                        &emsp;Nosso compromisso compreende a disponibilização, mediante as condições descritas
                        neste termo, de home page interna e externa que permita a integração profissional com 
                        outros atores, produtores de elenco e agentes, assim como o armazenamento e exposição 
                        de imagens, vídeos e informações de carreira profissional.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .2.6.
                    </span>
                    <span>
                        &emsp;Aceitamos o ingresso de pessoas físicas e jurídicas na plataforma Ooppah, todos definidos
                        como Usuários, se distinguindo unicamente pela forma de utilização da plataforma Ooppah, tendo em 
                        vista seu papel no cenário profissional e a gama de serviços contratados.
                    </span>
                </TextList>

                <TextList>
                    <span>  
                        .2.7.
                    </span>
                    <span>
                        &emsp;A Ooppah não é uma agência e, portanto, não garante trabalhos e não 
                        cobra do artista quando da realização de um trabalho.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .3.
                    </span>
                    <span>
                        A CONTA OOPPAH E A CONDUTA DOS USUÁRIOS
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.1.
                    </span>
                    <span>
                        &emsp;É necessário registrar-se como usuário e criar uma conta na
                        plataforma Ooppah para utilizar os recursos da Ooppah que forem restritos aos 
                        usuários cadastrados. O seu cadastro na plataforma Ooppah lhe confere o direito de 
                        montar um perfil com fotos, vídeos e dados de carreira que ficará visível para qualquer
                        pessoa que acessar a plataforma Ooppah. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.2.
                    </span> 
                    <span>
                        &emsp;Sua conta Ooppah é pessoal e intransferível, sendo expressamente vedada a utilização por terceiros, 
                        autorizados ou não, assim como a comercialização de sua conta. A conta Ooppah estará vinculada a um CPF ou 
                        CNPJ, cuja cópia deverá ser anexada no formulário de cadastramento da conta Ooppah. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.3.
                    </span>
                    <span>
                        &emsp;A plataforma Ooppah tem como objetivo a divulgação de materiais artísticos de modelos, 
                        atores e artistas, e qualquer outro tipo de utilização da plataforma será proibida.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.4.
                    </span>
                    <span>
                        &emsp;Ao cadastrar-se na plataforma Ooppah, você concorda em fornecer informações verídicas, precisas, 
                        completas e atualizadas sobre si mesmo, conforme solicitado no formulário de cadastro, e de atualizá-las sempre que necessário. 
                        Não podem ser utilizados nomes artísticos ou apelidos suscetíveis de causar confusão, que violem direitos de terceiros de 
                        qualquer natureza, ou que sejam considerados ofensivos, vulgares ou de qualquer outra forma inapropriados. 
                        A Ooppah poderá remover o usuário ou alterar a seu critério qualquer nome de usuário que viole as condições previstas nestes Termos de Uso. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.5.
                    </span>
                    <span>
                        &emsp;Ao usar a conta Ooppah, o Usuário concorda em não praticar os seguintes atos, dentre outros, sob pena de ser advertido, ter o seu 
                        cadastro e perfil na Ooppah suspenso ou cancelado, temporária ou definitivamente e ser legalmente responsabilizado, sem prejuízo de outras medidas:
                        Publicar, postar, submeter, distribuir ou disseminar qualquer material ou informação que, a exclusivo critério da Ooppah: 
                        (i) seja inapropriado, difamatório, obsceno, pornográfico, indecente, violento, ofensivo, preconceituoso ou que possa ferir a honra, 
                        imagem, privacidade e intimidade de terceiros, (ii) possa enganar ou iludir terceiros; (iii) infrinjam quaisquer direitos da personalidade 
                        (imagem, voz, nome e afins) e de propriedade intelectual; ou (vi) de qualquer forma viole direitos de terceiros;
                        Publicar, postar, submeter, distribuir ou disseminar qualquer material ou informação de que não tenha obtido as devidas licenças, 
                        autorizações e permissões dos respectivos titulares de direitos; Usar os recursos da Ooppah para disseminar publicidade, 
                        propaganda ou correspondência indesejada, como spams, correspondência em corrente ou em pirâmide, dentre outros;
                        Utilizar ou empregar quaisquer métodos que interfiram na quantidade de visualizações das fotos, vídeos ou perfis na plataforma Ooppah,
                        ou que forneçam vantagem indevida a qualquer Usuário; Fazer propaganda, promover ou oferecer para venda ou compra quaisquer produtos ou 
                        serviços, ou para qualquer propósito comercial ou promocional diverso do objeto da plataforma Ooppah, sem autorização prévia da Ooppah;
                        Violar ou encorajar outros a violar estes Termos de Uso, quaisquer leis, regulamentos ou direitos de terceiros;
                        Cadastrar-se com informações falsas, mesmo que visando o incremento de seu perfil, incluindo declarar idade falsa ou fingir ser qualquer pessoa
                        ou entidade que não é, para enganar outros ou para qualquer outro propósito;

                        Incentivar a divulgação ou consumo de drogas lícitas ou ilícitas em inobservância a legislação aplicável, 
                        em especial no tocante ao Estatuto da Criança e do Adolescente;
                        Utilizar a plataforma para intimidar, reprimir, assediar, praticar bullying com Usuários ou terceiros;
                        Desrespeitar as diferenças humanas e ideológicas, em especial as diferentes raças, etnias, religiões e nacionalidades;
                        Utilizar a plataforma para encontros amorosos, paqueras e afins;
                        Aproveitar as ferramentas de interação proporcionadas para obter vantagem ilícita ou indevida;
                        Solicitar ou compartilhar informações pessoais, de login, senhas próprias ou de outros usuários;

                        Atuar de modo a alterar a configuração do site ou sistema, carregar códigos ou qualquer outra 
                        conduta classificada como hacking, cracking e/ou assemelhadas;
                        Utilizar de meios automatizados de coleta e informações ou acesso (boots, robôs, spiders e afins);
                        Praticar qualquer ato visando sobrecarregar, alterar aparência ou funcionalidade, diminuir a capacidade,
                        performance ou qualidade da plataforma Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .3.6.
                    </span>
                    <span>
                        &emsp;Você é o único responsável por manter a confidencialidade da senha de acesso à plataforma Ooppah 
                        escolhida no momento do registro. A Ooppah não se responsabiliza por qualquer dano que resulte da 
                        divulgação da senha pelo Usuário a terceiros, ou da utilização da senha por terceiros para ter acesso 
                        à conta do Usuário. Caso ocorra o uso não autorizado de sua conta, o Usuário deverá notificar a Ooppah 
                        imediatamente, através do endereço de correio eletrônico <a href="mailto:contato@ooppah.com.br">contato@ooppah.com.br</a>.
                        
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.7.
                    </span>
                    <span>
                        &emsp;Caso o Usuário esqueça ou perca a senha ou seu nome de usuário, esses dados poderão ser recuperados se o 
                        Usuário responder adequadamente às verificações de segurança. A Ooppah se reserva o direito de suspender ou cancelar, 
                        a seu exclusivo critério, o acesso à conta do Usuário caso entenda haver uma violação de segurança. Por medida de segurança, 
                        a Ooppah poderá exigir que o Usuário mude todas e quaisquer das senhas usadas para acessar sua conta na plataforma. 
                        
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.8.
                    </span>
                    <span>
                        &emsp;Você deve ser preciso no preenchimento de seus dados pessoais e de cobrança, sendo o único responsável 
                        pela constante atualização destes. Os dados financeiros fornecidos pelos Usuários serão tratados como sigilosos, 
                        sendo vedada à Ooppah a sua divulgação a terceiros, salvo se por necessidade para a cobrança dos planos, imposição legal ou ordem judicial.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.9.
                    </span>
                    <span>
                        &emsp;É imperioso que você utilize somente seus dados pessoais para alimentar sua conta, 
                        bem como respeite a personalidade e a dignidade humana dos demais usuários.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.10.
                    </span>
                    <span>
                        &emsp;Os Usuários cadastrados como agentes poderão criar perfis em nome de seus
                        agenciados, desde que possuam autorização para tanto. A Ooppah poderá exigir dos Usuários cadastrados como agentes a apresentação de documentação que comprove a autorização para criação de perfis em nome de seus agenciados.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.11.
                    </span>
                    <span>
                        &emsp;As contas de usuários podem ser classificadas de formas distintas, tendo em vista o plano vigente à 
                        época de sua contratação ou, ainda, por outros critérios de análise da Ooppah.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.12.
                    </span>
                    <span>
                        &emsp;A plataforma Ooppah e seus serviços devem ser utilizados de forma sadia e cordial; nunca tente acessá-los 
                        em desacordo com os métodos e informações fornecidas.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.13.
                    </span>
                    <span>
                        &emsp;A plataforma Ooppah deve ser utilizada exclusivamente para interação sócioprofissional, 
                        sempre se observando o respeito entre os Usuários.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.14.
                    </span>
                    <span>
                        &emsp;A Ooppah se reserva o direito de limitar a quantidade de dados fornecidos e/ou armazenados 
                        por cada Usuário, incluindo limitadores numéricos ou quantitativos como, por exemplo, número de fotos; vídeos;
                        caracteres; e tudo o mais que possa vir a causar sobrecarga ou afronte ao layout escolhido para a plataforma Ooppah.
                    </span>

                </TextList>

                <TextList>
                    <span>
                        .3.15.
                    </span>
                    <span>
                        &emsp; A Ooppah se reserva o direito de alterar as condições do Perfil Gratuito sem aviso prévio, bem como cancelar a oferta do 
                        Perfl Gratuito a qualquer momento, sem aviso prévio, por motivos como limite de armazenamento no servidor, questões técnicas
                        e/ou administrativas. Um Perfil Gratuito pode ser excluído pela Ooppah a qualquer momento, especialmente se for considerado 
                        falso ou estiver incompleto de forma a compremeter a qualidade das pesquisas feitas na plataforma.
                    </span>

                </TextList>

                <Space />

                <TextList>
                    <span>
                        .4.
                    </span>
                    <span>
                        UTILIZAÇÃO DA PLATAFORMA OOPPAH PARA PERFIS DE MENORES DE IDADE
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.1.
                    </span>
                    <span>
                        &emsp;A contratação dos serviços oferecidos pela Ooppah é vedada a menores de 18 anos. 
                        Contudo, caso o responsável legal de um menor de idade opte por contratar os serviços da 
                        Ooppah e efetuar cadastro para disponibilizar um perfil para o menor de idade na plataforma 
                        Ooppah, este poderá fazê-lo desde que assine termo de autorização de uso de imagem, voz e dados
                        biográficos referente ao menor de idade.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.2.
                    </span>
                    <span>
                        &emsp; Para recebimento do termo de autorização de uso de imagem, voz e dados biográficos que 
                        precisa ser assinado pelo responsável do menor de idade, pedimos que o solicite através do
                        e-mail <a href="mailto:contato@ooppah.com.br">contato@ooppah.com.br</a>.
                    </span>
                </TextList>

                <TextList>
                    <span>  
                        .4.3.
                    </span>
                    <span>
                        &emsp;No caso de contratação de serviços para a criação de perfil para um menor de idade,
                        os dados de cadastro e a titularidade da conta devem constar em nome do responsável, não do menor de idade.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.4.
                    </span>     
                    <span>
                        &emsp;Em caso de contratação dos serviços da Ooppah para a criação de perfil para menor de idade, os responsáveis 
                        pelo menor serão os únicos autorizados a utilizar a conta na plataforma Ooppah, se responsabilizando por todo e 
                        qualquer material do menor inserido ou editado em sua conta na plataforma. 
                    </span>
                </TextList>


                <TextList>
                    <span>
                        .4.5. 
                    </span>     
                    <span>
                        &emsp;A senha da conta na plataforma Ooppah é pessoal e intransferível, sendo terminantemente proibido que os responsáveis 
                        pelo menor de idade cujo perfil foi inserido na plataforma emprestem a senha da conta da plataforma em qualquer caso, em especial 
                        para que: (a) o menor de idade realize diretamente inserções ou edições de materiais; (b) terceiros realizem diretamente inserções 
                        ou edições de materiais do menor de idade. Caso os responsáveis pelo menor descumpram a obrigação retro, serão os únicos responsáveis pelos danos dai advindos.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.6.
                    </span>     
                    <span>
                        &emsp;É terminantemente proibida a inserção de materiais na plataforma Ooppah nos quais o menor de idade apareça em poses sexys/sensuais/vexatórias ou 
                        quaisquer situações que comprometam a sua integridade física, psíquica e moral, em consonância com os artigos 17 e 18 do Estatuto da Criança e Adolescente. 
                        Caso a Ooppah tome conhecimento de qualquer material que infrinja o disposto neste item, os removerá imediatamente da plataforma, bloqueará a conta do Usuário e cancelará a sua assinatura.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.7.
                    </span>     
                    <span>
                        &emsp;Não constitui responsabilidade da Ooppah analisar o material do menor de idade inserido no perfil da plataforma Ooppah, sendo essa obrigação única e exclusiva dos responsáveis legais do 
                        menor de idade, sendo ressalvando a faculdade disposta no item 4.6 acima.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.8.
                    </span>     
                    <span>
                        &emsp;O perfil do menor de idade na plataforma Ooppah apenas será liberado para visualização pública do material inserido após o recebimento da via física do termo de autorização de uso de imagem,
                        voz e dados biográficos mencionado no item 4.1, bem como dos documentos que comprovem a condição de responsável legal da pessoa que assinou o termo.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.9. 
                    </span>     
                    <span>
                        &emsp;Caso a Ooppah não receba, no prazo de 30 (trinta) dias da data da realização do cadastro na plataforma Ooppah, o termo de autorização de uso de imagem, voz e dados biográficos assinado pelo responsável 
                        pelo menor de idade, com reconhecimento de firma, assim como documentos que comprovem a condição de responsável legal da pessoa que assinou o termo, a conta na plataforma Ooppah poderá ser cancelada. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .4.10.
                    </span>     
                    <span>
                        &emsp;Se qualquer pessoa, em especial pais ou responsáveis legais por menores de idade, acreditar que constam na plataforma Ooppah informações sobre menores de idade, sem a devida 
                        autorização ou com conteúdo inadequado, pedimos que nos contatem imediatamente para adoção das providências cabíveis.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .5.
                    </span>     
                    <span>
                        CONTEÚDO DAS CONTAS/PERFIS OOPPAH
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.1.
                    </span>     
                    <span>
                        &emsp;O conteúdo das contas/perfis na Ooppah é gerado exclusivamente pelos seus Usuários. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.2. 
                    </span>     
                    <span>
                        &emsp;O Usuário poderá submeter informações, imagens, vídeos, mensagens, comentários, e outros materiais 
                        ("Conteúdos do Usuário") para publicação na plataforma Ooppah. O Usuário é o único e exclusivo responsável 
                        pelos Conteúdos do Usuário que fornecer, publicar ou de qualquer forma submeter à plataforma Ooppah e pelas 
                        consequências geradas por estes atos, assim como isenta a Ooppah de qualquer responsabilidade a esse respeito.                    
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.3.
                    </span>     
                    <span>
                        &emsp;O Usuário concorda em respeitar os direitos, de qualquer natureza, da Ooppah e de terceiros, bem como
                        garante que não publicará (seja por que meio for) qualquer conteúdo que seja protegido por direitos da personalidade 
                        (imagem, voz, nome e afins) ou propriedade intelectual, exceto se for o seu legítimo titular ou estiver devidamente 
                        autorizado a fazê-lo. Em caso de publicação de conteúdo no qual estejam presentes direitos de terceiros, é obrigação do 
                        Usuário obter a licença para sua utilização, bem como atribuir o devido crédito ao terceiro cujo direito foi utilizado.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.4.
                    </span>     
                    <span>
                        &emsp;Os Usuários cadastrados como agentes deverão ter em seu poder documentos que comprovem ter autorização para realizar 
                        cadastro em nome de seus agenciados, documentos estes que poderão ser requisitados pela Ooppah sempre que entender necessário.;
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.5.
                    </span>     
                    <span>
                        &emsp;Os Usuários cadastrados como agentes serão totalmente responsáveis pelos conteúdos que inserirem na plataforma Ooppah, sejam
                        em relação à Ooppah, seus agenciados ou a terceiros. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.6.
                    </span>     
                    <span>
                        &emsp; O Usuário afirma e garante que detém todos os direitos de propriedade intelectual, ou de que natureza for, e as devidas 
                        autorizações sobre os conteúdos que publicar na Ooppah (seja por que meio for). Ao publicar Conteúdos do Usuário, o Usuário mantém 
                        todos os direitos de propriedade que já detinha sobre os conteúdos publicados.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.7.
                    </span>     
                    <span>
                        &emsp;O Usuário concede uma licença gratuita e mundial à Ooppah, pelo prazo de proteção legal de cada Conteúdo do Usuário publicado, 
                        em caráter definitivo, irretratável e irrevogável, para que a Ooppah possa, por si ou por terceiros, utilizar, armazenar, hospedar, reproduzir, 
                        copiar, modificar, em qualquer meio e formato, os Conteúdos do Usuário, no todo ou em parte, para quaisquer fins, inclusive para divulgação e promoção 
                        da Ooppah, bem como para quaisquer fins publicitários, promocionais, comerciais ou não, como melhor lhe aprouver. A licença concedida nesta cláusula 
                        abrange inclusive a autorização de uso dos direitos da personalidade (imagem, voz, nome e afins) do Usuário. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.8.
                    </span>     
                    <span>
                        &emsp;A Ooppah fica expressamente autorizada a utilizar as imagens e inseridas pelos Usuários em sua plataforma, em suas 
                        campanhas publicitárias e nas ferramentas de apresentação da sua plataforma, de forma isolada ou associadas a outras imagens,
                        sem que isso implique em qualquer remuneração do Usuário ou direito à indenização. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .5.9.
                    </span>     
                    <span>
                        &emsp;A Ooppah não está obrigada a monitorar as informações ou conteúdos postados por Usuários em sua plataforma. 
                        No entanto, a Ooppah, a seu exclusivo critério, se reserva o direito de remover qualquer material que viole 
                        estes Termos de Uso sem a prévia notificação aos Usuários. 
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .6.
                    </span>     
                    <span>
                        PROPRIEDADE INTELECTUAL E VIOLAÇÕES A DIREITOS DE TERCEIROS
                    </span>
                </TextList>


                <TextList>
                    <span>
                        .6.1.
                    </span>     
                    <span>
                        &emsp;A Ooppah é a única e exclusiva titular de todos os direitos de propriedade 
                        intelectual sobre a plataforma Ooppah e todos os bens e serviços a ela relacionados, incluindo as marcas, 
                        logotipos, nomes de domínio, lay-out, trade dress, designs, dentre outros (“Propriedade Intelectual da Ooppah). 
                        O Usuário está ciente e concorda que a Propriedade Intelectual da Ooppah jamais poderá ser utilizada de qualquer 
                        forma e em qualquer mídia sem autorização prévia, expressa e por escrito da Ooppah. O mero uso da plataforma Ooppah
                        não confere ao Usuário licença para utilizar-se da Propriedade Intelectual da Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.2.
                    </span>     
                    <span>
                        &emsp;É vedado ao Usuário tentar reverter a engenharia, decompilar ou desmontar, adaptar ou de outra forma modificar a plataforma 
                        Ooppah, seus conteúdos ou qualquer sistema fornecido pela Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.3.
                    </span>     
                    <span>
                        &emsp;A Ooppah não é obrigada a aceitar ou considerar quaisquer ideias, sugestões ou materiais submetidos por parte do público ("Sugestões"), 
                        sobretudo se tais Sugestões forem objeto de proteção por propriedade intelectual, concorrência desleal ou outros direitos. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.4.
                    </span>     
                    <span>
                        &emsp;No entanto, se o Usuário enviar qualquer Sugestão à Ooppah, o Usuário concede, com exclusividade, pelo prazo máximo permitido
                        em lei, licença sobre todos os direitos decorrentes da Sugestão, podendo a Ooppah utilizar livremente, sem qualquer compensação ao 
                        Usuário, quaisquer conceitos, ideias, know-how ou técnicas contidos em qualquer Sugestão para qualquer finalidade, inclusive, sem
                        limitação, desenvolvimento, comercialização e exploração de produtos e serviços no Brasil e no exterior a partir de tais informações. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.5.
                    </span>     
                    <span>
                        &emsp;É proibida a inserção pelo Usuário na plataforma Ooppah de conteúdo que contenha marcas registradas, imagem de terceiros, 
                        direitos autorais ou direitos de qualquer natureza, cujos titulares não tenham expressamente autorizado a sua utilização. Caso o 
                        Usuário descumpra a regra contida nesta cláusula, desde já declara que irá manter a Ooppah a salvo e indene de qualquer reclamação, 
                        ação judicial ou afim, sendo certo ainda que indenizará a Ooppah por qualquer dano advindo do seu descumprimento. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.6.
                    </span>     
                    <span>
                        &emsp;Apesar de não estar obrigada a monitorar as publicações dos usuários na plataforma Ooppah, 
                        a Ooppah se reserva o direito, a seu exclusivo critério, de tomar medidas repressivas contra o 
                        Usuário que violar direitos de terceiros na plataforma Ooppah. Caso algum Usuário viole direitos 
                        de terceiros, o interessado deve enviar uma denúncia à Ooppah pelo e-mail <a href="mailto:contato@ooppah.com.br">contato@ooppah.com.br</a>. 
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.7.
                    </span>     
                    <span>
                        &emsp;A denúncia deverá conter as seguintes informações: (i) declaração de que o interessado identificou material na plataforma 
                        Ooppah que viole o seu direito (ou direito de um terceiro que autorizou o interessado a agir em seu nome); (ii) descrição do objeto 
                        protegido (vídeo, imagem, obra, marca, fotografia, desenho industrial, dentre outros); (iii) descrição detalhada da maneira na qual
                        o direito foi violado; (iv) localização na plataforma Ooppah onde ocorreu a violação; (v) endereço, número de telefone e e-mail para 
                        que a Ooppah possa entrar em contato com o denunciante; (vi) os detalhes de contato que o interessado gostaria que fossem fornecidos
                        pela Ooppah ao Usuário alegadamente infrator, para que ele possa entrar em contato com o interessado diretamente, a fim de tentar 
                        resolver o problema; (vii) declaração do interessado de que as informações contidas na notificação são verdadeiras e que o interessado 
                        é o titular do direito violado ou está autorizado a agir em nome do titular, nos seguintes termos: "Declaro acreditar que o usuário 
                        denunciado não possui autorização do titular de direitos afetado. Declaro também que as informações aqui contidas são verdadeiras e que 
                        sou o titular de direitos afetado e/ou estou autorizado a mover ação judicial por violação de direitos com relação ao seu uso indevido.”; e (viii) assinatura do interessado.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.8.
                    </span>     
                    <span>
                        &emsp;O interessado em denunciar a violação deve verificar se o conteúdo presente na plataforma Ooppah de fato viola os seus direitos. Em caso de dúvidas, recomenda-se consultar 
                        um advogado. Usar este processo de notificação para fazer uma alegação falsa ou de má-fé pode gerar consequências legais para o notificante e ser considerado uma violação das 
                        condições previstas nestes Termos de Uso.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .6.9.
                    </span>     
                    <span>
                        &emsp;Analisada e confirmada a denúncia, a Ooppah poderá tomar as seguintes medidas: (i) remover ou desabilitar o conteúdo violador da plataforma Ooppah, (ii) notificar o Usuário 
                        infrator para que cesse seus atos ilegais, e (iii) bloquear ou cancelar a conta Ooppah do Usuário infrator. Caso o usuário infrator seja reincidente, isto é, já tenha cometido violações 
                        a direitos de terceiros e já tenha sido notificado por isso, sua conta de acesso à plataforma Ooppah poderá ser cancelada, a exclusivo critério da Ooppah.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .7.
                    </span>     
                    <span>
                        LINKS EXTERNOS
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .7.1.
                    </span>     
                    <span>
                        &emsp;Sempre que autorizado, o Usuário poderá fazer uso de links com serviços externos, 
                        tais quais, mas sem se limitar a, sites e servidores de imagens, áudios, textos, notícias e vídeos.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .7.2.
                    </span>     
                    <span>
                        &emsp;A informação emitida por terceiro é de responsabilidade deste e/ou do Usuário que a divulgar, estando 
                        a Ooppah isenta de qualquer responsabilidade acerca de seu teor, erros, omissões ou ilegalidades nela contida.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .7.3.
                    </span>     
                    <span>
                        &emsp;A estabilidade e manutenção destas mídias é de inteira responsabilidade do provedor ou site onde 
                        elas se encontrem, não existindo qualquer responsabilidade da Ooppah na manutenção ou armazenamento destes arquivos.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .7.4.
                    </span>     
                    <span>
                        &emsp;A boa utilização e respeito às normas de outros sites que possam estar atrelados à utilização da plataforma Ooppah, 
                        direta ou indiretamente, é de inteira responsabilidade do Usuário, incluindo aqueles que se prestam ao armazenamento e compartilhamento de áudio, vídeo e imagens.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .7.5.
                    </span>     
                    <span>
                        &emsp;Uma vez informada pelos detentores de direitos, ou pelos próprios responsáveis dos serviços externos, da violação pelos Usuários dos termos de uso de suas plataformas, 
                        será facultada à Ooppah a retirada imediata dos links de acesso, sem que isso implique em responsabilidade da Ooppah pela manutenção das mídias excluídas.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .8.
                    </span>     
                    <span>
                        &emsp;LIMITAÇÕES DE RESPONSABILIDADE DA OOPPAH
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.1.
                    </span>     
                    <span>
                        &emsp;A Ooppah não é responsável pelos Conteúdos de Usuários veiculados na 
                        plataforma Ooppah, tampouco pelas ações e omissões de seus usuários. A Ooppah não garante a veracidade ou legalidade dos Conteúdos de Usuários.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.2.
                    </span>     
                    <span>
                        &emsp;A Ooppah não garante que a plataforma Ooppah e os Conteúdos de Usuários veiculados estarão disponíveis ininterruptamente, que a plataforma Ooppah estará sempre 
                        livres de erros ou que o servidor onde a plataforma Ooppah esteja hospedada esteja livre de vírus ou erros. O Usuário concorda que a Ooppah não será responsável pelos 
                        prejuízos ou danos causados ao seu computador, dados ou sistemas de telecomunicação, como resultado do uso da plataforma Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.3.
                    </span>     
                    <span>
                        &emsp;Como condição para utilizar a plataforma Ooppah, o Usuário concorda em 
                        indenizar e isentar a Ooppah, seus representantes, diretores, parceiros, sócios, 
                        empregados, agentes e prestadores de serviços, de quaisquer reclamações, processos, 
                        perdas, responsabilidades, danos e despesas, incluindo honorários advocatícios razoáveis 
                        e custas judiciais, decorrentes ou em conexão com: (i) violação por parte do Usuário de 
                        quaisquer das declarações, garantias e obrigações que constam destes Termos de Uso; (ii) 
                        qualquer disputa, conflito, desentendimento, demanda, que o Usuário alegue ter com um ou mais 
                        dos demais usuários; (iii) atos e omissões do Usuário, incluindo condutas praticadas com negligência 
                        ou imperícia; (iv) qualquer uso da plataforma Ooppah sob a senha do Usuário, mesmo que tal uso não tenha 
                        sido autorizado por ele; e (v) qualquer Conteúdo do Usuário postado na plataforma Ooppah sob o seu nome de usuário.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.4.
                    </span>     
                    <span>
                        &emsp;O Usuário se responsabiliza perante a Ooppah por qualquer pleito ou reivindicação judicial ou extrajudicial 
                        fundados na violação de direitos de terceiros, resguardando o direito de regresso da Ooppah contra o Usuário.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.5.
                    </span>     
                    <span>
                        &emsp;Os Conteúdos dos Usuários não atribuem responsabilidade à Ooppah, incluindo aí fatos, opiniões, ofertas, recomendações 
                        ou outra forma de expressão, cuja veracidade e pertinência não poderão ser atribuídas à Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .8.6.
                    </span>     
                    <span>
                        &emsp;A Ooppah não está obrigada a monitorar os Usuários, podendo, de forma aleatória ou mediante provocação, 
                        averiguar a utilização dos Usuários, bem como verificar com terceiros a veracidade das informações e Conteúdos dos Usuários.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .9.
                    </span>     
                    <span>
                        PAGAMENTO
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .9.1.
                    </span>     
                    <span>
                        &emsp;A Ooppah é livre para utilizar plataformas de pagamento externas (e-commerce) 
                        visando o recebimento de pagamentos por meio de cartão, PIX, depósito ou boleto, tal qual, 
                        sem se limitar, os serviços prestados pela empresa moip.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .9.2.
                    </span>     
                    <span>
                        &emsp;Os planos (com prazos e preços) referentes à utilização da plataforma Ooppah são divulgados na própria plataforma. 
                        Uma vez escolhido o plano, a modalidade e efetuado o pagamento, o serviço será prestado pelo prazo escolhido e poderá ser 
                        renovado automaticamente por iguais e sucessivos períodos, até que o usuário manifeste expressamente seu interesse em cancelar o serviço.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .9.3.
                    </span>     
                    <span>
                        &emsp;Quando for escolhido o pagamento por cartão de crédito, o Usuário se sujeitará às regras da 
                        instituição financeira no tocante ao pagamento, cancelamento e tributação.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .9.4.
                    </span>     
                    <span>
                        &emsp;O valor contratado poderá ser cobrado em até 30 dias anteriores ao vencimento do plano contratado, evitando a paralisação dos serviços.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .9.5.
                    </span>     
                    <span>
                        &emsp;A insuficiência de fundos, a não autorização pela operadora de cartão de crédito ou o não pagamento do PIX poderá 
                        resultar no imediato cancelamento da conta do Usuário.
                    </span>
                </TextList>

                <TextList>
                    <span>
                       .9.6. 
                    </span>     
                    <span>
                        &emsp;Para interromper a renovação automática dos serviços da Ooppah, basta nos comunicar sua intenção com 
                        até 30 dias de antecedência do vencimento do plano contratado, através do email <a href="mailto:contato@ooppah.com.br">contato@ooppah.com.br</a> 
                        ou pelo próprio perfil acessando a área de configurações na aba “Conta” &gt; “Cancelar Conta”.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .10.
                    </span>     
                    <span>
                        CANCELAMENTO, SUSPENSÃO E EXCLUSÃO DE INFORMAÇÕES E USUÁRIOS
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .10.1.
                    </span>     
                    <span>
                        &emsp;Sentimos profundamente sempre que um usuário manifesta interesse em 
                        encerrar nossa relação, porém nossa meta sempre foi e será a satisfação e bem 
                        estar do Usuário. Sendo assim, caso deseje encerrar nossa relação, saiba que 
                        receberá nossa total atenção e desde já desejamos sucesso na continuidade de sua carreira.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .10.2.
                    </span>     
                    <span>
                        &emsp;O cancelamento da assinatura deve ser feito pelo próprio usuário 
                        dentro da sua própria conta de gerenciamento da assinatura.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .10.3.
                    </span>     
                    <span>
                        &emsp;Em caso de cancelamento da contratação dos serviços oferecidos pela Ooppah, a 
                        conta/perfil serão retirados da plataforma Ooppah em até 48 (quarenta e oito) horas, 
                        contadas do pedido de cancelamento da conta da plataforma ou da sustação do pagamento dos serviços contratados.
                    </span>
                </TextList>

               

                <TextList>
                    <span>
                        .10.4.
                    </span>     
                    <span>
                        &emsp;A Ooppah poderá, a qualquer tempo, efetuar a suspensão ou a exclusão de cadastro do Usuário que não 
                        respeitar as regras contidas neste Termo.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .10.5.
                    </span>     
                    <span>
                        &emsp; O Usuário que tiver o cadastro/conta suspenso ou cancelado poderá, em até 10 (dez) dias úteis, enviar pedido 
                        fundamentado de desbloqueio ou reativação que será apreciado pela Ooppah em até 10 (dez) dias úteis.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .10.6.
                    </span>     
                    <span>
                        &emsp; A Ooppah poderá, a qualquer tempo, a seu critério e sem aviso prévio, apagar, remover, editar ou bloquear conteúdo 
                        considerado obsceno, ilegal, calunioso, difamatório, em afronta a direito privado ou público ou mesmo os considerados inapropriados ou não relacionados aos objetivos da plataforma Ooppah.
                    </span>
                </TextList>

                <Space />

                <TextList>
                    <span>
                        .11.
                    </span>     
                    <span>
                        DISPOSIÇÕES GERAIS
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.1.
                    </span>     
                    <span>
                        &emsp;Encerrada ou cancelada a contratação da Ooppah cessam as suas obrigações 
                        com o Usuário, salvo as que, por sua natureza ou estipulação expressa, continuem a produzir efeitos.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.2.
                    </span>     
                    <span>
                        &emsp;Todos os meios de acesso à plataforma Ooppah são de inteira responsabilidade do usuário, tais como, sem 
                        se limitar a, o computador e acesso à Internet.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.3.
                    </span>     
                    <span>
                        &emsp;A Ooppah poderá modificar, suspender ou descontinuar a plataforma Ooppah (ou qualquer parte dela) a qualquer momento 
                        por qualquer motivo, a seu exclusivo critério, independentemente de comunicação prévia ao Usuário.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.4.
                    </span>     
                    <span>
                        &emsp;A plataforma Ooppah é apresentada no estado em que se encontra, não sendo cabível reclamação sobre aparência, funcionalidades, 
                        ferramentas, layout ou qualquer outra característica, cabendo ao usuário utilizar a plataforma Ooppah no estado que a recebe no sitio <a href="www.ooppah.com.br" target="_blank">www.ooppah.com.br</a>.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.5.
                    </span>     
                    <span>
                        &emsp;A Ooppah se reserva a todos os direitos que não foram expressamente concedidos aos Usuários.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.6.
                    </span>     
                    <span>
                        &emsp;Todos os direitos garantidos à Ooppah neste Termo de Uso serão repassados aos sucessores da Ooppah em caso de fusão, aquisição ou falência.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .11.7.
                    </span>     
                    <span>
                        &emsp;A Ooppah poderá realizar parcerias comerciais, promoções, campanhas de marketing e publicitárias a seu único critério, associando-se a outras marcas 
                        e empresas, nacionais ou estrangeiras como parte de seu modelo de negócio, sem que isso importe em qualquer remuneração ou desconto aos Usuários.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .11.8.
                    </span>     
                    <span>
                        &emsp;Estes Termos de Uso constituem o inteiro entendimento entre a Ooppah e o Usuário, substituem todos e quaisquer instrumentos, acordos ou contratos, 
                        verbais ou escritos, celebrados entre as partes com relação ao objeto aqui descrito antes da data em que estes Termos de Uso entraram em vigor. Existindo 
                        conflito entre estes Termos de Uso e qualquer outro documento existente entre a Ooppah e o Usuário, prevalecerão as disposições deste instrumento ou a que 
                        for mais benéfica à Ooppah.
                    </span>
                </TextList>

                <TextList>
                    <span>
                        .11.9.
                    </span>     
                    <span>
                        &emsp;O Usuário concorda que a invalidade ou inexequibilidade de qualquer disposição contida neste documento não afetará a validade e exequibilidade de qualquer outro 
                        dispositivo do mesmo e, se qualquer disposição for considerada de qualquer forma inválida ou inexequível, as outras disposições permanecerão em vigor e o presente documento 
                        será interpretado como se as disposições invalidadas ou inexequíveis não existissem.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .11.10.
                    </span>     
                    <span>
                        &emsp;A falha por parte da Ooppah de exigir, a qualquer tempo, o cumprimento de qualquer disposição destes Termos de Uso não afetará de qualquer forma o seu direito de exigir o 
                        cumprimento posterior de tal disposição.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .11.11.
                    </span>     
                    <span>
                        &emsp;Estes Termos de Uso, que compreendem também a Política de Privacidade, podem ser modificados a qualquer momento pela Ooppah. As modificações entrarão em vigor automaticamente na data da 
                        publicação da nova versão na plataforma Ooppah. Ao continuar acessando a plataforma Ooppah após a entrada em vigor das modificações o Usuário automaticamente concorda com os novos Termos de Uso.
                    </span>
                </TextList>

                 <TextList>
                    <span>
                        .11.12.
                    </span>     
                    <span>
                        &emsp;Estes Termos de Uso, que compreendem também a Política de Privacidade, podem ser modificados a qualquer momento pela Ooppah. As modificações entrarão em vigor automaticamente na data da publicação 
                        da nova versão na plataforma Ooppah. Ao continuar acessando a plataforma Ooppah após a entrada em vigor das modificações o Usuário automaticamente concorda com os novos Termos de Uso.
                    </span>
                </TextList>

            </Content>
        </Container>
    );

}

export default AcceptTerms;