import styled from "styled-components";

export const Container = styled.div`
`;


export const Title = styled.h1`
    font-size: 1.3em;
`;

export const Row = styled.div`
    display: flex;
    margin-bottom: 20px;
    position: relative;

    &.smaller-margin-bottom {
        margin-bottom: 5px;
    }

    .third-size {
        width: 30%;
    }

    .marginRight {
        margin-right: 5%;
    }

    .mini-marginRight {
        margin-right: 2%;
    }

    .half-size {
        width: 47%;
    }

    .full-size{
        width: 100%;
    }

    .special-phone {
        width: 40%;
    }

    .special-whats {
        width: 26%;
    }
`;


export const UnchangableInput = styled.div`
    width: 35%;
    margin-right: 2%;
    
    label {
        display:inline-block;
        margin-bottom: 5px;
    }
`;
export const UnchangableValue = styled.div`
    background-color: #eeeeee;
    padding: 6px 10px;

    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    border-color: gray;
    text-align: center;

    cursor: not-allowed;
`;

export const UploadMessage = styled.span`
    color: ${props => props.state ? "green": "red"};
`;

export const ButtonSave = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    outline: none;

    cursor: pointer;
`;

export const GroupSave = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;