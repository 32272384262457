import styled from "styled-components";

export const Container = styled.div`
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    border-color: #dcdcdc;
    border-width: 1px;
    border-style: solid;

    
    background-size: 100% 100%;
    background-position: center;
    background-repeat:no-repeat;

    span {
        font-size: 0.7em;
        color: gray;
    }

    
`;


export const PhotoContainer = styled.div`
    background-color: #e6e6e6;
    
    border-color: #dcdcdc;
    border-width: 1px;
    border-style: solid;
    
    text-align: right;
    
    background-size: 100% 100%;
    background-position: center;
    background-repeat:no-repeat;

    display: flex;
    flex-direction: column;

    span {
        font-size: 0.7em;
        color: gray;
    }

    /* ${props => props.photo && props.photo.should_blur == true ? `
    filter: blur(1px);
    `:``} */

    ${props => props.photo && props.photo.type === '4' ? `
        height: 120px;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        
    ` : ``}

`;

export const DeleteWrapper = styled.div`
    position: absolute;
    align-self: flex-end;
`;

export const FreeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    background-color: rgba(223, 223, 223, 0.7);
    
    button {
      cursor: pointer;
      width: auto;
      background-color: #FF843D;
      border: none;
      color: #131E2E;
      font-size: 0.7em;
      font-weight: bold;
      border-radius: 5px;
      padding: 8px 5px;
      margin: 10px 0;
    }
    
    span {
      text-align: center;
      font-weight: bold;
      color: #ff0000;
      font-size: 0.8em;
      padding-top: 20px;
    }

    button:hover {
      background-color: #121c2c;
      color: #FF843D;
      transition: 0.3s;
      border: 1px solid #FF843D;
    }
`;

export const LinkArea = styled.a`
  background-color: #FF843D;
  border: none;
  color: #131E2E;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 10px;

    &:hover{
      text-decoration: underline;
    }
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Ooppah = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #131E2E;
  font-size: 1.5em;

  ::first-letter{
    color: #FF843D;
  }

`;

export const ExpandedDivToBeClicked = styled.div`
    width: 100%;
    height: 100%;
`;

export const BackButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`;

export const ConfirmButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    margin-left: 5px;

    &:hover {
        background-color: #8c1103;
    }
`;

export const MessageText = styled.p`
    margin-bottom: 10px;
`;