import styled from "styled-components";

export const Container = styled.div`
    background-color: #dae4f0;

    display: flex;
    padding-right: 5px;
    align-items: center;
`;

export const TextContainer = styled.div`
`;

export const Title = styled.h1`
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
`;

export const Paragraph = styled.p`
    font-size: 1em;
    margin: 0;
`;

export const CircleContainer = styled.div`
    width: 100px;
    height: 100px;
`;