import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;

`;

export const Content = styled.div`
    padding: 0px 5px;

`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size : 0.9em;
    padding: 2px 5px;

    text-align: justify;
    text-justify: inter-word;

    a {
        white-space: nowrap;
        padding: 0px 0px;
        margin: 0px 0px;
    }

    span {
        padding-right: 5px;
    }
`;

export const TextList = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3px 5px;
    font-size : 0.9em;

    text-align: justify;
    text-justify: inter-word;

    span{
        padding-right: 5px;
    }
`;
export const Space = styled.div`
    padding-top: 15px;
`;