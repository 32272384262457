import RatingFeatures from "./RatingFeatures";
import { ButtonFilter, Container, Top, Content } from "./style";
import FilterIcon from '../../Assets/icons/B3.svg';
import DropDown from '../../Assets/icons/C5.svg';
import Icon from "../Icon";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import Modal from "../Modal";
import CategoryFilter from "./CategoryFilter";
import useGetTabHeight from '../../Hooks/useGetTabHeight';


const FeatureGallery = () => {

    const {currentUser} = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [featuresCategory, setFeaturesCategory] = useState([]);
    const [categorySelect, setCategorySelect ] = useState([]);
    const [filterRating, setFilterRating] = useState(1);

    const addCategoryItem = (category) => {
        setCategorySelect([...categorySelect, category]);

    } 

    const changeRating = (value) => {
        setFilterRating(value);
    }

    const removeCategoryItem = (category) => {
        const newCategoryList= categorySelect.filter((item) => {
            return category !== item;
        });
        setCategorySelect(newCategoryList);
    }

    const openCategoryModal = () => {

        setIsOpen(true);
    }
   
    const getUserFeatures = () => {
        let rawFeatures = currentUser.profile_features;
        let categoryArray = []
        rawFeatures.map(function(element) {
            if(!categoryArray.includes(element.feature.feature_category.name)){
                categoryArray.push(element.feature.feature_category.name);
            }
        });
        setFeaturesCategory(categoryArray);
    }

    useEffect(() => {
        getUserFeatures();
    }, []);
    
    
    const close = () => {
        setIsOpen(false);
    }

    return (
        <Container >
            <Top>
                <h3>Habilidades</h3>
                <ButtonFilter onClick={openCategoryModal}>
                    <Icon src={FilterIcon} width = "0.6em" height = '0.6em' />
                    <span>
                        Filtro
                    </span>
                    <Icon src={DropDown} width = "0.6em" height = '0.6em' classes="marginLeft" />
                </ButtonFilter>
            </Top>
            <Content>
                <RatingFeatures categoryFilter={categorySelect} rating={filterRating} />
            </Content>
            <Modal isOpen={isOpen} handleClose={close}>
                <CategoryFilter rating = {filterRating} changeRatingfilter = {changeRating} categories={featuresCategory} selectedCategories ={categorySelect} addCategoryItem = {addCategoryItem} removeCategoryItem = {removeCategoryItem}/>
            </Modal>
        </Container>


    );

}


export default FeatureGallery;
