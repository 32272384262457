import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Description = styled.span`
    color: ${props => props.isActive ? "#ed7333": "black"};
    cursor: pointer;
`;