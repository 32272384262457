import { useState } from "react";

const useForm = () => {

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const tempInputs = {...inputs};
        tempInputs[event.target.name] = event.target.value;
        setInputs(tempInputs);
    };

    const handleSubmit = (callback) => (event) =>{
        event.preventDefault();
        callback();
    };

    return [{inputs}, handleChange, handleSubmit];
};

export default useForm;