import styled from "styled-components";


export const Loader = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: ${props => props.dimension};
    height: ${props => props.dimension};
    animation: spin 1s linear infinite;

     @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 

`;