import styled from "styled-components";

export const Container = styled.div`
    background:${props => props.type == 1 ? '#5cb85c': '#e04641'};
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 30px;
	margin-bottom: 15px;
	width: 300px;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;

    
    width: 300px;
	color: #fff;
	padding: 20px 15px 10px 10px;
`;

export const CloseButton = styled.button`
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;

    margin-right: 15px;
    margin-bottom: 5px;
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
`;

export const TextContainer = styled.div``;

export const Title = styled.h1`
    font-weight: 700;
	font-size: 18px;
    line-height: 1em;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    width: 300px;
    height: 18px
`;

export const Message = styled.span`
    display:inline-block;
    margin: 0;
	text-align: justify;
    height: 18px;
    margin-left: -1px;
`;