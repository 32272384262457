import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './Hooks/useAuth';
import Login from './Pages/Login';
import Wizard from './Pages/Wizard';
import PrivateRoute from './Support/PrivateRoute';
import Profile from './Pages/Profile';
import ProfileViewer from './Pages/ProfileViewer';
import ProfileSetup from './Pages/ProfileSetup';
import Notification from './Pages/Notification';
import Exhibition from './Pages/Exhbition';
import { ToastProvider } from './Hooks/useToast';
import Toast from './Components/Toast';
import Layout from './Components/Layout';

function App() {

  return (
    <React.StrictMode>
      <AuthProvider>
        <ToastProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                <Route exact path = "/" element = { <Login /> } />
                <Route path = "/wizard" element = { <PrivateRoute> <Wizard/> </PrivateRoute>  } />
                <Route path = "/profile" element = { <PrivateRoute> <Profile /> </PrivateRoute>}/>
                <Route path = "/profile/edit" element = {<PrivateRoute> <ProfileSetup /> </PrivateRoute>} />
                <Route path = "/artists" element = {<Exhibition />} />
                <Route path = "/teste" element = {<PrivateRoute> <h1> Teste </h1></PrivateRoute>} />
                <Route path = "/:slug" element = {<ProfileViewer />}/>
                <Route path = "/profile/notification" element = {<Notification />} />
                <Route path = "/profile/wizard" element = {<Wizard />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <Toast />
        </ToastProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
