import Icon from "../../Icon";
import PhotoGallery from "../../PhotoGallery";
import QuestionIcon from '../../../Assets/icons/F3.svg';
import { Container, JustifiedText, Title } from "./style";
import { useState } from "react";
import Modal from "../../Modal";
import {photoSize} from "../../../Support/StringResourcers";

const PhotoTab = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
    }

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    return (
        <Container>
            <Title>Fotos de exibição <Icon src = {QuestionIcon} onclick = {handleOpenModal} width = "0.8em" height ="0.8em" paddingLeft="2px"/></Title>
            <PhotoGallery />

            <Modal isOpen={isOpen} handleClose ={handleCloseModal} >
                <JustifiedText>
                    {photoSize}
                </JustifiedText>
            </Modal>
        </Container>
    );
}

export default PhotoTab;