import { Loader} from "./style";

const Spinner = ({dimension}) => {



    return (
        <Loader dimension = {dimension} />

    );
}


export default Spinner;