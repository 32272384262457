import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import FeatureCategory from "./FeatureCategory";
import { Container, SaveButton, ButtonContainer, Guidetext } from "./style";
import axios from 'axios';
import Spinner from "../../Spinner";
import Modal from "../../Modal";
import { useToast } from "../../../Hooks/useToast";

const FeatureTab = () => {
    const {addToast} = useToast();
    const {currentUser, getUser} = useAuth();
    const {getToken} = useAuth();
    const [userFeatures, setUserFeatures] = useState([]);
    const [saveFeatures, setSaveFeatures] = useState([]);
    const [showSpiner, setShowSpiner] = useState(false);
    const limitHabilities = currentUser.access_rule.rule.limit_habilities;

    const makeUserFeatures = () => {

        let featuresArray = currentUser.profile_features;
        let tempFeaturesArray = [];
        for(let i=0; i < featuresArray.length; i++){
            const feature = {
                categoryId: featuresArray[i].feature.feature_category.id,
                id: featuresArray[i].feature.id,
                name: featuresArray[i].feature.name,
                skill: featuresArray[i].skill,  
            };
            tempFeaturesArray.push(feature);
        }
        setUserFeatures(tempFeaturesArray);
        setSaveFeatures(tempFeaturesArray)
    }

    const saveItems = async () => {
        if(saveFeatures.length >= limitHabilities ){
            addToast("Limite atingido", `Você possui um limite de ${limitHabilities} habilidades no seu plano atual. Remova algumas e tente novamente.`, 0);
            return ;
        }
        setShowSpiner(true);
        let url = `${process.env.REACT_APP_BASE_API}/profile-features`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, {profileFeatures: saveFeatures}, {headers:headers});
            addToast("Adição de habilidades", "Habilidade(s) adicionada(s) com sucesso!", 1);
            setShowSpiner(false);
            getUser();
        }catch(error){
            setShowSpiner(false);
            addToast("Erro de sistema", error.response.data.message, 0);
        }
        
    }

    useEffect(() =>{
        makeUserFeatures();
    }, [])


    return (
        <Container >
            <ButtonContainer>
                <Guidetext>
                    <b>Selecione as suas habilidades</b> clicando na lista da esquerda e depois clique novamente na lista da direita para <b>aumentar ou reduzir o seu nível de proficiência.</b>
                </Guidetext>
                <SaveButton onClick={() => {saveItems()}}>Salvar</SaveButton>
            </ButtonContainer>
            <FeatureCategory features={userFeatures} saveArray={saveFeatures} 
                setFeatures={setSaveFeatures} />

            <Modal isOpen={showSpiner}>
                <Spinner dimension={'40px'} />
            </Modal>

        </Container>
    );

}

export default FeatureTab;
