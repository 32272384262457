import CVInfos from "./CVInfos";
import { Container } from "./style";

const CVGallery = () => {

    return(
        <Container>
            <CVInfos />
        </Container>
    );


}


export default CVGallery;
