import { Container, LinkArea, RoundContainer, TextModalFreeInfo } from "./style";
import Icon from '../Icon';
import FaceIcon from '../../Assets/icons/face_icon.svg'
import LinkIcon from "../../Assets/icons/link_icon.svg";
import InstaIcon from "../../Assets/icons/insta_icon.svg";

import FreeFaceIcon from '../../Assets/icons/face_off.svg'
import FreeLinkIcon from "../../Assets/icons/link_off.svg";
import FreeInstaIcon from "../../Assets/icons/insta-off.svg";
import Modal from "../Modal";
import { useState } from "react";
import RoundImage from "../RoundImage";

const SocialMedia = ({profile, isFree, isViewer = false}) => {

    const contact = profile?.contact;
    const showFacebook = contact?.facebook ? true: false;
    const showInstagram = contact?.instagram ? true: false;
    const showLinkedin = contact?.linkedin ? true: false;
    const [showFreeInfo, setShowFreeInfo] = useState(false);
    const homePlansUrl = `${process.env.REACT_APP_HOME_URL}/plans`;

    const handleAgencyClick = () => {
        window.open(profile.agency_url ? profile.agency_url : "" , '_blank');
    }

    const handleFacebookClick = () => {
        window.open(contact.facebook ? contact.facebook: "" , '_blank');
    }
    const handleInstagramClick = () => {
        window.open(contact.instagram ? contact.instagram: "", '_blank');
    }

    const handleLinkedinClick = () => {
        window.open(contact.linkedin ? contact.linkedin: "" , '_blank');
    }

    const handleCloseFreeInfo = () => {
        setShowFreeInfo(false);
    };

    const handleOpenFreeInfo = () => {
        setShowFreeInfo(true);
    }

    return(
        <Container>
            { profile?.agency_id && (!isFree) &&
                <RoundContainer>
                    <RoundImage onClick={handleAgencyClick} src={profile.agencyPhoto} sizes = "2.5em"/> 
                </RoundContainer>
            }
            {   showFacebook && (!isFree) && 
                <Icon src={FaceIcon} onclick={handleFacebookClick}/>
            }

            {   showFacebook && isFree && 
                <Icon src={FreeFaceIcon} onclick={handleOpenFreeInfo} />
            }

            {   showInstagram && (!isFree) && 
                <Icon src={InstaIcon} onclick={handleInstagramClick} paddingLeft={"25px"}/>
            }

            {   showInstagram && (isFree) && 
                <Icon src={FreeInstaIcon } paddingLeft={"25px"} onclick={handleOpenFreeInfo}/>
            }

            {   showLinkedin && (!isFree) &&
                <Icon src={LinkIcon} onclick={handleLinkedinClick} paddingLeft={"25px"}/>
            }

            {   showLinkedin && (isFree) &&
                <Icon src={FreeLinkIcon} paddingLeft={"25px"} onclick={handleOpenFreeInfo}/>
            }
            <Modal isOpen={showFreeInfo} handleClose = {handleCloseFreeInfo}>

                { isViewer &&
                    <TextModalFreeInfo>
                        Poxa!... Que pena, esse perfil é gratuito e não tem direito aos links para redes sociais...<br></br>
                        Mas entre em contato com o artista e peça!
                    </TextModalFreeInfo>
                }

                { (!isViewer) &&
                    <TextModalFreeInfo>
                        Redes sociais integradas ao seu perfil são fundamentais para os contratantes! 
                        &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e ajude os produres a te conheccer melhor!  
                    </TextModalFreeInfo>
                }

            </Modal>

        </Container>
    );

}

export default SocialMedia;