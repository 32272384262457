import styled from "styled-components";

export const Container = styled.div`
    margin-top: 5px;
    color: #8B8B8B;
`;

export const ArtisticName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
        text-align: center;
        margin-right: 5px;
    }
`;

export const AgeAndCity = styled.div`
    font-size: 0.6em;
    display: flex;
    justify-content: center;

    .bold {
        font-weight: bold;
    }

    span {
        margin-right: 3px;
    }
`;