import Icon from "../../../Icon";
import ArrowUp from "../../../../Assets/icons/D5.svg";
import ArrowDown from "../../../../Assets/icons/C5.svg";
import BackIcon from "../../../../Assets/icons/G5.svg";
import TrashIcon from "../../../../Assets/icons/H5.svg";
import ReduceStarIcon from "../../../../Assets/icons/star-hollow.svg";
import RaiseStarIcon from "../../../../Assets/icons/star-full.svg";
import { 
    Container, CategoryName, TopContent, BodyContent, FeatureContentLeft,
    FeatureContentRight, Feature, ChangeFeatures, ChangeCommand,
    RightItems 
} from "./style";
import { useEffect, useState } from "react";
import StarCard from "../StarCard";
import VerticalSlider from "../../../VerticalSlider";

const CategoryIsolated = ({categoryName, features, userFeatures, categoryId , saveFeatures, setArrayFeatures}) => {

    const [featureOpen, setFeatureOpen] = useState(false);
    const [leftFeatures, setLeftFeatures] = useState([]);
    const [rigthFeatures, setRigthFeatures] = useState([]);
    const [seletedItem, setSeletedItem] = useState(-1);

    const makeLeftFeatures = () => {
        const leftFeaturesArray = [];
        const rigthFeaturesArray = [];
        for(let i=0; i < features.length; i++){

            let indexElem = userFeatures.findIndex(element => 
                element.id === features[i].id);
            if(indexElem === -1){
                let featureLeft = {
                    categoryId: categoryId,
                    id: features[i].id,
                    name: features[i].name,
                    skill: 1
                };
                leftFeaturesArray.push(featureLeft);
            }
        }

        for(let i=0; i < userFeatures.length; i++ ){
            let featureRight = {
                    categoryId: categoryId,
                    id: userFeatures[i].id,
                    name: userFeatures[i].name,
                    skill: userFeatures[i].skill
            };
            if(categoryId === userFeatures[i].categoryId){
                rigthFeaturesArray.push(featureRight);
            }
        }
        setRigthFeatures(rigthFeaturesArray);
        setLeftFeatures(leftFeaturesArray);

    }

    const changePositionArray = (id) => {
        const index = leftFeatures.findIndex( element => element.id == id);

        if(index !== -1){
            const feature = leftFeatures[index];
            const newleftFeatureArray = leftFeatures.filter((element) => {
                return (element.id !== id);
            });
            
            setRigthFeatures([...rigthFeatures, feature]);
            setLeftFeatures(newleftFeatureArray);
            setArrayFeatures([...saveFeatures, feature]);
        }
    }

    const selecItem = (id) => {
        const itemPosition = rigthFeatures.findIndex((element) => {
            return (element.id == id);
        });
        setSeletedItem(itemPosition);
    }


    const removeItem = () =>{
        
        const feature = rigthFeatures[seletedItem];
        feature.skill = 1;
        const newRightFeatureArray = rigthFeatures.filter((element) => {
                return (element.id !== feature.id);
        });

        const newSaveFeaturesArray = saveFeatures.filter((element) => {
                return (element.id !== feature.id);
        });

        setLeftFeatures([...leftFeatures, feature]);
        setRigthFeatures(newRightFeatureArray);

        setArrayFeatures(newSaveFeaturesArray);

        setSeletedItem(-1);
    }

    const removeAll = () =>{

        const features = rigthFeatures;
        for(let i=0; i < features.length; i++){
            features[i].skill = 1;
        }
        if(features.length > 1){
            const newsaveArray = saveFeatures.filter((element) => {
                return (element.categoryId !== features[0].categoryId);
            });

            setArrayFeatures(newsaveArray);
        }
        setLeftFeatures([...leftFeatures, ...features]);
        setRigthFeatures([]);
        setSeletedItem(-1);
    }

    const increaseStar = () => {
        if(selecItem !== -1){
            let feature = rigthFeatures[seletedItem];
            let tempRightArray = [...rigthFeatures];
            if(feature.skill < 5){
                for(let i=0; i < tempRightArray.length; i++){
                    if(i == seletedItem ){
                        let tempFeature =  tempRightArray[seletedItem];
                        tempRightArray[seletedItem].skill = tempFeature.skill + 1;
                        break;
                    } 
                }
                const index = saveFeatures.findIndex( element => element.id == feature.id);
                const savedFeature = saveFeatures[index];
                savedFeature.skill = feature.skill;
            }
            setRigthFeatures(tempRightArray);
        }
    }

    const decreaseStar = () => {
        if(selecItem !== -1){
            let feature = rigthFeatures[seletedItem];
            let tempRightArray = [...rigthFeatures];
            if(feature.skill > 1){
                for(let i=0; i < tempRightArray.length; i++){
                    if(i == seletedItem ){
                        let tempFeature =  tempRightArray[seletedItem];
                        tempRightArray[seletedItem].skill = tempFeature.skill - 1;
                        break;
                    } 
                }

                const index = saveFeatures.findIndex( element => element.id == feature.id);
                const savedFeature = saveFeatures[index];
                savedFeature.skill = feature.skill;
            }
            setRigthFeatures(tempRightArray);
        }
    }


    const handleOpenFeatures = () => {
        setFeatureOpen(!featureOpen);
    }

    useEffect(() => {
        makeLeftFeatures();
    }, [])    

    return(
        <Container >
            <VerticalSlider title={categoryName}>
                <BodyContent>
                    <FeatureContentLeft>
                        { 
                            leftFeatures.map((feature, key) =>{
                                return(
                                    <Feature key={key} onClick={() => {changePositionArray(feature.id)}}>
                                        {feature.name}
                                    </Feature>
                                );
                            })
                        }
                    </FeatureContentLeft>

                    <FeatureContentRight>
                        <RightItems>
                            { 
                                rigthFeatures.map((feature, key) =>{
                                        let selected = key == seletedItem ? "selected": "";
                                    return(
                                        <StarCard key={key} onClick={() => {selecItem(feature.id)}}
                                            featureName={feature.name} skill={feature.skill} classe={selected} />
                                    );
                                })
                            
                            }
                        </RightItems>
                        <ChangeFeatures>
                            <ChangeCommand>
                                <Icon src={BackIcon} width = "1.3em" height = '1.3em' onclick={() => {removeAll()}}/>
                            </ChangeCommand>

                            <ChangeCommand>
                                <Icon src={TrashIcon} width = "1.3em" height = '1.3em' onclick={() => {removeItem()}}/>
                            </ChangeCommand>

                            <ChangeCommand>
                                <Icon src={ReduceStarIcon} width = "1.3em" height = '1.3em' onclick={() =>{decreaseStar()}}/>
                            </ChangeCommand>

                            <ChangeCommand>
                                <Icon src={RaiseStarIcon} width = "1.3em" height = '1.3em' onclick={() => {increaseStar()}}/>
                            </ChangeCommand>
                        </ChangeFeatures>
                    </FeatureContentRight>
                </BodyContent>
            </VerticalSlider>
            
            
        </Container>
    );

}

export default CategoryIsolated;