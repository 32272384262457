import { Container, PageDelimiter } from "./style";
import TopBar from '../../Components/TopBar';
import Header from "../../Components/ProfileSetup/Header";
import { objectIsEmpty } from '../../Support/Util';
import { useAuth } from '../../Hooks/useAuth';
import Loading from '../../Components/Loading';
import Tabs from "../../Components/ProfileSetup/Tabs";
import Footer from "../../Components/Footer";
import { useState } from "react";
import ProfileCompleteness from "../../Components/ProfileCompleteness";
import useWindowSize from "../../Hooks/useWindowSize";
import { Navigate } from "react-router-dom";

const ProfileSetup = () => {

    const [windowWidth, windowHeight] = useWindowSize();

    const {currentUser} = useAuth();
    const [isGeralOpen, setIsGeralOpen] = useState(true);

    if(objectIsEmpty(currentUser)){
        return (<Loading />);
    }

    if(currentUser.access_rule.accepted_terms !== 1 || currentUser.access_rule.done_wizard !==1){
        return <Navigate to="/profile/wizard"/>;
    }

    return (
        <Container>
            <TopBar />
            <PageDelimiter>
                <Header isGeralOpen = {isGeralOpen} setIsGeralOpen = {setIsGeralOpen}/>
                {windowWidth >= 700 &&
                    <ProfileCompleteness />
                }
                <Tabs isGeralOpen = {isGeralOpen} setIsGeralOpen = {setIsGeralOpen}/>
            </PageDelimiter>
            <Footer />
        </Container>
    );
}

export default ProfileSetup;