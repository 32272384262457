import { useEffect } from "react";

export function useClickedOutside(ref, callback, setEventListener) {
    useEffect(() => {

        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
            
        }
        if(setEventListener){
            document.addEventListener("mousedown", handleClickOutside);
        }else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setEventListener]);
}
