import styled from "styled-components";



export const Content = styled.div`
    padding: 1px 2px;
    display: flex;
    justify-content: column;
   
    width: 98%;
    border-radius: 5px;
    border-style: none;

    

`;

export const NotificationText = styled.div`
    border-style: solid;
    border-color: #ABBCD5;
    border-radius: 3px;
    border-width: 1px;
    
    padding: 2px 2px;
    width: 98%;

    font-size: 0.7em;

    &.white {
        background-color: #FFFFFF;
    }

    &.gray{
        background-color: #DAE4F0;
    }
`;


export const NotificationTime = styled.div`
    font-size: 0.6em;
    padding-top: 2px;
    padding-bottom: 5px;
`;