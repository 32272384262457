import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
`;

export const VideoContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
`;

export const Title = styled.h1`
	margin: 0;
	font-size: 0.9em;
	text-align: center;
	margin-top: 5px;
    margin-bottom: 5px;
	color: #8B8B8B;
`;

export const DeleteWrapper = styled.div`
	position: absolute;
	right: 0;
`;

export const OrderWrapper = styled.div`
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
`;

export const BackButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`;

export const ConfirmButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    margin-left: 5px;

    &:hover {
        background-color: #8c1103;
    }
`;

export const MessageText = styled.p`
    margin-bottom: 10px;
`;

export const OrderButton = styled.button`
    margin: none;
    border: none;
    background: none;
    background-color: white;
    padding: 0;
    width: 1.6em;
    height: 1.6em;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
`;

export const Arrow = styled.img`
    width: 1.1em;
    height: 1.1em;
`;

export const ThumbArea = styled.img`
    max-width: 320px;
`;