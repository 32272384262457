import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import axios from 'axios';
import Spinner from "../Spinner";
import { Container, Message } from "./style";

const UploadFile  = () => {
    const {getUser} = useAuth();
    const [selectedFile, setSelectedFile ] = useState();
    const {getToken, currentUser, setCurrentUser} = useAuth();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [message, setMessage] = useState("");
    const [isMessageSuccess, setMessageSuccess] = useState(false);
    const [showUpload, setShowUpload] = useState(true);
    
    const onChangeFileHandle = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleSubmission = async (event) => {
        setIsButtonDisabled(true);
        setShowUpload(false);
        event.preventDefault();
        const formData = new FormData();


		formData.append('cv_file', selectedFile);
        let url = `${process.env.REACT_APP_BASE_API}/cv`
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, formData, {headers:headers});
            setCurrentUser({... currentUser, cv_filename: response.data});
            setMessageSuccess(true);
            setMessage("Upload realizado com sucesso!");
            getUser();
            

        }catch(error){
            setMessageSuccess(false);
            setMessage(error.response.data.errors['cv_file'][0]);
        }
        setIsButtonDisabled(false);
	};

    return(     

        <form onSubmit={handleSubmission} >
            {showUpload &&
                <Container>
                    <input type = 'file' name = 'cv' onChange = {onChangeFileHandle} accept="application/pdf"/>
                    <button disabled={isButtonDisabled}>
                        Enviar 
                    </button>
                </Container> 
            }
            {isButtonDisabled && <Spinner dimension={"40px"} />}
            <Message state={isMessageSuccess}> <span>{message} </span></Message>
                  
        </form>
    );
}


export default UploadFile;