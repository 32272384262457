import { Container } from "./style";
import { sleep } from '../../../Support/Util';
import { useEffect, useState } from "react";

const TabContentVisibility = ({children, isActive}) => {

    const [timeFinished, setTimeFinished] = useState(false);
    const [maxHeightToZero, setMaxHeightToZero] = useState(false);

    const setActive = async () => {
        await sleep(250);
        setTimeFinished(true);
    }

    const setMaxHeightToZeroTimer = async () => {
        await sleep(200);
        setMaxHeightToZero(true);
    }

    //TODO: Transição esta causando uma demora na renderização, o outro método
    //é remover a classe de max-height 0. no entanto, fará com que o scroll down
    //seja permanente, independente do tamanho das outras abas.
    useEffect(() => {
        if(isActive){
            setMaxHeightToZero(false);
            setActive();
        }else{
            setTimeFinished(false);
            setMaxHeightToZeroTimer();
        }
       
    }, [isActive]);

    let visibilityClass = isActive && timeFinished ? "visible": "";
    visibilityClass = visibilityClass + (maxHeightToZero ? "transition-correction ": "");


    return (
        <Container className = {visibilityClass}>
            {children}
        </Container>
    );
}

export default TabContentVisibility