import { useAuth } from "../../../../Hooks/useAuth";
import { ButtonGroup, DeleteButton, DownloadButton, Title, UploadButton } from "./style";
import Modal from '../../../Modal';
import UploadFile from "../../../UploadFile";
import { useState } from "react";
import Icon from "../../../Icon";
import ClipIcon from '../../../../Assets/icons/I6.svg';
import DownloadIcon from '../../../../Assets/icons/J6.svg';
import ThrashIcon from '../../../../Assets/icons/H5.svg';
import axios from 'axios';


const CVButtons = () => {
    const { currentUser, getToken, getUser} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);
    let showDownloadButton = false;
    let url = "";
    let cvInfo = "";


    if (currentUser.cv_url) {
        showDownloadButton = true;
        url =  currentUser.cv_url;
    }

    const changeModalState = () => {
        let modalState = showModal;
        setShowModal(!modalState);
    }

    const handleDeletion = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/cv`
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.delete(url, {headers:headers});
            getUser();
            

        }catch(error){
            console.log(error.response);
        }
        
	};

    return(

        <Title>
            <span>Release</span>
            <ButtonGroup>
                <UploadButton onClick={changeModalState}>
                    <span>
                        Anexos
                    </span>
                    <Icon classes="marginLeft" src={ClipIcon} alt={"Ícone de clip"} />
                </UploadButton>
                {showDownloadButton &&
                    <UploadButton onClick={() => {setShowDelModal(true)}}>
                        <span>
                            Del. PDF
                        </span>
                        <Icon classes="marginLeft" src={ThrashIcon} alt={"Ícone de lixo"} />
                    </UploadButton>
                }
                
                {showDownloadButton &&
                    <DownloadButton href={url} target="_blank">
                        <span> Currículo PDF</span>
                        <Icon classes="marginLeft" src={DownloadIcon} alt={"Ícone de download"} />
                    </DownloadButton>
                }

            </ButtonGroup>


            <Modal isOpen={showModal} handleClose={changeModalState}>

                <UploadFile />

            </Modal>

            <Modal isOpen={showDelModal} handleClose={() => {setShowDelModal(false)}}>
                <p>Certeza que deseja deletar o seu arquivo ?</p>
                <DeleteButton onClick = {() => {handleDeletion(); setShowDelModal(false)}}>
                    Deletar PDF
                </DeleteButton>
            </Modal>
        </Title>
    );

};


export default CVButtons;