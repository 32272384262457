import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const VideoContainer = styled.div`   
    position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
    }
`;

export const VideoTitle = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    color: #8B8B8B;
    .text{
        text-align: center;
        font-weight: bold;
    }
`;

export const VideoThumb = styled.img`
    width: 100%;
    &.invisible {
        display: none;
    }

`;
