import { ButtonSave, Container, GroupSave, Row, Title, UnchangableInput, UnchangableValue, UploadMessage } from "./style";
import Dropdown from "../../../Dropdown";
import InputBirthday from "../../../InputBirthday";
import InputV2 from "../../../InputV2";
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import axios from 'axios';
import DisketIcon from '../../../../Assets/icons/E3.svg';

import {
    getSexOptions,
    translateSexOption,
    transformDate,
    translateOoppahJob,
    OoppahJobOptions,
    attributeTranslate
} from '../../../../Support/Util'
import { nameVariationDescription, slugDescription } from "../../../../Support/StringResourcers";

import { useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import Icon from "../../../Icon";
import { useToast } from "../../../../Hooks/useToast";

const GeneralTab = () =>{

    const {currentUser, getToken, getUser} = useAuth();
    const {addToast} = useToast();
    const [sex, setSex] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [name, setName] =  useState('');
    const [lastname, setLastname] = useState("");
    const [artisticName, setArtisticName] = useState("");
    const [nameVariation, setNameVariation] = useState("");
    const [ooppahJob, setOoppahJob] = useState("");
    const [drt, setDrt] = useState("");
    const [drtUF, setDrtUF] = useState("");
    const [slug, setSlug] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");

    useState(() => {
        setSex(currentUser.characteristic.sex ? currentUser.characteristic.sex: "");
        setBirthdate(currentUser.characteristic.birth_date ? transformDate(currentUser.characteristic.birth_date): "");
        setName(currentUser.name ? currentUser.name: "");
        setNameVariation(currentUser.name_variation ? currentUser.name_variation : "" );
        setOoppahJob(currentUser.ooppah_job ? currentUser.ooppah_job : 0);
        setLastname(currentUser.last_name ? currentUser.last_name: "");
        setArtisticName(currentUser.artistic_name ? currentUser.artistic_name: "");
        setDrt(currentUser.drt ? currentUser.drt : "");
        setDrtUF(currentUser.drt_uf ? currentUser.drt_uf : "");
        setSlug(currentUser.slug);
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(true);
        setIsLoading(true);
        const data = {
            sex: sex,
            birth_date: birthdate,
            name: name,
            last_name: lastname,
            artistic_name: artisticName,
            name_variation: nameVariation,
            ooppah_job: ooppahJob,
            drt: drt,
            drt_uf: drtUF,
            slug: slug,
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/desktop/general`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers})
            getUser();
            addToast("Atualização de dados", "Dados atualizados com sucesso", 1);
            setIsLoading(false);
            setIsModalOpen(false);
        }catch(error){
            let outputMessage = "";
            setServerMessage("Problema ao tentar salvar os dados, tente novamente mais tarde")
            setIsLoading(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;
                    if(typeof outputMessage === 'string'){
                        outputMessage = attributeTranslate(outputMessage);
                    } else{
                        outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                    }
                   
                }

                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                            outputMessage = attributeTranslate(outputMessage);
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
            setIsModalOpen(false);
            addToast("Erro de sistema", outputMessage, 0);
        }
    }

    const sexOptions = getSexOptions();
    const ooppahOptions = OoppahJobOptions();

    return(
        <Container>
            <Title>
                Dados gerais
            </Title>
            <form onSubmit = {handleFormSubmit}>
                <Row>
                    <Dropdown
                        name = {"sex"}
                        label = {"Sexo"}
                        title = {"Sexo"}
                        options = {sexOptions}
                        valueDescription = {translateSexOption(sex)}
                        onChange = {(event) => {setSex(event.target.value)}}
                        className = {"marginRight"}
                    />

                    <InputBirthday
                        name = {'birth-date'}
                        label = {'Data de nascimento'}
                        placeholder = {'dd/mm/yyyy'}
                        onChange = {(event) => {setBirthdate(event.target.value)}}
                        value = {birthdate}
                    />
                </Row>
                
                <Row>
                    <InputV2
                        label = {"Nome"}
                        name = {'name'}
                        value = {name}
                        onChange = {(event) => {setName(event.target.value)}}
                        className = {'half-size marginRight'}
                    />
                    <InputV2
                        label = {"Sobrenome"}
                        name = {'lastname'}
                        value = {lastname}
                        onChange = {(event) => {setLastname(event.target.value)}}
                        className = {'half-size'}
                    />
                </Row>
                
                <Row>
                    <InputV2
                        label = {"Nome Artístico"}
                        name = {'artistic_name'}
                        value = {artisticName}
                        onChange = {(event) => {setArtisticName(event.target.value)}}
                        className = {"full-size"}
                    />
                </Row>

                <Row>
                    <InputV2
                        label = {"Variações de grafia"}
                        name = {'name_variation'}
                        value = {nameVariation}
                        onChange = {(event) => {setNameVariation(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {'Evellyn,Ewellin,Evileen'}
                        modalDescription = {nameVariationDescription}
                        maxLength = {40}
                    />
                </Row>

                <Row>
                    <UnchangableInput>
                        <label>CPF</label>
                        <UnchangableValue>
                            {currentUser.cpf}
                        </UnchangableValue>
                    </UnchangableInput>

                    <InputV2
                        label = {"DRT"}
                        name = {"drt"}
                        value = {drt}
                        onChange = {(event) => {setDrt(event.target.value)}}
                        className = {'third-size mini-marginRight'}
                    />
                    <InputV2
                        label = {"DRT UF"}
                        name = {"drt_uf"}
                        value = {drtUF}
                        onChange = {(event) => {setDrtUF(event.target.value)}}
                        className = {'third-size'}
                    />
                </Row>

                <Row>
                    <Dropdown
                        name = {"ooppah_job"}
                        label = {"Ja trabalhou pelo Ooppah?"}
                        title = {"Trabalho pelo Ooppah"}
                        options = {ooppahOptions}
                        valueDescription = {translateOoppahJob(ooppahJob)}
                        onChange = {(event) => {setOoppahJob(parseInt(event.target.value))}}
                        
                    />
                </Row>

                <Row>
                    <InputV2
                        label = {"Link Ooppah"}
                        name = {"slug"}
                        value = {slug}
                        onChange = {(event) => {setSlug(event.target.value)}}
                        modalDescription = {slugDescription}
                    />
                </Row>
                
                <GroupSave>
                    <ButtonSave>
                        <Icon src = {DisketIcon} width = "3em" height = "3em"/>
                        Salvar
                    </ButtonSave>
                </GroupSave>
                
            </form>

            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
            </Modal>
            
        </Container>
    );
}

export default GeneralTab;