import { Content, InputRadio, NotificationSize } from "./style";
import NotificationItemOld from "../../TopBar/NotificationBar/NotificationItem";

const NotificationItem = ({notification, changeSelected, index}) => {

    const is_read = notification.is_read == 1 ? true: false;
    const checkedInfo = is_read ? false: notification.selected;
    

    return(
        <Content>
            <InputRadio type="checkbox" checked={checkedInfo} 
                onChange={()=>{changeSelected(index)}} disabled={is_read}
            />
            
            <NotificationSize>
                <NotificationItemOld notification={notification} />
            </NotificationSize>
        </Content>
    );

}

export default NotificationItem;