import Photo from "../Photo";
import { Container } from "./style";

const PhotoIsolated = ({photos, deleteImage}) => {

    return (
        <Container>
            {photos.map((photo, key) => {
                return <Photo key = {key} photo = {photo} deleteImage={deleteImage}/>
            })}
           
        </Container>
    );
}

export default PhotoIsolated;