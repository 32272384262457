import styled from 'styled-components';

export const Container = styled.div`

`;

export const Title = styled.h1`
    font-size: 1.3em;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BackButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`;

export const ConfirmButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    margin-left: 5px;

    &:hover {
        background-color: #8c1103;
    }
`;

export const MessageText = styled.p`
    margin-bottom: 10px;
`;

export const AgencyInfo = styled.p`
    text-align: center;
`;

export const AgencyDisplay = styled.div`
    margin: 20px;
    padding: 10px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: black;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TitleAgency = styled.h1`
    margin: 0;
    margin-top: 10px;
    font-size: 1em;
    color: #4d4d4d;
`;

export const ButtonUnbind = styled.button`
    background: none;
    border-radius: 5px;
    border-color: red;
    color:red;
    padding: 5px 7px;

    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: .6;
    }

`;

export const Button = styled.button`
    background: none;
    border-color: blue;
    color: blue;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;

    &:disabled {
        cursor: not-allowed;
        opacity: .6;
    }

`;