import Footer from "../../Components/Footer";
import Loading from "../../Components/Loading";
import { objectIsEmpty } from '../../Support/Util';
import NotificationArea  from "../../Components/NotificationArea";
import TopBar from "../../Components/TopBar";
import { useAuth } from "../../Hooks/useAuth";
import { PageDelimiter } from "../Profile/style";
import { Container } from "./style";
import { Navigate } from "react-router-dom";

const Notification = () => {

    const { currentUser } = useAuth();
    
    if(objectIsEmpty(currentUser)){
        return (<Loading />);
    }

    if(currentUser.access_rule.accepted_terms !== 1 || currentUser.access_rule.done_wizard !==1){
        return <Navigate to="/profile/wizard"/>;
    }

    return(
        <Container>
            <TopBar />
            <PageDelimiter> 
                <NotificationArea/> 
            </PageDelimiter>
            <Footer />

        </Container>
    );

}

export default Notification;