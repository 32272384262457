import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    display:flex;
    flex-direction: column;
    align-items: center;
    
    @media(min-width: 400px){
        display:flex;
        flex-direction: column;
        align-items: center;

    }
`;

export const TabsList = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    width:100%;

    @media(min-width: 400px) {
        width: 400px;
        margin-top: 15px;
    }
    
`;

export const TabsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    width: 100%;

    @media(min-width: 400px){
        margin-top: 15px;
    }

    @media(min-width: 600px) {
        width: 500px;
        margin-top: 15px;
    }
`;