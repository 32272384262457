import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-color: #bfbfbf;
    border-radius: 5px;
    margin-top: 20px;
    height: fit-content;
    button:last-child{
        border-bottom: none;
    }
`;

export const TabButton = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    outline: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #bfbfbf;
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;
    color: #575757;
    ${props => props.isActive ? `
        border-left-style: solid;
        border-left-width: 3px;
        border-left-color: black;
    `: ``}

`;