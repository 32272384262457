import { useRef } from "react";
import { Container, Input, Label } from "./style";

const InputBirthday = ({label = null, name, placeholder, onChange, value}) => {

    const inputRef  =  useRef();

    const handleChange = () => {
        let birthdayValue = inputRef.current.value;
        birthdayValue = birthdayValue.replace(/\D/g, '');
        birthdayValue = birthdayValue.match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
        inputRef.current.value = !birthdayValue[2] ? birthdayValue[1] : `${birthdayValue[1]}/${birthdayValue[2]}${`${birthdayValue[3] ? `/${birthdayValue[3]}` : ''}`}${`${birthdayValue[4] ? `/${birthdayValue[4]}` : ''}`}`;
        onChange({target:{value: inputRef.current.value}})
    }

    return (
        <Container>
            {
                label &&
                <Label>{label}</Label>
            }
            <Input
                ref = {inputRef}
                name = {name}
                placeholder = {placeholder}
                onChange = {handleChange}
                value = {value}
            />
        </Container>
    );
}

export default InputBirthday