import { useRef, useState } from "react";
import { Container, DropdownContent, DropdownLink, Text } from "./style";
import { useClickedOutside } from "../../Hooks/useClickedOutside";

const DropdownLogin = () => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    const close = () => {
        setIsOpen(false);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    return (
        <Container ref={dropdownRef}>
            <Text classes = 'marginLeft-sm' color = "#BAC7D6" fontSize = "0.8em" onClick={clickHandle}>
                Cadastrar
            </Text>
            {isOpen &&
            <DropdownContent>
                <DropdownLink href = {process.env.REACT_APP_HOME_URL + '/#packages'}  className="marginBottom-md marginLeft-sm">
                    <span className='text-size'>Artista</span>
                </DropdownLink>
                <DropdownLink href = {process.env.REACT_APP_PRODUCER_BASE + '/producer/register'} className="marginBottom-md marginLeft-sm">
                    <span className='text-size'> Produtor </span>
                </DropdownLink>
                <DropdownLink href = "https://wa.me/5521994765872?%20text=Oi%20Ooppah!%20Gostaria%20de%20cadastrar%20minha%20agencia%20na%20plataforma.%20Como%20fa%C3%A7o?" className="marginBottom-md marginLeft-sm">
                    <span className='text-size'> Agência </span>
                </DropdownLink>
            </DropdownContent>
            }
        </Container>
    );
}

export default DropdownLogin;