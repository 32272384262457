import { Container, Content, NameFeature } from "./style.js";
import BlueStarDefault from '../../Assets/icons/A5A.svg';
import WhiteStarDefault from '../../Assets/icons/B5A.svg';
import BlueStar from '../../Assets/icons/A5.svg';
import WhiteStar from '../../Assets/icons/B5.svg';
import Icon from "../Icon";


const StarCard = ({skill, starWidth = "0.4em", starHeight = "0.4em", defaultStar = true }) => {
    const starBlueStyle = defaultStar ? BlueStarDefault: BlueStar;
    const starWhiteStyle = defaultStar ? WhiteStarDefault:  WhiteStar;

    return (
        <Container>
                {
                    [1, 2, 3, 4, 5].map((index, key) => {
                        return (
                            index <= skill ? <Icon width = {starWidth} height = {starHeight} key={key} src={starBlueStyle}/>  : <Icon width = {starWidth} height = {starHeight} key={key} src={starWhiteStyle}/>
                        )
                    })   
                }
        </Container>

    );
}


export default StarCard;