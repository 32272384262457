import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 10px;

    @media(min-width: 1080px){
        width: 95%;
    }

`;

export const HeaderBar = styled.div`
    display: flex;
    justify-content: center;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-width: 1px;
    border-color: #B3B3B3;
    padding: 5px 0px;

    @media(max-width: 700px){
        width: 100%;
        margin: auto;
    }
`;

export const LeftBar = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    border-width: 1px;
    border-color: #B3B3B3;
    border-style: solid;

    @media(max-width: 341px ){
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: auto;

    }

    @media(max-width: 700px){
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: auto;

    }
`;

export const BodyBar = styled.div`
    display: flex;
    width: 100%;
    @media(max-width: 700px){
        flex-direction: column;
    }

    @media(min-width: 700px){
        align-items: start;
    }

`;

export const RightBar = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;

    @media(max-width: 700px){
        width: 100%;
    }

    @media(min-width: 700px){
        width: 100%;
    }

`;

export const Button = styled.div`
    border: none;
    background: none;
    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const ButtonArea = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2px;
`;

export const SmallContent = styled.div`
    padding: 5px 0px;
`;

export const IconArea = styled.div`
    display: flex;
    width: 25%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    .text-icons{
        display: none;
    }

    @media(min-width: 700px){
        width: 100%;
        justify-content: flex-start;
        border-bottom-style: solid;
        border-width: 1px;
        border-color: #B3B3B3;

        .text-icons{
            display: block;
            text-align: center;
            padding: 3px 0px;
            font-size: 0.9em;
        }

        
    }
`;

export const ModalMessage = styled.span`
    
`;

export const ErrorMessage = styled.span`
    white-space: pre-wrap;
    color: ${props => props.state ? "green": "red"};

    
`;
