import styled from "styled-components";

export const Container = styled.div`
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    border-color: #dcdcdc;
    border-width: 1px;
    border-style: solid;

    
    background-size: 100% 100%;
    background-position: center;
    background-repeat:no-repeat;

    span {
        font-size: 0.7em;
        color: gray;
    }

`;


export const PhotoContainer = styled.div`
    background-color: #e6e6e6;
    
    border-color: #dcdcdc;
    border-width: 1px;
    border-style: solid;
    
    text-align: right;
    
    background-size: 100% 100%;
    background-position: center;
    background-repeat:no-repeat;

    span {
        font-size: 0.7em;
        color: gray;
    }

    ${props => props.photo.type === '4' ? `
        height: 120px;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        
    ` : ``}
`;

export const ExpandedDivToBeClicked = styled.div`
    width: 100%;
    height: 100%;
`;

