import { Container, TabsContent, TabsList } from "./style";

import PlayIconInactive from '../../Assets/icons/E2.svg';
import PhotoIconInactive from '../../Assets/icons/F2.svg';
import StarIconInactive from '../../Assets/icons/G2.svg';
import PersonIconInactive from '../../Assets/icons/H2.svg';
import DocumentIconInactive from '../../Assets/icons/I2.svg';

import PlayIconActive from '../../Assets/icons/E2A.svg';
import PhotoIconActive from '../../Assets/icons/F2A.svg';
import StarIconActive from '../../Assets/icons/G2A.svg';
import PersonIconActive from '../../Assets/icons/H2A.svg';
import DocumentIconActive from '../../Assets/icons/I2A.svg';

import Tab from "../Tab";
import { useState } from "react";
import TabContentVisibility from "../TabContentVisibility";
import PhotoGallery from "../PhotoGallery";
import VideoGallery from "../VideoGallery";
import FeaturesGallery from "../FeaturesGallery";
import CVGallery from "../CVGallery";
import CharacteristicsGallery from "../CharacteristicsGallery";

const Tabs = () => {

    const tabs = [
        {
            name: "Photos",
            iconIncative: PhotoIconInactive,
            iconActive: PhotoIconActive,
            iconAlt: "Photo icon"
        },
        {
            name: "Videos",
            iconIncative: PlayIconInactive,
            iconActive: PlayIconActive,
            iconAlt: "play icon"
        },
        {
            name: "Features",
            iconIncative: StarIconInactive,
            iconActive: StarIconActive,
            iconAlt: "Star icon"
        },
        {
            name: "Characteristics",
            iconIncative: PersonIconInactive,
            iconActive: PersonIconActive,
            iconAlt: "Person icon"
        },
        {
            name: "CV",
            iconIncative: DocumentIconInactive,
            iconActive: DocumentIconActive,
            iconAlt: "Document icon"
        },

    ];



    const [activeTab, setActiveTab] = useState(tabs[0].name);

    const clickHandle = (activeTab) => {
        setActiveTab(activeTab);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    return (
        <Container>
            <TabsList>
                {tabs.map((tab) => {
                    return <Tab key = {tab.name} 
                        clickHandle = {clickHandle} 
                        tabName = {tab.name} 
                        isActive = {activeTab === tab.name} 
                        activeIcon = {tab.iconActive}
                        inactiveIcon = {tab.iconIncative} 
                        alt = {tab.iconAlt} 
                    />;
                })}
            </TabsList>
            <TabsContent>
                <TabContentVisibility isActive = {tabs[0].name === activeTab}>
                    <PhotoGallery />
                </TabContentVisibility>
                <TabContentVisibility isActive = {tabs[1].name === activeTab}>
                    <VideoGallery />
                </TabContentVisibility>
                <TabContentVisibility isActive = {tabs[2].name === activeTab}>
                    <FeaturesGallery />
                </TabContentVisibility>
                <TabContentVisibility isActive = {tabs[3].name === activeTab}>
                    <CharacteristicsGallery />             
                </TabContentVisibility>
                <TabContentVisibility isActive = {tabs[4].name === activeTab}>
                    <CVGallery />
                </TabContentVisibility>
               
            </TabsContent>
        </Container>
    );
};

export default Tabs;