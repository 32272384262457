import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #DAE4F0;
`;

export const Content = styled.div`
    width: 100%;
    justify-content: center;
    padding: 5px 2px;

    @media(min-width: 1080px){
        padding: 5px 10px;
    }
    
`;

export const TextArea = styled.div`


`;

export const Row = styled.div`
    display: flex;
    margin-bottom: 20px;
    position: relative;

    &.smaller-margin-bottom {
        margin-bottom: 5px;
    }

    .third-size {
        width: 30%;
    }

    .marginRight {
        margin-right: 5%;
    }

    .mini-marginRight {
        margin-right: 2%;
    }

    .half-size {
        width: 47%;
    }

    .full-size{
        width: 100%;
    }

    .special-phone {
        width: 40%;
    }

    .special-whats {
        width: 26%;
    }
`;

export const PhoneGroup = styled.div`
    border-radius: 5px;
    border-style: solid;
    border-color: gray;
    border-width: 1px;

    padding: 8px;
    margin-bottom: 10px;
`;

export const Title = styled.h1`
    
    margin: 0;
    font-size: 1em;
    color: #4d4d4d;
`;

export const DeletePhoneButton = styled.button`
    border: none;
    background: none;
    position: absolute;

    bottom: 0;
    right: 5%;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    
    height: 30px;

    float: bottom;
`;


export const AddPhoneButton = styled.button`

    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 20px;
    cursor: pointer;
`;

export const Info = styled.p`
    text-align: center;
`;

export const InputArea = styled.div``;

export const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Button = styled.button`
    margin: auto;
    border: none;
    background: none;
    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const ErrorMessage = styled.span`
    white-space: pre-wrap;
    color: ${props => props.state ? "green": "red"};
`;

export const SuccessMessage = styled.span`
`;

