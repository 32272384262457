
import { Error } from './style.js';

const WarningMessage = (props) => {

    if(!props.show){
        return null;
    }

    return (
        <Error>
            {props.children}
        </Error>
    );
}

export default WarningMessage