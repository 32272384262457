import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    position:relative;
    min-height: 100vh;
`;

export const PageDelimiter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    padding-bottom: 145px;

    @media(max-width: 340px) {
        display: block;
    }

    @media(min-width: 1080px){
        width: 1080px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
`;