import { Container } from "./style";
import Photo from "../Photo";

const PhotoGroup = ({type = 1, photos, openImageCropper, baseOrder, deleteImage}) => {

    const EmptyPhotoInfo = [
        ['Corpo inteiro', 'full-body', '1'],
        ['Foto de painel', 'painel', '2'],
        ['3x4', 'three-by-four-1', '3'],
        ['3x4', 'three-by-four-2', '3']
    ]

    const initGroup = () => {
        const photosJSX = [];
        for(let i = 0; i < 4; i++){
            if(photos[i]){
                photosJSX.push(<Photo key = {i} order = {(baseOrder * 4) + i} photo = {photos[i]} className = {EmptyPhotoInfo[i][1]} deleteImage = {deleteImage}  />)
            }else {
                photosJSX.push(<Photo key = {i} order = {(baseOrder * 4) + i} label = {EmptyPhotoInfo[i][0]} className = {EmptyPhotoInfo[i][1]} type = {EmptyPhotoInfo[i][2]} openImageCropper = {openImageCropper}/>)
            }
        }

        return photosJSX;
    }

    return (
        <Container type = {type} >
            {initGroup()}
        </Container>
    );
}

export default PhotoGroup;