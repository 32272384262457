import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../Hooks/useAuth';

const PrivateRoute = ({children}) => {
    
    const { getToken } = useAuth();
    const location = useLocation();

    try{
        return getToken() ? children : <Navigate to = {'/'} state = {{from: location}} replace/>;
    }catch(error){
        return <Navigate to = "/" />
    }
    
}

export default PrivateRoute;