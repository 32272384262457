import { useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import InputV2 from "../../InputV2";
import axios from 'axios';
import { Button, ButtonArea, Container, Content, ErrorMessage, 
    Row, Text, Title } from "./styled";
import Modal from "../../Modal";

const SlugTab = ({onChange, slug, setSlug, setDoneStep}) => {

    const {getToken} = useAuth();
    const [checkOkay, setCheckOkay] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showErrorMessage, setErrorSuccessMessage] = useState(false);

    const nextState = 4;

    const checkData = async () => {
        if(slug.length < 3){
            setServerMessage("Seu link deve possuir uma quantidade maior de caracteres.");
            setErrorSuccessMessage(true);
            setIsModalOpen(true);

            return ;
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/slug?slug=${slug}`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(url, {headers:headers});
            if(response.data){
                setCheckOkay(true);
                setDoneStep(true);
                onChange(nextState);
            } else{
                setServerMessage("O link inserido já é utilizado! Tente novamente.");
            }
            
        }catch(error){
            let outputMessage = "";
            setDoneStep(false);
            setIsModalOpen(true);
            setErrorSuccessMessage(true);

            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;

                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }
        

    return (
        <Container>
            <Content>
                <Title>
                    <p>
                        O seu link Ooppah é a sua maior ferramenta para divulgação do seu perfil Ooppah.
                    </p>
                </Title>

                <Text>
                    <span>
                        Esse link é exclusivo da plataforma, com ele você poderá divulgar facilmente o seu perfil para qualquer 
                        um na internet, como amigos, agências, produtores, redes sociais e etc.
                    </span>
                </Text>

                <Text>
                    <span>
                        O mais legal é que o seu perfil Ooppah não se restringe a apenas 
                        oportunidades no Ooppah. Você pode mostrar o seu perfil pra quem 
                        você quiser, então não tenha medo de divulgar o seu link exclusivo por aí.
                    </span>

                </Text>

                <Row>
                    <InputV2
                        label = {"Link Ooppah:"}
                        name = {'slug'}
                        value = {slug}
                        onChange = {(event) => {setSlug(event.target.value)}}
                        className = {"full-size"}
                    />
                </Row>

                <Text>
                    <span>
                        O que seu link Ooppah não deve conter?
                    </span>

                    <ul>
                        <li>
                            Espaços em branco.
                        </li>
                        <li>
                            Caracteres especiais (@#$%&*\/).
                        </li>
                        
                    </ul>
                    <p>
                        Exemplos: arthursilva, allanpereira, ricardo_passos.
                    </p>
                </Text>

                {!checkOkay &&
                    <ButtonArea>
                        <Button onClick={() => {onChange(nextState - 2)}}>
                            Voltar
                        </Button>

                        <Button onClick={checkData}>
                            Verificar disponibilidade do link
                        </Button>
                    </ButtonArea>
                }
                

                <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>

                    {showErrorMessage &&
                        <ErrorMessage state = {!showErrorMessage}>{serverMessage}</ErrorMessage>
                    }

                </Modal>
            </Content>

        </Container>

    );

};

export default SlugTab;