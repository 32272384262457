import { useEffect, useState } from "react";
import { Content, NotificationText, NotificationTime } from "./style";

const NotificationItem = ({notification}) => {
    const isRead = notification.is_read == 0 ? "white": "gray";
    const [formatedtDate, setformatedDate] = useState("");

    const formatDate = () => {
        let date = new Date(notification.created_at);
        setformatedDate(date.getDate() +"/"+  date.getMonth() +"/"+ date.getFullYear()+ " "+date.getHours()+":"+ date.getMinutes());
    }

    useEffect(()=>{
        formatDate();
    })
    return(
        
        <Content >
            <NotificationText className = {isRead}>
                {notification.message}
                <NotificationTime>
                    Recebido em: {formatedtDate}
                </NotificationTime>

            </NotificationText>
        </Content>
       
    );
}

export default NotificationItem;