import { AboutTextArea, UploadMessage, AddPhoneButton, Container, DeleteManager, DeletePhoneButton, GroupButton, Info, ManagerButton, ManagerGroup, ManagerInfo, PhoneGroup, Row, TextAreaGroup, Title, UnchangableInput, UnchangableValue } from "./style";
import { useAuth } from '../../../Hooks/useAuth';
import VerticalSlider from '../../VerticalSlider';
import Dropdown from '../../Dropdown';
import { nameVariationDescription, slugDescription, contactVisibility } from "../../../Support/StringResourcers";
import { getSexOptions, transformDate, translateSexOption, translateVisibilityOptions, phoneTypeOptions, translatePhoneType, phoneIsWhatsappOptions, translateIsWhatsapp, prepareStateDropdown, prepareDistrictsDropdown, phoneVisibilityPermissionOptions, OoppahJobOptions, translateOoppahJob, attributeTranslate } from "../../../Support/Util";
import { useEffect, useState } from "react";
import InputBirthday from "../../InputBirthday";
import InputV2 from "../../InputV2";
import axios from 'axios';
import { SaveButton } from "../CharacteristicTab/CharacteristicForm/style";
import { ButtonContainer } from "../FeatureTab/style";
import Modal from '../../Modal';
import Spinner from '../../Spinner';
import ProfileCompleteness from "../../ProfileCompleteness";
import AgencyDisplay from "../../AgencyDisplay";

const GeneralTab = ({setActiveTab, clickHandle}) => {

    const {currentUser, getToken, getUser, isFree} = useAuth();
    const [sex, setSex] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [name, setName] =  useState('');
    const [lastname, setLastname] = useState("");
    const [artisticName, setArtisticName] = useState("");
    const [nameVariation, setNameVariation] = useState("");
    const [ooppahJob, setOoppahJob] = useState("");
    const [drt, setDrt] = useState("");
    const [drtUF, setDrtUF] = useState("");
    const [slug, setSlug] = useState("");
    const [about, setAbout] = useState("");
    const [phones, setPhones] = useState([]);
    const [contactEmail, setContactEmail] = useState("");

    const [addManager, setAddmanager] = useState(false);
    const [managerPhone, setManagerPhone] = useState("");
    const [managerIsWhatsapp, setManagerIsWhatsapp] = useState("");
    const [managerPhoneType, setManagerPhoneType] = useState("");
    const [managerName, setManagerName] = useState("");
    const [managerEmail, setManagerEmail] = useState("");

    const [states, setStates] = useState([]);
    const [state, setState] = useState({});

    const [districts, setDistricts] = useState([]);
    const [filteredDistrict, setFilteredDistrict] = useState([]);
    const [district, setDistrict] = useState({});

    const [linkedin, setLinkedin] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");

    const sexOptions = getSexOptions();
    const ooppahOptions = OoppahJobOptions();
    const phoneTypes = phoneTypeOptions();
    const whatsappOptions = phoneIsWhatsappOptions();
    const phoneVisibility = phoneVisibilityPermissionOptions(isFree);
    const getStates = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/state`;

        try {
            const response = await axios.get(url)
            setStates(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }
    }

    const getDistrict = async (id) => {
        let url = `${process.env.REACT_APP_BASE_API}/district/${id}}`;

        try {
            const response = await axios.get(url)
            setDistricts(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }
    }

    const updatePhonesType = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].type = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesWhatsapp = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].is_whatsapp = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesVisibility = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].visibility_permission = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesNumber = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].number = event.target.value;
        setPhones(tempPhones);
    }

    const addPhone = () => {
        if(phones.length >= 3){
            return;
        }
        const newPhone = {type: "", is_whatsapp:"", number:""}
        setPhones([...phones, newPhone]);
    }

    const deletePhone = (phone) => {
        const updatedPhones = phones.filter( (item) => {
            return phone !== item;
        }); 
        setPhones(updatedPhones);
    }

    const deleteManager = () => {
        setAddmanager(false)
        setManagerEmail("");
        setManagerPhone("")
        setManagerIsWhatsapp("");
        setManagerPhoneType("");
        setManagerName("");
    }

    const handleFormSubmit = async () => {
        setIsModalOpen(true);
        setIsLoading(true);
        const data = {
            sex: sex,
            birth_date: birthdate,
            name: name,
            last_name: lastname,
            artistic_name: artisticName,
            name_variation: nameVariation,
            ooppah_job: ooppahJob,
            drt: drt,
            drt_uf: drtUF,
            slug: slug,
            about: about,
            phones: phones,
            contact_email:contactEmail,
            manager_phone: managerPhone,
            manager_is_whatsapp: managerIsWhatsapp,
            manager_phone_type: managerPhoneType,
            manager_name: managerName,
            manager_email:managerEmail,
            state: state.id,
            district: district.id,
            linkedin: linkedin,
            instagram: instagram,
            facebook: facebook
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/general`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers});
            setServerMessage("Dados salvos com sucesso!");
            setIsLoading(false);
            setIsUploadSuccess(true);
            getUser();
        }catch(error){
            //TODO add flash error message;
            let outputMessage = "";
            setIsLoading(false);
            setIsUploadSuccess(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;
                    if(typeof outputMessage === 'string'){
                        outputMessage = attributeTranslate(outputMessage);
                    } else{
                        outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                    }
                }

                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                            outputMessage = attributeTranslate(outputMessage);
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }


    useEffect( () => {
        setSex(currentUser.characteristic.sex ? currentUser.characteristic.sex: "");
        setBirthdate(currentUser.characteristic.birth_date ? transformDate(currentUser.characteristic.birth_date): "")
        setName(currentUser.name ? currentUser.name: "");
        setNameVariation(currentUser.name_variation ? currentUser.name_variation : "" );
        setOoppahJob(currentUser.ooppah_job ? currentUser.ooppah_job : 0);
        setLastname(currentUser.last_name ? currentUser.last_name: "");
        setArtisticName(currentUser.artistic_name ? currentUser.artistic_name: "");
        setDrt(currentUser.drt ? currentUser.drt : "");
        setDrtUF(currentUser.drt_uf ? currentUser.drt_uf : "");
        setSlug(currentUser.slug);
        setAbout(currentUser.characteristic.about ? currentUser.characteristic.about : "");
        setPhones(currentUser.contact.phones);
        setContactEmail(currentUser.contact.contact_email ? currentUser.contact.contact_email: "" );
        setManagerPhone(currentUser.contact.manager ? currentUser.contact.manager.phone : "")
        setManagerIsWhatsapp(currentUser.contact.manager ? currentUser.contact.manager.is_whatsapp : "");
        setManagerPhoneType(currentUser.contact.manager ? currentUser.contact.manager.phone_type : "");
        setManagerName(currentUser.contact.manager ? currentUser.contact.manager.name : "");
        setManagerEmail(currentUser.contact.manager ? currentUser.contact.manager.email : "");
        setAddmanager(currentUser.contact.manager? true: false);
        setState(currentUser.address.state);
        setDistrict(currentUser.address.district);
        setLinkedin(currentUser.contact.linkedin ? currentUser.contact.linkedin : "");
        setInstagram(currentUser.contact.instagram ? currentUser.contact.instagram: "");
        setFacebook(currentUser.contact.facebook ? currentUser.contact.facebook : "");
        
    }, []);

    useEffect(() => {
        getStates();
    }, []);

    useEffect(() => {
        getDistrict(state.id);
    }, [state])

    return (
        <Container>
            <ProfileCompleteness />
            <ButtonContainer>
                <SaveButton onClick = {handleFormSubmit}>Salvar</SaveButton>
            </ButtonContainer>
            <VerticalSlider title={"Dados gerais"}>
                <Row>
                    <Dropdown
                        name = {"sex"}
                        label = {"Sexo"}
                        title = {"Sexo"}
                        options = {sexOptions}
                        valueDescription = {translateSexOption(sex)}
                        onChange = {(event) => {setSex(event.target.value)}}
                        className = {"marginRight"}
                    />

                    <InputBirthday
                        name = {'birth-date'}
                        label = {'Data de nascimento'}
                        placeholder = {'dd/mm/yyyy'}
                        onChange = {(event) => {setBirthdate(event.target.value)}}
                        value = {birthdate}
                    />
                </Row>
                
                <Row>
                    <InputV2
                        label = {"Nome"}
                        name = {'name'}
                        value = {name}
                        onChange = {(event) => {setName(event.target.value)}}
                        className = {'half-size marginRight'}
                    />
                    <InputV2
                        label = {"Sobrenome"}
                        name = {'lastname'}
                        value = {lastname}
                        onChange = {(event) => {setLastname(event.target.value)}}
                        className = {'half-size'}
                    />
                </Row>
                
                <Row>
                    <InputV2
                        label = {"Nome Artístico"}
                        name = {'artistic_name'}
                        value = {artisticName}
                        onChange = {(event) => {setArtisticName(event.target.value)}}
                        className = {"full-size"}
                    />
                </Row>

                <Row>
                    <InputV2
                        label = {"Variações de grafia"}
                        name = {'name_variation'}
                        value = {nameVariation}
                        onChange = {(event) => {setNameVariation(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {'Evellyn,Ewellin,Evileen'}
                        modalDescription = {nameVariationDescription}
                        maxLength = {40}
                    />
                </Row>

                <Row>
                    <UnchangableInput>
                        <label>CPF</label>
                        <UnchangableValue>
                            {currentUser.cpf}
                        </UnchangableValue>
                    </UnchangableInput>

                    <InputV2
                        label = {"DRT"}
                        name = {"drt"}
                        value = {drt}
                        onChange = {(event) => {setDrt(event.target.value)}}
                        className = {'third-size mini-marginRight'}
                    />
                    <InputV2
                        label = {"DRT UF"}
                        name = {"drt_uf"}
                        value = {drtUF}
                        onChange = {(event) => {setDrtUF(event.target.value)}}
                        className = {'third-size'}
                    />
                </Row>

                <Row>
                    <Dropdown
                        name = {"ooppah_job"}
                        label = {"Ja trabalhou pelo Ooppah?"}
                        title = {"Trabalho pelo Ooppah"}
                        options = {ooppahOptions}
                        valueDescription = {translateOoppahJob(ooppahJob)}
                        onChange = {(event) => {setOoppahJob(parseInt(event.target.value))}}
                        
                    />
                </Row>

                <Row>
                    <InputV2
                        label = {"Link Ooppah"}
                        name = {"slug"}
                        value = {slug}
                        onChange = {(event) => {setSlug(event.target.value)}}
                        modalDescription = {slugDescription}
                    />
                </Row>
                
            </VerticalSlider>

            <VerticalSlider title={"Sobre você"}>
                <TextAreaGroup>
                    <label>
                        Sobre você (Texto do perfil)
                    </label>
                    <AboutTextArea value={about} onChange = {(event) => { setAbout(event.target.value)}} />
                </TextAreaGroup>
            </VerticalSlider>

            <VerticalSlider title = {"Informações de contato"} watch = {[phones, addManager]}>
               
                <Title>Telefones</Title>
                {
                    phones.map((phone, index) => {
                        return (
                            <PhoneGroup key = {index}>
                                <Row className = {"smaller-margin-bottom"}>
                                    <Dropdown
                                        name = {`phones[${index}].type`}
                                        label = {"Tipo"}
                                        title = {"Tipo"}
                                        options = {phoneTypes}
                                        valueDescription = {translatePhoneType(phones[index].type)}
                                        onChange = {(event) => {updatePhonesType(event, index)}}
                                        className = {"half-size marginRight"}
                                    />

                                    <Dropdown
                                        name = {`phones[${index}].is_whatsapp`}
                                        label = {"Whatsapp?"}
                                        title = {"Whatsapp?"}
                                        options = {whatsappOptions}
                                        valueDescription = {translateIsWhatsapp(phones[index].is_whatsapp)}
                                        onChange = {(event) => {updatePhonesWhatsapp(event, index)}}
                                        className = {"half-size marginRight"}
                                        
                                    />
                                   
                                    
                                </Row>
                                <Row className = "smaller-margin-bottom" >
                                    <InputV2
                                        label = {"Número"}
                                        name = {`phones[${index}].number`}
                                        value = {phones[index].number}
                                        onChange = {(event) => {updatePhonesNumber(event, index)}}
                                        className = {"half-size marginRight"}
                                    />
                                     <Dropdown
                                        name = {`phones[${index}].visibility_permission`}
                                        label = {"Visibilidade"}
                                        title = {"Visibilidade"}
                                        options = {phoneVisibility}
                                        modalDescription = {contactVisibility}
                                        valueDescription = {translateVisibilityOptions(phones[index].visibility_permission)}
                                        onChange = {(event) => {updatePhonesVisibility(event, index)}}
                                        className = {"half-size marginRight"}
                                        
                                    />
                                    
                                </Row>
                                <Row className="flex-end">
                                    <DeletePhoneButton onClick={() => {deletePhone(phone)}}>Remover</DeletePhoneButton>
                                </Row>
                            </PhoneGroup>
                        );
                    })
                }
                { phones.length < 3 &&
                    <AddPhoneButton onClick = {addPhone} type = "button">
                        Adicionar novo número
                    </AddPhoneButton>
                }

                <Row>
                    <InputV2
                        label = {"Email de contato"}
                        name = {"contact_email"}
                        placeholder = {"example@gmail.com"}
                        value = {contactEmail}
                        onChange = {(event) => {setContactEmail(event.target.value)}}
                        className = {"full-size"}
                    />
                </Row>
                
                <AgencyDisplay clickHandle = {clickHandle} />

                <Title>Contatos de Empresário</Title>

                <ManagerInfo>
                    Possui um empresário? Utilize o formulário abaixo para informar os dados de contato do seu empresário.
                </ManagerInfo>

                {
                    !addManager &&
                    <GroupButton>
                        <ManagerButton onClick = { (event) => {setAddmanager(true)}}>Adicionar empresário</ManagerButton>
                    </GroupButton>
                    
                }
                {
                    (addManager) &&
                    <ManagerGroup>
                        <Row className = {"smaller-margin-bottom"}>
                            <Dropdown
                                name = {`manager-phone-type`}
                                label = {"Tipo"}
                                title = {"Tipo"}
                                options = {phoneTypes}
                                valueDescription = {translatePhoneType(managerPhoneType)}
                                onChange = {(event) => {setManagerPhoneType(event.target.value)}}
                                className = {"half-size marginRight"}
                            />

                            <Dropdown
                                name = {`manager-is-whatsapp`}
                                label = {"Whatsapp?"}
                                title = {"Whatsapp?"}
                                options = {whatsappOptions}
                                valueDescription = {translateIsWhatsapp(managerIsWhatsapp)}
                                onChange = {(event) => {setManagerIsWhatsapp(event.target.value)}}
                                className = {"half-size marginRight"}
                                
                            />
                        </Row>
                        <Row className = "smaller-margin-bottom" >
                            <InputV2
                                label = {"Número"}
                                name = {`manager-number`}
                                value = {managerPhone}
                                onChange = {(event) => {setManagerPhone(event.target.value)}}
                                className = {"half-size marginRight"}
                            />

                            <DeleteManager onClick = {deleteManager}>Remover empresário</DeleteManager>
                        </Row>
                        <Row>
                            <InputV2
                                label = {"email"}
                                name = {`manager-email`}
                                value = {managerEmail}
                                onChange = {(event) => {setManagerEmail(event.target.value)}}
                                className = {"full-size marginRight"}
                            />
                        </Row>
                        <Row>
                            <InputV2
                                label = {"Nome"}
                                name = {`manager-name`}
                                value = {managerName}
                                onChange = {(event) => {setManagerName(event.target.value)}}
                                className = {"full-size marginRight"}
                            />
                        </Row>
                    </ManagerGroup> 
                }
            </VerticalSlider>

            <VerticalSlider title = {"Localidade"}>
                <Row>

                    <Dropdown
                        searchable
                        name = {"UF"}
                        label = {"UF"}
                        title = {"UF"}
                        options = {prepareStateDropdown(states)}
                        valueDescription = {state.name}
                        onChange = {(event) => {setState(event.target.value); setDistrict("")}}
                        className = {"full-size"}
                    />
                </Row>
                <Row>
                    <Dropdown
                        searchable
                        name = {"district"}
                        label = {"Município"}
                        title = {"Município"}
                        options = {prepareDistrictsDropdown(districts)}
                        valueDescription = {district.name}
                        onChange = {(event) => {setDistrict(event.target.value)}}
                        className = {"full-size"}
                    />

                </Row>

                <Row>
                    <Info>
                        Os dados de Cidade e Estado informados aqui serão vistos pelos produtores e visitantes.
                    </Info>
               
                </Row>
            </VerticalSlider>

            <VerticalSlider title={"Redes sociais"}>
                <Row>
                    <InputV2
                        label = {"Link completo do Instagram"}
                        name = {'instagram'}
                        value = {instagram}
                        onChange = {(event) => {setInstagram(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.instagram.com/ooppahoficial/"}
                    />
                </Row>
                <Row>
                    <InputV2
                        label = {"Link completo do Linkedin"}
                        name = {'linkedin'}
                        value = {linkedin}
                        onChange = {(event) => {setLinkedin(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.linkedin.com/company/ooppah"}
                    />
                </Row>
                <Row>
                    <InputV2
                        label = {"Link completo do facebook"}
                        name = {'facebook'}
                        value = {facebook}
                        onChange = {(event) => {setFacebook(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.facebook.com/ooppahoficial/"}
                    />
                </Row>
            </VerticalSlider>
            
            <VerticalSlider title = {"Gerenciar conta"}>
                <Info>
                    Para gerenciar financeiramente a sua conta, <a target = {"_blank"} href = {`${process.env.REACT_APP_ACCOUNT_SUBSCRIPTIONS}`}>clique aqui</a>
                </Info>
            </VerticalSlider>

            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
                {!isLoading &&
                    <UploadMessage state = {isUploadSuccess}>{serverMessage}</UploadMessage>
                }

            </Modal>

        </Container>
    );
}

export default GeneralTab;