import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const ConfigContainer = styled.div`

    border-style: solid;
    border-width: 1px;
    border-color: gray;
    border-radius: 5px;
    padding: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        border: none;
        background: none;

        background-color: #385799;
        color: white;

        border-radius: 7px;

        padding: 4px 15px;

        cursor: pointer;

        margin-bottom: 5px;
    }

    p {
        text-align: center;
    }
    
    h1 {
        text-align: center;
    }

`;


export const VideoFreeThumb = styled.div`
    position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
    margin-bottom: 20px;
`;

export const BaitImg = styled.img`
    width: 100%;
    cursor: pointer;
`;

export const TextModalFreeInfo = styled.span`
`;

export const LinkArea = styled.a`
    &hover{
        text-decoration: underline;
    }
`;