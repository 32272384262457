import { useState, useEffect, useRef } from "react";
import { BaitImg, ConfigContainer, Container, LinkArea, TextModalFreeInfo, VideoFreeThumb } from "./style";
import VideoIsolated from "./VideoIsolated";
import { useAuth } from "../../Hooks/useAuth"
import axios from 'axios';
import { Link } from "react-router-dom";
import VideoBait from "../../Assets/images/addVideoFree.jpeg";
import Modal from '../Modal/index.js';

const VideoGallery =  () => {

    const {getToken, isFree} = useAuth();
    const [videos, setVideos] = useState([]);
    const [showMessageFree, setshowMessageFree] = useState(false);
    const homePlansUrl = `${process.env.REACT_APP_HOME_URL }/plans`;
    const productOoppahStoreUrl = "https://www.store.ooppah.com";

    const getUserVideos = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/video`
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(url,{headers:headers})
            setVideos(response.data);

        }catch(error){
            //TODO add flash error message;
            console.log(error.response.data.message);
        }
    };

    const handleCloseMessageFree = () => {
        setshowMessageFree(false);
    };

    const handleOpenMessageFree = () => {
        setshowMessageFree(true);
    }

    useEffect(() => {
        
        getUserVideos();
        
    }, []);

    return (
        <Container >
            {
                videos.map((video, key) => {
                    return <VideoIsolated key = {key} url = {video.url} videoTitle = {video.title} allowFullScreen/>
                })
            }

            { isFree && 
                <VideoFreeThumb onClick={handleOpenMessageFree}>
                    <BaitImg src={VideoBait}/>
                    <span>Adicione um vídeo</span>
                </VideoFreeThumb>

            }

            <ConfigContainer >
                <h1>Gerencie seus videos</h1>
                <p>Você pode adicionar, remove-los e alterar a sequencia!</p>
                <Link to = "/profile/edit" state = {{tab: 'Videos'}}>
                    <button>Clique aqui</button>
                </Link>
            </ConfigContainer>

            <Modal isOpen={showMessageFree} handleClose = {handleCloseMessageFree}>
                <TextModalFreeInfo>
                    Mais vídeos aumentam em até 8x suas chances de escalação! 
                    &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e suba muito mais vídeos! 
                    Não tem vídeo atual ? O Ooppah resolve! <LinkArea href={productOoppahStoreUrl} target="_blank"> Realizamos vídeos profissionais 
                    no Rio de Janeiro.</LinkArea>
                </TextModalFreeInfo>
            </Modal>
        </Container>
    );
}

export default VideoGallery;