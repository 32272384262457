import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 10px;
    color: #8B8B8B;
`;

export const Metric = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    text-align: center;
`;

export const MetricLegend = styled.span`
    font-size: 0.6em;
`;

export const TextModalFreeInfo = styled.span`
`;

export const LinkArea = styled.a`
    &hover{
        text-decoration: underline;
    }
`;