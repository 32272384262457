import { createContext, useContext, useState } from "react";

export const ToastContext = createContext();

export const ToastProvider = ({children}) => {
    const [toasts, setToast] = useState([]);

    const addToast = (title, message, type = 1) => {
        setToast([...toasts, {title: title, message: message, type: type, id: generateId}]);
    }

    const deleteToast = (toast) => {
        setToast(toasts.filter((item) => {
            return item.id !== toast.id;
        }));
    }

    const generateId = () => {
        return Math.floor((Math.random() * 100) + 1);
    }

    return (
        <ToastContext.Provider value = {{addToast, deleteToast, toasts}}>
            {children}
        </ToastContext.Provider>
    );
}

export const useToast = () => {
    return useContext(ToastContext);
}