import { DropdownContent, DropdownLink, DropdownWrapper, Header, Line, TextContent } from './style';
import RoundImage from '../../RoundImage';
import GearIcon from '../../../Assets/icons/G1.svg';
import KeyIcon from '../../../Assets/icons/H1.svg';
import LockIcon from '../../../Assets/icons/I1.svg';
import ExitIcon from '../../../Assets/icons/J1.svg';
import PersonIcon from '../../../Assets/icons/K7A.svg';
import { useAuth } from "../../../Hooks/useAuth";
import { useRef, useState } from 'react';
import Icon from '../../Icon';
import { useClickedOutside } from '../../../Hooks/useClickedOutside';
import { Link } from 'react-router-dom';
import { translateStatusCode } from '../../../Support/Util';

const ProducerSmallMenu = ({classes}) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const {producerLogOut, producerCurrentUser} = useAuth();

    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    const close = () => {
        setIsOpen(false);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    const profileImageMedium = producerCurrentUser?.userable?.avatar_url;
    const name = producerCurrentUser?.userable?.name;
    const type = producerCurrentUser?.userable_type;

    return (
        <DropdownWrapper ref = {dropdownRef}>
            <RoundImage sizes = "2em" src = {profileImageMedium ? profileImageMedium : PersonIcon } onClick = {clickHandle} style = {{marginLeft: '10px'}}/>
            { isOpen &&
            <DropdownContent>
                <Header>
                    <RoundImage sizes = "2.4em" src = {profileImageMedium ? profileImageMedium : PersonIcon} style = {{marginLeft: '10px', marginRight:'5px'}}/>
                    <TextContent color = {"#"}>
                        <span>{name}</span>
                        {
                            type == 'producer' ?
                            <a href = { `${process.env.REACT_APP_PRODUCER_BASE}/producer/dashboard`} >Meu perfil</a>
                            :
                            <a href = { `${process.env.REACT_APP_PRODUCER_BASE}/researcher/dashboard`} >Meu perfil</a>
                        }
                       
                    </TextContent>
                </Header>
                <Line />
                
                <DropdownLink onClick={producerLogOut} className='marginTop-md marginLeft-sm cursorPointer'>
                    <Icon width='1em' height='1em' src = {ExitIcon} classes = "marginRight" />
                    <span className='text-size'>Sair</span>
                </DropdownLink>
                
            </DropdownContent>
            }
            
        </DropdownWrapper>
    )
}

export default ProducerSmallMenu;