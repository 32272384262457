import { useAuth } from "../../../../Hooks/useAuth";
import { AgencyDisplay, AgencyInfo, BackButton, Button, ButtonUnbind, ConfirmButton, Container, ContentWrapper, MessageText, Title, TitleAgency } from "./style";
import {useState, useEffect} from 'react';
import axios from 'axios';
import Dropdown from "../../../Dropdown";
import { useToast } from "../../../../Hooks/useToast";
import Modal from "../../../Modal";
import { objectIsEmpty } from "../../../../Support/Util";
import RoundImage from "../../../RoundImage";

const AgencyTab = () => {

    const [allAgencies, setAllAgencies] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState({});

    const [agency, setAgency] = useState({});

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const {getToken} = useAuth();
    const {addToast} = useToast();

    const prepareAgencies = (agencies) => {
        return agencies.map((agency) => {return {value: agency, description: agency.name}});
    }

    const handleGetAllAgencies = async () => {

        let apiUrl = `${process.env.REACT_APP_BASE_API}/agency?onlyNames=1`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(apiUrl,{headers:headers});
            console.log(response); 
            setAllAgencies(response.data.agencies);
        }catch(error){
            console.log(error);
        }

    }

    const handleGetSingleAgency = async (agency_id) => {
        
        let apiUrl = `${process.env.REACT_APP_BASE_API}/agency/${agency_id}/get`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(apiUrl,{headers:headers});
            setAgency(response.data.agency);
            console.log(response);
        }catch(error){
            console.log(error.response);
            
        }
    }

    const handleApplyToAgency = async (agency_id) => {
        setIsButtonDisabled(true);
        let apiUrl = `${process.env.REACT_APP_BASE_API}/agency`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(apiUrl,{
                agency_id: agency_id
            },{headers:headers});
            
            if(response.data.status == 200){
                addToast("Sucesso", "Solicitação de agenciamento enviada", 1);
            }else {
                addToast("Problema", response.data.message, 0);
                setIsButtonDisabled(false);
            }

        }catch(error){
            console.log(error.response);
            setIsButtonDisabled(false);
        }
    }

    const handleChangeDropdown = (agency) => {
        setSelectedAgency(agency)
        console.log(agency);
        handleGetSingleAgency(agency.id)
    }

    useEffect(() => {
        handleGetAllAgencies();
    }, []);

    return (
        <Container>
            <Title>Procure a sua agência </Title>
            <ContentWrapper>
                <Dropdown
                    searchable
                    name = {`selectedAgency`}
                    label = {""}
                    title = {"Selecione uma agência"}
                    options = {prepareAgencies(allAgencies)}
                    valueDescription = {selectedAgency.name}
                    onChange = {(event) => handleChangeDropdown(event.target.value)}
                
                />

                {!objectIsEmpty(agency) &&
                    <AgencyDisplay>
                        <RoundImage src={agency.avatar_url} sizes = "7em"/>
                        <TitleAgency>
                            {agency.name}
                        </TitleAgency>
                        <AgencyInfo>
                            {agency.about}
                        </AgencyInfo>
                        <Button onClick={() => {handleApplyToAgency(agency.id)}} disabled = {isButtonDisabled}>
                            Solicitar Vínculo
                        </Button>
                    </AgencyDisplay>
                }
            </ContentWrapper>
        </Container>
    )
}

export default AgencyTab;