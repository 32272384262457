import styled from "styled-components";

export const Error = styled.h4`
    color:red;
    border-radius: 5px;
    border-color: rgb(148, 0, 0);
    border-style: solid;
    border-width: 1px;
    padding: 2px 10px;
    background-color: rgba(255, 0, 0, 0.322);
    text-align:center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 0;
`;