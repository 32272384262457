import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: Column;
    align-items: center;

    position: absolute;
    bottom: 0;
    height: 120px;

    background-color: #121D2E;
    width: 100%;
    
    padding-bottom: 5px;

    color: #bac7d6;

    @media( min-width: 500px ){
        flex-direction: row;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
    }
`;

export const OoppahText = styled.p`
    color: #bac7d6;
    text-align: center;
    font-size: 0.7em;
    @media ( min-width: 500px){
        text-align: right;
    }
`;

export const OoppahLinks = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OoppahInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const OoppahSocials = styled.div`
    display: flex;
    margin-bottom: 10px;
    padding-left: 7px;
`;

export const Link = styled.a`
    padding: 3px 7px;
    cursor: pointer;
    text-decoration: none;
    color: #bac7d6;
`;

export const Links = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8em;
`;