import { useEffect, useRef, useState } from "react";
import { Container, Content, Header, Title } from "./style";

import ArrowDown from '../../Assets/icons/C5.svg';
import ArrowUp from '../../Assets/icons/D5.svg';
import Icon from "../Icon";
import { sleep } from "../../Support/Util";

const VerticalSlider = ({title, children, watch}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [overflowHidden, setOverflowHidden] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);
    const contentDom = useRef(null);

    const handleSliderToggle = (event) => {
        event.preventDefault();
        let currentState = isOpen;
        setIsOpen(!currentState);
    }

    const decideContentHeight = () => {
        return isOpen? contentHeight:  0;
    }

    const decideOverflowHidden = () =>{
        return overflowHidden ? "hidden":"visible"; 
    }

    const delayToApplyOverflowVisible = async () => {
        await sleep(500);
        if(isOpen){
            setOverflowHidden(true);
        }else {
            setOverflowHidden(false);
        }
        
    }

    useEffect(() => {
        setContentHeight(contentDom.current.scrollHeight);
    }, watch ? watch : []);

    useEffect(() => {
        setContentHeight(contentDom.current.scrollHeight);
    }, []);
    
    useEffect(()=>{
        if(isOpen){
            delayToApplyOverflowVisible();
        }else {
            setOverflowHidden(true);
        }
    }, [isOpen])

    return (
        <Container>
            <Header onClick = {handleSliderToggle}>
                <Title >
                    {title}
                </Title>
                <Icon src = {isOpen ? ArrowUp : ArrowDown} />
            </Header>

            <Content ref = {contentDom} style = {{height: decideContentHeight(), overflow: decideOverflowHidden()}} isOpen = {isOpen}>
                {children}
            </Content>
        </Container>
    );
}

export default VerticalSlider;