import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const Top = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 10px;

    h3 {
        font-size: 0.8em;
    }
`; 

export const Content = styled.div`
    width: 100%;
`;

export const ButtonFilter = styled.button`
    display: flex;
    align-items: center;

    padding: 3px 15px;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px;

    span{
        font-size: 0.7em;
    }
`;
