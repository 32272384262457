import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 48%;

    
`;

export const Header = styled.button`
    margin: 0;
    background: 0;
    border: 0;

    border-width: 1px;
    border-style: solid;
    border-color: gray;
    border-radius: 3px;

    box-sizing: border-box;
    padding: 4px 6px;
    
    display: flex;
    justify-content: space-between;
    background-color: white;
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 1.2em;
    font-weight: normal;
    color: ${ (props) => props.isPlaceholder ? "#c4c4c4": "black" };
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 98%;
    z-index: 20;
    background-color: #FFFFFF;
    top: 85%;
    overflow-y: scroll;
    max-height: 120px;

    box-shadow: -2px 2px 5px -1px rgb(150 , 150, 150);
    
`;

export const Option = styled.button`
    width: 100%;
    overflow: hidden
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    color: ${props => props.canSelect == false ? '#a19f9f': ""};

    margin: 0;
    background: 0;
    border: 0;

    text-align: left;

    padding: 7px 10px;

    &:hover {
        background-color: #385799;
        color: white;
    }
`;

export const Label = styled.label`
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 5px;
`;

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SearchableWrapper = styled.div`
    box-sizing: border-box;
    padding: 3px 5px;
    
    display: flex;
    justify-content: center;
    

    .search-input {
        padding: 3px 5px;
        width: 90%;
    }
`;

export const JustifiedText = styled.p`
    text-align: justify;
    white-space: pre-wrap;
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

export const AlignIcon = styled.div`
    padding-left: 2px;
    
`;

export const TextGray = styled.span`
    color: #bfc3c9;
`;