import { useEffect, useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import { Container} from "./style";
import axios from 'axios';
import CategoryIsolated from "../CategoryIsolated";

const FeatureCategory = ({features, saveArray, setFeatures}) => {
    const [featureCategories, setFeatureCategories] = useState({});
    const {getToken} = useAuth();


    const getFeatureCategories = async () => {

        let apiUrl = `${process.env.REACT_APP_BASE_API}/featureCategory?type=2`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get( apiUrl, {headers:headers});
            setFeatureCategories(response.data);
        } catch(error){
            console.log(error.response.data.message);
        }

    }

    useEffect(() =>{
        getFeatureCategories();
        
    }, []);

    return (
        <Container>
            { Object.keys(featureCategories).map( key => {
                return(
                    <CategoryIsolated key={key} categoryName={featureCategories[key].name} 
                        features={featureCategories[key].features} userFeatures={features} 
                        categoryId={featureCategories[key].id} saveFeatures={saveArray} 
                        setArrayFeatures={setFeatures} />
                );
            })}

        </Container>

    );

}


export default FeatureCategory;