import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;

    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin-top: 5px;
`;

export const StatusText = styled.span`
    margin-right: 10px;
`;

export const CompletudeText = styled.span`
    &.teste {
        display: flex;
    }
`;

export const RedTag = styled.span`
    display: inline-block;
    background-color: red;
    color: white;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    line-height: 2rem;
    text-align: center;
`;