import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 700px){
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-itens: center;
    }
`;

export const EmailInfo = styled.span`
    text-align: center;
    font-size: 0.8em;
    color: #385799;
    text-decoration: underline;

    @media(min-width: 700px){
        margin-top: 0px;
        line-height: 1.5em;
    }   
`;

export const PhoneInfo = styled.span`
    text-align: center;
    font-size: 0.8em;
    color: #1a1a1a;

    margin-top: 10px;

    border-style: solid;
    border-color: #1a1a1a;
    border-width: 1px;
    border-radius: 20px;
    padding: 3px 7px;

    @media(min-width: 700px){
        margin-top: 0px;
    }
`;