import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 75px;
    
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
`;

export const InputToggle = styled.input`
    display: none;
`;

export const LabelToggle = styled.label`
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
`;

export const ToggleInner = styled.span`
    display: block;
    width: 200%;
    margin-left: ${props => props.checked ? "0" : "-100%"};
    transition: margin 0.3s ease-in 0s;
    
    &::before {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
        content: "Sim";
        text-transform: uppercase;
        padding-left: 10px;
        background-color: #385799;
        color: #fff;
    }

    &::after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
        content: "Não";
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
    }


`;

export const ToggleSwitch = styled.span`
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${props => props.checked ? "0":"40px"};
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
`;

