import { useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { Button, Content, Text, Title, VideoArea, VideoContainer } from "./style";

const VideoTab = ({onChange}) => {
    const url = useState("https://www.youtube.com/embed/2xK5IzvJSqY?autoplay=0&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=1&showinfo=0");
    const urlFree = useState("https://www.youtube.com/embed/FYbLV7spLyk?autoplay=0&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=1&showinfo=0");
    const nextState = 1;
    const {isFree, currentUser} = useAuth();

    return (
        <Content>
            {(isFree || currentUser.access_rule.rule.code == "OOPPAH_PROFILE_FREEMIUM") &&
                <>
                    <Title>
                        <span>Usuário gratuito</span>
                    </Title>
                    <VideoArea>
                        <VideoContainer >
                            <iframe className ="iframe" src={urlFree} frameBorder="0"></iframe>
                        </VideoContainer>
                    </VideoArea>
                </>
            }

            <Title>
                <span>Bem-vindo ao Ooppah!</span>
            </Title>

            <VideoArea>
                <VideoContainer >
                    <iframe className ="iframe" src={url} frameBorder="0"></iframe>
                </VideoContainer>
            </VideoArea>
            
            <Text>
                <span>Criamos esse passo a passo para te ajudar.</span>
            </Text>

            <Text>
                <span>Vamos começar?</span>
            </Text>

            <Button onClick={() => {onChange(nextState)}}>
                Sim, vamos!
            </Button>

        </Content>
    );


};


export default VideoTab;


