import styled from "styled-components";

export const Container = styled.div`

`;

export const InputRangeMin = styled.input`
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;

        background-color: #aec4e3;
        border: none;
        
        box-shadow: 0 0 1px 1px #ced4da;
        border-radius: 4px;
        cursor: pointer;
        height: 26px;
        width: 22px;
        margin-top: 17px;
        pointer-events: all;
        position: relative;
    }

    &::-moz-range-thumb{
        background-color: #aec4e3;
        border: none;
        
        box-shadow: 0 0 1px 1px #ced4da;
        border-radius: 4px;
        cursor: pointer;
        height: 26px;
        width: 22px;
        margin-top: 17px;
        pointer-events: all;
        position: relative;
    }

    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;

    z-index: 4;
`;

export const InputRangeMax = styled.input`
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;

        background-color: #aec4e3;
        border: none;
        
        box-shadow: 0 0 1px 1px #aec4e3;
        border-radius: 4px;
        cursor: pointer;
        height: 26px;
        width: 22px;
        margin-top: 17px;
        pointer-events: all;
        position: relative;
    }

    &::-moz-range-thumb{
        background-color: #aec4e3;
        border: none;
        
        box-shadow: 0 0 1px 1px #ced4da;
        border-radius: 4px;
        cursor: pointer;
        height: 26px;
        width: 22px;
        margin-top: 17px;
        pointer-events: all;
        position: relative;
    }

    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
    z-index: 3;
`;

export const Slider = styled.div`
    position: relative;
    width: 200px;

    height: 20px;
`;

export const SliderTrack = styled.div`
    
    border-radius: 10px;

    height: 20px;
    position: absolute;

    background-color: white;
    width: 100%;
    z-index: 1;
`;

export const SliderRange = styled.div`

    border-radius: 3px;
   

    height: 20px;
    position: absolute;

    background-color: #385799;
    z-index: 2;
    
`;