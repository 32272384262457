import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

`;


export const Title = styled.h2`
    width: 100%;
    font-size: 0.8em;
    font-weight: normal;
`;

export const Line = styled.div`
    border-width: 1px 0 0 0;
    
    border-color: #B3B3B3;
    border-style: solid;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;

`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    width: 100%;
`;

export const Necessities = styled.div`
    width: 100%;    
    flex-wrap : wrap;
`;

export const Necessity = styled.div`
    display: inline-block;

    border-radius: 5px;
    border-width: 0;

    padding: 4px 9px;

    margin-right: 3px;
    margin-bottom: 10px;

    color: white;
    font-size: 1.0em;

    background-color: #4d4d4d;
`;