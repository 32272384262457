import { AgeAndLocation, AgencyArea, AgencyButtons, AgencyContent, ButtonArea, CardButtonDelimiter, Container, DataGroup, Delimiter, DisplayIconInfo, GenericActionButton, LastUpdate, Left, Line, MiniFavoriteButton, MiniGenericButton, Name, PlanInfo, ProfileContent, ProfileDelimiter, ProfileInfo, ProfilePhoto, ProfileSeparator, Right, SeeProfile, SpanIconButton, StatisticArea, TextBold, TextGeneric, ThreeDots } from "./style";
import { characterCounter, formattedNumberStatistics, getAge, returnFormattedDate } from "../../Support/Util";
import Icon from "../Icon";
import BarsIcon from "../../Assets/icons/bar.svg";
import GrayEyeIcon from "../../Assets/icons/eye.svg";
import EyeIcon from "../../Assets/icons/blueEye.svg";
import PersonIcon from "../../Assets/icons/user.svg";
import useWindowSize from "../../Hooks/useWindowSize";
import { useEffect, useState } from "react";
import FormatAbout from "../FormatAbout";
import RoundImage from "../RoundImage";
import { SealIcons } from "../../Support/Contants";
import OoppahJobcIcon from '../../Assets/icons/trabalhos.svg';
import WhiteStarIcon from "../../Assets/icons/E6.svg";
import FolderIcon from "../../Assets/icons/folder_icon.svg";
import TagIcon from "../../Assets/icons/tag_icon.svg";
import { useAuth } from "../../Hooks/useAuth";
import { addNewFavorite, removeFavorite } from "../../Services/FavoriteSerive";
import { useToast } from "../../Hooks/useToast";

const GenericCardArtistView = ({profile}) => {

    const [windowWidth, ] = useWindowSize();
    const [showAbout, setShowAbout] = useState(windowWidth > 960? true: false);
    const aboutSize = windowWidth > 960 ? 350 : 150;
    const [about, counter] = characterCounter(profile.about, aboutSize);
    const showLastUpdate = profile.mostRecentDate === "0" ? false: true;

    const hasAgency = profile.agency_id !== null ? true: false;
    const agencyPhoto = hasAgency? profile.avatar_agency: "";
    const {getProducerToken, producerCurrentUser} = useAuth();
    const [isProducer, setIsProducer] = useState(false);
    const {addToast} = useToast();
    const [isFavorite, setIsFavorite] = useState(profile.isFavorite ? profile.isFavorite: false);

    const handleGoToProfile = () => {
        window.open(`/${profile.slug}`,'_blank');
    }

    const handleGoToAgency = () => {
        window.open(`${profile.agency_url}`,'_blank');
    }

    const getProfileLevelICon = (profileLevel) => {

        return SealIcons[profileLevel];
    }

    const handleRemoveFavorite = async () => {
        const response =  await removeFavorite(getProducerToken(), profile.id);
        if(response.status === 200){
            setIsFavorite(false);
            addToast("Sucesso", "Favorito removido com sucesso!", 1);
        } else if(response.status === 400){
            let erroMessage = "";
            if(response.data.message==="Favorito não encontrado!"){
                erroMessage = "Atualize a página e tente novamente.";
            } else if(response.data.message === "Dados incompletos."){
                erroMessage = "Faça o login novamente e tente outra vez.";
            } else{
                erroMessage = "Entre em contato com o suporte.";
            }
            addToast("Falha", erroMessage , 0);
        } 
        
        else{
            addToast("Falha", 'Entre em contato com o suporte.', 0);
        }

    }

    const handleAddFavorite = async () => {
        const response =  await addNewFavorite(getProducerToken(), profile.id);
        if(response.status === 200){
            setIsFavorite(true);
            addToast("Sucesso", "Favorito adicionado com sucesso", 1);
        } else if(response.status === 400){
            let messageError = response.data.message === "O favorito já foi adicionado!" ?
                "O artista já foi adicionado como favorito! Atualize a página e verifique novamente.": "Entre em contato com o suporte.";
            if(response.data.message === "O favorito já foi adicionado!" ? false: true){
                messageError = response.data.message === "Dados incompletos." ?
                "Faça o login novamente e tente outra vez.": "Entre em contato com o suporte.";
            }
            addToast("Falha", messageError , 0);

        } else{
            addToast("Falha", 'Entre em contato com o suporte.', 0);
        }
    }

    useEffect(() => {
        if(producerCurrentUser){
            setIsProducer(producerCurrentUser.userable_type === "producer");
        } else{
            setIsProducer(false);
        }
        
    }, []);


    if(windowWidth < 960){
        return(
            <Container >
                <Delimiter>
                    <ProfileDelimiter>
                        <Left>
                            <ProfilePhoto >
                                <RoundImage onClick={handleGoToProfile} sizes = {"7em"} src = {profile.profile_photo ? profile.profile_photo : PersonIcon} />
                            </ProfilePhoto>
                        </Left>

                        <Right>
                            <ProfileInfo>
                                <ProfileSeparator>
                                    <Name onClick={handleGoToProfile}>
                                        {profile.artistic_name}
                                    </Name>

                                    <AgeAndLocation>
                                        <TextBold>{getAge(profile.birth_date) +" anos"}</TextBold>
                                        &nbsp;|&nbsp;
                                        {profile.district}
                                        &nbsp;|&nbsp;
                                        {profile.state}
                                    </AgeAndLocation>

                                    <LastUpdate>
                                        Última atualização: {showLastUpdate ? returnFormattedDate(profile.mostRecentDate) : "---"}
                                        {/*<Icon classes={"marginLeftSmall"} src = {UserBackIcon} width = {"0.9em"} height = {'0.9em'} alt = "Last Update Icon"/> */}
                                    </LastUpdate>
                                    
                                    { showAbout && 
                                        <FormatAbout about = {about}/> 
                                    }
                                </ProfileSeparator>
                                <StatisticArea>
                                    <DataGroup>
                                        <Icon src = {BarsIcon} width = {"0.8em"} height = {'0.8em'} alt = "Graphic Bar Icon" />
                                        <TextGeneric>&nbsp;{profile.has_search_count == 1 ? formattedNumberStatistics(profile.search_count) : "---"}</TextGeneric>
                                    </DataGroup>
                                    
                                    {profile.ooppah_job == 1 &&
                                        <DataGroup>
                                            <Icon src = {OoppahJobcIcon} width = {"1.7em"} height = {'1.7em'} alt = "Job Icon"/>
                                        </DataGroup>
                                    }

                                    <DataGroup>
                                        <Icon src = {GrayEyeIcon} width = {"1em"} height = {'1em'} alt = "Eye Icon"/>
                                        <TextGeneric>&nbsp;{profile.has_access_count == 1 ? formattedNumberStatistics(profile.access_count) : "---"}</TextGeneric>
                                    </DataGroup>
                                </StatisticArea>
                            </ProfileInfo>
                        </Right>
                    </ProfileDelimiter>

                    <ButtonArea>
                                
                        {!isProducer &&
                            <SeeProfile onClick={handleGoToProfile}>
                                <Icon src = {EyeIcon} alt = "Eye Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                <TextBold>Visitar perfil</TextBold>
                            </SeeProfile>
                        }

                        {isProducer &&
                            <>
                                <MiniGenericButton onClick={handleGoToProfile}>
                                    <Icon src = {EyeIcon} alt = "Eye Icon" width = {"2.2em"} height = {'2.2em'} /> 
                                </MiniGenericButton>

                                <MiniFavoriteButton onClick={isFavorite? handleRemoveFavorite: handleAddFavorite} isFavorite={isFavorite}>
                                    <Icon src = {WhiteStarIcon} alt = "Star Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                </MiniFavoriteButton>

                                <MiniGenericButton disabled>
                                    <Icon src = {FolderIcon} alt = "Folder Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                </MiniGenericButton>

                                <MiniGenericButton disabled>
                                    <Icon src = {TagIcon} alt = "Tag Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                </MiniGenericButton>
                            </>  
                        }                      
                        
                        <AgencyButtons>
                            <AgencyContent isVisible = {profile.agencyPhoto != null && profile.profile_level !== "1"}>
                                <RoundImage onClick={handleGoToAgency} src={profile.agency_id ? profile.agencyPhoto : PersonIcon} sizes = "2.5em"/> 
                            </AgencyContent>

                            
                            {getProfileLevelICon(profile.profile_level) &&
                                <Icon width="1.5em" height="4.0em" src = {getProfileLevelICon(profile.profile_level)} classes = {"marginRight"} />  
                            }
                            
                        </AgencyButtons>

                    </ButtonArea>
                </Delimiter>
                <Line/>

            </Container>
        );
    }

    return (
        <Container>
            <Delimiter>
                <ProfileDelimiter>
                    <Left>
                        <ProfilePhoto onClick={handleGoToProfile}>
                            <RoundImage sizes = {"9em"} src = {profile.profile_photo ? profile.profile_photo : PersonIcon} />
                        </ProfilePhoto>
                    </Left>

                    <Right>
                        <ProfileInfo>
                            <ProfileContent>
                                <Name onClick={handleGoToProfile}>
                                    {profile.artistic_name}
                                </Name>

                                <AgeAndLocation>
                                    <TextBold>{getAge(profile.birth_date) +" anos"}</TextBold>
                                    &nbsp;|&nbsp;
                                    {profile.district}
                                    &nbsp;|&nbsp;
                                    {profile.state}
                                </AgeAndLocation>

                                <LastUpdate>
                                    Última atualização: {showLastUpdate ? returnFormattedDate(profile.mostRecentDate) : "---"}
                                    {/*<Icon classes={"marginLeftSmall"} src = {UserBackIcon} width = {"0.9em"} height = {'0.9em'} alt = "Last Update Icon"/>*/}
                                </LastUpdate>
                                
                                { showAbout && 
                                    <FormatAbout about = {about}/> 
                                }

                            </ProfileContent>

                            <AgencyArea>
                                <AgencyContent isVisible = {profile.agencyPhoto != null && profile.profile_level !== "1"}>
                                    <RoundImage onClick={handleGoToAgency} src={profile.agency_id ? profile.agencyPhoto : PersonIcon} sizes = "4em"/> 
                                </AgencyContent>
                
                                {getProfileLevelICon(profile.profile_level) &&
                                    <Icon width="1.5em" height="4.0em" src = {getProfileLevelICon(profile.profile_level)} classes = {"marginRight"} />  
                                }

                                
                            </AgencyArea>
                        </ProfileInfo>

                        <ButtonArea>

                            <StatisticArea>
                                <DataGroup>
                                    <Icon src = {BarsIcon} width = {"1.2em"} height = {'1.2em'} alt = "Graphic Bar Icon" />
                                    <TextGeneric>&nbsp;{profile.has_search_count == 1 ? formattedNumberStatistics(profile.search_count) : "---"}</TextGeneric>
                                </DataGroup>

                                {profile.ooppah_job == 1 &&
                                    <DataGroup>
                                        <Icon src = {OoppahJobcIcon} width = {"1.8em"} height = {'1.8em'} alt = "Job Icon"/>
                                    </DataGroup>
                                }

                                <DataGroup>
                                    <Icon src = {GrayEyeIcon} width = {"1.2em"} height = {'1.2em'} alt = "Eye Icon"/>
                                    <TextGeneric>&nbsp;{profile.has_access_count == 1 ? formattedNumberStatistics(profile.access_count) : "---"}</TextGeneric>
                                </DataGroup>
                            </StatisticArea>

                            <CardButtonDelimiter isProducer={isProducer}>
                                {!isProducer && 
                                    <SeeProfile onClick={handleGoToProfile}>
                                        <Icon src = {EyeIcon} alt = "Eye Icon" width = {"2.2em"} height = {'2.2em'} classes={"marginRight"}/> 
                                        <TextBold>Visitar perfil</TextBold>
                                    </SeeProfile>
                                }

                                {isProducer &&
                                    <>
                                        <MiniGenericButton onClick={handleGoToProfile}>
                                            <Icon src = {EyeIcon} alt = "Eye Icon" width = {"2.2em"} height = {'2.2em'} /> 
                                        </MiniGenericButton>

                                        <MiniFavoriteButton onClick={isFavorite? handleRemoveFavorite: handleAddFavorite} isFavorite={isFavorite}>
                                            <Icon src = {WhiteStarIcon} alt = "Star Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                        </MiniFavoriteButton>

                                        <MiniGenericButton disabled>
                                            <Icon src = {FolderIcon} alt = "Folder Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                        </MiniGenericButton>

                                        <MiniGenericButton disabled>
                                            <Icon src = {TagIcon} alt = "Tag Icon" width = {"2.2em"} height = {'2.2em'}/> 
                                        </MiniGenericButton>
                                    </>
                                }
                            </CardButtonDelimiter>
                        </ButtonArea>
                    </Right>
                </ProfileDelimiter>
            </Delimiter>

            <Line/>

        </Container>);



}

export default GenericCardArtistView;