import { useEffect, useState } from "react";
import { Button, ButtonArea, Container, Content, Info, InputArea, PhoneGroup, 
    Row, Title, ErrorMessage} from "./style";
import { getSexOptions, translateSexOption, translateVisibilityOptions, 
    phoneTypeOptions, translatePhoneType, phoneIsWhatsappOptions, 
    contactVisibilityOptions, translateIsWhatsapp, prepareStateDropdown, 
    prepareDistrictsDropdown, phoneVisibilityPermissionOptions, getAge } from "../../../Support/Util";
import InputBirthday from "../../InputBirthday";
import InputV2 from "../../InputV2";
import Dropdown from "../../Dropdown";
import axios from 'axios';
import Modal from "../../Modal";
import { contactVisibility } from "../../../Support/StringResourcers";

const ProfileInfoTab = (
        { onChange, artisticName, setArtisticName, sex, setSex, birthdate, setBirthdate, 
            phones, setPhones, state, setState, district, setDistrict, setDoneStep, isFree, 
            isFreemium
        }
    ) => {

    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const sexOptions = getSexOptions();
    const permissionOptions = contactVisibilityOptions();
    const phoneTypes = phoneTypeOptions();
    const whatsappOptions = phoneIsWhatsappOptions();
    const phoneVisibility = phoneVisibilityPermissionOptions(isFree);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    const nextState = 3;

    const updatePhonesType = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].type = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesWhatsapp = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].is_whatsapp = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesNumber = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].number = formatNumber(event.target.value);
        setPhones(tempPhones);
    }

    const updatePhonesVisibility = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].visibility_permission = event.target.value;
        setPhones(tempPhones);
    }

    const getDistrict = async (id) => {
        let url = `${process.env.REACT_APP_BASE_API}/district/${id}}`;

        try {
            const response = await axios.get(url)
            setDistricts(response.data);
        }catch(error){
            setServerMessage(error.data);
            setIsModalOpen(true);
        }
    }

    const getStates = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/state`;

        try {
            const response = await axios.get(url)
            setStates(response.data);
        }catch(error){
            setServerMessage(error.data);
            setIsModalOpen(true);

        }
    }

    const formatNumber = (number) =>{
        let start = "";
        let tail = "";
        number = number.replace("(", "");
        number = number.replace(")", "");

        for(let i=0; i < number.length; i++){
            if(i < 2){
                start = start + number.charAt(i);
            } else {
                tail = tail + number.charAt(i);
            }

        }

        if(number.length > 2){
            return "(" + start + ")" + tail;
        } else {
            return start + tail;
        }
    }


    const checkData = async () => {
        let erroMessage = "Dado(s) incorreto(s):\n";
        let errorCount = 0;

        if(phones.length < 1){

            erroMessage = erroMessage + "Adicione ao menos 1 (um) telefone para contato.\n";
            errorCount++; 

        } else{

            if(!phones[0].type){
                erroMessage = erroMessage + "Adicione 1 (um) tipo ao telefone para contato.\n";
                errorCount++; 

            }

            if(!phones[0].is_whatsapp){
                erroMessage = erroMessage + "Indique se o telefone apresentado é whatsapp ou não.\n";
                errorCount++; 

            }

            if(!phones[0].number){
                erroMessage = erroMessage + "Adicione o número de telefone para contato.\n";
                errorCount++; 

            }

            if(!phones[0].visibility_permission){
                erroMessage = erroMessage + "Adicione o tipo de permissão para visibilidade do seu contato.\n";
                errorCount++;

            }

        }

        if(artisticName.length < 3){
            erroMessage = erroMessage + "Nome artístico deve possuir ao menos 3 letras.\n";
            errorCount++;
        }

        if(sex != 1 && sex != 2 && sex != 3){
            erroMessage = erroMessage + "Sexo possui valor inválido!\n";
            errorCount++;
        }
        
        if(birthdate.length === 10){
            let [day, month, year] = birthdate.split('/');
            let newbirthdate =  year + "-" + month + "-" + day;
            if(isFreemium || isFree){
                if(getAge(newbirthdate) < 18){
                    erroMessage = erroMessage + "Você deve possuir ao menos 18 anos para utilizar a plataforma Ooppah.\n";
                    errorCount++;
                }
            }
        }

        if(birthdate.length < 10 || birthdate.length > 10){
            erroMessage = erroMessage + "Data de nascimento inválida!\n";

            errorCount++;
        }
        
        if(!district.id){
            erroMessage = erroMessage + "Adicione um Município!\n";
            errorCount++;
        }

        if(!state.id){
            erroMessage = erroMessage + "Adicione um Estado!\n";
            errorCount++;
        }

        if(errorCount == 0){
            let clearNumber = phones[0].number;
            clearNumber = clearNumber.replace("(", "");
            clearNumber = clearNumber.replace(")", "");
            clearNumber = clearNumber.replace("-", "");
            clearNumber = clearNumber.replace(" ", "");
            const tempPhones = [...phones];
            tempPhones[0].number = clearNumber;
            setPhones(tempPhones);
            setDoneStep(true);
            onChange(nextState);
        } else{
            setDoneStep(false);
            setServerMessage(erroMessage);
            setIsModalOpen(true);
        }
    }

    useEffect(() => {
        if(phones[0].number.length > 0){
            const tempPhones = [...phones];
            tempPhones[0].number = formatNumber(tempPhones[0].number);
            setPhones(tempPhones);
        }
        
        getStates();
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;

        if (birthdate.match(regex) === null) {
            let newBirthDate = new Date (birthdate);
            setBirthdate(newBirthDate.toLocaleDateString('pt-BR', {timeZone: 'UTC'}));    
        }
    }, []);

    useEffect(() => {
        getDistrict(state.id);
    }, [state]);

    return(
        <Container>
            <Content>
                <InputArea>
                    <Title>Dados gerais</Title>
                    <Row>
                        <InputV2
                            label = {"Nome Artístico"}
                            name = {'artistic_name'}
                            value = {artisticName}
                            onChange = {(event) => {setArtisticName(event.target.value)}}
                            className = {"full-size"}
                        />
                    </Row>

                    <Row>
                        <Dropdown
                            name = {"sex"}
                            label = {"Sexo"}
                            title = {"Sexo"}
                            options = {sexOptions}
                            valueDescription = {translateSexOption(sex)}
                            onChange = {(event) => {setSex(event.target.value)}}
                            className = {"marginRight"}
                        />

                        <InputBirthday
                            name = {'birth-date'}
                            label = {'Data de nascimento'}
                            placeholder = {'dd/mm/yyyy'}
                            onChange = {(event) => {setBirthdate(event.target.value)}}
                            value = {birthdate}
                        />
                    </Row>
                </InputArea>

                <InputArea>

                    <Row>
                        <Info>
                            A visibilidade representa como os seus contantos serão exibidos para terceiros.
                        </Info>
                    </Row>

                    <Title>Telefones</Title>
                    {
                        <>  
                            <PhoneGroup key = {0}>
                                <Row className = {"smaller-margin-bottom"}>
                                    <Dropdown
                                        name = {`phones[${0}].type`}
                                        label = {"Tipo"}
                                        title = {"Tipo"}
                                        options = {phoneTypes}
                                        valueDescription = {translatePhoneType(phones[0].type)}
                                        onChange = {(event) => {updatePhonesType(event, 0)}}
                                        className = {"half-size marginRight"}
                                    />

                                    <Dropdown
                                        name = {`phones[${0}].is_whatsapp`}
                                        label = {"Whatsapp?"}
                                        title = {"Whatsapp?"}
                                        options = {whatsappOptions}
                                        valueDescription = {translateIsWhatsapp(phones[0].is_whatsapp)}
                                        onChange = {(event) => {updatePhonesWhatsapp(event, 0)}}
                                        className = {"half-size marginRight"}
                                        
                                    />
                                </Row>

                                <Row className = "smaller-margin-bottom" >
                                    <InputV2
                                        placeholder = {"(XX)XXXXXXXXX"}
                                        label = {"Número"}
                                        name = {`phones[${0}].number`}
                                        value = {phones[0].number}
                                        onChange = {(event) => {updatePhonesNumber(event, 0)}}
                                        className = {"half-size marginRight"}
                                    />

                                    <Dropdown
                                        name = {`phones[${0}].visibility_permission`}
                                        label = {"Visibilidade"}
                                        title = {"Visibilidade"}
                                        options = {phoneVisibility}
                                        modalDescription = {contactVisibility}
                                        valueDescription = {translateVisibilityOptions(phones[0].visibility_permission)}
                                        onChange = {(event) => {updatePhonesVisibility(event, 0)}}
                                        className = {"half-size marginRight"}  
                                    />
                                </Row>
                                
                            </PhoneGroup>
                        </>
                    }

                </InputArea>
                
                <InputArea>
                    <Title>Localidade</Title>
                    <Row>
                        <Dropdown
                            searchable
                            name = {"UF"}
                            label = {"UF"}
                            title = {"UF"}
                            options = {prepareStateDropdown(states)}
                            valueDescription = {state.name}
                            onChange = {(event) => {setState(event.target.value); setDistrict("")}}
                            className = {"full-size"}
                        />
                    </Row>
                    <Row>
                        <Dropdown
                            searchable
                            name = {"district"}
                            label = {"Município"}
                            title = {"Município"}
                            options = {prepareDistrictsDropdown(districts)}
                            valueDescription = {district.name}
                            onChange = {(event) => {setDistrict(event.target.value)}}
                            className = {"full-size"}
                        />
                    </Row>

                    <Row>
                        <Info>
                            Os dados de Cidade e Estado informados aqui serão vistos pelos produtores e visitantes.
                        </Info>
                    </Row>
                </InputArea>

                <ButtonArea>
                    <Button onClick={() => {onChange(nextState - 2)}}>
                        Voltar
                    </Button>

                    <Button onClick={checkData}>
                        Salvar dados
                    </Button>
                </ButtonArea>
               
                <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                        <ErrorMessage state = {false}>{serverMessage}</ErrorMessage>
                </Modal>
            </Content>
        </Container>
    );
}

export default ProfileInfoTab;