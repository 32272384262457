import { useState } from "react";
import "draft-js/dist/Draft.css";
import DOMPurify from 'dompurify';
import axios from 'axios';
import Modal from "../../Modal";
import Spinner from "../../Spinner";
import CVButtons from "./CVButtons";
import DraftEditor from "../../DraftEditor";
import { useAuth } from "../../../Hooks/useAuth";
import { Container, NotShowCv, SaveButton, SaveContent, Title, Wyg } from "./style";

const CVTab = () => {
   
    const { currentUser, getUser} = useAuth();
    const {getToken} = useAuth(); 
    const [showSpiner, setShowSpiner] = useState(false);
    const showCv = currentUser.access_rule.rule.has_cv == 0? false: true;
    const [showMessageBackend, setShowMessageBackend ] = useState(false);
    const [backendMessage, setBackendMessage] = useState("");

    const handleCloseErrorBack = () =>{
        setShowMessageBackend(false);
    }

    const saveCV = async (cvText) => {
        setShowSpiner(true);
        let url = `${process.env.REACT_APP_BASE_API}/cv-text`;
        let token = getToken();
        let cleanCv = DOMPurify.sanitize(cvText);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, {cv: cleanCv}, {headers:headers});
            setShowSpiner(false);
            getUser();
        }catch(error){
            setShowSpiner(false);
            setShowMessageBackend(true);
            setBackendMessage(error.response.data.message);
        }
       
    }

    return(
        <Container>
            {(showCv) &&
                <div>
                    <CVButtons/>
                        <Wyg>
                            <DraftEditor sendCv={saveCV}/>
                        </Wyg>
                </div>
            }
            { !showCv &&
                <NotShowCv>
                    <span>Seu plano não inclui CV.</span>
                </NotShowCv>
            }
            
            <Modal isOpen={showSpiner}>
                <Spinner dimension={'40px'} />
            </Modal>

            <Modal isOpen={showMessageBackend} handleClose={handleCloseErrorBack}>
                {backendMessage}
            </Modal>
        </Container>
    );
};

export default CVTab;