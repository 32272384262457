import { Container, Loader, Logo, Spinner, Spinner2 } from './style.js'
import LogoWhite from '../../Assets/images/logo-white.png';

const Loading = () => {


    return (
        <Container>
            <Logo src = {LogoWhite} />
            <Loader>
                <Spinner />
                <Spinner2 />
            </Loader>
            
        </Container>
    );
}

export default Loading;