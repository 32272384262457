import styled from "styled-components";

export const Container = styled.div`
    margin: 5px;
    padding: 5px 5px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 0.5em black;
`;

export const ButtonArea = styled.div``;

export const AcceptedButton = styled.button`
    background-color: #005454;
    color: white;
    padding: 3px 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .6;
    }
`;

export const DeclineButton = styled.button`
    margin-left: 5px;
    background-color: #610a00;
    color: white;
    padding: 3px 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #8c1103;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .6;
    }
`;

export const TextArea = styled.span`
    display: inline-block;
    box-sizing: border-box;
    font-size: 0.8em;
    text-align: justify;
    width: 100%;
    padding: 5px;
`;

export const Link = styled.a``;