import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 10px;
    width: 100%;
    display: block;
`;



export const Wyg = styled.div`
    width: 100%;
`;

export const SaveContent = styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const SaveButton = styled.button`
    display: flex;
    border-style: none;
    width: max-content;
    
    color: white;
    background-color: #385799;
    border-radius: 7px;
    
    padding: 3px 7px;
    font-size: 0.8em;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const NotShowCv = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center
`;