import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
`;


export const PageDelimiter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media(min-width: 1080px){
        width: 1080px;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 40px;
    }

    @media(max-width: 340px) {
        display: block;
    }
`;

export const SpinnerContent = styled.div`
    width: 95%;
    @media(min-width: 1080px){
        width: 65%;
    }
    display: flex;
    justify-content: center;
`;
