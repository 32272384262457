import styled from "styled-components";

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const DropdownContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;

    padding-top:5px;
    
    width: 9em;
    z-index: 2;
    background-color: #FFFFFF;

    top: calc(100% + 10px);
    right: 0;

    box-shadow: 0px 3px 7px rgba(200, 200, 200, 1);

    .adjustLink {
        text-decoration: none;
        display: flex;
        align-items: center;

        margin-bottom: 5px;
        margin-left: 10px;
        color: black;

        &.marginTop-md {
            margin-top:20px;
        }

        &.marginTop-lg {
            margin-top:30px;
        }
        &.marginLeft-sm {
            margin-left: 10px;
        }

        .dark-text {
            color: black;
        }

        .text-size {
            font-size: 0.7em;
    }
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;

    span {
        font-size: 0.7em;
        font-weight: bold;
    }
`;

export const TextContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
        color: #131E2F;
        font-size: 0.6em;

    }

    a:visited {
        color: #131E2F;
    }

    span {
        margin-bottom: 2px;
    }
`;


export const DropdownLink = styled.a`

    text-decoration: none;
    display: flex;
    align-items: center;

    margin-bottom: 5px;

    &.marginTop-md {
        margin-top:20px;
    }

    &.marginTop-lg {
        margin-top:30px;
    }
    &.marginLeft-sm {
        margin-left: 10px;
    }

    .dark-text {
        color: black;
    }

    .text-size {
        font-size: 0.7em;
    }

    &.cursorPointer {
        cursor: pointer;
    }
    
`;

export const Line = styled.div`
    border-top-width: 1px;
    border-bottom: 0;

    border-color: #B3B3B3;
    border-style: solid;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;
`;