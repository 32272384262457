import { Container, Info, TabsContent, TabsDesktop, TabsList } from "./style";
import Tab from "../../Tab";
import TabContentVisibility from "../../TabContentVisibility";

import PlayIconActive from '../../../Assets/icons/A4.svg';
import PlayIconInactive from '../../../Assets/icons/B4.svg';
import PhotoIconActive from '../../../Assets/icons/C4.svg';
import PhotoIconInactive from '../../../Assets/icons/D4.svg';
import StarIconActive from '../../../Assets/icons/E4.svg';
import StarIconInactive from '../../../Assets/icons/F4.svg';
import PersonIconActive from '../../../Assets/icons/G4.svg';
import PersonIconInactive from '../../../Assets/icons/H4.svg';
import DocumentIconActive from '../../../Assets/icons/I4.svg';
import DocumentIconInactive from '../../../Assets/icons/J4.svg';
import { useState } from "react";
import VideoTab from "../VideoTab";
import FeatureTab from "../FeatureTab";
import PhotoTab from "../PhotoTab";
import CharacteristicTab from "../CharacteristicTab";
import GeneralTab from "../GeneralTab";
import GeneralTabDesktop from '../Desktop/GeneralTab';
import CVTab from "../CVTab";
import LeftTabs from "../Desktop/LeftTabs";
import useWindowSize from "../../../Hooks/useWindowSize";
import AboutTab from "../Desktop/AboutTab";
import ContactTab from "../Desktop/ContactTab";
import AddressTab from "../Desktop/AddressTab";
import SocialsTab from "../Desktop/SocialsTab";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sleep } from "../../../Support/Util";
import AgencyTab from "../Desktop/AgencyTab";

const Tabs = ({isGeralOpen, setIsGeralOpen}) => {

    const location = useLocation();

    const tabs = [
        {
            name: "Videos",
            description: "Vídeos",
            iconIncative: PlayIconInactive,
            iconActive: PlayIconActive,
            iconAlt: "play icon"
        },
        {
            name: "Photos",
            description: "Fotos",
            iconIncative: PhotoIconInactive,
            iconActive: PhotoIconActive,
            iconAlt: "Photo icon"
        },
        {
            name: "Features",
            description: "Habilidades",
            iconIncative: StarIconInactive,
            iconActive: StarIconActive,
            iconAlt: "Star icon"
        },
        {
            name: "Characteristics",
            description: "Aspectos",
            iconIncative: PersonIconInactive,
            iconActive: PersonIconActive,
            iconAlt: "Person icon"
        },
        {
            name: "CV",
            description: "Currículo",
            iconIncative: DocumentIconInactive,
            iconActive: DocumentIconActive,
            iconAlt: "Document icon"
        },

    ];

    const [windowWidth, windowHeight] =  useWindowSize();

    const [activeTab, setActiveTab] = useState('');

    const clickHandle = (activeTab) => {
        setActiveTab(activeTab);
        setIsGeralOpen(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
    }

    useEffect(async() => {
        if(location.state){
            await sleep(1000);
            clickHandle("Videos");
        }
    }, [])

    return (
        <Container>
            <TabsList>
                {tabs.map((tab) => {
                    return (<Tab key = {tab.name}
                        tabDescription = {tab.description}
                        clickHandle = {clickHandle} 
                        tabName = {tab.name} 
                        isActive = {activeTab === tab.name && !isGeralOpen} 
                        activeIcon = {tab.iconActive}
                        inactiveIcon = {tab.iconIncative} 
                        alt = {tab.iconAlt}
                        iconSizes = "1.6em"
                    />);
                })}
            </TabsList>
            <TabsDesktop>
                {windowWidth >= 700 && <LeftTabs changeTabHandle = {clickHandle} activeTab = {activeTab}/>}
                <TabsContent>
                    {
                        windowWidth < 700 &&

                        <TabContentVisibility isActive={isGeralOpen}>
                            <GeneralTab  clickHandle = {clickHandle}/>
                        </TabContentVisibility>
                    }
                    <TabContentVisibility isActive = {tabs[0].name === activeTab && !isGeralOpen}>
                        <VideoTab />
                    </TabContentVisibility>
                    <TabContentVisibility isActive = {tabs[1].name === activeTab && !isGeralOpen}>
                        <PhotoTab />
                    </TabContentVisibility>
                    <TabContentVisibility isActive = {tabs[2].name === activeTab && !isGeralOpen}>
                        <FeatureTab/>
                    </TabContentVisibility>
                    <TabContentVisibility isActive = {tabs[3].name === activeTab && !isGeralOpen}>
                        <CharacteristicTab />
                    </TabContentVisibility>
                    <TabContentVisibility isActive = {tabs[4].name === activeTab && !isGeralOpen}>
                        <CVTab />
                    </TabContentVisibility>
                    <TabContentVisibility isActive = {"agencies" === activeTab && !isGeralOpen}>
                        <AgencyTab />   
                    </TabContentVisibility>
                    {
                        windowWidth > 700 &&
                        <div style={{width: "100%"}}>
                            <TabContentVisibility isActive = {"general" === activeTab && !isGeralOpen}>
                                <GeneralTabDesktop />
                            </TabContentVisibility>
                            <TabContentVisibility isActive = {"about-you" === activeTab && !isGeralOpen}>
                                <AboutTab />
                            </TabContentVisibility>
                            <TabContentVisibility isActive = {"contact" === activeTab && !isGeralOpen}>
                                <ContactTab clickHandle = {clickHandle}/>
                            </TabContentVisibility>
                            <TabContentVisibility isActive = {"address" === activeTab && !isGeralOpen}>
                                <AddressTab />
                            </TabContentVisibility>
                            <TabContentVisibility isActive = {"socials" === activeTab && !isGeralOpen}>
                                <SocialsTab />
                            </TabContentVisibility>
                            <TabContentVisibility isActive = {"account" === activeTab && !isGeralOpen}>
                                <Info>
                                    Para gerenciar financeiramente a sua conta, <a target = {"_blank"} href = {`${process.env.REACT_APP_ACCOUNT_SUBSCRIPTIONS}`}>clique aqui</a>
                                </Info>
                            </TabContentVisibility>
                            
                        </div>
                    }
                </TabsContent>
            </TabsDesktop>
        </Container>
    );
}

export default Tabs;