import { Container } from "./style";
import {Outlet} from 'react-router-dom';
import TopBar from "../TopBar";
import Footer from "../Footer";
import FloatActionButton from "../FloatActionButton";

const Layout = () => {

    return(
        <Container>
            <Outlet />
            <FloatActionButton />
        </Container>
    );
}

export default Layout;