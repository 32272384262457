import axios from "axios";


export const addNewFavorite = async (producerToken, profile_id) => {
    let finalUrl = `${process.env.REACT_APP_BASE_API}/profile-favorite`;
    const headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + producerToken.token };
    const data = {profile_id: profile_id};

    try{
        const response = await axios.post(finalUrl, data,  {headers: headers});
        return response;
    }catch(error){
        return error.response;
    }
}

export const removeFavorite = async (producerToken, profile_id) => {
    let finalUrl = `${process.env.REACT_APP_BASE_API}/profile-favorite/remove`;
    const headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + producerToken.token };
    const data = {profile_id: profile_id};

    try{
        const response = await axios.post(finalUrl, data,  {headers: headers});
        return response;
    }catch(error){
        return error.response;
    }
}