import styled, { keyframes } from "styled-components";

const ToastInRight  = keyframes`
    from {
        transform: translateX(100%);
        
    }
    to {
        transform: translateX(0);
    }
`;

const ToastInLeft  = keyframes`
    from {
        transform: translateX(-100%);
        
    }
    to {
        transform: translateX(0);
    }
`;

export const Container = styled.div`
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999

    &.top-right {
        top: 12px;
        right: 12px;
        transition: transform .6s ease-in-out;
        animation: ${ToastInRight} .7s;
    }
    
    &.bottom-right {
        bottom: 12px;
        right: 12px;
        transition: transform .6s ease-in-out;
        animation: ${ToastInRight} .7s;
    }
    
    &.top-left {
        top: 12px;
        left: 12px;
        transition: transform .6s ease-in;
        animation: ${ToastInLeft} .7s;
    }
    
    &.bottom-left {
        bottom: 12px;
        left: 12px;
        transition: transform .6s ease-in;
        animation: ${ToastInLeft} .7s;
    }
`;

