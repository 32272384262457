import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CategoryList = styled.div`
    width: 100%;    
    flex-wrap : wrap;
`;

export const Card = styled.div`
    border-radius: 5px;
    border-style: solid;
    display: inline-block;
    border-width: 0;
    
    padding: 3px 7px;

    margin-right: 3px;
    margin-bottom: 2px;
    
    background-color: #121D2E;
    color: white;
    font-size: 1.0em;
    cursor: pointer;
`;

export const Title = styled.h3`
    font-size: 1.0em;
    margin: 0;
`;

export const SubTitle = styled.h3`
    font-size: 0.8em;
`;

export const StarContent = styled.div`

    display: inline-block;
    align-items: center;
    padding: 3px 15px;

    background-color: #121D2E;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: fit-content;
    
    cursor: pointer;

`;

