import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import CharacteristicForm from "./CharacteristicForm";
import { Container} from "./style";
import axios from 'axios';

const CharacteristicTab = () => {

    const [features, setFeatures] = useState([]);
    const {getToken} = useAuth();

    useEffect(() => {
        const getCharacteristics = async () => {
            let apiUrl = `${process.env.REACT_APP_BASE_API}/featureCategory?type=1`;
            let token = getToken();

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `${token.type} ${token.token}`
            }

            try{
                const response = await axios.get(
                    apiUrl,
                    {
                        headers:headers
                    }
                );
                setFeatures(response.data);
            } catch(error){
                console.log(error.response);
            }
        };

        getCharacteristics();
    }, []);

    return (
        <Container >
            <CharacteristicForm features = {features} />
        </Container>
    );
}

export default CharacteristicTab;