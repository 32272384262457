
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import useForm from '../../Hooks/useForm';
import WarningMessage from '../../Components/WarningMessage';
import axios from 'axios';
import { Container, Card, Input, Button, Logo } from './style.js';
import smallLogo from '../../Assets/images/logo.png';
import {useAuth} from '../../Hooks/useAuth';
import {useToast} from '../../Hooks/useToast';
import { useEffect } from 'react';

const Login = (props) => {
    const [disabledButton, setDisabledButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState({show: false, message: ""});
    const [{inputs}, handleChange, handleSubmit] = useForm();
    const { getToken, setToken } = useAuth();
    const location = useLocation();
    const {addToast} = useToast();

    const checkAuthentication = async () => {
        setDisabledButton(true);
        setErrorMessage({...errorMessage, show: false});
        
        let apiUrl = process.env.REACT_APP_API_AUTH;
        const headers = {'Content-Type': 'application/json'}

        try{
            
            const response = await axios.post(apiUrl,{
                login: inputs.cpf,
                password: inputs.password
            }, headers);
            
            setToken(response.data);
            
        }catch(error){
            setErrorMessage({show: true, message: error.response.data.message});
        }
        
        setDisabledButton(false);
    }

    const testeToast = () => {
        addToast("Titulo teste", "Mensagem teste", 0);
    }

    if(getToken()) {
        if(location.state?.from){
            const from = location.state?.from;
            return <Navigate to = {`${from.pathname}${from.search}`} />
        }

        return <Navigate to = {'/profile'} />
        
        
    }

    return (
        <Container>
            <Card>
                <Logo src={smallLogo} alt = "Ooppah Logo" />
                <form onSubmit = {handleSubmit(checkAuthentication)}  autoComplete = "off">
                    <WarningMessage show = {errorMessage.show} > {errorMessage.message} </WarningMessage>
                    <Input type = 'text' name = 'cpf' placeholder = 'CPF' onChange = {handleChange}/>
                    <Input type = 'password' name = 'password' placeholder = 'Senha' onChange = {handleChange} />
                    <Button disabled = {disabledButton}>Enviar</Button>
                </form>
                <p>Esqueceu a senha? <a href={process.env.REACT_APP_RESET_PASSWORD_LINK}>Recupere a senha</a></p>
            </Card>
        </Container>
    );
}

export default Login;