import { Container } from "../style";
import Icon from '../../Icon';

const Tab = ({isActive, activeIcon, inactiveIcon, alt, clickHandle, tabName}) => {


    return (
        <Container>
            {isActive ? 
            <Icon src = {activeIcon} alt = {alt}  width = "1.3em" height = "1.3em" />
            :
            <Icon src = {inactiveIcon} alt = {alt} onclick = {() => { clickHandle(tabName) }} width = "1.3em" height = "1.3em" />
            }
        </Container>
    );
}

export default Tab;