import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useToast } from '../../Hooks/useToast';
import PhotoGroup from "./PhotoGroup";
import PhotoIsolated from "./PhotoIsolated";
import axios from 'axios';
import Modal from '../Modal';
import Spinner from '../Spinner';
import Icon from "../Icon";
import QuestionIcon from '../../Assets/icons/F3.svg'
import { ConfirmButton, Container, DeclineButton, EndButtons, EndGroup, FinishedImage, LimitInfo, LinkArea, Message, TextArea, TextInfo } from "./style";
import ImageCropper from "../ImageCropper";

const PhotoGallery = () => {

    const {addToast} = useToast();

    const {getToken, currentUser, isFree} = useAuth();
    const [photos, setPhotos] = useState([]);
    const [separatedPhotos, setSeparatedPhotos] = useState([]);
    const [cropperType, setCropperType] = useState('4');
    const [croppedImage, setCroppedImage] = useState();
    const [croppedImageDimensions, setcroppedImageDimensions] = useState({});
    const [photoOrder, setPhotoOrder] = useState();
    const [blobFile, setBlobFile] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [finishedCropping, setFinishedCropping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadConcluded, setUploadConcluded] = useState(false);
    const [limitText, setLimitText] = useState("");
    const [showLimitFreeMensage, setShowLimitFreeMensage] = useState(false);
    const homePlansUrl = `${process.env.REACT_APP_HOME_URL}/plans`;
    const productOoppahStoreUrl = "https://www.store.ooppah.com";

    const limitPhotos = currentUser.access_rule.rule.limit_photo;

    const concludeCropping = () => {
        setFinishedCropping(true);
    }

    const insertCroppedImage =(croppedImage) => {
        setCroppedImage(croppedImage);
    }

    const backButton = () => {
        setFinishedCropping(false);
    }

    const confirmImageSend = async () => {
        if(photos.length  >= limitPhotos){
            addToast("Limite atingido", `Você possui um limite de ${limitPhotos} fotos no seu plano atual. Remova algumas e tente novamente.`, 0);
            return;
        }
        if(!croppedImage) return;
        setIsLoading(true);

        let url = `${process.env.REACT_APP_BASE_API}/photo`;
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        const formData = new FormData();
        formData.append('photo', blobFile);
        formData.append('type', cropperType);
        formData.append('order', photoOrder);

        try {
            const response = await axios.post(url, formData, {headers:headers});
            addToast("Adição de foto", "Foto adicionada com sucesso. Estamos preparando sua imagem. Obs: Caso a imagem não carregue aguarde um momento e atualize a página.", 1);
            handleCloseModal();
            setTimeout(function () {
                getUserPhotos();
            }, 5000);
            setLimitText("Limite de fotos: "+photos.length+"/"+limitPhotos);
            
            
        }catch(error){
            addToast("Erro de sistema", error.response.data.message, 0);
            
        }
        setIsLoading(false);
        
    }

    const getMaxPhotoOrder = () => {
        let maxOrder = 0;
        for(let i = 0; i < photos.length; i++ ){
            if(photos[i].order > maxOrder){
                maxOrder = photos[i].order;
            }
        }
        return maxOrder;
    }

    const limitePhotoMessage = () => {
        if(isFree){
            handleOpenShowLimitFreeMensage();
        } else{
            addToast("Limite de fotos", "Você chegou no seu limite de fotos! Delete alguma  foto antiga para adicionar uma nova.", 0);
        }
    }

    const deleteImage = async (photo) => {
        let url = `${process.env.REACT_APP_BASE_API}/photo/${photo.id}`;
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.delete(url, {headers:headers});
            if(photo.type === '4'){
                const newPhotos = separatedPhotos.filter((item) => {return item.id !== photo.id});
                setSeparatedPhotos(newPhotos);
            }else{
                const newPhotos = specifyBlurForLimit(photos.filter((item) => {return item.id !== photo.id}));
                setLimitText("Limite de fotos: "+newPhotos.length+"/"+limitPhotos);
                setPhotos(newPhotos);
            }
            addToast("Remoção de fotos", "Mensagem removida com sucesso.", 1);
        }catch(error){
            addToast("Remoção de fotos", error.response.data.message, 0);
        }
        
    }

    const getUserPhotos = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/photo`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(url,{headers:headers})
            setSeparatedPhotos(response.data.filter((photo) => { return photo.type == "4" }));
            const _photos = specifyBlurForLimit(response.data.filter((photo) => { return photo.type !== "4" }));
            setPhotos(_photos);

        }catch(error){
            addToast("Erro de sistema", "Problemas ao solicitar fotos", 0);
        }
        setLimitText("Limite de fotos: "+photos.length+"/"+limitPhotos);
    };

    const openImageCropper = (type, order = 0) => {
        setCropperType(type);
        setPhotoOrder(order);
        setIsModalOpen(true);
        
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFinishedCropping(false);
        setUploadConcluded(false);
    }

    //take a photo type specified and remove it from array;
    const getPhotoByType = (type, photos) => {
        for(let i = 0; i < photos.length; i++){
            if(photos[i].type == type){
                let photo = photos[i];
                photos.splice(i, 1);
                return photo;
            }
        }
        return null;
    }

    const getPhotoByOrder = (order) => {
        for(let i = 0; i < photos.length; i++ ){
            if(photos[i].order == order){
                return photos[i];
            }
        }

        return null;
    }

    const defineImageStyle = () => {
        if(!croppedImageDimensions) return ({width: '100%'});

        let srcWidth = croppedImageDimensions.width;
        let srcHeight = croppedImageDimensions.height;
        
        let maxWidth = window.innerWidth * 0.8;
        let maxHeight = window.innerHeight * 0.7;

        let ratio = Math.min(maxWidth/srcWidth, maxHeight/srcHeight);

        return ({width: srcWidth*ratio, height: srcHeight*ratio});
    }

    const initGallery = () => {
        const galleries = [];

        const maxOrder = getMaxPhotoOrder();
        let limitGroupsQuantity = Math.ceil(photos.length/4);
        if(photos.length % 4 == 0){
            limitGroupsQuantity = limitGroupsQuantity + 1;
        }
        let limitGroupsOrder = Math.ceil(maxOrder/4);

        let limitGroups = limitGroupsQuantity > limitGroupsOrder ? limitGroupsQuantity : limitGroupsOrder;

        for(let i = 0 ; i < limitGroups; i++){
            const selectedPhotos = [];
            selectedPhotos.push(getPhotoByOrder((i * 4) + 0));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 1));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 2));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 3));

            if(i % 2 == 0){
                galleries.push(<PhotoGroup key = {i} baseOrder = {i} photos = {selectedPhotos} openImageCropper = {photos.length < limitPhotos ? openImageCropper : limitePhotoMessage} deleteImage = {deleteImage}/>);
            } else {
                galleries.push(<PhotoGroup key = {i} baseOrder = {i} photos = {selectedPhotos} type = '2' openImageCropper = {photos.length < limitPhotos ? openImageCropper : limitePhotoMessage} deleteImage = {deleteImage}/> );
            }
        }
        return galleries
    }

    const handleCloseShowLimitFreeMensage = () =>{
        setShowLimitFreeMensage(false);
    }

    const handleOpenShowLimitFreeMensage = () => {
        setShowLimitFreeMensage(true);
    }


    const specifyBlurForLimit = (_photos) => {
        const limit = limitPhotos;
        let counter = 0;

        _photos.map((photo) => {
            if(counter < limit) {
                photo.should_blur = false;
            }else {
                photo.should_blur = true;
            }
            counter++;
        });

        return _photos;
    }

    useEffect(() => {
        getUserPhotos();
    }, []);

    useEffect(() => {
        setLimitText("Limite de fotos: "+photos.length+"/"+limitPhotos);
    }, [photos]);
    


    useEffect(() => {
        if(!croppedImage) return;
        const img = new Image();
        img.src = croppedImage;
        img.onload = () => {
            setcroppedImageDimensions({width:img.width, height: img.height});
        }
    }, [croppedImage]);

    return (
        <Container >
            <LimitInfo>
                <TextArea>
                    {limitText}
                    <Icon 
                      classes= "cursor-click" 
                      width = "1em" 
                      height="1em" 
                      src={QuestionIcon} 
                      alt= "Icone para abrir um modal para mais informações." 
                      onclick={handleOpenShowLimitFreeMensage} 
                    />
                </TextArea>
            </LimitInfo>
            {
                initGallery()
            }
            
            <PhotoIsolated photos = {separatedPhotos} deleteImage = {deleteImage}/>

            <Modal isOpen={isModalOpen} handleClose = {handleCloseModal} >
                
                <ImageCropper 
                    cropperType = {cropperType} 
                    setCroppedImage = {insertCroppedImage}
                    finishedCropping = {finishedCropping} 
                    concludeCropping = {concludeCropping}
                    setBlobFile = {setBlobFile}
                    separetedPhotos = {separatedPhotos}
                />
                {(finishedCropping && croppedImage && (!uploadConcluded) && (!isLoading)) &&
                    <EndGroup>
                        <EndButtons>
                            <DeclineButton onClick={backButton}>
                                Voltar
                            </DeclineButton>
                            <ConfirmButton onClick={confirmImageSend}>
                                Confirmar
                            </ConfirmButton>
                        </EndButtons>
                        <FinishedImage style={defineImageStyle()} src={croppedImage}  />
                    </EndGroup>
                }
                { isLoading &&
                    <Spinner />

                }
            
            </Modal>

            <Modal isOpen={showLimitFreeMensage} handleClose = {handleCloseShowLimitFreeMensage}>
                <TextInfo>
                    Mais fotos aumentam em até 4x suas chances de escalação! <LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e suba até 20 fotos!
                    Não tem foto atual? O Ooppah resolve! <LinkArea href={productOoppahStoreUrl} target="_blank">Realizamos sessões de fotos profissionais no Rio de Janeiro.</LinkArea>
                </TextInfo>
            </Modal>

        </Container>
    );
}

export default PhotoGallery;