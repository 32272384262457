import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0px;
`;

export const RoundContainer = styled.div`
margin-right: 10px;
`
export const TextModalFreeInfo = styled.span`
`;

export const LinkArea = styled.a`
    &hover{
        text-decoration: underline;
    }
`;