import { useEffect, useState } from "react";
import { Container, Link, Links, OoppahInfos, OoppahLinks, OoppahSocials, OoppahText } from "./style";
import LogoGray from '../../Assets/images/logo_gray.svg';
import Icon from "../Icon";
import FacebookIcon from '../../Assets/icons/G6.svg';
import InstagramIcon from '../../Assets/icons/H6.svg';

const Footer = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleChangeWindowSize = () => {
        setScreenWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleChangeWindowSize);
        return () => {

            window.removeEventListener('resize', handleChangeWindowSize)
        }
    }, [])

    const handleFacebookClick = () => {
        window.open('https://pt-br.facebook.com/ooppahoficial/', '_blank');
    }
    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/ooppahoficial/', '_blank');
    }

    if(screenWidth < 500){
        return (
            <Container >
                <Icon src = {LogoGray} alt = "Logo Ooppah" width = "5em" height = "2em"/>
                <OoppahText>
                    OOPPAH SOLUCOES ARTISTICAS LTDA | CNPJ 23.825.830/0001-53
                </OoppahText>
            </Container>
        );
    }

    return (
        <Container >
            <OoppahLinks>
                <OoppahSocials>
                    <Icon src={FacebookIcon} alt = "Facebook Icon" onclick={handleFacebookClick} />
                    <Icon src={InstagramIcon} alt = "Instagram Icon" onclick={handleInstagramClick} classes="marginLeft"  />
                </OoppahSocials>
                <Links>
                    <Link href = "https://ooppah.com/">PÁGINA INICIAL</Link>
                    |
                    <Link href = "https://ooppah.com/#packages" target="_blank">CADASTRE-SE</Link>
                    |
                    <Link href = "https://docs.ooppah.com/terms-of-use" target="_blank">TERMOS DE USO</Link>
                    |
                    <Link href = "https://ooppah.com.br/contato">CONTATO</Link>
                </Links>
            </OoppahLinks>
            <OoppahInfos>
                <Icon src = {LogoGray} alt = "Logo Ooppah" width = "5em" height = "2em"/>
                <OoppahText>
                    OOPPAH SOLUCOES ARTISTICAS LTDA | CNPJ 23.825.830/0001-53
                </OoppahText>

                <OoppahText>
                    Copyright © 2013 - {new Date().getFullYear()}. Todos os direitos reservados
                </OoppahText>
            </OoppahInfos>
        </Container>
    );
}

export default Footer;