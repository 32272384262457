import { ArtisticName, Container, AgeAndCity, LastUpdate } from './style';
import { getAge, capitalizeFirstLetter } from '../../../Support/Util';
import Icon from '../../Icon';
import { SealIcons } from '../../../Support/Contants';

const ProfileShortInfo = ({profile}) => {

    const getProfileLevelICon = (profileLevel) => {

        return SealIcons[profileLevel];
    }

    const returnFormattedDate = (userDate) => {
        const year = userDate.getFullYear(); 
        let month = userDate.getMonth() + 1;
        let day = userDate.getDate();
        day = day.toString().length < 2 ? "0" + day: day;
        month = month.toString().length < 2 ?  "0" + month: month;

        return day + "/" + month + "/" + year;
    } 

    const profileLevel = profile.access_rule.rule.profile_level;
    const birthDate = profile.characteristic.birth_date;
    const state = profile.address.state ? profile.address.state.abbreviation: "";
    const district = profile.address.district ? ", " + profile.address.district.name : "";
    const DRT = profile.drt;
    const DRT_UF = profile.drt_uf;
    const date = new Date(profile.most_recent_date);

    return (
        <Container>
            <ArtisticName>
                <span>{profile.artistic_name}</span>
                {
                    getProfileLevelICon(profileLevel) &&
                    <Icon width='1.5em' height='1.5em' src = {getProfileLevelICon(profileLevel)} alt = "user level icon"/>
                }
            </ArtisticName>
            <AgeAndCity>
                <span className='bold'>{getAge(birthDate)} anos</span>
                <span>|</span>
                <span>{state}{district}</span>
            </AgeAndCity>
            { DRT && DRT_UF &&
                <AgeAndCity>
                    <span>DRT: {DRT_UF} {DRT}</span>
                </AgeAndCity>
            }
            <LastUpdate>
                Ultima atualização: {returnFormattedDate(date)}
            </LastUpdate>
           
        </Container>
    );
}

export default ProfileShortInfo;