import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    margin-left: 5px;
`;

export const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-size: 0.8em;
`;

export const Title = styled.span`

`;

export const Value = styled.span`
    font-weight: bold;
`;