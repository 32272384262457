import { useState, useEffect } from "react";
import { Container, ExpandedDivToBeClicked, PhotoContainer} from "./style";
import Modal from '../../Modal';

const Photo= ({className = "", label = "", photo = null, type = '4', openImageCropper, order, deleteImage}) => {

    const [isExpandedImageOpen, setIsExpandedImageOpen] = useState(false);
    const [imageDimension, setImageDimension] = useState({});

    const getImageSize = () => {
        const image = new Image();
        image.src = photo.original_url;
        image.onload = () => {
            setImageDimension({
                width: image.width,
                height: image.height
            })
        };
    }

    const handleExpandedImageClose = () => {
        setIsExpandedImageOpen(false);
    }

    const handleOpenExpandedImage = () => {
        setIsExpandedImageOpen(true);
    }

    const defineImageStyle = () => {

        let srcWidth = imageDimension.width;
        let srcHeight = imageDimension.height;
        
        let maxWidth = window.innerWidth * 0.8;
        let maxHeight = window.innerHeight * 0.7;

        let ratio = Math.min(maxWidth/srcWidth, maxHeight/srcHeight);

        return ({width: srcWidth*ratio, height: srcHeight*ratio});
    }

    const SelectImageUrl = (isOriginal = false) => {
        const width = window.innerWidth;

        if(isOriginal){
            return photo.original_url;
        }

        if(width >= 600){
            return photo.medium_url;
        }else {
            return photo.small_url;
        }
    }

    useEffect(() => {
        if(photo){
            getImageSize();
        }
    }, [photo]);

    if(!photo){
        return (
            <Container className = {className} >
        
            </Container>
        )
    }



    return (
        <PhotoContainer style={{backgroundImage: `url(${SelectImageUrl(photo.type === '4')})`}} className={className} photo = {photo}>
            <ExpandedDivToBeClicked onClick = {handleOpenExpandedImage}></ExpandedDivToBeClicked>

            <Modal isOpen={isExpandedImageOpen} handleClose = {handleExpandedImageClose}>
                <img src={photo.original_url} style={defineImageStyle()} />
            </Modal>
        </PhotoContainer>
    );
    
}

export default Photo;