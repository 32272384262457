import ProfileList from "../../Components/ProfileList";
import SearchSideBar from "../../Components/SearchSideBar";
import axios from 'axios';
import TopBar from "../../Components/TopBar";
import { Container, PageDelimiter, SpinnerContent } from "./style";
import { useEffect, useState } from "react";
import Spinner from "../../Components/Spinner";
import { getCookie, setCookie } from "../../Support/Cookies";
import { useAuth } from "../../Hooks/useAuth";

const Exhibition = () => {

    const [profiles, setProfiles] = useState([]);
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const cookieDomain = process.env.NODE_ENV === "development" ? "localhost" : ".ooppah.com";
    const {getProducerToken} = useAuth();

    const getProfiles = async (page = 1) => {
        setIsLoading(true);
        const producerToken = getProducerToken();

        let headers = {};
        if(getCookie('Search-Cookie') === undefined){
            setCookie('Search-Cookie', searchParams+'&page='+page, 30, cookieDomain);
        } else{
            let searchCookie = getCookie('Search-Cookie');
            headers = {
                'Content-Type': 'application/json',
                'Search-Cookie': searchCookie,
                'New-Search': searchParams+'&page='+page
            }
        }

        if(producerToken !== false){
            headers.Authorization =  `Bearer ${producerToken.token}`;
        }

        const url = `${process.env.REACT_APP_BASE_API}/profile?page=${page}&${searchParams}`;
        try {

            const response = await axios.get(url, {headers:headers});
            setProfiles(response.data.data);
            //delete response.data.data;
            setPagination(response.data);
        
        }catch (error) {
            console.log(error);
        }
        setCookie('Search-Cookie', searchParams+'&page='+page, 30, cookieDomain);
        setIsLoading(false);
    }

    const handleSearchSubmit = (params) => {
        const searchParams = new URLSearchParams(params);
        const parameters = searchParams.toString();
        setSearchParams(parameters);
    }

    useEffect(() => {
        getProfiles();
    }, [searchParams]);


    return (
        <Container>
            <TopBar />
            <PageDelimiter>
                <SearchSideBar handleSearchSubmit = {handleSearchSubmit} />
                {
                    isLoading ?
                    <SpinnerContent>
                        <Spinner dimension = {"50px"}/>
                    </SpinnerContent>
                    
                    :
                    <ProfileList profiles = {profiles} pagination = {pagination} getProfiles = {getProfiles}/>
                }
                
            </PageDelimiter>
        </Container>
    );
}

export default Exhibition