import { useEffect, useState } from "react";
import { useAuth } from "../../../../../Hooks/useAuth";
import Icon from "../../../../Icon";
import { CleanAll, Container, Option, Options, SelectedFeature, SelectionGroup, Selections } from "./style";
import BackIcon from '../../../../../Assets/icons/G5.svg';
import XIcon from '../../../../../Assets/icons/K2.svg';

const SpecialConditionSelector = ({options, usersSelection, setUsersSelection, featureCategoryIds}) => {

    const {currentUser} = useAuth();
    
    const [usersOption, setUsersOptions] = useState([]);
    
    const getFeatureFromUser = (categoryId) =>{
        const features = currentUser.profile_features_characteristics.filter(( element )=>{
            return (element.feature.feature_category.id == categoryId);
        });

        const featuresClean = features.map((element) => {
            return {id: element.feature.id, name: element.feature.name}
        });
        return featuresClean;
    }

    const addFeature = (feature) => {
        //remove from array options
        const updatedOptions = usersOption.filter( (item) => {
            return item.id !== feature.id;
        });
        setUsersOptions(updatedOptions);
        //add on the selections array
        setUsersSelection([...usersSelection, feature]);
    }

    const removeAll = () => {
        setUsersOptions([...usersOption, ...usersSelection]);
        setUsersSelection([]);
    }

    const removeFeature = (feature) => {
        const updatedSelections = usersSelection.filter((item) => {
            return item.id !== feature.id;
        });

        setUsersSelection(updatedSelections);
        setUsersOptions([...usersOption, feature]);
    }
    

    useEffect(()=>{
        let tempUserSelections = [];
        featureCategoryIds.map((id) => {
            tempUserSelections = [...tempUserSelections, ...getFeatureFromUser(id)]
        });
        
        setUsersSelection(tempUserSelections)
    },[])

    useEffect( () => {
        const tempOptions = options.filter((feature) => {
            let existId = usersSelection.find( (element) => {
                return (element.id == feature.id)
            })
            if(existId){
                return false;
            }
            return true;
        });

        setUsersOptions(tempOptions);
    },[options])

    return (
        <Container>
            <Options>
                {
                    usersOption.map( (feature, index) => {
                        return (
                            <Option onClick={() => {addFeature(feature)}} key = {index}>{feature.name}</Option>
                        );
                    })
                }
            </Options>
            <SelectionGroup>    
                <Selections>
                    {
                        usersSelection.map( (feature, index) => {
                            return (
                                <SelectedFeature key = {index}>
                                    <span>{feature.name}</span>
                                    <Icon 
                                        src = {XIcon} 
                                        width = {'0.8em'} 
                                        height = {'0.8em'}
                                        onclick = {() => {removeFeature(feature)}}
                                    />
                                </SelectedFeature>
                                
                            );
                        })
                    }
                </Selections>
                <CleanAll type = "button" onClick={removeAll}>
                    Apagar todos
                    <Icon src = {BackIcon} width = {"1.6em"} height = {"1.6em"}/>
                </CleanAll>
            </SelectionGroup>
        </Container>
    );
}

export default SpecialConditionSelector;