import { Button, ButtonArea, Container, Content, Text, Title } from "./style";

const CredentialsTab = ({onChange}) => {
    const nextState = 5;

    return(
        <Container>
            <Content>
                <Title>
                    Atualizações de senhas, endereços e formas de contato.
                </Title>

                <Text>
                    Para atualizar a sua senha, endereço para correspondência, e-mail, 
                    telefones e outras informações, por gentileza, utilize o menu de 
                    configurações na página inicial da plataforma, após aceitar os termos
                    de uso.
                </Text>

                <ButtonArea>
                        <Button onClick={() => {onChange(nextState - 2)}}>
                            Voltar
                        </Button>

                        <Button onClick={() => {onChange(nextState)}}>
                            Próximo passo
                        </Button>
                </ButtonArea>
            </Content>
        </Container>
    );
}

export default CredentialsTab;