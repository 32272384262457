import styled from "styled-components";


export const Container = styled.div`
    
    span {
        display: inline;
        color: #3A4659;
        margin-right: 6px;
        font-size: 0.9em;
    }

    padding: 3px 5px;
    margin: 5px 0px;
    cursor: pointer;

    &.selected {
        background-color: #BAC7D6;
    }
`;


export const Content = styled.div`
    display: flex;

`;

export const Element = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
   

`;