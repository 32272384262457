import { CircleContainer, Container, Paragraph, TextContainer, Title } from "./style";
import PercentageCircle from '../PercentageCircle';
import {useAuth} from '../../Hooks/useAuth';
import { useEffect, useState } from "react";
import axios from 'axios';

const ProfileCompleteness = () => {
    const {currentUser,getToken} = useAuth();
    const [percentage, setPercentage] = useState(0);
    const total = 17;

    

    useEffect( async () => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/profile/completeness`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(
                apiUrl,
                {
                    headers:headers
                }
            );

            let count = 0;
            if(currentUser.artistic_name){
                count++;
            }
            if(currentUser.profile_photo_original){
                count++;
            }
            if(currentUser.cv || currentUser.cv_url){
                count++;
            }
        
            if(currentUser.name_variation){
                count++;
            }
        
            if(currentUser.contact.contact_email){
                count++;
            }
        
            if(currentUser.contact.phones[0]){
                count++;
            }
        
            if(currentUser.address.state){
                count++;
            }
        
            if(currentUser.address.district){
                count++;
            }
        
            if(currentUser.characteristic.weight){
                count++;
            }
        
            if(currentUser.characteristic.height){
                count++;
            }
        
            if(currentUser.characteristic.about){
                count++;
            }
        
            if(currentUser.characteristic.mannequins){
                count++;
            }
        
            if(currentUser.characteristic.shoe_size){
                count++;
            }
        
            if(currentUser.profile_features.length > 0){
                count++;
            }
        
            if(currentUser.profile_features_characteristics.length > 0){
                count++;
            }

            if(response.data.videos > 0) {
                count++;
            }

            if(response.data.photos > 0) {
                count++;
            }

            setPercentage(Math.ceil((count/total)*100));
            
            
        }catch(error){
            console.log(error.response);
        }

    }, [currentUser]);

    return (
        <Container>
            <CircleContainer>
                <PercentageCircle percentage = {percentage}/>
            </CircleContainer>
            
            { percentage < 100 &&
                <TextContainer>
                    <Title>
                        Quase lá!
                    </Title>
                    <Paragraph>
                        Complete o seu cadastro e garanta a melhor posição em nossos mecanismos de busca.
                    </Paragraph>
                </TextContainer>
            }
            { percentage == 100 &&
                <TextContainer>
                <Title>
                   Muito bem!
                </Title>
                <Paragraph>
                    Lembre-se de manter suas informações mais atualizadas o possível.
                </Paragraph>
            </TextContainer>
            }
            
        </Container>
    );
}

export default ProfileCompleteness;