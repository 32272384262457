import { AddFriend, ContactWhatsApp, Container } from "./style";
import {useAuth} from '../../../Hooks/useAuth';
import AddIcon from '../../../Assets/icons/H3.svg';
import WhatsAppIcon from '../../../Assets/icons/K4.svg';
import Icon from "../../Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { useToast } from "../../../Hooks/useToast";
import { transformPhone } from "../../../Support/Util";

const Interactions = ({isContactOpen, contacts, name, profile}) => {

    const {isLoggedIn, currentUser, getToken} = useAuth();
    const [whatsappPhone, setWhatsappPhone] = useState(undefined);
    const [disabledButton, setdisabledButton] = useState(false);
    const [phoneUrl, setphoneUrl] = useState(undefined);
    const {addToast} = useToast();

    const addFriend = async () => {
        if(profile.id === currentUser.id) return ;
        const profile_requester = currentUser.id;
        const profile_requested = profile.id;
        setdisabledButton(true);

        let apiUrl = `${process.env.REACT_APP_BASE_API}/friendship`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.post(
                apiUrl,{
                    profile_id: profile_requester,
                    requested_id: profile_requested
                },{headers:headers}
            );
            addToast("Solicitação de amizade", "Solicitação enviada com sucesso!", 1);
            
        }catch(error){
            if(error.response.status === 400){
                console.log(error.response.data.message);
                addToast("Solicitação de amizade", error.response.data.message, 0);
            }
        }
    }

    useEffect(() => {
        let phone = profile.contact.phones.find((item) => {
            return item.is_whatsapp === "1";
        })
        setWhatsappPhone(phone);
        if(phone){
            setphoneUrl(phone.number.replace(/\D/g, ""));
        }
    }, [profile]);

    return (
        <Container>
            {
                isLoggedIn() &&
                <AddFriend onClick={addFriend} disabledBtn={disabledButton} >
                    <Icon src = {AddIcon} alt = "Add ícone" />
                    Adicionar ao círculo
                </AddFriend>
            }
            
            {
                whatsappPhone && isContactOpen && 
                <ContactWhatsApp
                href={`https://wa.me/55${whatsappPhone.number? phoneUrl : ""}?text=Oi%20${name},%20te%20achei%20no%20Ooppah!`}
                target="_blank">
                    <Icon src = {WhatsAppIcon} alt = "ícone whatsapp"/>
                    {transformPhone(whatsappPhone.number)}
                </ContactWhatsApp>
            }
           
        </Container>
    );
}

export default Interactions