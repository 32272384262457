import styled from "styled-components";

export const Container = styled.div`
    max-width: 600px;
    max-height: 800px;
    display:${props => props.finishedCropping ? 'none': 'flex'};
    flex-direction column;
    align-items: center;

    input {
        display:none;
    }

    label {
        background-color:#121D2E;
        cursor:pointer;

        padding: 3px 7px;

        border-style: solid;
        border-radius: 6px;
        border-width: 1px;

        color: white;
    }

    label:hover {
        background-color: #2d4872;
    }
`;

export const ControllerGroup = styled.div`
    display: flex;
    margin-bottom: 5px;
    button {
        cursor:pointer;
        margin-right: 5px;
    }

`;

export const FileGroup = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

export const FinishButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;

    padding: 3px 7px;

    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor:pointer;

    &:disabled {
        
        opacity: 0.5;
    }

    &:hover {
        background-color: #003636;
    }

`;

export const GalleryButton = styled.label`
    background-color:#121D2E;
    cursor:pointer;

    padding: 3px 7px;

    border-style: solid;
    border-radius: 6px;
    border-width: 1px;

    color: white;

    &:hover {
        background-color: #2d4872;
    }
`;

export const GalleryContainer = styled.div`
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    max-height: 600px;
    overflow-y: scroll;
`;

export const PhotoWrapper = styled.div`
    position: relative;
`;

export const PhotoClicker = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    cursor: pointer;
`;