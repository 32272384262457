import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const ButtonGroup = styled.div`
    display: flex;
    font-size: 0.7em;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
        color: #8B8B8B;
    }

`;

export const CVText = styled.div`
    display: flex;
    text-align: left;
    width: 100%;
    color: #3A4659;
    box-sizing: boder-box;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;

`;

export const DownloadButton = styled.a`
    
    display: flex;
    align-items: center;
    margin-left: 5px;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    
    text-decoration: none;
    color: white;

    background-color: #121D2E;
    
    cursor: pointer;


    &: hover {
        background-color: #2d4872;
    }

`;