import Icon from '../Icon';
import QuestionIcon from '../../Assets/icons/F3.svg';
import Modal from '../Modal';
import { Container, Input, JustifiedText, Label, LabelWrapper } from './style';
import { useState } from 'react';

const InputV2  = ({label = null, name, placeholder, onChange, value, className, modalDescription = "", maxLength}) => {

    const [isOpen, setIsOpen] = useState(false);


    const handleCloseModal = () => {
        setIsOpen(false);
    }

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    return (
        <Container className = {className}>
            <LabelWrapper>
                {
                    label &&
                    <Label>{label}</Label>
                }
                {
                    modalDescription.length > 0 &&
                    <Icon src = {QuestionIcon} onclick = {handleOpenModal}/>
                }   
                
            </LabelWrapper>
            <Input 
                name = {name}
                placeholder = {placeholder}
                onChange = {onChange}
                value = {value}
                maxLength = {maxLength}
            />

            <Modal isOpen={isOpen} handleClose ={handleCloseModal} >
                <JustifiedText>
                    {modalDescription}
                </JustifiedText>
            </Modal>
        </Container>
    )
}

export default InputV2;