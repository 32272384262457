import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.isActive ? "#2d3847" : "#ccd5e1"};
    margin: 0 3px;
    align-items: center;

    cursor: pointer;
`;

export const Circle = styled.div`
    width: 6px;
    height: 6px;

    border-style: solid;
    border-width: 4px;
    border-radius: 50%;
    border-color: ${props => props.isActive ? "#131f30" : "#8ba1ba"};
`;

export const Rectangle = styled.div`
    border-radius: 3px;
    background-color: ${props => props.isActive ? "#131f30" : "#8ba1ba"};
    color: white;
    width: 24px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MiniCircle = styled.div`
    width: 3px;
    height: 3px;
    border: none;
    border-radius: 50%;
    background-color: white;
    margin-right: 1px;
`;
