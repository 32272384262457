import { objectIsEmpty } from "../../Support/Util";
import PageSelector from "./PageSelector";
import { Container } from "./style";

const Paginator = ({pagination, getResources}) => {

    const getArrayPages = () => {
        if(objectIsEmpty(pagination)) return []
        
        const pages = []; 
        const currentPage = pagination.current_page;
        const lastPage = pagination.last_page;
        const firstPage = 1;

        pages.push(currentPage);

        let atualPage = currentPage - 1;
        
        for(let i = 3; i > 0; i--){
            if(atualPage < 1) break;
            pages.unshift(atualPage);
            atualPage--;
        }

        atualPage = currentPage + 1;
        for(let i = 0; i < 3; i++){
            if(atualPage > lastPage) break;
            pages.push(atualPage);
            atualPage++;
        }

        if(pages[0] !== 1){ 
            pages.unshift('inicio');
        }

        if(pages[pages.length - 1] !== lastPage){
            pages.push('final');
        }

        return pages;
    }

    return(
        <Container>
            {
                getArrayPages().map((item, index) => {
                    return (
                        <PageSelector 
                            key = {index}
                            pagination = {pagination}
                            getResources = {getResources} 
                            isActive = {item == pagination.current_page}
                            pageNumber = {item}
                        />  
                    )
                })
            }
            
        </Container>
    );
}

export default Paginator;