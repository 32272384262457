import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #DAE4F0;
`;

export const Content = styled.div`
    width: 100%;
    justify-content: center;
    padding: 5px 2px;
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size : 1em;
    padding: 10px 5px;

    text-align: justify;
    text-justify: inter-word;
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size : 0.8em;
    padding: 7px 5px;

    text-align: justify;
    text-justify: inter-word;
`;

export const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Button = styled.button`
    margin: auto;
    border: none;
    background: none;
    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;
