import { BackButton, ConfirmButton, Container, DeleteWrapper, PhotoContainer, MessageText, ExpandedDivToBeClicked, FreeWrapper, LinkArea, TextInfo, Ooppah } from "./style";
import PhotoPlusIcon from '../../../Assets/icons/A6.svg';
import TrashIcon from '../../../Assets/icons/C6.svg';
import Icon from '../../Icon';
import Modal from "../../Modal";
import { useEffect, useState } from "react";

const Photo= ({className = "", label = "", photo = null, type = '4', openImageCropper, order, deleteImage}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpandedImageOpen, setIsExpandedImageOpen] = useState(false);
    const [imageDimension, setImageDimension] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const homePlansUrl = `${process.env.REACT_APP_HOME_URL}/plans`;
    const productOoppahStoreUrl = "https://www.store.ooppah.com";

    const openModalInfoPhotos = () => setModalOpen(true);
    const closeModalInfoPhotos = () => setModalOpen(false);

    const getImageSize = () => {
        const image = new Image();
        image.src = photo.original_url;
        image.onload = () => {
            setImageDimension({
                width: image.width,
                height: image.height
            })
        };
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }
    
    const handleExpandedImageClose = () => {
        setIsExpandedImageOpen(false);
    }

    const openModal = () =>{
        setIsModalOpen(true);
    }

    const handleOpenExpandedImage = () => {
        setIsExpandedImageOpen(true);
    }

    const defineImageStyle = () => {

        let srcWidth = imageDimension.width;
        let srcHeight = imageDimension.height;
        
        let maxWidth = window.innerWidth * 0.8;
        let maxHeight = window.innerHeight * 0.7;

        let ratio = Math.min(maxWidth/srcWidth, maxHeight/srcHeight);

        return ({width: srcWidth*ratio, height: srcHeight*ratio});
    }

    const SelectImageUrl = (isOriginal = false) => {
        const width = window.innerWidth;

        if(isOriginal){
            return photo.original_url;
        }

        if(width >= 600){
            return photo.medium_url
        }else {
            return photo.small_url
        }
    }

    useEffect(() => {
        if(photo){
            getImageSize();
        }
    }, [photo]);

    if(!photo){
        return (
            <Container className = {className} >
                <Icon onclick = {() => {openImageCropper(type, order)}} src = {PhotoPlusIcon} width = "2em" height = "2em" paddingLeft = "10px"/>
                <span>{label}</span>
            </Container>
        )
    }

    return (
        <PhotoContainer style={{backgroundImage: `url(${SelectImageUrl(photo.type === '4')})`}} className={className} photo = {photo} >
            <DeleteWrapper>
                <Icon src = {TrashIcon} paddingLeft = {"3px"} onclick = {() => {openModal()}} />
            </DeleteWrapper>
            
            {photo.should_blur &&
              <FreeWrapper> 
                <span>Imagem indisponível para exibição!</span>
                <button onClick={openModalInfoPhotos}>Saiba mais!</button>
              </FreeWrapper>
            }
            
            <ExpandedDivToBeClicked onClick = {handleOpenExpandedImage}></ExpandedDivToBeClicked>

            <Modal isOpen = {isModalOpen} handleClose = {handleCloseModal}>
                <MessageText>Tem certeza que deseja deleta a imagem ?</MessageText>
                <BackButton onClick={handleCloseModal}>Voltar</BackButton>
                <ConfirmButton onClick={() => {deleteImage(photo); setIsModalOpen(false)}}>Deletar</ConfirmButton>
            </Modal>
            <Modal isOpen={isExpandedImageOpen} handleClose = {handleExpandedImageClose}>
                <img src={photo.original_url} style={defineImageStyle()} />
            </Modal>

            <Modal isOpen={modalOpen} handleClose={closeModalInfoPhotos}>
              <TextInfo>
                <span>Você ultrapassou o limite de fotos do seu plano!</span>
                <span>Mais fotos aumentam em até 4x suas chances de escalação!</span>
                <span>
                  <LinkArea href={homePlansUrl} target="_blank">Confira nossos planos!</LinkArea> 
                </span> 
                <span>Não tem foto atual? O <Ooppah>Ooppah</Ooppah> resolve!</span> 
                <LinkArea href={productOoppahStoreUrl} target="_blank">
                  Realizamos sessões de fotos profissionais no Rio de Janeiro.
                </LinkArea>
              </TextInfo>
            </Modal>
        </PhotoContainer>
    );
    
}

export default Photo;