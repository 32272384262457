import { Container, Description } from "./style";
import useWindowSize from '../../Hooks/useWindowSize';
import Icon from '../Icon';

const Tab = ({isActive, activeIcon, inactiveIcon, alt, clickHandle, tabName, iconSizes = "1.3em", tabDescription}) => {

    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <Container>
            {isActive ? 
            <Icon src = {activeIcon} alt = {alt}  width = {iconSizes} height = {iconSizes} />
            :
            <Icon src = {inactiveIcon} alt = {alt} onclick = {() => { clickHandle(tabName) }} width = {iconSizes} height = {iconSizes} />
            }
            {windowWidth > 700 &&
            <Description isActive = {isActive} onClick = {() => { clickHandle(tabName)}}>{tabDescription}</Description>
            }
        </Container>
    );
}

export default Tab;