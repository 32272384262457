import styled from "styled-components";

const grid1 = "'full-body  painel painel'" + "\n" + "'full-body three-by-four-1 three-by-four-2'"
const grid2 = "'painel painel full-body'" + "\n" + "'three-by-four-1 three-by-four-2 full-body'"

export const Container = styled.div`
    display: grid;

    grid-template-areas:
    ${props => props.type == 1? grid1 : grid2};

    grid-template-columns: 32% 32% 32%;
    grid-template-rows: 48% 48%;

    gap: 5px;


    .full-body {
        grid-area: full-body;
    }

    .three-by-four-1 {
        grid-area: three-by-four-1;
    }

    .three-by-four-2 {
        grid-area: three-by-four-2;
    }

    .painel {
        grid-area: painel;
    }

    @media(min-width: 320px){
        height: 220px;
    }

    @media(min-width: 340px){
        height: 230px;
    }

    @media(min-width: 360px) {
        height: 245px;
    }

    @media(min-width: 370px) {
        height: 260px;
    }

    @media(min-width: 380px) {
        height: 270px;
    }

    @media(min-width: 400px) {
        height: 290px;
    }

    @media(min-width: 600px){
        height: 350px;
        width: 465px;
        
    }
    @media(min-width: 1080px) {
        grid-template-columns: 155px 155px 155px;
        grid-template-rows: 175px 175px;
        height: 350px;
        width: 465px;
        margin-bottom: 7px;
    }

`;