import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0px 5px;
`;

export const Title = styled.div`
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    font-size: 0.8em;

    @media(min-width: 1080px){
        padding: 15px 0px;
    }
`;

export const Button = styled.button`
    margin: auto;
    border: none;
    background: none;
    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const Text = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    font-size: 0.8em;

    @media(min-width: 1080px){
        padding: 15px 0px;
    }
`;

export const VideoArea = styled.div`
    width: 100%; 
`;

export const VideoContainer = styled.div`   
    position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
    }
`;