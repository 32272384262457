
import { Container, LinkArea, Metric, MetricLegend, TextModalFreeInfo } from './style.js'
import StatisticIcon from '../../Assets/icons/A2.svg';
import OoppahJobcIcon from '../../Assets/icons/trabalhos.svg';
import ValueField from './ValueField';
import EyeIcon from '../../Assets/icons/B2.svg';
import LockedField from './LockedField';
import Icon from '../Icon';
import { hasFree } from '../../Support/Util.js';
import Modal from '../Modal/index.js';
import { useState } from 'react';


const ProfileViewerStatisticBar = ({profile}) => {

    const hasAccessCount = profile.access_rule.rule.has_access_count;
    const hasSearchCount = profile.access_rule.rule.has_search_count;
    const searchCount = profile.metric.search_count;
    const accessCount = profile.metric.access_count;
    const isFree = hasFree(profile);

    const homePlansUrl = `${process.env.REACT_APP_HOME_URL }/plans`;
    const [showMessageFree, setshowMessageFree] = useState(false);
    const [isSearchIcon, setIsSearchIcon] = useState(false);

    const handleCloseMessageFree = () => {
        setshowMessageFree(false);
    };

    const handleOpenMessageFree = (left) => {
        setIsSearchIcon(left);
        setshowMessageFree(true);
    }

    return (
        <Container>
                <Metric> 
                    {hasAccessCount == 1 && (!isFree)?
                        <ValueField imageIcon = {StatisticIcon} fieldNumber = {searchCount} iconAlt = {"search icon"} />
                    :
                        <LockedField onclick={(() =>handleOpenMessageFree(true))}/>
                    }   
                    <MetricLegend>Pesquisas</MetricLegend>
                </Metric>

                { profile.ooppah_job == 1 && !isFree &&
                    <Metric>
                        <Icon src = {OoppahJobcIcon} width = "1.4em" height = '1.4em'/>
                        <MetricLegend>Já conseguiu trabalho pelo Ooppah</MetricLegend>
                    </Metric>
                }

                <Metric>
                    { hasSearchCount == 1 && (!isFree)?
                        <ValueField imageIcon = {EyeIcon} fieldNumber = {accessCount} iconAlt = {"access icon"} />
                    :
                        <LockedField onclick={(() =>handleOpenMessageFree(true))}/>
                    }
                    <MetricLegend>Visualizações do perfil</MetricLegend>
                </Metric>

                <Modal isOpen={showMessageFree} handleClose = {handleCloseMessageFree}>
                    { isSearchIcon &&
                        <TextModalFreeInfo>
                            Descubra quantas vezes seu perfil apareceu no resultado de pesquisadores!
                            &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e passe na frente de todos os perfis gratuitos!
                        </TextModalFreeInfo>
                    }
                    { (!isSearchIcon) &&
                        <TextModalFreeInfo>
                            Descubra se seu perfil está realmente chamando a atenção dos contratantes!
                            &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e acesso ao número de visitantes do seu perfil!
                        </TextModalFreeInfo>
                    }
                </Modal>
        </Container>
    );


}

export default ProfileViewerStatisticBar;