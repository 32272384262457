import { useEffect, useState } from "react";
import { BodyBar, Button, ButtonArea, Container, ErrorMessage, HeaderBar, 
    IconArea, LeftBar, ModalMessage, RightBar, SmallContent } from "./style";
import PhotoIcon from '../../Assets/icons/F2B.svg';
import DataIcon from '../../Assets/icons/I2B.svg';
import SocialIcon from '../../Assets/icons/C3A.svg';
import CredentialIcon from '../../Assets/icons/H1.svg';
import VideoTab from "./VideoTab";
import Icon from "../Icon";
import PhotoTab from "./PhotoTab";
import ProfileInfoTab from "./ProfileInfoTab";
import SlugTab from "./SlugTab";
import CredentialsTab from "./CredentialsTab";
import AcceptTerms from "../AcceptTerms";
import { useAuth } from "../../Hooks/useAuth";
import { NavLink, Redirect } from "react-router-dom";
import { useNavigate } from 'react-router';
import axios from 'axios';
import Modal from "../Modal";

const Wizard = () => {

    const [title, setTitle] = useState("Bem-vindo(a) ao nosso wizard!");
    const [tabState, setTabState ] = useState(0);

    const [artisticName, setArtisticName] = useState("");
    const [sex, setSex] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [slug, setSlug] = useState("");
    const [phones, setPhones] = useState();
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const {logOut, getToken, currentUser, getUser, isFree} = useAuth();

    const [doneStep1, setDoneStep1] = useState(false);
    const [doneStep2, setDoneStep2] = useState(false);
    const [doneStep3, setDoneStep3] = useState(false);
    const [doneStep4, setDoneStep4] = useState(false);

    const isFreemium = currentUser.access_rule.rule.code === "OOPPAH_PROFILE_FREEMIUM";
    let navigate = useNavigate();

    const changeTabState = (newStage) => {
        switch (newStage) {
            case 1:
                setTitle("Fotos");
                setTabState(1);
                break;
            case 2:
                setTitle("Dados pessoais");
                setTabState(2);
                break;
            case 3:
                setTitle("Link pessoal");
                setTabState(3);
                break;
            case 4:
                setTitle("Credenciais");
                setTabState(4);
                break;
            case 5:
                setTitle("Termos de uso");
                setTabState(5);
                break;
            default:
                setTabState(0);
                break;
        }

    }

    const sendData = async () => {

        let url = `${process.env.REACT_APP_BASE_API}/profile/wizard`;
        let token = getToken();
        setShowMessage(false);
        const newProfileWizard = {
            artistic_name: artisticName,
            sex: sex,
            birth_date: birthdate,
            slug: slug,
            phones: phones,
            state: state.id,
            district: district.id,
            done_wizard: 1,
            accepted_terms: 1
        };
        

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, newProfileWizard ,{headers:headers});
            await getUser();
            navigate('/profile/edit');
        }catch(error){
            let outputMessage = "";
            setShowMessage(true);
            //setServerMessage(error.response.data);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;

                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
            setIsModalOpen(true);
        }
    }

    const changeTab = (newState) =>{
        if(newState === 1 && tabState === 0){
            changeTabState(newState);
        }

        if(newState === 1 && doneStep1){
            changeTabState(newState);
        }

        if(newState === 2 && doneStep2){
            changeTabState(newState);
        }

        if(newState === 3 && doneStep3){
            changeTabState(newState);
        }

        if(newState === 4 && doneStep4){
            changeTabState(newState);
        }

    }

    useEffect(()=> {
        setArtisticName(currentUser.artistic_name);
        setSex(currentUser.characteristic.sex ? currentUser.characteristic.sex: "" );
        setBirthdate(currentUser.characteristic.birth_date);
        setSlug("");
        setPhones(currentUser.contact.phones.length > 0 ? [currentUser.contact.phones[0]] : [{"type":"","is_whatsapp":"" , "number":"", "visibility_permission":""}]);
        setState(currentUser.address.state);
        setDistrict(currentUser.address.district);
    },[]);

    return(
        <Container>
            <HeaderBar>
                <span>{title}</span>
            </HeaderBar>

            <BodyBar>
                <LeftBar>
                    <IconArea backgroundColor={tabState == 1 ? "#DAE4F0" : ""} onClick={() => {changeTab(1)}}>
                        <Icon src = {PhotoIcon} paddingLeft={7} /> <span className="text-icons"> Foto do perfil</span>
                    </IconArea>

                    <IconArea backgroundColor={tabState == 2 ? "#DAE4F0" : ""} onClick={() => {changeTab(2)}}>
                        <Icon src = {DataIcon} paddingLeft={7}/> <span className="text-icons"> Dados pessoais</span>
                    </IconArea>

                    <IconArea backgroundColor={tabState == 3 ? "#DAE4F0" : ""} onClick={() => {changeTab(3)}}>
                        <Icon src = {SocialIcon} paddingLeft={7}/> <span className="text-icons"> Link pessoal</span>
                    </IconArea>

                    <IconArea backgroundColor={tabState == 4 ? "#DAE4F0" : ""} onClick={() => {changeTab(4)}}>
                        <Icon src = {CredentialIcon} paddingLeft={7}/> <span className="text-icons"> Credenciais</span>
                    </IconArea>
                </LeftBar>

                <RightBar>
                    { tabState == 0 &&
                        <VideoTab onChange={changeTabState} />
                    }

                    { tabState == 1 &&
                        <PhotoTab onChange={changeTabState} setDoneStep ={setDoneStep1}/>
                    }

                    { tabState == 2 &&
                        <ProfileInfoTab onChange={changeTabState}  artisticName={artisticName}  setArtisticName = {setArtisticName}
                            sex ={sex} setSex={setSex} birthdate={birthdate} setBirthdate={setBirthdate} 
                            phones={phones} setPhones={setPhones} state={state} setState={setState} district={district}
                            setDistrict={setDistrict} setDoneStep ={setDoneStep2} isFree={isFree} isFreemium={isFreemium}

                        />
                    }

                    { tabState == 3 &&
                        <SlugTab onChange={changeTabState} slug={slug} setSlug={setSlug} setDoneStep ={setDoneStep3}/>
                    }

                    { tabState == 4 &&
                        <CredentialsTab onChange={changeTabState} setDoneStep ={setDoneStep4}/>
                    }

                    { tabState == 5 &&

                        <SmallContent>
                            <AcceptTerms/>
                            
                            <ButtonArea>

                                <NavLink to="/" onClick={logOut}>
                                    <Button>
                                        Não aceito
                                    </Button>
                                </NavLink>
                                
                                
                                <Button onClick={sendData}>
                                    Aceitar e continuar
                                </Button>
                                
                            </ButtonArea>

                        </SmallContent>
                    }

                </RightBar>

                <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>

                    {showMessage &&
                        <ErrorMessage state = {!showMessage}>{serverMessage}</ErrorMessage>
                    }
                </Modal>

            </BodyBar>

        </Container>
    );

};

export default Wizard;