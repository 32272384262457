import {Text} from './style';

const TextLink = ({onclick, children, color, fontSize, classes = ""}) => {

    return (
        <Text className = {classes} onClick={onclick} 
            color = {color} fontSize = {fontSize}
        >
            {children}
        </Text>
    );
}

export default TextLink