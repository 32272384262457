import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 95%;
    @media(min-width: 1080px){
            width: 30%;
        .maximum-width {
            width: 100%;
        }
    }
    
`;

export const Curtain = styled.div`
    background-color: black;
    background: rgba(255,255,255,.8);
    position: absolute;
    top:0; left:0; right:0; bottom:0;
    z-index: 1000;
    color: black;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    p {
        text-align: center;
        opacity: 1;
    }

    div {
        background-color: #e6f5fa;
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
    }

    a {
        display: block;
        text-decoration: none;
        color: black;
        font-weight: bold;
        margin-top: 5px;
        font-style: italic;
    }
`;

export const RedirectLink = styled.div`
    margin-top: 10px;
    a {
        color: white;
    }

    a:visited {
        color:white;
    }
`;

export const KeywordBox = styled.div`
    background-color: #DAE4F0;

    padding-top: 10px;
    padding-left: 10px;

    border-radius: 3px;

    margin-bottom: 15px;
`;

export const KeywordGroup = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 15px;
    margin-top: 10px;
`;

export const Title = styled.h1`
    color: #131E2F;
    margin: 0;
    font-size: 1em;
    margin-right: 10px;
`;

export const AdvancedSearchGroup = styled.div`
    background-color: #DAE4F0;
    
    padding: 10px;
    padding-bottom: 20px;

    border-radius: 3px;

    margin-bottom: 15px;
`;

export const TitleGroup = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.label`
    margin-top: 10px;
    margin-bottom: 10px;
    display:inline-block;
`;

export const Spacer = styled.div`
    margin-bottom: 20px;
`

export const ButtonGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ButtonSearch = styled.button`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    width: 70%;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    height: 30px;
`;

export const ButtonCleanFilter = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    border: none;
    background: none;

    background-color: #DAE4F0;

    border-radius: 7px;
    border-style: solid;
    border-width: 2px;
    border-color: #bdcbdf;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    height: 30px;

`;

export const DisplayIconInfo = styled.div`
    display: flex;
    align-items: center;

    span{
        font-size: 1.5em;
    }

`;

export const SpanIconButtonTouch = styled.button`
    max-width: 75px;
    display: none;   
    background-color: rgb(218, 228, 240);
    color: #183A78;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.0em;

    span {
        padding-right: 5px;
    } 
    

    @media (hover: none) {
        display: flex;
    }
`;


export const SpanIconButton = styled.div`
    max-width: 75px;
    display: none;   
    background-color: rgb(218, 228, 240);
    color: #183A78;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.0em;

    span {
        padding-right: 5px;
    } 
    
    @media (hover: hover) {
        display: flex;
    }
`;