import { ButtonSeeAll, DropDownContainer, DropDownContent, Content, Header, NotificationArea, ReadAll, Title, BlankSpace } from "./style";
import { useAuth} from "../../../Hooks/useAuth";
import NotificationItem from "./NotificationItem";
import NotificationIcon from  '../../../Assets/icons/F1.svg';
import HasNotificationIcon from  '../../../Assets/icons/E1.svg';
import { useClickedOutside } from "../../../Hooks/useClickedOutside";
import Icon from "../../Icon";
import { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Friendship from "../../Friendship";
import { hasJsonStructure } from "../../../Support/Util";

const NotificationBar = ({has_notification}) => {
    const [notificationList, setNotificationList] = useState([]);
    const dropdownRef = useRef(null);
    const {getToken} = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const close = () => {
        setIsOpen(false);
    }

    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    const getNotifications = async () => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/notification`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.get(
                apiUrl,
                {
                    headers:headers
                }
            );

            setNotificationList(response.data.data);
            
        }catch(error){
            console.log(error.response);
        }
     
    }

    const readAll = async() => {
        const tempNotificationList = [...notificationList];
        const newNotificationStatus = tempNotificationList.map(function(notification) {
                                        return {...notification, is_read: 1}
                                    });
        setNotificationList(newNotificationStatus);

        let apiUrl = `${process.env.REACT_APP_BASE_API}/notification`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.post(
                apiUrl,{notifications: notificationList},{headers:headers}
            );
            
        }catch(error){
            console.log(error.response);
        }

    }

    useEffect(() => {
        /* getNotifications(); */
    }, []);


    return (
        
        <DropDownContainer ref = {dropdownRef}>
            <Icon show = {true} onclick={clickHandle} src = {has_notification? HasNotificationIcon: NotificationIcon} alt = "Notification icon" />
            {isOpen &&
                <DropDownContent>
                    <Header>
                        <Title>Notificações</Title>
                        <ReadAll onClick={readAll}>Marcar como lidas </ReadAll>
                    </Header>
                    <NotificationArea>
                        {notificationList.map((notification, key) => {
                                if(!notification.message){
                                    return <BlankSpace key={key} />;
                                }
                                if(notification.message.includes('profile_requester')){
                                    notification.message.replace('\\','');
                                    const friendship = JSON.parse(notification.message);
                                    
                                    if(friendship.type === "friendship"){
                                        return <Friendship key={key} friendship={friendship}  notification= {notification}/>
                                    }
                                }
                                    
                                    return (<NotificationItem notification={notification} key={key}></NotificationItem>);
                                })
                        }
                    </NotificationArea>

                    <Link to = "/profile/notification" className='adjustLink'>
                        <ButtonSeeAll> Ver todas as notificações</ButtonSeeAll>
                    </Link>
                </DropDownContent>
            }
        </DropDownContainer>
       
    );


}

export default NotificationBar;