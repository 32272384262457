import { useCallback, useEffect, useRef, useState } from "react";
import { Container, InputRangeMax, InputRangeMin, Slider, SliderLeftValue, SliderRange, SliderRightValue, SliderTrack } from "./style";

const MultiRangeSlider = ({min, max, onChange}) => {

    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);

    const minValueRef = useRef(null);
    const maxValueRef = useRef(null);
    const range = useRef(null);

    const handleOnMinChange = (event) => {
        const value = Math.min(+event.target.value, maxValue - 1);
        setMinValue(value);
    }

    const handleOnMaxChange = (event) => {
        const value = Math.max(+event.target.value, minValue + 1);
        setMaxValue(value);
    }

    const getPercent = useCallback((value) => {
        return Math.round(((value - min) / (max - min)) * 100);
    }, [min, max]);

    useEffect( () => {
        if(!maxValueRef.current) return;

        const minPercent = getPercent(minValue);
        const maxPercent = getPercent(+maxValueRef.current.value);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
          }

    }, [minValue, getPercent])

    useEffect(() => {
        if (minValueRef.current) {
            const minPercent = getPercent(+minValueRef.current.value);
            const maxPercent = getPercent(maxValue);
      
            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxValue, getPercent]);

    useEffect(() => {
        onChange({ min: minValue, max: maxValue });
    }, [minValue, maxValue]);

    return (
        <Container>
            <InputRangeMin
                type = {"range"}
                min = {min}
                max = {max}
                ref = {minValueRef}
                value = {minValue}
                onChange = {handleOnMinChange}
            />
            <InputRangeMax
                type = {"range"}
                min = {min}
                max = {max}
                ref = {maxValueRef}
                value = {maxValue}
                onChange = {handleOnMaxChange}
            />
            <Slider>
                <SliderTrack />
                <SliderRange ref = {range} />
            </Slider>
        </Container>
    );
}
export default MultiRangeSlider;