import { AcceptedButton, ButtonArea, Container, DeclineButton, TextArea } from "./styled";
import {Link} from 'react-router-dom';
import { useToast} from '../../Hooks/useToast';
import { useAuth } from "../../Hooks/useAuth";
import axios from "axios";
import { useState } from "react";

const Friendship = ({friendship, notification}) => {

    const {addToast} = useToast();
    const { getToken } = useAuth();
    const [acceptClick, setAcceptClick] = useState(false);


    const accept = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/friendship`;
        let token = getToken();
        setAcceptClick(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const data = {
                notification_id: notification.id,
                friendship_id: friendship.friendship_id,
                status: 1
            };
            const response = await axios.put(url, data, {headers:headers});
            addToast("Solicitação de amizade", response.data, 1);
        } catch(error) {
            addToast("Solicitação de amizade", "Problema ao alterar a solicitação de amizade", 0);
        }
    }

    const decline = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/friendship`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const data = {
                notification_id: notification.id,
                friendship_id: friendship.friendship_id,
                status: 0
            };
            console.log(data);
            const response = await axios.put(url, data, {headers:headers});
            addToast("Solicitação de amizade", response.data, 1);
        } catch(error) {
            addToast("Solicitação de amizade", "Problema ao alterar a solicitação de amizade", 0);
        }
    }
    return(
        <Container>
            <TextArea>
                Você recebeu uma solicitação de amizade de 
                <Link to={"/" + friendship.requester_slug}  > {friendship.artistic_name}</Link>
            </TextArea>
            <ButtonArea>
                <AcceptedButton onClick={accept} disabled={acceptClick}>Aceitar</AcceptedButton>
                <DeclineButton onClick={decline} disabled={acceptClick}>Negar</DeclineButton>
            </ButtonArea>
        </Container>
    );


}

export default Friendship;