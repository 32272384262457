const Text = ({percentage}) => {
    return (
        <text
            x = {"50%"}
            y = {"50%"}
            dominantBaseline = {"central"}
            textAnchor = {"middle"}
            fontSize = {"1em"}
        >
            {percentage.toFixed(0)}%
        </text>
    );
}

export default Text;