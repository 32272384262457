import styled from "styled-components";

export const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: #121D2E;
    padding: 7px 5px;

    display: flex;
    justify-content: center;

`;

export const BarDelimiter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media(min-width: 1080px){
        width: 1080px;
    }

    @media(max-width: 340px) {
        display: block;
    }
`;


export const LeftSide = styled.div`
    display: flex;
    align-items: center;
`;

export const RightSide = styled.div`
    display: flex;
    align-items: center;

    @media( max-width: 340px) {
        float: right;
    }
`;

export const NotConnected = styled.div`
    display: flex;
    align-items: center;
`;