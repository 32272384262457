import { Container, CVText, Title, DownloadButton, ButtonGroup } from "./style";
import DownloadIcon from '../../../Assets/icons/J6.svg';
import Icon from "../../Icon";
import DOMPurify from 'dompurify';
import jsPDF from 'jspdf';
import { useRef } from "react";

const CVInfos = ({profile}) => {
    const currentElement = useRef(null);
    let showDownloadButton = false;
    let cvInfo = "";
    let url = "";


    if (profile.cv) {
        cvInfo = DOMPurify.sanitize(profile.cv);
    }

    if (profile.cv_url) {
        showDownloadButton = true;
        url =  profile.cv_url;
    }


    const generatePDF = () => {
        let doc = new jsPDF({ format: 'a4', orientation: 'p', });

        doc.html(currentElement.current, {
            callback: function (doc) {
                doc.save('artistaCv.pdf');
            },
            width: 150, html2canvas: { scale: 0.40 }
        }
        );

    }



    return (
        <Container>
            <Title>
                <span className="text" >Release</span>
                <ButtonGroup>
                    {showDownloadButton &&
                        <DownloadButton href={url} target="_blank">
                            <span> Currículo PDF</span>
                            <Icon classes="marginLeft" src={DownloadIcon} />
                        </DownloadButton>
                    }
                </ButtonGroup>

            </Title>

            <CVText ref={currentElement}>
                <div dangerouslySetInnerHTML={{ __html: cvInfo }} />
            </CVText>
        </Container>
    );

}

export default CVInfos;