import styled from "styled-components";

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const DropdownContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;

    padding-top:5px;
    
    width: 9em;
    z-index: 2;
    background-color: #121D2E;
    left: calc(0% - 5px);

    top: calc(100% + 10px);
    box-shadow: 0 -2px 7px rgba(0, 0, 0, 1);
`;

export const DropdownLink = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #BAC7D6;

    margin-bottom: 5px;

    &.marginTop-md {
        margin-top:20px;
    }
    &.marginLeft-sm {
        margin-left: 10px;
    }

    &.marginBottom-md {
        margin-bottom: 15px;
    }

    .dark-text {
        color: #BAC7D6;
        opacity: 0.5;
    }

    .text-size {
        font-size: 0.7em;
    }
`;