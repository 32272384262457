import { toFixed } from '../../../Support/Util';
import Icon from '../../Icon';
import { Container} from './style.js';

const ValueField = ({imageIcon, fieldNumber, iconAlt}) => {


    let finalValue = fieldNumber;

    if(fieldNumber >= 1000 && fieldNumber < 1000000){
        finalValue = toFixed((fieldNumber/1000) , 2) + " k";
    }

    if(fieldNumber >= 1000000){
        finalValue = toFixed((fieldNumber/1000000), 2) + " m";
    }
    
    return (
        <Container>
            <Icon src = {imageIcon} alt = {iconAlt} width = "1.2em" height = '1.2em'/>
            <span >{finalValue}</span>
        </Container>
    );
}

export default ValueField;