import IntermediateIcon from '../Assets/icons/selo_premium.svg';
import AdvancedIcon from '../Assets/icons/selo_vip.svg';

export const SealIcons = {
    "1": null,
    "2": null,
    "3": IntermediateIcon,
    "4": AdvancedIcon
};

export const ListNameAttributes = [
    ['slug', 'Link Ooopah'],
    ['name', 'nome'],
    ['last_name', 'sobrenome'],
    ['artistic_name', 'nome artístico'],
    ['name_variation', 'variação de nome'],
    ['birth_date', 'data de nascimento']
];