import { Container, Content, Element } from "./style.js";
import Stars from "../../../Stars";

const StarCard = ({featureName , skill, showBackground = false, onClick, classe}) => {

    return (
        <Container onClick={onClick} className={classe}>
            <Element>
                <span>
                    {featureName}
                </span>
                <Content>
                        <Stars starWidth = "0.6em" starHeight = "0.6em" skill={skill} defaultStar={false}/>
                </Content>
            </Element>
        </Container>

    );
}


export default StarCard;