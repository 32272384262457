import { Container, TextArea, Title, Value } from "./style";
import Icon from '../../Icon';

const Characteristic = ({iconFile, characteristic = null}) => {
    
    if(!characteristic) return (<></>);
    
    return (
         <Container>
             <Icon src = {iconFile}/>
             <TextArea>
                 <Title>{characteristic.feature.feature_category.name}:</Title>
                 <Value>{characteristic.feature.name}</Value>
             </TextArea>
         </Container>
     );
 }

 export default Characteristic;