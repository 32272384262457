import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;

`;

export const Delimiter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: 720px) {
        width: 95%;
    }
`;

export const ProfileDelimiter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    @media(min-width: 960px){
        justify-content: space-between;
    }

`;

export const ProfilePhoto = styled.div`
    padding: 5px 5px;
    cursor: pointer;
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    width: 35%;
    
    @media(min-width: 960px){
        justify-content: center;
        align-items: center;
        width: 25%;
    }
`;

export const Right = styled.div`
    display: flex;
    padding-left: 20px;
    width: 65%;

    @media(min-width: 960px) {
        flex-direction: column;
        padding-left: 0px;
    }

    @media(min-width: 1080px){
        width: 80%;
    }
    
`;

export const ProfileInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(min-width: 960px) {
        flex-direction: row;
    }
`;

export const Name = styled.span`
    font-size: 1.1em;
    width: 100%;
    display: inline;
    cursor: pointer;
`;

export const AgeAndLocation = styled.span`
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    font-size: 0.7em;

    @media(min-width: 960px) {
        font-size: 0.8em;
    }
`;


export const PlanInfo = styled.span`
    font-size: 1em;
    margin-top: 3px;
    color: #b3b3b3;

    @media(min-width: 960px) {
        font-size: 1.1em;
    }
    
`;

export const LastUpdate = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #b3b3b3;
    margin-top: 5px;
    font-size: 0.7em;

    @media(min-width: 960px) {
        font-size: 0.9em;
    }
`;


export const About = styled.span`
    width: 100%;
`;

export const DataGroup = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    width: 33%;
`;

export const StatisticArea = styled.div`
    display: flex;
    flex-direction: row;
    color: #b3b3b3;
    margin-top: 5px;
    
    @media(min-width: 960px) {
        width: 40%;
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 2px 5px;

    @media(min-width: 960px) {
        margin-top: 15px;
    }

`;


export const SeeProfile = styled.div`
    width: 33%;
    height: 38px;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: #183A78;
    border: 1px solid #183A78;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.6em;

    @media(min-width: 960px){
        width: 45%;
        height: 45px;
        
    }

`;

export const TextBold = styled.span`
    font-weight: bold;
`;

export const RemoveFromAgency = styled.button`
    width: 33%;
    height: 38px;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.5em;

    @media(min-width: 600px) {
        width: 25%;
        height: 45px;
    }

    @media(min-width: 1024px) {
        width: 45%;
        height: 45px;
    }
`;

export const AgencyContent = styled.div`
    display: ${props => props.isVisible ? "flex":"none"};
    align-items: center;
    position: relative;
    right: 0;
    width: 30%;

    @media(min-width: 960px) {
        width: 90%;
        justify-content: center;
        position: relative;
    }
`;

export const ProfileSeparator = styled.div`
`;

export const ThreeDots = styled.div`
    position: relative;
    top: 0;
    width: 10%;
    
    @media(min-width: 600px) {
        justify-content: space-between;
    }

`;

export const ProfileContent = styled.div`
    width: 100%;

`;

export const AgencyArea = styled.div`
    display: flex;
    justify-content: center;
    width: 38%;
    padding: 10px 0px;
`;

export const CardButtonDelimiter = styled.div`
    width: 60%;
    display: flex;
    justify-content: ${(props) => (props.isProducer ? "space-between": "end")};
    
    @media(min-width: 600px) {
        padding-left: 10px;
    }
`;

export const TextGeneric = styled.span`
    display: inline-block;
`;

export const GenericActionButton = styled.button`
    width: 33%;
    height: 38px;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.5em;

    @media(min-width: 600px) {
        width: 25%;
        height: 45px;
    }

    @media(min-width: 1024px) {
        width: 45%;
        height: 45px;
    }

`;

export const MiniGenericButton = styled.button`
    width: 10%;
    min-width: 40px;
    height: 38px;
    border-radius: 10px;
    background-color: white;
    color: #183A78;
    border: 1px solid #183A78;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.6em;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        color: #e8dfdf;
        background-color: #e8dfdf;
    }

    @media(min-width: 960px){
        width: 20%;
        height: 45px;
        
    }

`;

export const MiniFavoriteButton = styled.button`
    width: 10%;
    min-width: 40px;
    height: 38px;
    border-radius: 10px;
    background-color: ${(props) => (props.isFavorite ? "#ed7333": "#c1c7d6")};
    color: #183A78;
    border: 1px solid #183A78;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.6em;

    @media(min-width: 960px){
        width: 20%;
        height: 45px;
        
    }

`;

export const Line = styled.div`
    border-width: 1px 0 0 0;
    
    border-color: #B3B3B3;
    border-style: solid;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;
`;

export const AgencyButtons = styled.div`
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;
`;
