import StarCard from "../StarCard";
import { CategoryContainer, CategoryTitle, CategoryContent, Container, CategoryStar} from "./style";

const RatingFeatures = ({categoryFilter, rating, profile}) => {

    
    const showUserFeatures = () =>{
        let rawFeatures = profile.profile_features;
        let cleanFeatures = {};
        
         rawFeatures.map(function(element) {
            let category = element.feature.feature_category.name;

            if(categoryFilter.includes(category) || categoryFilter.length == 0){
                if(rating <= element.skill){
                    if(!cleanFeatures[category]){
                        cleanFeatures[category] = [] ;   
                    }

                    cleanFeatures[category].push(
                        [element.feature.name, element.skill, element.feature.feature_category.type]);
                }

            }

        });
        return cleanFeatures;
    }

    const  features = showUserFeatures();

    return(
        <Container>
            <CategoryContainer>
                {
                    Object.keys(features).map((categoryName, key) => {
                        let starsFeature = (
                            <CategoryContent key={key} >
                                <CategoryTitle >
                                    {categoryName}
                                </CategoryTitle>
                                <CategoryStar >
                                    {features[categoryName].map((feature, key)=>{
                                        
                                        return (<StarCard key={key} featureName={feature[0]}  skill={feature[1]} />);
                                    })

                                    }

                                </CategoryStar>

                            </CategoryContent>
                        );
                        return starsFeature;
                    })
                }
            </CategoryContainer>
        </Container>
    );
}

export default RatingFeatures;