import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    
    transform: translateX(-30px);
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

    opacity: 0;
    pointer-events: none;
    overflow: hidden;

    z-index: 0;

    &.visible {
        
        opacity: 1;
        max-height: none;
        overflow: visible;
        transform: translateX(0);
        pointer-events: auto;
        
    }

    &.transition-correction {
        max-height: 0;
    }
`;