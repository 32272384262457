import { useRef, useState } from "react";
import { Container, DropdownContent, DropdownLink, Text } from "./style";
import { useClickedOutside } from "../../Hooks/useClickedOutside";
import { Link } from "react-router-dom";

const DropdownEnter = () => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    const close = () => {
        setIsOpen(false);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    return (
        <Container ref={dropdownRef}>
            <Text classes = 'marginLeft-sm' color = "#BAC7D6" fontSize = "0.8em" onClick={clickHandle}>
                Entrar
            </Text>
            {isOpen &&
            <DropdownContent>
                <Link to="/">
                    <DropdownLink className="marginBottom-md marginLeft-sm">
                        <span className='text-size'> Artista </span>
                    </DropdownLink>
                </Link>
                <DropdownLink href = {process.env.REACT_APP_PRODUCER_BASE + '/login'} className="marginBottom-md marginLeft-sm">
                    <span className='text-size'> Produtor </span>
                </DropdownLink>
                <DropdownLink href = {process.env.REACT_APP_PRODUCER_BASE + '/login'} className="marginBottom-md marginLeft-sm">
                    <span className='text-size'> Agência </span>
                </DropdownLink>
            </DropdownContent>
            }
        </Container>
    );
}

export default DropdownEnter;