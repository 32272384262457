import { useAuth } from "../../Hooks/useAuth";
import { Container, Line, PageDelimiter } from "./style";
import { objectIsEmpty } from '../../Support/Util';
import Loading from '../../Components/Loading';
import TopBar from '../../Components/TopBar';
import ProfileHeader from '../../Components/ProfileHeader';
import Tabs from "../../Components/Tabs";
import Footer from "../../Components/Footer";
import { Navigate } from "react-router-dom";
import StatusDisplayer from "../../Components/StatusDisplayer";
import PremiumBar from "../../Components/PremiumBar";
import Modal from "../../Components/Modal";
import { useState, useEffect} from 'react';

const Profile = () => {
    const { currentUser, isFree } = useAuth();

    if(objectIsEmpty(currentUser)){
        return (<Loading />);
    }

    if(currentUser.access_rule.accepted_terms !== 1 || currentUser.access_rule.done_wizard !==1){
        return <Navigate to="/profile/wizard"/>;
    }

    return(
        <Container>
            <TopBar />
            <PageDelimiter>
                <StatusDisplayer />
                <ProfileHeader />
                {isFree &&
                    <PremiumBar />
                }
                <Line />
                <Tabs />
            </PageDelimiter>
            <Footer />
        </Container>
    );
}

export default Profile;