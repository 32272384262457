import { Container, VideoContainer, VideoTitle, VideoThumb} from "./style";

const VideoIsolated = ({url, videoTitle} ) => {
    //const [showThumb, setShowThumb] = useState(false);
    
    return (
        <Container>

            {/*
                <VideoThumb src = {thumb} className={showThumb ? "" : "invisible"} />
            */}

            <VideoContainer >
                <iframe className ="iframe" src={url} frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
            </VideoContainer>

            <VideoTitle>
                <span className="text">{videoTitle} </span>
            </VideoTitle>

            
        </Container>


    );

}

export default VideoIsolated;