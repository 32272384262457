import Icon from '../../Icon';
import { Container} from './style.js';
import LockIcon from '../../../Assets/icons/C2.svg';

const LockedField = ({onclick}) => {

    return (
        <Container onClick = {onclick}>
            <Icon src = {LockIcon} alt = {"lock icon"}/>
            <span >---</span>
        </Container>
    );
}

export default LockedField;