import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InputRadio = styled.input``;

export const NotificationSize = styled.div`
    width: 90%;
`;
