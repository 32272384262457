import { useEffect, useState } from "react";
import { Container, InputToggle, LabelToggle, ToggleInner, ToggleSwitch } from "./style";

const InputToggleSwitch = ({name, onChange}) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        onChange(checked);
    }, [checked])

    return(
        <Container>
            <InputToggle
                type = {"checkbox"}
                name = {name}
                id = {name}
                checked = {checked}
                onChange = {(event) => {setChecked(event.target.checked)}}
            />
            <LabelToggle htmlFor = {name}>
                <ToggleInner checked = {checked} />
                <ToggleSwitch checked = {checked} />
            </LabelToggle>
        </Container>
    );
}

export default InputToggleSwitch