import { useEffect, useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import { contactVisibilityOptions, phoneIsWhatsappOptions, phoneTypeOptions, translateIsWhatsapp, translatePhoneType, translateVisibilityOptions, phoneVisibilityPermissionOptions, attributeTranslate} from "../../../../Support/Util";
import Dropdown from "../../../Dropdown";
import InputV2 from "../../../InputV2";
import Modal from "../../../Modal";
import Spinner from "../../../Spinner";
import axios from 'axios';
import { AddPhoneButton, ButtonSave, Container, DeleteManager, DeletePhoneButton, GroupButton, GroupSave, ManagerButton, ManagerGroup, ManagerInfo, PhoneGroup, Row, Title, UploadMessage } from "./style";
import Icon from "../../../Icon";
import DisketIcon from '../../../../Assets/icons/E3.svg';
import { contactVisibility } from "../../../../Support/StringResourcers";
import AgencyDisplay from "../../../AgencyDisplay";

const ContactTab = ({clickHandle}) => {

    const {currentUser, getToken, getUser, isFree} = useAuth();

    const [phones, setPhones] = useState([]);
    const [contactEmail, setContactEmail] = useState("");

    const [addManager, setAddmanager] = useState(false);
    const [managerPhone, setManagerPhone] = useState("");
    const [managerIsWhatsapp, setManagerIsWhatsapp] = useState("");
    const [managerPhoneType, setManagerPhoneType] = useState("");
    const [managerName, setManagerName] = useState("");
    const [managerEmail, setManagerEmail] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");

    



    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(true);
        setIsLoading(true);
        const data = {
            phones: phones,
            contact_email: contactEmail,
            manager_phone: managerPhone,
            manager_is_whatsapp: managerIsWhatsapp,
            manager_phone_type: managerPhoneType,
            manager_name: managerName,
            manager_email: managerEmail,
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/desktop/contact`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers});
            getUser();
            setServerMessage("Dados salvos com sucesso!");
            setIsLoading(false);
            setIsUploadSuccess(true)
        }catch(error){
            //TODO add flash error message;
            let outputMessage = "";
            setIsLoading(false);
            setIsUploadSuccess(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;
                    if(typeof outputMessage === 'string'){
                        outputMessage = attributeTranslate(outputMessage);
                    } else{
                        outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                    }
                }

                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                            outputMessage = attributeTranslate(outputMessage);
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }

    const permissionOptions = contactVisibilityOptions();
    const phoneTypes = phoneTypeOptions();
    const whatsappOptions = phoneIsWhatsappOptions();
    const phoneVisibility = phoneVisibilityPermissionOptions(isFree);

    useEffect(() => {
        setPhones(currentUser.contact.phones);
        setContactEmail(currentUser.contact.contact_email ? currentUser.contact.contact_email: "");
        setManagerPhone(currentUser.contact.manager ? currentUser.contact.manager.phone : "")
        setManagerIsWhatsapp(currentUser.contact.manager ? currentUser.contact.manager.is_whatsapp : "");
        setManagerPhoneType(currentUser.contact.manager ? currentUser.contact.manager.phone_type : "");
        setManagerName(currentUser.contact.manager ? currentUser.contact.manager.name : "");
        setManagerEmail(currentUser.contact.manager ? currentUser.contact.manager.email : "");
        setAddmanager(currentUser.contact.manager? true: false);
    }, []); 

    const updatePhonesType = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].type = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesWhatsapp = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].is_whatsapp = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesVisibility = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].visibility_permission = event.target.value;
        setPhones(tempPhones);
    }

    const updatePhonesNumber = (event, index) => {
        const tempPhones = [...phones];
        tempPhones[index].number = formatNumber(event.target.value);
        setPhones(tempPhones);
    }

    const addPhone = () => {
        if(phones.length >= 3){
            return;
        }
        const newPhone = {type: "", is_whatsapp:"", number:"", visibility_permission: ""}
        setPhones([...phones, newPhone]);
    }

    const deletePhone = (phone) => {
        const updatedPhones = phones.filter( (item) => {
            return phone !== item;
        }); 
        setPhones(updatedPhones);
    }

    const deleteManager = () => {
        setAddmanager(false)
        setManagerEmail("");
        setManagerPhone("")
        setManagerIsWhatsapp("");
        setManagerPhoneType("");
        setManagerName("");
    }

    const formatNumber = (number) =>{
        let start = "";
        let tail = "";
        number = number.replace("(", "");
        number = number.replace(")", "");

        for(let i=0; i < number.length; i++){
            if(i < 2){
                start = start + number.charAt(i);
            } else {
                tail = tail + number.charAt(i);
            }

        }

        if(number.length > 2){
            return "(" + start + ")" + tail;
        } else {
            return start + tail;
        }
    }

    return(
        <Container>
            <form onSubmit = {handleFormSubmit}>
                
                <Title>Telefones</Title>
                {
                    phones.map((phone, index) => {
                        return (
                            <PhoneGroup key = {index}>
                                <Row className = {"smaller-margin-bottom"}>
                                    <Dropdown
                                        name = {`phones[${index}].type`}
                                        label = {"Tipo"}
                                        title = {"Tipo"}
                                        options = {phoneTypes}
                                        valueDescription = {translatePhoneType(phones[index].type)}
                                        onChange = {(event) => {updatePhonesType(event, index)}}
                                        className = {"half-size marginRight"}
                                    />

                                    <Dropdown
                                        name = {`phones[${index}].is_whatsapp`}
                                        label = {"Whatsapp?"}
                                        title = {"Whatsapp?"}
                                        options = {whatsappOptions}
                                        valueDescription = {translateIsWhatsapp(phones[index].is_whatsapp)}
                                        onChange = {(event) => {updatePhonesWhatsapp(event, index)}}
                                        className = {"half-size marginRight"}
                                        
                                    />
                                </Row>
                                <Row className = "smaller-margin-bottom" >
                                    <InputV2
                                        label = {"Número"}
                                        name = {`phones[${index}].number`}
                                        value = {phones[index].number ? formatNumber(phones[index].number) : ""}
                                        onChange = {(event) => {updatePhonesNumber(event, index)}}
                                        className = {"half-size marginRight"}
                                    />

                                    <Dropdown
                                        name = {`phones[${index}].visibility_permission`}
                                        label = {"Visibilidade"}
                                        title = {"Visibilidade"}
                                        options = {phoneVisibility}
                                        modalDescription = {contactVisibility}
                                        valueDescription = {translateVisibilityOptions(phones[index].visibility_permission)}
                                        onChange = {(event) => {updatePhonesVisibility(event, index)}}
                                        className = {"half-size marginRight"}
                                        
                                    />
                                </Row>
                                <Row className="flex-end">
                                    
                                    <DeletePhoneButton type="button" onClick={() => {deletePhone(phone)}}>Remover</DeletePhoneButton>
                                </Row>
                            </PhoneGroup>
                        );
                    })
                }
                { phones.length < 3 &&
                    <AddPhoneButton onClick = {addPhone} type = "button">
                        Adicionar novo número
                    </AddPhoneButton>
                }

                <Row>
                    <InputV2
                        label = {"Email de contato"}
                        name = {"contact_email"}
                        placeholder = {"example@gmail.com"}
                        value = {contactEmail}
                        onChange = {(event) => {setContactEmail(event.target.value)}}
                        className = {"full-size"}
                    />
                </Row>

                <AgencyDisplay clickHandle = {clickHandle} />
                
                <Title>Contatos de Empresário</Title>

                <ManagerInfo>
                    Possui um empresário? Utilize o formulário abaixo para informar os dados de contato do seu empresário.
                </ManagerInfo>

                {
                    !addManager &&
                    <GroupButton>
                        <ManagerButton onClick = { (event) => {setAddmanager(true)}}>Adicionar empresário</ManagerButton>
                    </GroupButton>
                    
                }
                {
                    (addManager) &&
                    <ManagerGroup>
                        <Row className = {"smaller-margin-bottom"}>
                            <Dropdown
                                name = {`manager-phone-type`}
                                label = {"Tipo"}
                                title = {"Tipo"}
                                options = {phoneTypes}
                                valueDescription = {translatePhoneType(managerPhoneType)}
                                onChange = {(event) => {setManagerPhoneType(event.target.value)}}
                                className = {"half-size marginRight"}
                            />

                            <Dropdown
                                name = {`manager-is-whatsapp`}
                                label = {"Whatsapp?"}
                                title = {"Whatsapp?"}
                                options = {whatsappOptions}
                                valueDescription = {translateIsWhatsapp(managerIsWhatsapp)}
                                onChange = {(event) => {setManagerIsWhatsapp(event.target.value)}}
                                className = {"half-size marginRight"}
                                
                            />
                        </Row>
                        <Row className = "smaller-margin-bottom" >
                            <InputV2
                                label = {"Número"}
                                name = {`manager-number`}
                                value = {managerPhone}
                                onChange = {(event) => {setManagerPhone(event.target.value)}}
                                className = {"half-size marginRight"}
                            />

                            <DeleteManager onClick = {deleteManager}>Remover empresário</DeleteManager>
                        </Row>
                        <Row>
                            <InputV2
                                label = {"email"}
                                name = {`manager-email`}
                                value = {managerEmail}
                                onChange = {(event) => {setManagerEmail(event.target.value)}}
                                className = {"full-size marginRight"}
                            />
                        </Row>
                        <Row>
                            <InputV2
                                label = {"Nome"}
                                name = {`manager-name`}
                                value = {managerName}
                                onChange = {(event) => {setManagerName(event.target.value)}}
                                className = {"full-size marginRight"}
                            />
                        </Row>
                    </ManagerGroup> 
                }
                <GroupSave>
                    <ButtonSave>
                        <Icon src = {DisketIcon} width = "3em" height = "3em"/>
                        Salvar
                    </ButtonSave>
                </GroupSave>
            </form>
            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
                {!isLoading &&
                    <UploadMessage state = {isUploadSuccess}>{serverMessage}</UploadMessage>
                }

            </Modal>
        </Container>
    );
}

export default ContactTab