import { Arrow, BackButton, ConfirmButton, Container, DeleteWrapper, MessageText, OrderButton, OrderWrapper, ThumbArea, Title, VideoContainer } from "./style";
import Icon from "../../../Icon";
import XIcon from '../../../../Assets/icons/C6.svg';
import { useState } from "react";
import Modal from "../../../Modal";

import ArrowDown from '../../../../Assets/icons/C5.svg';
import ArrowUp from '../../../../Assets/icons/D5.svg';
import ThumbVimeo from '../../../../Assets/images/addVideoFree.jpeg';

const VideoThumb = ({index, video, deleteVideo, orderVideos}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const isVimeoVideo = video.url.includes("vimeo.com");

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const openModal = () =>{
        setIsModalOpen(true);
    }

    const handleOnDirectionUp = () => {
        orderVideos(index, -1);
    }

    const handleOnDirectionDown = () => {
        orderVideos(index, 1);
    }

    return (
        <Container>
            <VideoContainer>
                <OrderWrapper>
                    <OrderButton onClick={handleOnDirectionUp}>
                        <Arrow src = {ArrowUp} />
                    </OrderButton>
                    <OrderButton onClick={handleOnDirectionDown}>
                        <Arrow src = {ArrowDown} />
                    </OrderButton>
                </OrderWrapper>
                <DeleteWrapper>
                    <Icon src = {XIcon} onclick = {openModal} />
                </DeleteWrapper>
                <ThumbArea src = {isVimeoVideo? ThumbVimeo: video.thumb}/>
                
            </VideoContainer>
            <Title>
                {video.title}
            </Title>

            <Modal isOpen = {isModalOpen} handleClose = {handleCloseModal}>
                <MessageText>Tem certeza que deseja deleta o vídeo ?</MessageText>
                <BackButton onClick={handleCloseModal}>Voltar</BackButton>
                <ConfirmButton onClick={() => {deleteVideo(video); setIsModalOpen(false)}}>Deletar</ConfirmButton>
            </Modal>
        </Container>
    );
}

export default VideoThumb;