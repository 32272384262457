import { Image } from "./style"

const RoundImage = ({sizes, src, onClick = null, style = null}) => {

    let imageStyle = {
        cursor: onClick ? 'pointer': 'auto',

    }

    imageStyle = style ? {...imageStyle, ...style}: imageStyle;

    return (
        <Image sizes = {sizes} src = {src} onClick = {onClick} style = {imageStyle}/>
    )
}
export default RoundImage;