import { CloseButton, Container, IconContainer, Message, TextContainer, Title } from "./style";
import CheckIcon from '../../../Assets/icons/check.svg';
import DangerIcon from '../../../Assets/icons/danger.svg';

const Toaster = ({toaster, onClick}) => {

    return(
        <Container type = {toaster.type}>
            <CloseButton onClick={onClick}>X</CloseButton>
            <IconContainer>
                <img src={toaster.type == 1 ? CheckIcon : DangerIcon} alt="" />
                <Title>{toaster.title}</Title>
            </IconContainer>
            <TextContainer>
                
                <Message>{toaster.message}</Message>
            </TextContainer>
        </Container>
    )
}

export default Toaster;