import { Container, Content } from "./style.js";
import Stars from "../../Stars";

const StarCard = ({featureName , skill, showStars=false}) => {

    return (
        <Container>
            <span>
                {featureName}
            </span>
            <Content>
                    <Stars skill={skill}/>
            </Content>
        </Container>

    );
}


export default StarCard;