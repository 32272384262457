import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    height: 32px;

    border-width: 1px;
    border-style: solid;
    border-color: gray;
    border-radius: 7px;

    margin-bottom:10px;

    font-size: 18px;

    box-sizing: border-box;
    padding-left: 8px;
    &::placeholder {
        color:#c4c4c4;
    }
`;

export const Label = styled.label`
    margin-right: 5px;
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

export const JustifiedText = styled.p`
    text-align: justify;
`;