import { Button, ButtonArea, Content, Text, TextArea } from "./styles";
import Icon from "../Icon";
import StarIcon from '../../Assets/icons/J5A.svg';

const PremiumBar = () =>{



    return (
        <Content>
            <TextArea>
                <Text font={"normal"} color={"#ffffff"}>
                    Aumente suas chances!
                </Text>

                <Text font={"bold"} color={"#ffffff"}>
                    Apareça até 20x mais em pesquisas!
                </Text>

            </TextArea>

            <ButtonArea>
                <Button href = {process.env.REACT_APP_ACCOUNT_SUBSCRIPTIONS} target = "_blank"> 
                    <Icon src={StarIcon} width={"2em"} height={"1.5em"}/>
                    <Text font={"bold"} color={"#131E2E"}>
                       Seja Premium 
                    </Text>
                </Button>

            </ButtonArea>
        </Content>
    );

}


export default PremiumBar;