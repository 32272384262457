import CVInfos from "./CVInfos";
import { Container } from "./style";

const ProfileViewerCVGallery = ({profile}) => {

    return(
        <Container >
            <CVInfos profile={profile}/>
        </Container>
    );


}


export default ProfileViewerCVGallery;
