import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const FinishedImage = styled.img`
    max-height: 600px;
`;

export const EndGroup = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 700px;
`;

export const EndButtons = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

export const DeclineButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #8c1103;
    }
`;

export const ConfirmButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`

export const Message = styled.div`
    color: ${props => props.state ? "green": "red"};
`;

export const LimitInfo = styled.div`
    display: flex;
    justify-content: center;
`;

export const TextArea = styled.div`
    border-radius: 5px;
    padding: 5px 2px;
    margin: 10px 5px;
    font-size: 1em;
    background-color: #dae4f0;
    display: flex;
    gap: 16px;
`;

export const TextInfo = styled.span`


`;

export const LinkArea = styled.a`
    &hover{
        text-decoration: underline;
    }
`;
