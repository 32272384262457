import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const CategoryTitle = styled.h3`
    text-align: left;
    color: #8B8B8B;
    font-size: 0.7em;
`;

export const CategoryContent = styled.div`
`;

export const CategoryContainer = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
`;

export const CategoryStar = styled.div`
    display: flex;
    flex-wrap : wrap;
    
    width: 100%;

`;