import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
`;

export const Label = styled.label`
    margin-bottom: 5px;
`;

export const Input = styled.input`
    height: 32px;

    border-width: 1px;
    border-style: solid;
    border-color: gray;
    border-radius: 7px;

    margin-bottom:10px;

    font-size: 18px;

    box-sizing: border-box;
    padding-left: 8px;
`;
