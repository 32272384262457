import styled from "styled-components";

export const Container = styled.div`
`;


export const Title = styled.h1`
    font-size: 1.3em;
`;

export const Row = styled.div`
    display: flex;
    margin-bottom: 20px;
    position: relative;

    &.smaller-margin-bottom {
        margin-bottom: 5px;
    }

    .third-size {
        width: 30%;
    }

    .marginRight {
        margin-right: 5%;
    }

    .mini-marginRight {
        margin-right: 2%;
    }

    .half-size {
        width: 47%;
    }

    .full-size{
        width: 100%;
    }

    .special-phone {
        width: 40%;
    }

    .special-whats {
        width: 26%;
    }

    &.flex-end {
        justify-content: flex-end;
    }
`;


export const UploadMessage = styled.span`
    color: ${props => props.state ? "green": "red"};
`;

export const ButtonSave = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    outline: none;

    cursor: pointer;
`;

export const GroupSave = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;


export const AddPhoneButton = styled.button`

    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 20px;
    cursor: pointer;
`;

export const PhoneGroup = styled.div`
    border-radius: 5px;
    border-style: solid;
    border-color: gray;
    border-width: 1px;

    padding: 8px;
    margin-bottom: 10px;
`;

export const DeletePhoneButton = styled.button`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-right: 5%;
    cursor: pointer;
    
    height: 30px;

    float: bottom;
`;

export const ManagerInfo = styled.p`
    text-align: center;
`;


export const ManagerButton = styled.button`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    
    height: 30px;

    float: bottom;
`;

export const GroupButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

export const ManagerGroup = styled.div`
    border-radius: 5px;
    border-style: solid;
    border-color: gray;
    border-width: 1px;

    padding: 8px;
    margin-bottom: 10px;
`;

export const DeleteManager = styled.button`
    border: none;
    background: none;
    position: absolute;

    bottom: 7px;
    right: 5%;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    
    height: 30px;

    font-size: 0.7em;
`;