import styled from "styled-components";

export const Container = styled.div`
`;

export const Title = styled.h1`
    display: flex;
    margin: 5px 0;
    font-size: 1em;
    color: #4d4d4d;
`;

export const JustifiedText = styled.p`
    text-align: justify;
`;