import styled from "styled-components";

export const Container = styled.div`
    
`;

export const Content = styled.div`
    display: flex;
`;


export const ProfileInfo = styled.div`
    margin-left: 20px;
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

export const Name = styled.h1`
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    
`;

export const ButtonContact = styled.button`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    cursor: pointer;
`;

export const SubTitle = styled.div`
    font-size: 0.9em;
    margin-bottom: 5px;
`;

export const AccountLevel = styled.div`
    font-size: 0.9em;
    display: flex;
    align-items: center;
    color: #8B8B8B;
`;

export const AboutText = styled.p`
    color: #8B8B8B;
`;

export const StatisticBar = styled.div`
    display: flex;
    margin-bottom: 15px;

`;

export const DataGroup = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
`;

export const Line = styled.div`
    border-width: 1px 0 0 0;
    
    border-color: #B3B3B3;
    border-style: solid;
    
    width: 100%;

    margin-top: 15px;
    margin-bottom: 10px;
`;

export const LastDate = styled.div`
    color: #8B8B8B;
`;