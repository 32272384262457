import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column; 
`;

export const SaveButton = styled.button`
    display: flex;
    border-style: none;
    width: max-content;
    
    color: white;
    background-color: #385799;
    border-radius: 7px;
    
    padding: 3px 7px;
    font-size: 0.8em;
    margin-bottom: 10px;
    margin-right: 10px;

    @media (min-width: 420px){
        margin-right: 22px;
    }

    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

`;

export const Guidetext = styled.span`
    text-align: center;
    width: 80%;
`;