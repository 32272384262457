import { Container } from "./style";
import Text from "./Text";
import Circle from './Circle';

const PercentageCircle = ({percentage}) => {

    return (
        <svg width = {100} height = {100}>
            <g transform = {"rotate(-90 50 50)"}>
                <Circle colour = { "lightgrey" } />
                <Circle colour = { "blue" } percentage = {percentage}/>

            </g>
            <Text percentage = {percentage} />
        </svg>
    );
}

export default PercentageCircle;