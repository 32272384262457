import { transformPhone } from "../../../Support/Util";
import { 
    ContactGroup, Container, EmailGroup, EmailInfo, OpenContactButton, 
    PhoneGroup, PhoneInfo, AgencyContainer, AgencyText, AgencyProfilePhoto, 
    AgencyTextContent, ManagerContent, PermittedManagerContent, 
    ContactContainer, 
    ContactWrapper,
    PhoneGroupProducer,
    OpenContactButtonA} from "./style";
import InfoIcon from '../../../Assets/icons/H8.svg';
import WhatsAppIcon from '../../../Assets/icons/K4.svg'
import Icon from '../../Icon';
import Modal from "../../Modal";
import { useEffect, useState } from "react";
import axios from 'axios';
import Interactions from "../Interactions";
import { useAuth } from "../../../Hooks/useAuth";

const ProfileContactInfo = ({profile, isContactOpen, handleContactOpen}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [agency, setAgency] = useState({phones:[]});
    const [showManagerNumber, setShowManagerNumber] = useState(true);
    const [managerPhone, setManagerPhone] = useState("");
    const [managerEmail, setManagerEmail] = useState("");
    const [managerName, setManagerName] = useState("");
    const [managerPhoneIsWhatsapp, setManagerPhoneIsWhatsapp ] = useState("");
    const { getProducerToken } = useAuth();
    let showPrivateMessage = false;
    
    
    let email = "";
    let phone = null;

    const handleClose = () =>{
        setIsOpen(false);
    }
    
    const handleOpen = () => {
        setIsOpen(true);
    }

    if(profile.contact){
        
        showPrivateMessage = profile.contact.phones.length > 0 ? false: true;
        if(profile.contact.contact_email) {
            email = profile.contact.contact_email;
        }

        if(profile.contact.phones && profile.contact.phones.length >= 1 ){
            phone  = profile.contact.phones[0] ? transformPhone(profile.contact.phones[0].number): "";
            
        }
    }

    const getUserAgency = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/agency/${profile.agency_id}`;

        try {
            const response = await axios.get(url);
            setAgency(response.data.agency);
            console.log(response.data.agency);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }

    }

    const chooseContactInfo = () => {

        if(profile.agency_id){
            return 1;

        } else if(profile.contact.manager) {
            ////name, phone, phone_type, is_whatsapp, visualization_permission
            return 2;

        } else{

            return 3;
        }

    } 
    const showInfoTab = chooseContactInfo();

    useEffect( ()=>{
        if(showInfoTab == 1){
            getUserAgency();
        }

        if(profile.contact.manager){
            if(!profile.contact.manager.phone){
                setShowManagerNumber(false);
            }
        }
        if(profile.contact.manager){
            if(profile.contact.manager.phone){
                setManagerPhone(profile.contact.manager.phone);
            }

            if(profile.contact.manager.email){
                setManagerEmail(profile.contact.manager.email);
            }

            if(profile.contact.manager.name){
                setManagerName(profile.contact.manager.name);
            }

            if(profile.contact.manager.is_whatsapp){
                setManagerPhoneIsWhatsapp(profile.contact.manager.is_whatsapp);
            }
        }

    },[]);

    
    return (
        <Container>
            {
                !isContactOpen &&  getProducerToken() &&
                <OpenContactButton onClick={handleContactOpen}>
                    Ver contatos
                </OpenContactButton>
            }
            {
                !isContactOpen &&  !getProducerToken() &&
                <OpenContactButtonA href = {process.env.REACT_APP_PRODUCER_BASE + '/login'} >
                    Apenas pesquisadores e produtores logados tem acesso aos contatos. Faça seu login clicando aqui
                </OpenContactButtonA>
            }
            {
                isContactOpen &&
                <ContactGroup>
                    <ContactWrapper>
                        <EmailGroup>
                            <EmailInfo>
                                {profile.agency_id ? agency.contact_email : email}
                            </EmailInfo>
                        </EmailGroup>
                        
                        <PhoneGroup>
                            
                            <PhoneInfo>   
                                {showInfoTab == 1 &&
                                    agency.phones[0] ? transformPhone(agency.phones[0].number): ""
                                }
                                {showInfoTab == 2 &&
                                    managerPhone ? transformPhone(managerPhone): ""
                                }
                                {showInfoTab == 3 && phone &&
                                    phone? transformPhone(phone): ""
                                }
                            </PhoneInfo>
                        
                            <Icon src = {InfoIcon} alt = "Icone de interrogação" onclick={handleOpen} />
                        </PhoneGroup>
                    </ContactWrapper>
                    <Interactions profile = {profile} isContactOpen = {isContactOpen} contacts = {profile.contact} name={profile.name}/>
                    
                </ContactGroup>
            }
            <Modal isOpen = {isOpen} handleClose = {handleClose}>
                { 
                    <ContactContainer>
                    {showInfoTab == 1 &&
                        <AgencyContainer>
                            {agency.avatar_url &&
                                <AgencyProfilePhoto  src={agency.avatar_url}/>
                            }

                            <AgencyTextContent>
                                <AgencyText>
                                Agência: {agency.name} 
                                </AgencyText>
                                
                                {agency.phones.map((phone, key)=>{
                                return( 
                                    <AgencyText key={key}>
                                        {transformPhone(phone.number)}
                                        {phone.is_whatsapp == 1 && 

                                            <Icon src={WhatsAppIcon} classes="marginLeft"></Icon>
                                        }
                                    </AgencyText>
                                    );
                                })}

                            </AgencyTextContent>
                        </AgencyContainer>
                    }

                    {showInfoTab == 2 &&

                        <ManagerContent>
                            <PermittedManagerContent>
                                Empresário:  {managerName}
                                {showManagerNumber &&
                                    <PhoneGroup>
                                        Telefone: {managerPhone ? transformPhone(managerPhone): ""} 
                                        {managerPhoneIsWhatsapp == 1 && 
                                            <Icon src={WhatsAppIcon} classes="marginLeft"></Icon>
                                        }
                                    </PhoneGroup>
                                }

                                {!showManagerNumber &&
                                    <EmailGroup>
                                        E-mail: {managerEmail}
                                    </EmailGroup>
                                }
                            </PermittedManagerContent>
                        </ManagerContent>
                    }

                    { showInfoTab == 3 &&
                        <div>
                            <p>Contato(s) do artista:</p>
                            {profile.contact.phones.map((phone, key) =>{

                                if(phone.visibility_permission != 2) {
                                    return(
                                        <PhoneGroup key={key}>
                                            {transformPhone(phone.number)}
                                            {phone.is_whatsapp == 1 && 
                                                <Icon src={WhatsAppIcon} classes="marginLeft"></Icon>
                                            }
                                            {phone.visibility_permission == 2 &&
                                                <span>Contato exclusivo para produtor</span>
                                            }
                                        </PhoneGroup>
                                        
                                    );
                                } else {
                                    return (
                                        <PhoneGroupProducer>
                                            {phone.visibility_permission == 2 &&
                                                <span>Exclusivo para produtor</span>
                                            }
                                            <PhoneGroup>
                                                {transformPhone(phone.number)}
                                                {phone.is_whatsapp == 1 && 
                                                    <Icon src={WhatsAppIcon} classes="marginLeft"></Icon>
                                                }
                                            </PhoneGroup>
                                        </PhoneGroupProducer>
                                    );
                                }
                                

                            })}
                            { showPrivateMessage &&
                                <p>
                                    Contatos restristos pelo usuário. 
                                </p>
                            }
                        </div>
                        
                    }
                    </ContactContainer>
                }

                
            </Modal>
        </Container> 
    );
}

export default ProfileContactInfo;