import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #DAE4F0;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2px 0px;
    text-align: center;

    .sub-title{
        text-align: center;
    }
`;

export const AddProfilePhotoButton = styled.button`
    border: none;
    background: none;
    font-size: 0.7em;
    text-align: left;
    text-decoration: underline;
    padding: 5px 0px;
    cursor: pointer;
`;

export const EndGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
`;

export const EndButtons = styled.div`
    display: flex;
    margin-bottom: 5px;
`;


export const DeclineButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #8c1103;
    }
`;

export const ConfirmButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`


export const Message = styled.div`
    color: ${props => props.state ? "green": "red"};
`;

export const FinishedImage = styled.img`
`;

export const ButtonArea = styled.div`
    display: flex;
    justify-content: center;

`;

export const PhotoArea = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0px;
`;

export const Button = styled.button`
    margin: auto;
    border: none;
    background: none;
    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    :disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &.photo-button {
        background-color: #EF742C;
    }
`;

