export function setCookie(name, value, expireDays, domain) {
    let date = new Date();
    //60 * 1000 é minutos , 60 * 60 * 1000 é hora, 24 * 60 * 60 * 1000 é dia;
    date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    const expires = `expires=` + date.toUTCString();
    document.cookie = `${name}=${value}; ${expires}; domain=${domain};  path=/`;
}

export function getCookie(name){
    const cookieName = `${name}=`;
    const cookieDecode = decodeURIComponent(document.cookie);
    const splited = cookieDecode.split('; ');
    let response;
    splited.forEach(val => {
        if (val.indexOf(cookieName) === 0) response = val.substring(cookieName.length);
    })

    return response;
}
