import { useEffect, useState } from "react";

const useWindowSize = () => {

    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

    const handleDimensionUpdate = () => {
        setDimensions({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        window.addEventListener('resize', handleDimensionUpdate);

        return () => {
            window.removeEventListener('resize', handleDimensionUpdate)
        }
    },[])

    return [dimensions.width, dimensions.height];
}

export default useWindowSize;