import { Container, TabButton } from "./style";

const LeftTabs = ({changeTabHandle, activeTab}) => {

    return (
        <Container>
            <TabButton isActive = {'general' == activeTab} onClick = {() => {changeTabHandle("general")}}>
                Dados gerais
            </TabButton>
            <TabButton  isActive = {'about-you' == activeTab} onClick = {() => {changeTabHandle("about-you")}}>
                Sobre você
            </TabButton>
            <TabButton  isActive = {'contact' == activeTab} onClick = {() => {changeTabHandle("contact")}}>
                Informações de contato
            </TabButton>
            <TabButton  isActive = {'address' == activeTab} onClick = {() => {changeTabHandle("address")}}>
                Localidade
            </TabButton>
            <TabButton  isActive = {'socials' == activeTab} onClick = {() => {changeTabHandle("socials")}}>
                Redes sociais
            </TabButton>
            <TabButton  isActive = {'account' == activeTab} onClick = {() => {changeTabHandle("account")}}>
                Gerenciar conta
            </TabButton>
        </Container>
    );
};

export default LeftTabs;