import { ListNameAttributes } from "./Contants";

export const objectIsEmpty = (object) => {
    let result = Object.keys(object).length === 0;
    return result;
}

export const translateStatusCode = (statusCode) => {
    const statusOptions = {
        "0": "Bloqueado",
        "1": "Ativado" ,
        "2": "Desativado",
        "3": "Desativado",
        "4": "Pendente",
        "5": "Suspenso",
        "6": "Atrasado",
        "7": "Cancelado"
    };
    
    const statusColor = {
        "0":"#9c0303",
        "1":"#008700",
        "2":"#6e6e6e",
        "3":"#6e6e6e",
        "4":"#bf6b04",
        "5":"#e6c805",
        "6":"#f7534a",
        "7":"#cf1106"
    }

    return [statusOptions[statusCode], statusColor[statusCode]];
}

export const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getAge = (dateString) => {
    const ageDifMs = Date.now() - new Date(dateString).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const characterCounter = (string , size) => {;
    if(!string) return ["", 0];
    let counter = string.length;
    if(counter > size){
        string = string.slice(0, size) + "...";
    }
    return [string, counter];
}

export const transformPhone = (number) => {
    if(!number) return "";
    number = number.replace(/[^0-9 ]/g, "")
    let transformedPhone = "";
    transformedPhone = "(" + number.slice(0, 2) + ") ";
    transformedPhone = transformedPhone + number.slice(2);
    return transformedPhone;
}

export const toFixed = (num) => {
    return num.toString().match(/^-?\d+(?:.\d{0,2})?/)[0];
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const transformFeature = (featureName, categoryName) => {
    
    if(!featureName) return null;
    
    return {
        feature: {
            name:featureName,
            feature_category:{
                name: categoryName
            }
        }
    };
}

export const areEqual = (array1, array2) => {
    if(array1.length !== array2.length) return false;

    return array1.every((element, index) => {
        if(element === array2[index]){
            return true;
        }

        return false;
    });
}

export const getSexOptions = () => {
    return [
        {value: "1", description: "Masculino"},
        {value: "2", description: "Feminino"},
        {value: "3", description: "Outro(s)"}
    ]
}

export const translateSexOption = (sex) => {
    switch(sex){
        case "1":
            return "Masculino";
            break;
        case "2":
            return "Feminino";
            break;
        case "3":
            return "Outro(s)"
            break;
    }
}

export const transformDate = (date) => {
    const addZeros = (value) => {
        return value.length > 1 ? value : `0${value}`;
    }
    const dateObject = new Date(date);
    const finalDate = new Date(dateObject.toISOString().slice(0,-1));
    return `${addZeros(String(finalDate.getDate()))}/${addZeros(String(finalDate.getMonth()+1))}/${finalDate.getFullYear()}`
}

export const contactVisibilityOptions = () => {
    return [
        {value: "1", description: "Somente eu"},
        {value: "2", description: "Eu e produtores"},
        {value: "3", description: "Público"}
    ];
}

export const translateVisibilityOptions = (value) => {
    switch(value){
        case "1":
            return "Somente eu";
            break;
        case "2":
            return "Eu e produtores";
            break;
        case "3":
            return "Público"
            break;
    }
}

export const phoneTypeOptions = () => {
    return [
        {value: "1", description: "Celular"},
        {value: "2", description: "Fixo"},
        {value: "3", description: "Somente mensagem"}
    ]
}

export const translatePhoneType = (type) => {

    switch(type){
        case "1":
            return "Celular";
            break;
        case "2":
            return "Fixo";
            break;
        case "3":
            return "Somente mensagem"
            break;
    }
}


export const phoneVisibilityPermissionOptions = (isFree=false) => {
    if(isFree){
        return [
            {value: '1', canSelect: false, description: "Somente eu"},
            {value: '2', canSelect: false, description: "Eu e produtores"},
            {value: '3', canSelect: true, description: "Público"}
        ];
    }

    return [
        {value: '1', description: "Somente eu"},
        {value: '2', description: "Eu e produtores"},
        {value: '3', description: "Público"}
    ];
}

export const phoneIsWhatsappOptions = () => {
    return [
        {value: "1", description: "Sim"},
        {value: "0", description: "Não"}
    ];
}

export const OoppahJobOptions = () => {
    return [
        {value: 1, description: "Sim"},
        {value: 0, description: "Não"}
    ]
}

export const translateOoppahJob = (value) => {
    switch(value){
        case 1:
            return "Sim";
            break;
        case 0:
            return "Não";
            break;
    }
}

export const translateIsWhatsapp = (value) => {
    switch(value){
        case "1":
            return "Sim";
            break;
        case "0":
            return "Não";
            break;
    }
}

export const  prepareStateDropdown = (states) => {
    return states.map((state) => {
        return {
            value:state,
            description:state.name
        }
    });
}

export const prepareDistrictsDropdown = (districts) => {
    return districts.map((district) => {
        return {
            value: district,
            description: district.name
        }
    });
}

export const AbbreviateNumber = (number) => {
    let finalValue = number;

    if(number >= 1000 && number < 1000000){
        finalValue = toFixed((number/1000) , 2) + " k";
    }

    if(number >= 1000000){
        finalValue = toFixed((number/1000000), 2) + " m";
    }

    return finalValue;
}

export const floatFilter = (number) => {
    return number.replace(/[^\d.-]/g, '');
}

export const hasJsonStructure = (text) => {
    if (typeof text !== 'string') return false;
    try {
        const result = JSON.parse(text);
        const type = Object.prototype.toString.call(result);
        return true;
    } catch (err) {
        return false;
    }
}

export const hasFree = (profile) =>{
    return profile.access_rule.rule.profile_level === "1";
}

export const attributeTranslate = (oldString) => {

    let newString = oldString;
    for(let x = 0; x < ListNameAttributes.length; x++){
        newString =  newString.replace(ListNameAttributes[x][0], ListNameAttributes[x][1]);
    }

    return newString;
}

export const returnFormattedDate = (userDate) => {
    const formateDate = new Date(userDate);
    const year = formateDate.getFullYear(); 
    let month = formateDate.getMonth() + 1;
    let day = formateDate.getDate();
    day = day.toString().length < 2 ? "0" + day: day;
    month = month.toString().length < 2 ?  "0" + month: month;
    return day + "/" + month + "/" + year;
} 

export const formattedNumberStatistics = (fieldNumber) => {
    
    let finalValue = fieldNumber;

    if(fieldNumber >= 1000 && fieldNumber < 1000000){
        finalValue = toFixed((fieldNumber/1000) , 2) + "k";
    }

    if(fieldNumber >= 1000000){
        finalValue = toFixed((fieldNumber/1000000), 2) + "m";
    }
    
    return finalValue;      
}
