import Footer from "../../Components/Footer/index.js";
import Loading from "../../Components/Loading/index.js";
import TopBar from "../../Components/TopBar/index.js";
import WizardTab from "../../Components/Wizard"
import { useAuth } from "../../Hooks/useAuth.js";
import { objectIsEmpty } from "../../Support/Util.js";
import { Container, PageDelimiter } from "./style.js";
import { Navigate } from "react-router-dom";

const Wizard = () => {
    const { currentUser } = useAuth();
    
    if(objectIsEmpty(currentUser)){
        return (<Loading />);
    }

    if(currentUser.access_rule.accepted_terms == 1 && currentUser.access_rule.done_wizard == 1){
        return <Navigate to="/profile/edit"/>;
    }

    return (
        <Container>
            <TopBar />
            <PageDelimiter>
                <WizardTab />
            </PageDelimiter> 
            <Footer />
        </Container>
    );
}

export default Wizard;