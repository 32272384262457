import { useEffect, useState } from "react";
import { Container, PageDelimiter, Line } from "./style";
import { objectIsEmpty } from '../../Support/Util';
import Loading from '../../Components/Loading';
import { useParams } from "react-router-dom";
import axios from 'axios';
import TopBar from "../../Components/TopBar";
import ProfileViewerHeader from '../../Components/ProfileViewerHeader';
import ProfileViewerTabs from '../../Components/ProfileViewerTabs';
import Footer from "../../Components/Footer";
import { useAuth } from "../../Hooks/useAuth";


const ProfileViewer = () => {

    const {slug} =  useParams();
    const [profile, setProfile] = useState({});

    const {getToken, getProducerToken} = useAuth();

    useEffect(() => {
        const userIsBot = isBot(); 
        const getProfile = async () => {

            const token = getToken();
            const producerToken = getProducerToken();
            const headers = {
                'Content-Type': 'application/json',
            }
            if(token !== false){
                headers.Authorization =  `${token.type} ${token.token}`;
            }

            if(producerToken !== false){
                headers.Authorization =  `Bearer ${producerToken.token}`;
            }

            let url = `${process.env.REACT_APP_BASE_API}/user/${slug}?bot=${userIsBot}`;
            try{
                const response = await axios.get(url, {headers: headers});
                setProfile(response.data);
            }catch(error) {
                console.log(error.response);
                if(error.response.status == 400){
                    window.location.href = `${process.env.REACT_APP_HOME_URL}`;
                }                
            }
        }

        getProfile();
    }, []);

    const isBot = () =>{
        const userAgent = window.navigator.userAgent;
        const botUserAgents = [
            'googlebot', 'yahoo', 'bingbot', 'baiduspider','facebookexternalhit',
            'twitterbot', 'rogerbot', 'linkedinbot', 'embedly', 'quora link preview',
            'showyoubot', 'outbrain', 'pinterest','developers.google.com/+/web/snippet',
            'slackbot', 'vkShare', 'W3C_Validator', 'redditbot', 'Applebot', 'bot',
            'webcrawler', 'whatsapp' 
        ];

        const hasBot = botUserAgents.some(function(botUserAgent) {
            return userAgent.toLowerCase().indexOf(botUserAgent.toLowerCase()) !== -1;
        });

        if(hasBot){
            return true;
        }
        return false;

    }

    if(objectIsEmpty(profile)){
        return (<Loading />);
    }
    

    return (
        <Container>
            <TopBar />
            <PageDelimiter>
                <ProfileViewerHeader profile = {profile} />
                <Line />
                <ProfileViewerTabs profile = {profile} />
            </PageDelimiter>
            <Footer />
        </Container>
    )
}

export default ProfileViewer;