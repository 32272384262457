import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;

    @media(min-width: 700px) {
        width: 100%;
        flex-direction: row;
        
    }
    
    
`;

export const AddFriend = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    padding: 3px 25px;
    font-size: 0.7em;
    border-radius: 8px;
    background-color: white;
    color: #385799;
    cursor: pointer;

    border-style: solid;
    border-width: 1px;
    border-color: #385799;
    margin-bottom: 10px;
    @media(min-width: 700px){
        margin-bottom: 0;
    }
    ${props=>props.disabledBtn ? 
        `background-color:#cfccc6;
        cursor: not-allowed;
        opacity: 0.7;`:``}
`;

export const ContactWhatsApp = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 0;
    padding: 3px 25px;
    font-size: 0.7em;
    border-radius: 8px;
    background-color: #005454;
    color: white;
    cursor: pointer;
`;

export const AddFavorite = styled.button`
`;