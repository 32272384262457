import styled from "styled-components";

export const ProfileImage = styled.img`
    width: 6em;
    height: 6em;

    background: linear-gradient(white, white) padding-box, linear-gradient(30deg, ${props => props.colorGradientStart}, ${props => props.colorGradientEnd}) border-box;
    border-radius: 50%;
    //border-top-right-radius: 0;
    padding: 3px;
    border: 3px solid transparent;
`;

export const ProfilePremium = styled.div`
    margin-top: 5px;
    margin-botton: 5px;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    font-weight: bold;
    border-color:  ${props => props.color};
    border-radius: 5px;
    color: ${props => props.color};

`;