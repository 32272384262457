import styled, { css } from 'styled-components';

export const FloatButton = styled.button`
    position:fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    cursor:pointer;
    border-radius: 30px;
    background-color:rgb(56, 82, 156);
    color: white;
    font-size: 18px;
    padding: 10px;
    box-sizing: border-box;
    transition: all 0.2s 0s linear
    &:hover {
        background-color: #e78724;
    }
`;

export const Title = styled.h1`
    text-align: center;
`;