import styled from "styled-components";


export const Text = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.fontSize};

    &.marginLeft-sm {
        margin-left: 10px;
    }

    &.marginRight-sm {
        margin-right: 10px;
    }
    cursor: pointer;
`;