import { useEffect, useRef, useState } from "react";
import PhotoGroup from "./PhotoGroup";
import PhotoIsolated from "./PhotoIsolated";
import axios from 'axios';
import {Container} from "./style";

const ProfileViewerPhotoGallery = ({profile}) => {

    const [photos, setPhotos] = useState([]);
    const [separatedPhotos, setSeparatedPhotos] = useState([]);
    const limitPhotos = profile.access_rule.rule.limit_photo;
    
    const getUserPhotos = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/profile/${profile.id}/photos`;


        try {
            const response = await axios.get(url)
            setSeparatedPhotos(response.data.filter((photo) => { return photo.type == "4" }));
            let limitedPhotos = response.data.filter((photo) => { return photo.type !== "4" });
            setPhotos(limitedPhotos.slice(0, limitPhotos));

        }catch(error){
            //TODO add flash error message;
            console.log(error.response.data.message);
        }
    };


    const getMaxPhotoOrder = () => {
        let maxOrder = 0;
        for(let i = 0; i < photos.length; i++ ){
            if(photos[i].order > maxOrder){
                maxOrder = photos[i].order;
            }
        }
        return maxOrder;
    }

    const getPhotoByOrder = (order) => {
        for(let i = 0; i < photos.length; i++ ){
            if(photos[i].order == order){
                return photos[i];
            }
        }

        return null;
    }


    const initGallery = () => {
        const galleries = [];

        const maxOrder = getMaxPhotoOrder();
        let limitGroups = Math.ceil(maxOrder/4);

        if(photos.length == 1 && maxOrder == 0){
            limitGroups = 1;
        }

        for(let i = 0 ; i < limitGroups; i++){
            const selectedPhotos = [];
            selectedPhotos.push(getPhotoByOrder((i * 4) + 0));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 1));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 2));
            selectedPhotos.push(getPhotoByOrder((i * 4) + 3));

            if(i % 2 == 0){
                galleries.push(<PhotoGroup key = {i} baseOrder = {i} photos = {selectedPhotos}  />);
            } else {
                galleries.push(<PhotoGroup key = {i} baseOrder = {i} photos = {selectedPhotos} type = '2' /> );
            }
        }
        return galleries
    }

    useEffect(() => {
        getUserPhotos();
    }, []);

    
    return (
        <Container >
            {
                initGallery()
            }
            
            <PhotoIsolated photos = {separatedPhotos}/>

        </Container>
    );
}

export default ProfileViewerPhotoGallery ;