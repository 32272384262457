import styled from "styled-components";

export const ButtonGroup = styled.div`
    display: flex;
    font-size: 0.7em;
`;

export const UploadButton = styled.div`
    
    display: flex;
    align-items: center;

    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 7px;
       
    background-color: #FFFFFF;

    cursor: pointer;
    text-decoration: none;
    margin-left:5px;

    &: hover {
        background-color: #BAC7D6;
    }

`;

export const DownloadButton = styled.a`
    
    display: flex;
    align-items: center;
    margin-left: 5px;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    
    text-decoration: none;
    color: white;

    background-color: #121D2E;
    
    cursor: pointer;


    &: hover {
        background-color: #2d4872;
    }

`;
export const SendButton = styled.button`
    display: flex;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const DeleteButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    margin-left: 5px;

    &:hover {
        background-color: #8c1103;
    }
`;
