import React, { useRef, useState } from 'react';
import { FloatButton, Title } from './style';
import { useClickedOutside } from '../../Hooks/useClickedOutside';
import Modal from '../Modal';

const FloatActionButton = ({}) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <FloatButton onClick={handleOpen}>
                Dúvidas/Problemas ? 
            </FloatButton>
            
            <Modal isOpen={isOpen} handleClose = {handleClose}>
                <Title>
                    Vamos te ajudar
                </Title>
                <p>
                    Alguns problemas podem ser resolvidos no lado do cliente, antes de entrar em contato certifique-se de:
                </p>
                <ol>
                    <li>
                        Atualizar corretamente o seu navegador
                        <ul>
                            <li><a href='https://support.google.com/chrome/answer/95414?hl=pt-BR&co=GENIE.Platform%3DDesktop' target='__blank'>Chrome</a></li>
                            <li><a href='https://support.mozilla.org/pt-BR/kb/atualize-o-firefox' target='__blank'>Firefox</a></li>
                            <li><a href='https://tecnoblog.net/responde/como-atualizar-o-opera/'>Opera</a></li>
                            <li><a href='https://support.microsoft.com/pt-br/topic/configura%C3%A7%C3%B5es-de-atualiza%C3%A7%C3%A3o-do-microsoft-edge-af8aaca2-1b69-4870-94fe-18822dbb7ef1#:~:text=No%20navegador%2C%20acesse%20Configura%C3%A7%C3%B5es%20e,Baixar%20e%20instalar%20para%20continuar.'>Edge</a></li>
                        </ul>
                    </li>
                    <li>
                        Renovar a cache do navegador
                        <ul>
                            <li>Padrão: apertar as teclas CTROL + F5 do seu teclado (ambas as teclas simultaneamente)</li>
                            <li><a href='https://support.google.com/accounts/answer/32050?hl=pt-BR&co=GENIE.Platform%3DDesktop' target='__blank'>Chrome</a></li>
                            <li><a href='https://support.mozilla.org/pt-BR/kb/como-limpar-cache-firefox#:~:text=Clique%20em%20Hist%C3%B3rico%20e%20selecione,clique%20no%20bot%C3%A3o%20Limpar%20agora.' target='__blank'>Firefox</a></li>
                            <li><a href='https://www.tutoriaisprojetoweb.com.br/como-limpar-cache-e-cookies-do-navegador-opera'>Opera</a></li>
                            <li><a href='https://tecnoblog.net/responde/como-limpar-o-cache-do-microsoft-edge/'>Edge</a></li>
                        </ul>    
                    </li>
                </ol>
                <p>
                    Se o problema persistir, verifique o nossa lista de perguntas frequentes clicando <a href={process.env.REACT_APP_HOME_URL  + '/faq'}>aqui</a>
                </p>

                <a style={{display: "block", width: "100%", textAlign: "center"}} href='https://wa.me/5521994765872?text=Ol%C3%A1,%20estou%20com%20problemas.'>O seu problema ainda persiste? clique aqui e converse com o nosso suporte</a>
            </Modal>
        </>
    );
}

export default FloatActionButton;