import { EditorState , convertToRaw, ContentState, convertFromHTML,} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import DOMPurify from 'dompurify';
import { useAuth } from "../../Hooks/useAuth";
import { SaveButton, SaveContent, Container } from "./style";


const DraftEditor = ({sendCv}) => {

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );
    const { currentUser} = useAuth();
    const [cvText, setCvText] = useState("");

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setCvText(currentContentAsHTML);
    }

    const createMarkup = (html) => {
        return  {
            __html: DOMPurify.sanitize(html)
        }
    }

    useEffect( () =>{
        if (currentUser.cv) {
            const cvInfo = DOMPurify.sanitize(currentUser.cv);
            setCvText(cvInfo);
            setEditorState(
                EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(cvInfo)))
            )
        }
    }, []);
  
    return (
        <Container>
            <Editor 
                editorState = {editorState}
                onEditorStateChange = {handleEditorChange}
                editorClassName ={"editor-class"}
                editorStyle ={{borderColor: 'black', borderWidth: '1px', borderStyle: 'solid'}}
                placeholder="Digite aqui"
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'underline']
                    },

                    blockType: {
                        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote']
                    },
                    fontSize: {  
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
                    },
                    fontFamily: {
                        options: ['Arial', 'Georgia', 'Impact', 'Times New Roman', 'Verdana'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],

                    },
                    textAlign: {
                        options: ['left', 'center', 'right', 'justify']
                    },
                    textAlign: {
                        options: ['left', 'center', 'right', 'justify']
                    },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    

                }}
            />

            <SaveContent>
                <SaveButton onClick={() => {sendCv(cvText)}}>
                    Salvar
                </SaveButton>
            </SaveContent>

        </Container>
    );
}

export default DraftEditor;