import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media(min-width: 700px){
        width: 700px;
    }
    
`;

export const IconGroup = styled.div`
    display: flex;
    padding: 20px 12px;
`;

export const IconContainer = styled.div`
    margin-left: 8px;
`;

export const IconDescription = styled.span`
    font-size: 0.7em;
    color: gray;
`;

export const ProfileDetail = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 12px;
    width: 70%;
`;

export const ProfileData = styled.div`
    margin-left: 12px;

`;

export const EditProfilePhotoButton = styled.button`
    border: none;
    background: none;
    font-size: 0.7em;
    text-align: left;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
`;

export const ProfileName = styled.p`
    font-weight: bold;
    font-size: 0.8em;
    text-align: left;
    margin: 0;
`;

export const FinishedImage = styled.img`
`;

export const EndGroup = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
`;

export const EndButtons = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

export const DeclineButton = styled.button`
    background-color: #610a00;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #8c1103;
    }
`;

export const ConfirmButton = styled.button`
    margin-left: 10px;
    background-color: #005454;
    color: white;
    padding: 3px 7px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background-color: #003636;
    }
`


export const Message = styled.div`
    color: ${props => props.state ? "green": "red"};
`;