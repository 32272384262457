import { useState } from 'react';
import { useAuth } from '../../Hooks/useAuth';
import useWindowSize from '../../Hooks/useWindowSize';
import { characterCounter } from '../../Support/Util';
import Modal from '../Modal';
import SocialMedia from '../SocialMedia';
import StatisticBar from '../StatisticBar';
import ProfileContactInfo from './ProfileContactInfo';
import ProfilePhoto from './ProfilePhoto';
import ProfileShortInfo from './ProfileShortInfo';
import { Container, TopHeader, LeftSide, RightSide, About, SeeMore, Line, AboutTitle, OkButton, TopHeaderDesktop } from './style';
import CopyProfileUrl from './CopyProfileUrl';

const ProfileHeader = () => {

    const [windowWidth, windowHeight] = useWindowSize();
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const {currentUser, isFree} = useAuth();

    const handleCloseAbout = () => {
        setIsAboutOpen(false);
    };

    const handleOpenAbout = () => {
        setIsAboutOpen(true);
    }

    const aboutSize = windowWidth > 700 ? 350 : 150;
    

    const [about, counter] = characterCounter(currentUser.characteristic.about, aboutSize);
    const completeAbout = currentUser.characteristic.about;

    if(windowWidth > 700) {
        return(
            <Container>
                <TopHeaderDesktop>
                    <LeftSide>
                        <ProfilePhoto />
                        <ProfileShortInfo />

                    </LeftSide>
                    <RightSide>
                        <About>
                            {about}
                        </About>
                        { counter > aboutSize && 
                        <SeeMore onClick={handleOpenAbout}>
                            Ver mais
                        </SeeMore>
                        }
                        
                        <Line />

                        <ProfileContactInfo />
                        <SocialMedia contact={currentUser.contact} isFree={isFree}/>
                        <CopyProfileUrl profile_slug={currentUser.slug}/>
                        <StatisticBar />
                    </RightSide>
                </TopHeaderDesktop>
                <Modal isOpen={isAboutOpen} handleClose = {handleCloseAbout}>
                    <About>
                        <AboutTitle>
                            Sobre o artista
                        </AboutTitle>
                        {completeAbout}
                        <OkButton onClick={handleCloseAbout}>Ok</OkButton>

                    </About>
                </Modal>
            </Container>
        );
    }

    return (
        <Container>
            <TopHeader>
                <LeftSide>
                    <ProfilePhoto />
                    <ProfileShortInfo />
                </LeftSide>
                <RightSide>
                    <About>
                        {about}
                    </About>
                    { counter > aboutSize && 
                    <SeeMore onClick={handleOpenAbout}>
                        Ver mais
                    </SeeMore>
                    }

                    <Line />

                    <ProfileContactInfo />

                    <SocialMedia contact={currentUser.contact} isFree={isFree}/>

                    <CopyProfileUrl profile_slug={currentUser.slug}/>
                    
                </RightSide>
            </TopHeader>
            
            <Modal isOpen={isAboutOpen} handleClose = {handleCloseAbout}>
               <About>
                   <AboutTitle>
                       Sobre o artista
                   </AboutTitle>
                   {completeAbout}
                   <OkButton onClick={handleCloseAbout}>Ok</OkButton>

               </About>
            </Modal>
            <StatisticBar />
        </Container>
    );
}

export default ProfileHeader