import styled from "styled-components";

export const Container = styled.div`
    border: 1px solid #bfbfbf;
    border-radius: 7px;
    cursor: text;
    padding: 5px;

    .editor-class {
        min-height: 250px; 

    }
`;

export const SaveButton = styled.button`
    display: flex;
    border-style: none;
    width: max-content;
    
    color: white;
    background-color: #385799;
    border-radius: 7px;
    
    padding: 3px 7px;
    font-size: 0.8em;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const SaveContent = styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;