import Icon from "../../Icon";
import { Container, CopyArea, DefaultArea, DefaultText, JustifiedText, UrlText } from "./style";
import CopyIcon from '../../../Assets/icons/copy-icon.svg';
import QuestionIcon from '../../../Assets/icons/F3.svg';
import { useToast } from "../../../Hooks/useToast";
import {slugChange} from "../../../Support/StringResourcers";
import Modal from "../../Modal";
import { useState } from "react";

const CopyProfileUrl = ({profile_slug}) => {

    const {addToast} = useToast();
    const [isOpen, setIsOpen] = useState(false);


    const handleCloseModal = () => {
        setIsOpen(false);
    }

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    const handleCopyToClipBoard = () => {
        navigator.clipboard.writeText("https://ooppah.com/"+profile_slug);
        addToast("Link pessoal", "Seu link pessoal foi copiado! Agora é só enviar e compartilhar!", 1);
    }

    return(
        <Container>
            <DefaultArea>
                <DefaultText>Aqui está seu link para compartilhar </DefaultText>
                <Icon classes = "cursor-click" width = "0.8em" height="0.8em" src={QuestionIcon} alt = "Question Icon" onclick={handleOpenModal } />
            </DefaultArea>

            <CopyArea>
                <UrlText>https://ooppah.com/{profile_slug}</UrlText>
                <Icon classes = "cursor-click" width = "0.8em" height="0.8em" src={CopyIcon} alt = "Copy Icon" onclick={handleCopyToClipBoard} />
            </CopyArea>

            <Modal isOpen={isOpen} handleClose ={handleCloseModal} >
                <JustifiedText>
                    {slugChange}
                </JustifiedText>
            </Modal>
        </Container>
    );

}

export default CopyProfileUrl;

