import { CompletudeText, Container, RedTag, StatusText } from "./style";
import {useAuth} from '../../Hooks/useAuth';
import axios from 'axios';
import { useEffect, useState } from "react";
import { translateStatusCode } from "../../Support/Util";
import Modal from '../Modal';
import InterrogationIcon from '../../Assets/icons/F3.svg'
import Icon from "../Icon";

const StatusDisplayer = () => {

    const { currentUser, getToken } = useAuth();

    const [completeness, setCompleteness] = useState({photos: 0, videos: 0});

    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);

    const statusCode = currentUser.access_rule.status;
    const [status, statusColor] = translateStatusCode( statusCode );
    const acceptedTerms = currentUser.access_rule.accepted_terms;
    const doneWizard = currentUser.access_rule.done_wizard;
    const hidden = currentUser.access_rule.hidden;
    const countCharacteristics = currentUser.profile_features.length + currentUser.profile_features_characteristics.length;

    useEffect(async () => {
        setIsLoading(true);
        let apiUrl = `${process.env.REACT_APP_BASE_API}/profile/completeness`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(
                apiUrl,
                {
                    headers:headers
                }
            );
            setCompleteness(response.data);
            setIsVisible( 
                statusCode == "1" && 
                //response.data.photos >= 3 && 
                //response.data.videos >= 1 &&
                acceptedTerms == 1 &&
                doneWizard == 1 &&
                //countCharacteristics >= 8 &&
                hidden == 0
            );
            setIsLoading(false);
        }catch(error){
            console.log(error.response);
        }
    }, []);


    return (
        <>
        <Container>
            <StatusText>
                Status: <span style={{color: statusColor}}>{status}</span>
                {status != "Ativado" ? <img src={InterrogationIcon} onClick = {() => {setIsModalStatusOpen(true)}} style = {{width: "1.1em", height: "1.1em", cursor: "pointer"}} /> : ""}
            </StatusText>
            <CompletudeText>
                Perfil visivel? {isVisible ? <span style={{color: "#008700"}}>Sim</span> :<span className="teste" style={{color: "#f7534a"}}>
                    Não <img src={InterrogationIcon} onClick = {() => {setIsModalOpen(true)}} style = {{width: "1.1em", height: "1.1em", cursor: "pointer"}} />
                </span>}
            </CompletudeText>
            
            <Modal isOpen={isModalStatusOpen} handleClose = {() => {setIsModalStatusOpen(false)}}>
                Para que você possa ser visto, sua assinatura precisa estar ativa.
                Para checar o status da assua assinatura, <a target = {"_blank"} href = {`${process.env.REACT_APP_ACCOUNT_SUBSCRIPTIONS}`}>clique aqui</a>

            </Modal>
            <Modal isOpen={isModalOpen} handleClose = {() => {setIsModalOpen(false)}}>
                {
                    statusCode != "1" &&
                    <p> O status da sua assinatura deve ser ativa.</p>
                    }
                {/*
                    completeness.photos < 3 &&
                    <p>Você precisa cadastrar ao menos três fotos.</p>
                    */}
                {/*
                    completeness.videos <1 &&
                    <p>É preciso ter ao menos um vídeo cadastrado.</p>
                    */}
                 {
                     acceptedTerms != 1 &&
                     <p>Você precisa aceitar os termos de uso, entre em contato com a equipe de suporte.</p>
                     }
                 {
                     doneWizard != 1 &&
                     <p>É preciso seguir para o Wizard e realizar o cadastro básico. Entre em contato com a equipe de suporte</p>
                     }
                 {/*
                    countCharacteristics < 8 &&
                    <p>Entre características e habilidades é preciso escolher ao menos 8.</p>
                    */}
                 {
                     hidden == 1 && 
                     <p>O seu perfil foi ocultado. Entre em contato com o suporte.</p>
                     }
            </Modal>
        </Container>
        {
            !isVisible && !isLoading &&
            <RedTag>
                	
                <span style={{fontSize: 60}}> 	&#9888; </span><br />
                ATENÇÃO, VOCÊ NÃO ESTÁ VISIVEL PARA OS USUÁRIOS,<br /> CLIQUE NA INTERROGAÇÃO ACIMA E VERIFIQUE O MOTIVO
            </RedTag>
        }
        </>
    );
}

export default StatusDisplayer