import styled from "styled-components";
import { keyframes } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-color: #121D2E;
`;

export const Logo = styled.img`
    margin-bottom: 20px;
`;

export const Loader = styled.div`
    position: relative;
    margin-top: 10px;

    width: 55px;
    height:  55px;
`;

const SpinAnimation = keyframes`
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
    position:absolute;
    width: 50px;
    height: 54px;

    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: #385799;

    -webkit-animation: ${SpinAnimation} .75s infinite linear;
    
`;


export const Spinner2 = styled.div`
    position: absolute;
    width: 54px;
    height: 50px;

    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: #EF742C;

    -webkit-animation: ${SpinAnimation} .75s infinite linear;
`;
