import { Container, CVText, Title, UploadButton, DownloadButton, ButtonGroup, SendButton } from "./style";
import DownloadIcon from '../../../Assets/icons/J6.svg';
import ClipIcon from '../../../Assets/icons/I6.svg';
import Icon from "../../Icon";
import { useAuth } from "../../../Hooks/useAuth";
import DOMPurify from 'dompurify';
import jsPDF from 'jspdf';
import { useRef, useState } from "react";
import Modal from "../../Modal";
import UploadFile from "../../UploadFile";

const CVInfos = () => {
    const { currentUser, getToken } = useAuth();
    const currentElement = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const showAnexo = currentUser.access_rule.has_cv == 0 ? false: true;
    let showDownloadButton = false;
    let cvInfo = "";
    let url = "";


    if (currentUser.cv) {
        cvInfo = DOMPurify.sanitize(currentUser.cv);
    }

    if (currentUser.cv_url) {
        showDownloadButton = true;
        url = currentUser.cv_url;
    }

    const changeModalState = () => {
        let modalState = showModal;
        setShowModal(!modalState);
    }

    const generatePDF = () => {
        let doc = new jsPDF({ format: 'a4', orientation: 'p', });

        doc.html(currentElement.current, {
            callback: function (doc) {
                doc.save('artistaCv.pdf');
            },
            width: 150, html2canvas: { scale: 0.40 }
        }
        );

    }



    return (
        <Container>
            <Title>
                {cvInfo &&
                    <span className="text" >Release</span>
                }
                {!cvInfo &&
                    <span className="text" >O Release do usuário(a) é restrito.</span>
                }
                <ButtonGroup>
                    {showAnexo &&
                        <UploadButton onClick={changeModalState}>

                            <span>
                                Anexar
                            </span>
                            <Icon classes="marginLeft" src={ClipIcon} />

                        </UploadButton>
                    }

                    {showDownloadButton &&
                        <DownloadButton href={url} target="_blank">
                            <span> Currículo PDF</span>
                            <Icon classes="marginLeft" src={DownloadIcon} />
                        </DownloadButton>
                    }
                </ButtonGroup>

            </Title>

            <CVText ref={currentElement}>
                <div dangerouslySetInnerHTML={{ __html: cvInfo }} />
            </CVText>
            <Modal isOpen={showModal} handleClose={changeModalState}>

                <UploadFile />

            </Modal>
        </Container>
    );

}

export default CVInfos;