import styled from "styled-components"

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100vw;

    position:relative;
    min-height: 100vh;
`;


export const PageDelimiter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    padding-bottom: 140px;

    @media(min-width: 1080px){
        width: 1080px;
    }

    @media(max-width: 340px) {
        display: block;
    }
`;

export const Line = styled.div`
    border-width: 1px;
    border-color: #B3B3B3;
    border-style: solid;
    border-bottom: 0;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;

    @media(min-width: 700px){
        width: 700px;
    }
`;