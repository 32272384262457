import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const AddVideoText = styled.h1`
    display: flex;
    font-weight: bold;
    font-size: 0.9em;
`;

export const SubmitButton = styled.button`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    cursor: pointer;

`;


export const Line = styled.div`
    border-width: 1px 0 0 0;
    
    border-color: #B3B3B3;
    border-style: solid;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;

    cursor:pointer;
    

`;

export const Videos = styled.div`

`;

export const OrderButton = styled.div`
    border: none;
    background: none;

    background-color: #385799;
    color: white;

    border-radius: 7px;

    padding: 4px 15px;

    font-size: 0.8em;
    margin-top: 10px;
    cursor: pointer;
`;

export const OrderContainer = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    border-radius: 5px;
    padding: 5px;
`;

export const OrderText = styled.p`
    text-align: center;
`;

export const LimitInfo = styled.div`
    border-radius: 5px;
    text-align: center;
    padding: 5px 2px;
    margin: 2px 2px;
    font-size: 0.7em;
    background-color: #dae4f0;
    display: inline-block;
`;

export const JustifiedText = styled.p`
    text-align: justify;
`;