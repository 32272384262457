import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const EmailInfo = styled.span`
    text-align: center;
    font-size: 0.8em;
    color: #385799;
    text-decoration: underline;

    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
   
    word-break: break-all;
    
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

export const PhoneInfo = styled.span`
    text-align: center;
    font-size: 0.7em;
    color: #1a1a1a;

    border-style: solid;
    border-color: #1a1a1a;
    border-width: 1px;
    border-radius: 8px;
    padding: 3px 20px;

    margin-right: 5px;
`;

export const ContactGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const OpenContactButton = styled.button`
    color: white;
    background-color: #385799;
    border: 0;
    padding: 7px 12px;
    border-radius: 5px;
    font-size: 0.7em;
    cursor: pointer;

    &:hover {
        background-color: #2A2ADE;
    }

    @media(min-width: 700px) {
        max-width: 150px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }

`;

export const OpenContactButtonA = styled.a`
    color: white;
    background-color: #385799;
    border: 0;
    padding: 7px 12px;
    border-radius: 5px;
    font-size: 0.7em;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:hover {
        background-color: #2A2ADE;
    }

    @media(min-width: 700px) {
        max-width: 200px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }

`;

export const PhoneGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(max-width: 700px){
        margin-top: 10px;
    }
`;

export const PhoneGroupProducer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding: 2px;

    @media(max-width: 700px){
        margin-top: 10px;
    }
`;

export const ContactWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media(min-width: 700px){
        flex-direction: row;
    }

`;

export const EmailGroup = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`;

export const AgencyContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AgencyText = styled.div`
    display: flex;
    align-items: center;
    padding-top: 3px;
    font-size: 0.8em;

`;

export const AgencyTextContent = styled.div`
    padding-left: 10px;

`;

export const AgencyProfilePhoto = styled.img`
    width: 7em;
`;

export const ManagerContent = styled.div``
;

export const NotPermittedManagerContent = styled.div`
    display: flex;
    text-align: center;
`;

export const PermittedManagerContent = styled.div`
`;

export const Link = styled.a`
`;

export const NotPermittedProfileContent = styled.div``;

export const ContactContainer = styled.div``;