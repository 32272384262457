import styled from "styled-components";

export const Container = styled.div`
    border-radius:5px;

    border-width: 1px;
    border-style: solid;
    border-color: gray;

    padding: 10px 5px;

    border-radius: 5px;

    margin-bottom: 10px;
`;

export const Title = styled.h1`
    cursor: pointer;
    margin: 0;

    font-size: 1em;
    color: #4d4d4d;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Content = styled.div`
    transition: height ease 0.5s;
    overflow: hidden;
    animation: 0.5s delay-overflow;
    border-radius:5px;
`;