import { useState, useEffect} from "react";
import { Container } from "./style";
import VideoIsolated from "./VideoIsolated";
import axios from 'axios';

const ProfileViewerVideoGallery =  ({profile}) => {

    const [videos, setVideos] = useState([]);
    const limitVideos = profile.access_rule.rule.limit_video;

    const getUserVideos = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/profile/${profile.id}/videos`;

        try {
            const response = await axios.get(url);
            let limitedData = response.data.slice(0, limitVideos);
            setVideos(limitedData);

        }catch(error){
            //TODO add flash error message;
            console.log(error.response.data.message);
        }
        
    };

    useEffect(() => {
        
        getUserVideos();
        
    }, []);

    return (
        <Container >
            {
                videos.map((video, key) => {
                    return <VideoIsolated key = {key} url = {video.url} videoTitle = {video.title} allowFullScreen/>
                })
            }
        </Container>
    );
}

export default ProfileViewerVideoGallery;