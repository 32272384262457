import { DropdownContent, DropdownLink, DropdownWrapper, Header, Line, TextContent } from './style';
import RoundImage from '../../RoundImage';
import GearIcon from '../../../Assets/icons/G1.svg';
import KeyIcon from '../../../Assets/icons/H1.svg';
import LockIcon from '../../../Assets/icons/I1.svg';
import ExitIcon from '../../../Assets/icons/J1.svg';
import PersonIcon from '../../../Assets/icons/K7A.svg';
import { useAuth } from "../../../Hooks/useAuth";
import { useRef, useState } from 'react';
import Icon from '../../Icon';
import { useClickedOutside } from '../../../Hooks/useClickedOutside';
import { Link } from 'react-router-dom';
import { translateStatusCode } from '../../../Support/Util';

const UserSmallMenu = ({classes}) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const {logOut, currentUser} = useAuth();
    
    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    const close = () => {
        setIsOpen(false);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    const [status, statusColor] = translateStatusCode(currentUser.access_rule.status);
    const profileImageMedium =  currentUser.profile_photo_medium;
    const name = currentUser.artistic_name;

    return (
        <DropdownWrapper ref = {dropdownRef}>
            <RoundImage sizes = "2em" src = {profileImageMedium ? profileImageMedium : PersonIcon } onClick = {clickHandle} style = {{marginLeft: '10px'}}/>
            { isOpen &&
            <DropdownContent>
                <Header>
                    <RoundImage sizes = "2.4em" src = {profileImageMedium ? profileImageMedium : PersonIcon} style = {{marginLeft: '10px', marginRight:'5px'}}/>
                    <TextContent color = {"#"}>
                        <span>{name}</span>
                        <Link to = "/profile">Meu perfil</Link>
                    </TextContent>
                </Header>
                <Line />
                <Link to = "/profile/edit" className='adjustLink'>
                    <Icon src = {GearIcon} classes = "marginRight" />
                    <span className='text-size'>Configuração</span>
                </Link>
                <DropdownLink href = {process.env.REACT_APP_ACCOUNT_HOME} target = "_blank" className='marginLeft-sm'>
                    <Icon src = {KeyIcon} classes = "marginRight" />
                    <TextContent>
                        <span className='dark-text text-size'>Status da conta</span>
                        <span style={{color: statusColor }} className='dark-text text-size'>{status}</span>
                    </TextContent>
                </DropdownLink>
                <DropdownLink href = {process.env.REACT_APP_ACCOUNT_PASSWORD} target = "_blank" className='marginLeft-sm'>
                    <Icon src = {LockIcon} classes = "marginRight" />
                    <span className='dark-text text-size'>Alterar senha</span>
                </DropdownLink>
                <DropdownLink onClick={logOut} className='marginTop-md marginLeft-sm cursorPointer'>
                    <Icon width='1em' height='1em' src = {ExitIcon} classes = "marginRight" />
                    <span className='text-size'>Sair</span>
                </DropdownLink>
                
            </DropdownContent>
            }
            
        </DropdownWrapper>
    )
}

export default UserSmallMenu;