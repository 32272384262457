import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const DropDownContainer = styled.div`
    position: relative;
`;

export const DropDownContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;

    top: 40px;
    width: 15em;
    right: -32px;
    padding-top: 5px;

    z-index: 2;
    background-color: #f0f1f2;

    border-style: none;
    border-width: 1px;
    border-radius: 5px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2px 5px;
`;

export const Title = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 0.6em;
`;

export const ReadAll = styled.button`
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 0.6em;

    padding: 0px 0px;
	border: none;
    cursor: pointer;

`;

export const NotificationArea = styled.div`
    overflow-y: scroll;
    height: 150px;
    
`;

export const ButtonSeeAll = styled.button`
    font-size: 0.6em;
    flex-wrap : wrap;
    padding-left: 2px;
    padding-top: 5px;
    border: none;
    padding-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
`;

export const BlankSpace = styled.div``;
