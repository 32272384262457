import { useEffect, useState } from 'react';
import { useAuth } from '../../../Hooks/useAuth';
import { ProfileImage, ProfilePremium } from './style';

const ProfilePhoto = () => {

    const {currentUser, isFree} = useAuth();
    const colorGradientStart = isFree ? "#ded6d3" : "#EF742C";
    const colorGradientEnd = isFree ? "#bdb4b1":"#5c2c1d";
    const [planLabel, setPlanLabel] = useState("");
    const [labelColor, setLabelColor] = useState("");

    useEffect(() => {
        switch(currentUser.access_rule.rule.profile_level){  
            case '2':
                setPlanLabel("LIGHT");
                setLabelColor("#777d91");
                break;
            case '3':
                setPlanLabel("PREMIUM");
                setLabelColor("#6989BA");
                break;
            case '4':
                setPlanLabel("VIP");
                setLabelColor("#EF742C");
                break; 
        }
    }, []);

    return(
        <>
            <ProfileImage src = {currentUser.profile_photo_medium} 
                colorGradientStart={colorGradientStart}
                colorGradientEnd={colorGradientEnd}
            />
            { (!isFree) &&
                <ProfilePremium color={labelColor}>
                    {planLabel}
                </ProfilePremium>
            }

        </>
    );
}

export default ProfilePhoto;