import { Container, ControllerGroup, FileGroup, FinishButton, GalleryButton, GalleryContainer, PhotoClicker, PhotoWrapper } from "./style";
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import { useEffect, useRef, useState } from "react";
import Photo from "../../Components/ProfileViewerPhotoGallery/Photo";

const ImageCropper = ({cropperType, setCroppedImage, finishedCropping, concludeCropping, setBlobFile, separetedPhotos = []}) => {
    
    const aspects = {1:148/355, 2:312/172, 3:154/173, 4:16/9, 5:16/16}

    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [imageSrc, setImageSrc] = useState('');
    const [rotation, setRotation] = useState(0);
    const [scale, setScale] = useState(1);
    const [aspect, setAspect] = useState(aspects[cropperType]);

    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    const TO_RADIANS = Math.PI / 180;

    const imageRef = useRef(null);

    const rotateLeft = () => {
        let atualRotation = rotation;
        atualRotation = atualRotation - 5;
        setRotation(atualRotation);
    }

    const rotateRight = () => {
        let atualRotation = rotation;
        atualRotation = atualRotation + 5;
        setRotation(atualRotation);
    }

    const zoomIn = () => {
        let atualScale = scale;
        atualScale = atualScale + 0.1;
        setScale(atualScale);
    }

    const zoomOut = () => {
        let atualScale = scale;
        atualScale = atualScale - 0.1;
        setScale(atualScale);
    }

    const onFileSelection = (event) => {
        if(event.target.files && (event.target.files.length > 0)){
            setCrop(undefined);
            const reader = new FileReader();
            //Set the function that will be called when the read event finishes
            reader.addEventListener('load', () => {setImageSrc(reader.result.toString() || '')});
            //start the reading
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const onLoadedImage = (e) => {
        if(aspect){
            const {width, height} =  e.currentTarget;
            setCrop(centralizeCrop(width, height, aspect));
        }
    }

    const centralizeCrop = (mediaWidth, mediaHeight, aspect) => {
        const centeredCrop = centerCrop(makeAspectCrop({unit: "%", width: 90}, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
        return centeredCrop;
    }

    const onRotationChange = (event) => {
        setRotation(Math.min(180, Math.max(-180, Number(event.target.value))));
    }

    const onScaleChange = (event) => {
        setScale(Number(event.target.value));
    }

    const onCroppingComplete = async (crop) => {
        if(imageRef && crop.width && crop.height) {
            setCompletedCrop(crop);
            const croppedImage = await getCroppedImage(imageRef.current, crop, rotation, scale);
            setCroppedImage(croppedImage);
        }
    }

    const getCroppedImage =  (src, crop, rotation = 0, scale = 1) => {

        const canvas = document.createElement('canvas');

        const scaleX = src.naturalWidth / src.width;
        const scaleY = src.naturalHeight / src.height;

        canvas.width = Math.floor(crop.width * scaleX);
        canvas.height = Math.floor(crop.height * scaleY);

        const ctx = canvas.getContext('2d');

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotation * TO_RADIANS;

        const centerX = src.naturalWidth / 2;
        const centerY = src.naturalHeight / 2;

        ctx.save();
        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);

        ctx.drawImage(
            src,
            0,
            0,
            src.naturalWidth,
            src.naturalHeight,
            0,
            0,
            src.naturalWidth,
            src.naturalHeight,
        )
        ctx.restore()


        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if(!blob) {
                    reject(new Error('canvas is empty'));
                    return;
                }

                blob.name = 'croppedImage.jpeg';
                setBlobFile(blob);
                const croppedImageUrl = window.URL.createObjectURL(blob);
                resolve(croppedImageUrl);
            }, 'image/jpeg');
        });
    }

    const onGalleryClick = async (photo) => {
        let response = await fetch(photo.original_url);
        let data = await response.blob();
        let metadata = {
          type: 'image/jpeg'
        };
        let file = new File([data], "test.jpg", metadata);

        setCrop(undefined);
        const reader = new FileReader();
        //Set the function that will be called when the read event finishes
        reader.addEventListener('load', () => {setImageSrc(reader.result.toString() || '')});
        //start the reading
        reader.readAsDataURL(file);
        
    }

    useEffect(()=> {
        if(imageRef && completedCrop && completedCrop.width && completedCrop.height) {
            onCroppingComplete(completedCrop);
        }
        
    }, [rotation, scale]);

    return (
        <Container finishedCropping = {finishedCropping}>
            <input id = "image-file" type="file" accept="image/*" onChange={onFileSelection} />
            <FileGroup>
                {separetedPhotos && separetedPhotos.length >= 1 &&
                    <GalleryButton onClick={() => {setIsGalleryOpen(true); setImageSrc(null)}}>Buscar da galeria</GalleryButton>
                }
                <label htmlFor = "image-file" >Selecionar imagem</label>
                <FinishButton disabled = {!imageSrc} onClick = {concludeCropping}>Finalizar</FinishButton>
            </FileGroup>
            <ControllerGroup>
                <button onClick={rotateLeft} disabled = {!imageSrc}>Girar esquerda</button>
                <button onClick={rotateRight} disabled = {!imageSrc}>Girar direita</button>
                <button onClick={zoomIn} disabled = {!imageSrc}>Zoom in</button>
                <button onClick={zoomOut} disabled = {!imageSrc}>Zoom out</button>
            </ControllerGroup>

            {isGalleryOpen && !Boolean(imageSrc) &&
                <GalleryContainer>
                    {separetedPhotos.map((photo, key) => {
                        return(
                        <PhotoWrapper key = {key}>
                            <Photo  photo = {photo}/>
                            <PhotoClicker onClick={() => {onGalleryClick(photo)}} />
                        </PhotoWrapper>
                        )
                         
                    })}
                </GalleryContainer> 
            }

            {Boolean(imageSrc) && 
            (
                <ReactCrop
                    aspect = {aspect}
                    crop = {crop}
                    onChange = {(_, percentCrop) => setCrop(percentCrop)}
                    onComplete = { (crop) => onCroppingComplete(crop) }
                >
                    <img 
                        ref={imageRef} 
                        alt = "imagem a ser cortada" 
                        src={imageSrc}
                        style = {{transform: `rotate(${rotation}deg) scale(${scale})`, maxHeight: "500px"}}
                        onLoad = {onLoadedImage} 
                    />   
                </ReactCrop>
            )
            }
        </Container>
    );
}

export default ImageCropper;