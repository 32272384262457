import { useEffect, useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import Icon from "../../../Icon";
import InputV2 from "../../../InputV2";
import { ButtonSave, Container, GroupSave, Row, UploadMessage } from "./style";
import DisketIcon from '../../../../Assets/icons/E3.svg';
import axios from 'axios';
import Modal from "../../../Modal";
import Spinner from "../../../Spinner";

const SocialsTab = () => {

    const {currentUser, getToken, getUser} = useAuth();

    const [linkedin, setLinkedin] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(true);
        setIsLoading(true);
        
        const data = {
            linkedin: linkedin,
            instagram: instagram,
            facebook: facebook
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/desktop/socials`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers})
            getUser();
            setServerMessage("Dados salvos com sucesso!");
            setIsLoading(false);
            setIsUploadSuccess(true)
        }catch(error){
            //TODO add flash error message;
            let outputMessage = "";
            setIsLoading(false);
            setIsUploadSuccess(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                }

                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }



    useEffect( () => {
        setLinkedin(currentUser.contact.linkedin ? currentUser.contact.linkedin : "");
        setInstagram(currentUser.contact.instagram ? currentUser.contact.instagram: "");
        setFacebook(currentUser.contact.facebook ? currentUser.contact.facebook : "");
        
    }, []);

    return(
        <Container>
            <form onSubmit = {handleFormSubmit}>
                <Row>
                    <InputV2
                        label = {"Link completo do Instagram"}
                        name = {'instagram'}
                        value = {instagram}
                        onChange = {(event) => {setInstagram(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.instagram.com/ooppahoficial/"}
                    />
                </Row>
                <Row>
                    <InputV2
                        label = {"Link completo do Linkedin"}
                        name = {'linkedin'}
                        value = {linkedin}
                        onChange = {(event) => {setLinkedin(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.linkedin.com/company/ooppah"}
                    />
                </Row>
                <Row>
                    <InputV2
                        label = {"Link completo do facebook"}
                        name = {'facebook'}
                        value = {facebook}
                        onChange = {(event) => {setFacebook(event.target.value)}}
                        className = {"full-size"}
                        placeholder = {"https://www.facebook.com/ooppahoficial/"}
                    />
                </Row>
                <GroupSave>
                    <ButtonSave>
                        <Icon src = {DisketIcon} width = "3em" height = "3em"/>
                        Salvar
                    </ButtonSave>
                </GroupSave>
            </form>
            

            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
                {!isLoading &&
                    <UploadMessage state = {isUploadSuccess}>{serverMessage}</UploadMessage>
                }

            </Modal>
        </Container>
    );
}

export default SocialsTab;