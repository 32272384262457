import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`;

export const Card = styled.section `
    padding: 15px;
    border-radius: 15px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Input = styled.input `
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    height: 43px;
    box-sizing: border-box;
    padding-left: 8px;
    border-color: rgb(83, 82, 82);
    border-style: solid;
    border-width: 2px;
    margin-bottom: 10px;
`;

export const Button = styled.button `
    font-size: 18px;
    width: 100%;
    height: 43px;
    border: none;
    border-radius: 5px;
    background-color: #38529C;
    font-weight: bold;
    color: white;

    &:disabled {
        opacity: 0.6;
        cursor:wait;
    }  
`;

export const Logo = styled.img `
    margin-bottom: 10px;
`;