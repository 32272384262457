import { useEffect, useState } from "react";
import { AdvancedSearchGroup, ButtonCleanFilter, ButtonGroup, ButtonSearch, Container, Curtain, DisplayIconInfo, KeywordBox, KeywordGroup, Label, RedirectLink, Spacer, SpanIconButton, SpanIconButtonTouch, Title, TitleGroup } from "./style";
import InputV2 from '../InputV2';
import Dropdown from '../Dropdown';
import GlassIcon from '../../Assets/icons/B1A.svg';
import filterIcon from '../../Assets/icons/B3.svg';
import BackIcon from '../../Assets/icons/G5A.svg';
import Icon from '../Icon';
import { getSexOptions, prepareDistrictsDropdown, prepareStateDropdown, translateSexOption } from "../../Support/Util";
import axios from 'axios';
import MultiRangeSlider from "../MultiRangeSlider";
import InputToggleSwitch from "../InputToggleSwitch";
import { useAuth } from "../../Hooks/useAuth";
import Modal from "../Modal";
import EyeIcon from "../../Assets/icons/blueEye.svg";
import BlueStarIcon from "../../Assets/icons/A5.svg";
import FolderIcon from "../../Assets/icons/folder_icon.svg";
import TagIcon from "../../Assets/icons/tag_icon.svg";
import QuestionIcon from "../../Assets/icons/F3.svg";
import useWindowSize from "../../Hooks/useWindowSize";

const SearchSideBar = ({handleSearchSubmit}) => {

    const [keyword, setKeyword] = useState("");
    const [sex, setSex] = useState("");
    const [ageRange, setAgeRange] = useState({
        min: 0,
        max: 120
    });
    
    const [hasAgency, setHasAgency] = useState(false);

    const [states, setStates] = useState([]);
    const [state, setState] = useState('');

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState("");

    const { getProducerToken } = useAuth();

    const [isOpenIcons, setIsOpenIcons] = useState(false);

    const getStates = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/state`;

        try {
            const response = await axios.get(url)
            setStates(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error.data);
        }
    }

    const getDistrict = async (id) => {
        let url = `${process.env.REACT_APP_BASE_API}/district/${id}}`;

        try {
            const response = await axios.get(url)
            setDistricts(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const data = {
            initial_age: ageRange.min,
            final_age: ageRange.max,
            sex: sex,
            keyword: keyword,
            agency: hasAgency,
            state: state ? state.id: "",
            district: district ? district.id: "",
        }
        handleSearchSubmit(data);
    }

    const cleanFilter = (event) => {
        event.preventDefault();

        setKeyword("");
        setSex("");
        setAgeRange({
            min: 0,
            max: 120
        });
        setHasAgency(false);
        setState("");
        setDistrict("");

        
    }

    const changeModalState = () => {
        setIsOpenIcons(false);

    }

    useEffect(() => {
        getStates();
        
    }, []);

    useEffect(() => {
        getDistrict(state.id);
    }, [state])

    return (
        <Container>
            { !getProducerToken() && 
                <Curtain>
                    <div>
                        <p>
                            Apenas Pesquisadores e produtores conectados podem realizar pesquisas e acessar contatos telefônicos.
                            <a href = { `${process.env.REACT_APP_PRODUCER_BASE}/login`} >clique aqui e conecte-se</a>
                        
                        </p>
                    </div>
                </Curtain>
            }
            <form autoComplete="off" onSubmit={handleOnSubmit}>
                <KeywordBox>
                    <Title>Buscar por nome</Title>

                    <KeywordGroup>
                        <Icon src = {GlassIcon} classes = {"marginRight"}/>
                        <InputV2
                            placeholder = {"Nome ou sobrenome..."}
                            name = {'keyword'}
                            onChange = {(event) => {setKeyword(event.target.value)}}
                            value = {keyword}
                            className = {"maximum-width"}
                        />
                    </KeywordGroup>

                    <SpanIconButtonTouch 
                        type= "button"
                        onClick={()=>{setIsOpenIcons(true)}}
                    >
                        <span>Ícones</span> 
                        <Icon src={QuestionIcon} alt = "Question Icon" ></Icon>
                    </SpanIconButtonTouch>
                   
                   <SpanIconButton
                        onMouseOver={()=>{setIsOpenIcons(true)}}
                        onMouseOut={()=>{setIsOpenIcons(false)}}
                    >
                        <span>Ícones</span> 
                        <Icon src={QuestionIcon} alt = "Question Icon" ></Icon>
                    </SpanIconButton> 

                </KeywordBox>
                
                <AdvancedSearchGroup>
                    <TitleGroup>
                        <Title>Filtros avançados</Title>
                        <Icon src = {filterIcon} />
                    </TitleGroup>
                    
                    <Dropdown
                        label = {"Sexo"}
                        title = {"Sexo"}
                        options = {getSexOptions()}
                        valueDescription = {translateSexOption(sex)}
                        onChange = {(event) => {setSex(event.target.value)}}
                        className = {"maximum-width"}
                    />

                    <Label>Idade (entre <b>{ageRange.min}</b> e <b>{ageRange.max}</b> anos)</Label>
                    <MultiRangeSlider min = {0} max = {120} onChange = { ({min, max}) => {setAgeRange({min: min, max: max})}}/>
                    <Label>Artista com agente?</Label>
                    <InputToggleSwitch name = {"hasAgency"} onChange = {(checked) => setHasAgency(checked)}/>
                    <Label>Localidade</Label>
                    <Dropdown
                        name = {"state"}
                        title = {"Estado"}
                        options = {prepareStateDropdown(states)}
                        valueDescription = {state.name}
                        onChange = {(event) => {setState(event.target.value); setDistrict("")}}
                        className = {"maximum-width"}
                    />
                    <Spacer />
                    <Dropdown
                        name = {"district"}
                        title = {"Município"}
                        options = {prepareDistrictsDropdown(districts)}
                        valueDescription = {district.name}
                        onChange = {(event) => {setDistrict(event.target.value)}}
                        className = {"maximum-width"}
                    />
                    <Spacer />  
  
                    <ButtonGroup>
                        <ButtonSearch>Pesquisar</ButtonSearch>
                        <Spacer />
                        <ButtonCleanFilter type = "button" onClick={cleanFilter}>
                            Limpar Filtro
                            <Icon src = {BackIcon} classes = {"marginLeft"} />
                        </ButtonCleanFilter>
                    </ButtonGroup>
                </AdvancedSearchGroup>
            </form>

            <Modal isOpen={isOpenIcons} handleClose={changeModalState}>

                <DisplayIconInfo>
                        <Icon src = {EyeIcon} alt = "Eye Icon" width = {"1.5em"} height = {'1.5em'} classes="marginRight"/> Visitar perfil
                </DisplayIconInfo>

                <DisplayIconInfo>
                    <Icon src = {BlueStarIcon} alt = "Star Icon" width = {"1.5em"} height = {'1.5em'} classes="marginRight"/> Adicionar como favorito
                </DisplayIconInfo>

                <DisplayIconInfo>
                    <Icon src = {FolderIcon} alt = "Eye Icon" width = {"1.5em"} height = {'1.5em'} classes="marginRight"/> Adicionar em uma pasta
                </DisplayIconInfo>

                <DisplayIconInfo>
                    <Icon src = {TagIcon} alt = "Eye Icon" width = {"1.5em"} height = {'1.5em'} classes="marginRight"/> Adicionar tag
                </DisplayIconInfo>

            </Modal>

        </Container>
    );
}

export default SearchSideBar;