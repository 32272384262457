
import { Container, Metric, MetricLegend, TextModalFreeInfo, LinkArea } from './style.js'
import StatisticIcon from '../../Assets/icons/A2.svg';
import OoppahJobcIcon from '../../Assets/icons/trabalhos.svg';
import ValueField from './ValueField';
import EyeIcon from '../../Assets/icons/B2.svg';
import { useAuth } from '../../Hooks/useAuth';
import LockedField from './LockedField/index.js';
import Icon from '../Icon/index.js';
import { useState } from 'react';
import Modal from '../Modal/index.js';


const StatisticBar = () => {

    const {currentUser, isFree} = useAuth();
    const hasAccessCount = currentUser.access_rule.rule.has_access_count;
    const hasSearchCount = currentUser.access_rule.rule.has_search_count;
    const searchCount = currentUser.metric.search_count;
    const accessCount = currentUser.metric.access_count;

    const homePlansUrl = `${process.env.REACT_APP_HOME_URL }/plans`;
    const [showMessageFree, setshowMessageFree] = useState(false);
    const [isSearchIcon, setIsSearchIcon] = useState(false);

    const handleCloseMessageFree = () => {
        setshowMessageFree(false);
    };

    const handleOpenMessageFree = (left) => {
        setIsSearchIcon(left);
        setshowMessageFree(true);
    }

    return (
        <Container>
                <Metric> 
                    {hasAccessCount == 1 && (!isFree)?
                        <ValueField imageIcon = {StatisticIcon} fieldNumber = {searchCount} iconAlt = {"search icon"} />
                    :
                        <LockedField onclick={(() =>handleOpenMessageFree(true))}/>
                    }   
                    <MetricLegend>Pesquisas</MetricLegend>
                </Metric>

                { currentUser.ooppah_job == 1  &&
                <Metric>
                    <Icon src = {OoppahJobcIcon} width = "1.4em" height = '1.4em'/>
                    <MetricLegend>Já conseguiu trabalho pelo Ooppah</MetricLegend>
                </Metric>
                }
                

                <Metric>
                    { hasSearchCount == 1 && (!isFree)?
                        <ValueField imageIcon = {EyeIcon} fieldNumber = {accessCount} iconAlt = {"access icon"} />
                    :
                        <LockedField onclick={(() =>handleOpenMessageFree(false))}/>
                    }
                    <MetricLegend>Visualizações do perfil</MetricLegend>
                </Metric>

                <Modal isOpen={showMessageFree} handleClose = {handleCloseMessageFree}>
                    { isSearchIcon &&
                        <TextModalFreeInfo>
                            Descubra quantas vezes seu perfil apareceu no resultado de pesquisadores!
                            &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e passe na frente de todos os perfis gratuitos!
                        </TextModalFreeInfo>
                    }
                    { (!isSearchIcon) &&
                        <TextModalFreeInfo>
                            Descubra se seu perfil está realmente chamando a atenção dos contratantes!
                            &nbsp;<LinkArea href={homePlansUrl} target="_blank">Seja premium</LinkArea> e acesso ao número de visitantes do seu perfil!
                        </TextModalFreeInfo>
                    }
                </Modal>
        </Container>
    );


}

export default StatisticBar;