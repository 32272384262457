import { useEffect, useState } from "react";
import InputV2 from '../../InputV2';
import {useAuth} from '../../../Hooks/useAuth';
import { Container, AddVideoText, SubmitButton, Line, Videos, OrderButton, OrderText, OrderContainer, LimitInfo, JustifiedText } from "./style";
import axios from 'axios';
import VideoThumb from "./VideoThumb";
import Modal from "../../Modal";
import Spinner from "../../Spinner";
import Icon from "../../Icon";
import QuestionIcon from '../../../Assets/icons/F3.svg';
import { useToast } from "../../../Hooks/useToast";

const VideoTab = () => {
    const {currentUser} = useAuth();
    const {addToast} = useToast();
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");

    const [disabledButton, setDisabledButton] = useState(false);
    const [videos, setVideos] = useState([]);
    const {getToken} = useAuth();
    const [showSpiner, setShowSpiner] = useState(false);
    const limitVideos =  currentUser.access_rule.rule.limit_video;
    const [showModalLimit, setShowModalLimit] = useState(false);
    const [showModalBackendError, setShowModalBackendError] = useState(false);
    const [backendErrorMessage, setBackendErrorMessage ] = useState("");
    const [limitText, setLimitText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
    }

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    const handleCloseBackendError = () =>  {
        setShowModalBackendError(false);

    }

    const handleCloseLimitModal = () => {
        setShowModalLimit(false);

    }

    const handleCloseSpinner = () =>{
        setShowSpiner(false);
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if(videos.length >= limitVideos){
            addToast("Limite atingido", `Você possui um limite de ${limitVideos} vídeos no seu plano atual. Remova algumas e tente novamente.`, 0);
            setShowSpiner(false);
            return;
        }
        
        if(url.includes("vimeo.com")){
            const urlRegex1 = /^(https?:\/\/)?(vimeo)\.(com)([\/\d ]+)$/;
            //const urlRegex2 = /^(vimeo)\.(com)([\/\d ]+)$/;

            if(url.match(urlRegex1) == null){
                addToast("Link inválido!", "A url (link) que você utilizou é inválida! Sua url do vimeo deve possuir o seguinte formato: https://vimeo.com/365109570", 0);
                setShowSpiner(false);
                return;
            }
        }
        
        setShowSpiner(true);
        setDisabledButton(true);

        let apiUrl = `${process.env.REACT_APP_BASE_API}/video`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try{
            const response = await axios.post(
                apiUrl,
                {
                    url: url,
                    title: title
                },
                {
                    headers:headers
                }
            );
            await getVideos();
            addToast("Adição de vídeo", "Vídeo adicionado com sucesso!", 1);
            setUrl('');
            setTitle('');
        } catch(error){
            let outputMessage = "";
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.\n";
                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    } 
                    
                    else{
                        if(Array.isArray(errorMessages)){
                            errorMessages.map((message) => {
                                outputMessage = outputMessage + message + "\n";
                            });
                        }
                    }
                }
                
            }

            if(outputMessage.length == 0){
                outputMessage = "Ocorreu alguma falha! Entre em contato com o suporte.";
            }
            addToast("Erro de sistema", outputMessage, 0);
        }

        setDisabledButton(false);
        handleCloseSpinner();
    }

    const getVideos = async () => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/video`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.get(
                apiUrl,
                {
                    headers:headers
                }
            );

            setVideos(response.data);
        }catch(error){
            addToast("Erro de sistema", "Problemas ao solicitar vídeos", 0);
        }
     
    }

    const orderVideos = (index, direction) => {
        const orderedVideos = [...videos];
        let videosLength =  orderedVideos.length;
        if((index + direction) >= videosLength || (index+direction) < 0) return;
        let value = orderedVideos.splice(index, 1)[0]
        orderedVideos.splice((index+direction), 0, value);
        setVideos(orderedVideos);
    }

    const updateVideoOrders = async () => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/video/order`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try{
            const response = await axios.post(
                apiUrl,
                {
                    videos: videos
                },
                {
                    headers:headers
                }
            );
            addToast("Ordenação de vídeo", "Ordem ajustada com sucesso!", 1);

        } catch(error){
            addToast("Erro de sistema", error.response.data.message, 0);
        }

        setLimitText("Limite de vídeos "+videos.length+"/"+limitVideos);
    }

    const deleteVideo = async (video) => {

        let apiUrl = `${process.env.REACT_APP_BASE_API}/video/${video.id}`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.delete(
                apiUrl,
                {
                    headers:headers
                }
            );
            const newVideos = videos.filter((item) => {return item.id !== video.id});
            setVideos(newVideos);
            setLimitText("Limite de vídeos "+newVideos.length+"/"+limitVideos);
            addToast("Remoção de vídeo", "Vídeo removido com sucesso", 1);
        }catch(error){
            addToast("Erro de sistema", error.response.data.message, 0);
        }
        
    }

    useEffect(()=>{
        getVideos();
        
    },[]);

    useEffect(()=>{
        setLimitText("Limite de vídeos "+videos.length+"/"+limitVideos);
    }, [videos])

    return (
        <Container >
            <AddVideoText>
                Adicionar video {/* <Icon src = {QuestionIcon} onclick = {handleOpenModal} width = "0.8em" height ="0.8em" paddingLeft="2px"/>*/}
            </AddVideoText>

            <JustifiedText>
                <p>
                    Atualmente aceitamos vídeos do Youtube e Vimeo.
                    Novidade: links de <b>shorts</b> e <b>youtu.be</b> são aceitos.
                </p>
                <p>
                    Vídeos do vimeo necessariamente devem possuir o link com o seguinte formato: <b> https://vimeo.com/365109570</b>. 
                </p>
                <p>
                    Não aceitamos listas e playlists YouTube. Também não aceitamos páginas pessoais do Vimeo que se caracterizam pela presença de <b>/user</b> ou <b>/manager</b> dentro da URL.
                </p>
            </JustifiedText>
            <LimitInfo>
                {limitText}
            </LimitInfo>
            { (videos.length < limitVideos) &&
                <form onSubmit={handleOnSubmit} autoComplete = "off">
                    <InputV2 
                        label = {"Link do video"}
                        placeholder = {"Ex: https://www.youtube.com/watch?v=smhbaKoXLec"}
                        name = 'url'
                        onChange={(event) => setUrl(event.target.value)}
                        value = {url}
                    />

                    <InputV2 
                        label = {"Título do vídeo"}
                        
                        name = 'title'
                        onChange={(event) => setTitle(event.target.value)}
                        value = {title}
                    />
                    <SubmitButton disabled ={disabledButton}>Enviar</SubmitButton>
                </form>
            }
            <Line />
            <OrderContainer>
                <OrderText>
                    Ordene os seus videos da forma que quiser ! Clique nas setas em cada um deles para trocar de posição e depois clique em salvar ordem.
                </OrderText>
            </OrderContainer>
            
            <OrderButton onClick={updateVideoOrders}>Salvar ordem dos vídeos</OrderButton>
            <Videos>
                {videos.map((video, index)=>{
                    return (
                        <VideoThumb key = {index} index = {index} video = {video} deleteVideo = {deleteVideo} orderVideos = {orderVideos}/>
                    );
                })}
            </Videos>

            <Modal isOpen={showSpiner} handleClose={handleCloseSpinner}>
                <Spinner dimension={'40px'} />
            </Modal>

            {/* 
            <Modal isOpen={isOpen} handleClose ={handleCloseModal} >
                <JustifiedText>
                    <p>
                        Atualmente aceitamos vídeos do Youtube e Vimeo.
                        Novidade: links de <b>shorts</b> e <b>youtu.be</b> são aceitos.
                    </p>
                    <p>
                        Vídeos do vimeo necessariamente devem possuir o link com o seguinte formato: <b> https://vimeo.com/365109570</b>. 
                    </p>
                    <p>
                        Não aceitamos listas e playlists YouTube. Também não aceitamos páginas pessoais do Vimeo que se caracterizam pela presença de <b>/user</b> ou <b>/manager</b> dentro da URL.
                    </p>
                </JustifiedText>
            </Modal>
            */}
        </Container>
    );
}

export default VideoTab;