import axios from 'axios';
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import { getCookie, setCookie } from '../Support/Cookies';
import { objectIsEmpty } from '../Support/Util';

export const AuthContext = createContext();

const PROFILE_LVL_BASIC = "1";

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState({});
    const [authToken, setAuthToken] = useState();
    const [isFree, setIsFree] = useState(false);
    const [producerCurrentUser, setProducerCurrentUser] = useState({});
    const [producerAuthToken, setProducerAuthToken] = useState();

    const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };

    const setToken = (data) => {

        let cookieDomain = ".ooppah.com";
        if(process.env.NODE_ENV === "development"){
            cookieDomain = "localhost";
        }

        //setting cookies to comunicate with the account
        setCookie('ooppah_token', data.token, 30, cookieDomain);
        setCookie('ooppah_token_type',"Bearer", 30, cookieDomain);
        setCookie('ooppah_token_expires', data.expires_in, 30, cookieDomain);
        //Setting local storage in case if user closes the browser
        localStorage.setItem("ooppah_user_token", JSON.stringify(data));
        //Load it to the react state;
        setAuthToken(data); 
    }

    const getProducerToken = () => {

        //Try to findo the token on state;
        let tokenInfo = producerAuthToken;
        //Return the token if it's on state
        if(tokenInfo) return tokenInfo;
        //If it's not setted on state, search it on localStorage.
        tokenInfo = JSON.parse(localStorage.getItem('ooppah_producer_token'));
        //Return the token if it's on local storage;
        if(tokenInfo) return tokenInfo;
        //Try to find the token on cookies
        if(getCookie('ooppah_producer_token') === undefined || getCookie('ooppah_producer_token') == null){
            // user not connected
            return false;
        }else {
            //USer is loggend in
            tokenInfo = {
                "token": getCookie('ooppah_producer_token'),
                "type": getCookie('ooppah_producer_token_type'),
                'expires_at': getCookie('ooppah_producer_token_expires')
            }
            localStorage.setItem('ooppah_producer_token', JSON.stringify(tokenInfo))

            return tokenInfo;
        }
    }

    const getToken = () => {

        //Try to findo the token on state;
        let tokenInfo = authToken;
        //Return the token if it's on state
        if(tokenInfo) return tokenInfo;
        //If it's not setted on state, search it on localStorage.
        tokenInfo = JSON.parse(localStorage.getItem('ooppah_user_token'));
        //Return the token if it's on local storage;
        if(tokenInfo) return tokenInfo;
        //Try to find the token on cookies
        if(getCookie('ooppah_token') === undefined || getCookie('ooppah_token') == null){
            // user not connected
            return false;
        }else {
            //USer is loggend in
            tokenInfo = {
                "token": getCookie('ooppah_token'),
                "type": getCookie('ooppah_token_type'),
                'expires_at': getCookie('ooppah_token_expires')
            }
            localStorage.setItem('ooppah_user_token', JSON.stringify(tokenInfo))

            return tokenInfo;
        }
    }

    const logOut = () => {
        let cookieDomain = ".ooppah.com";
        if(process.env.NODE_ENV === "development"){
            cookieDomain = "localhost";
        }

        //Removing tokens
        setCookie('ooppah_token', '', -1, cookieDomain);
        setCookie('ooppah_token_type','', -1, cookieDomain);
        setCookie('ooppah_token_expires', '', -1, cookieDomain);

        localStorage.removeItem("ooppah_user_token");

        setAuthToken();
        setCurrentUser({});

        return <Navigate to = {'/'} />
    }

    const producerLogOut = () => {

        let cookieDomain = ".ooppah.com";
        if(process.env.NODE_ENV === "development"){
            cookieDomain = "localhost";
        }

        //Removing tokens
        setCookie('ooppah_producer_token', '', -1, cookieDomain);
        setCookie('ooppah_producer_token_type','', -1, cookieDomain);
        setCookie('ooppah_producer_token_expires', '', -1, cookieDomain);


        localStorage.removeItem("ooppah_producer_token");

        setProducerAuthToken('');
        setProducerCurrentUser({});

        return <Navigate to = {'/'} />
    }

    const getUser = async () => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/getuser`;
        let token = getToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(apiUrl,{headers:headers});
            setCurrentUser(response.data);
            setIsFree(response.data.access_rule.rule.profile_level === PROFILE_LVL_BASIC);
        
            
        }catch(error){
            //Add the warning message to be displayed TODO
            if(error.response.status == "401"){
                logOut();
            }
            console.log(error.response.data.message);
            console.log(error.response);
        }
    }

    const getProducer = async () => {
        let apiUrl = `${process.env.REACT_APP_PRODUCER_BASE_API}/user/self`;
        let token = getProducerToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(apiUrl,{headers:headers});
            console.log(response);
            setProducerCurrentUser(response.data.user);
            
        }catch(error){
            //Add the warning message to be displayed TODO
            if(error.response.status == "401"){
                logOut();
            }
            console.log(error.response.data.message);
            console.log(error.response);
        }
    }

    const isLoggedIn = () => {
        if(!objectIsEmpty(currentUser)){
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if(!authToken){
            let token  = getToken();
            if(token){
                setAuthToken(token);
            }
        }

        if(!producerAuthToken) {
            let token = getProducerToken();
            if(token) {
                setProducerAuthToken(token);
            }
        }
    }, [])

    useEffect(() => {
        if(authToken){
            getUser();
        }
    }, [authToken])

    useEffect(() => {
        if(producerAuthToken){
            getProducer();
        }
    }, [producerAuthToken])

    return (
        <AuthContext.Provider value = {{currentUser, setCurrentUser, producerCurrentUser, setToken, getToken, getProducerToken, logOut, producerLogOut,isLoggedIn, getUser, isFree}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}
