import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 250px;

    display: flex;

    border-width: 1px;
    border-style: solid;
    border-radius: 4px;

`;

export const Options = styled.div`
    width: 50%;
    height: 100%;
    box-sizing: border-box;

    padding: 4px 10px;

    overflow-y: scroll;

    font-size: 0.8em;
`;

export const Option = styled.p`
    cursor: pointer;
`;

export const Selections = styled.div`
    width: 100%;
    box-sizing: border-box;

    padding: 4px 5px;

    overflow-y: scroll;
`;

export const SelectionGroup = styled.div`
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;

    padding: 5px 5px;
`;

export const SelectedFeature = styled.div`
    background-color: #666666;

    box-sizing: border-box;
    color: white;
    padding 8px 12px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 0.8em;

    display: flex;
    justify-content: space-between; 
    align-items: center;
`;

export const CleanAll = styled.button`
    border: none;
    background: none;

    border-radius: 5px;
    border-width: 1px;
    border-color: red;
    border-style: solid;

    box-sizing: border-box;
    padding: 8px 12px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    cursor: pointer;


`;