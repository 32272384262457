import DOMPurify from "dompurify";
import { Container, CVText } from "./style";

const FormatAbout = ({about}) =>{
    let newAbout = "";

    if (about) {
        newAbout = DOMPurify.sanitize(about);
    }

    return(
        <Container>
            <CVText>
                <div dangerouslySetInnerHTML={{ __html: newAbout }} />
            </CVText>
        </Container>
    );

}

export default FormatAbout;