import { Circle, Container, MiniCircle, Rectangle } from "./style";

const PageSelector = ({pageNumber, getResources, isActive, pagination}) => {

    const isNumber = typeof pageNumber == "number";

    if(!isNumber) {
        const isInicio = pageNumber === "inicio";
        return(
            <Container onClick={() => {isInicio ? getResources(1):getResources(pagination.last_page)}} isActive = {isActive}>
                {pageNumber}
                <Rectangle>
                    <MiniCircle />
                    <MiniCircle />
                    <MiniCircle />
                </Rectangle>
            </Container>
        );    
    }

    return(
        <Container onClick={ () => {getResources(pageNumber)}} isActive = {isActive}>
            {pageNumber}
            <Circle isActive = {isActive}/>
        </Container>
    );
}

export default PageSelector;