import { useToast } from "../../Hooks/useToast";
import {Container} from "./style";
import Toaster from "./Toaster";

const Toast = () => {

    const {toasts, deleteToast} = useToast();

    return (
        <Container className="bottom-right">
            {
                toasts.map((toaster, index) => {
                    return (
                        <Toaster key = {index} toaster = {toaster} onClick = {() => {deleteToast(toaster)}} />
                    )
                })
            }
        </Container>
    );
}

export default Toast;