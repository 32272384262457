import styled from "styled-components";

export const Container = styled.div`

`;

export const CategoryName = styled.div`
    font-weight: bold;
`;

export const TopContent = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
`;

export const BodyContent = styled.div`
    display: flex;
`;

export const FeatureContentLeft = styled.div`
    padding-top: 10px;
    padding-bottom: 0;
    height: 250px;
    width: 50%;

    overflow-y: scroll;
    overflow-x: hidden;
    
    background-color: white;
    background-clip: content-box;
    
`;

export const Feature = styled.div`
    font-size: 0.9em;
    margin: 8px 0px;
    cursor: pointer;
`;

export const FeatureContentRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    height: 250px;
    width: 50%;
    padding-bottom: 0;
    overflow-x: hidden;
`;

export const RightItems = styled.div`
    background-color: white;
    overflow-y: scroll;
    justify-content: center;
    width: 100%;
`;

export const ChangeFeatures = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 3px;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #BAC7D6;
    width: 92%;
    
`;

export const ChangeCommand = styled.div`


`;