import SearchIcon from '../../Assets/icons/B1.svg';
import NewsIcon from '../../Assets/icons/C1.svg';
import LogoGray from '../../Assets/images/logo_gray.svg';
import TextLink from '../../Components/TextLink';
import { useAuth } from "../../Hooks/useAuth";
import DropdowHamburguer from './DropdownHamburguer';
import Icon from '../Icon';
import { BarDelimiter, Container, LeftSide, NotConnected, RightSide } from './style';
import UserSmallMenu from './UserSmallMenu';
import { Link, useNavigate } from 'react-router-dom';
import NotificationBar from './NotificationBar';
import { objectIsEmpty } from '../../Support/Util';
import ProducerSmallMenu from './ProducerSmallMenu';
import DropdownLogin from '../DropdownLogin';
import DropdownEnter from '../DropdownEnter';


const TopBar = () => {

    const navigate = useNavigate();
    const { currentUser, isLoggedIn, getProducerToken } = useAuth();

    const onEnterClick = () => {
        console.log("Enter");
    }

    const onSearchClick = () => {
        navigate("/artists");
    }

    const onLogoClick = () => {
        window.location.href = process.env.REACT_APP_HOME_URL;
    }

    return (
        <Container>
            <BarDelimiter>
                <LeftSide>
                    <DropdowHamburguer />
                    <Icon src = {LogoGray} alt = "Logo Ooppah" width = "5em" height = "2em" onclick={onLogoClick}/>
                    <Icon classes = "marginRight marginLeft" src = {SearchIcon} alt = "search icon" onclick={onSearchClick}/>    
                </LeftSide>

                <RightSide>
                    <Icon classes = "marginRight" src = {NewsIcon} alt = "News icon" />
                    
                    
                    {(!isLoggedIn() && !getProducerToken()) && 
                    <NotConnected>    
                        <DropdownLogin />
                        <TextLink classes = 'marginLeft-sm marginRight-sm' color = "#BAC7D6" fontSize = "0.8em">
                        </TextLink>
                        <DropdownEnter />
                    </NotConnected>
                    }
                    {isLoggedIn() &&
                        <NotificationBar  has_notification={currentUser.has_notification}/>
                    }
                    {isLoggedIn() &&
                        <UserSmallMenu classes = "marginLeft" />
                    }

                    {(!isLoggedIn() && getProducerToken()) && 
                        <ProducerSmallMenu classes = "marginLeft" />
                    }

                    
                </RightSide>
            </BarDelimiter>
        </Container>
        
    );
}

export default TopBar;