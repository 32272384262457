import {DropdownContent, DropdownWrapper, DropdownLink} from './style';
import MarketIcon from '../../../Assets/icons/J3.svg';
import PlayIcon from '../../../Assets/icons/K3.svg';
import Icon from '../../Icon'
import { useRef, useState } from 'react';
import HamburguerMenu from '../../../Assets/icons/A1.svg';
import { useClickedOutside } from '../../../Hooks/useClickedOutside';

const DropdowHamburguer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    const clickHandle = () => {
        let newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
    }

    const close = () => {
        setIsOpen(false);
    }

    useClickedOutside(dropdownRef, close, isOpen);

    return (
        <DropdownWrapper ref={dropdownRef}>
            <Icon onclick={clickHandle} backgroundColor={isOpen ? "#04070d": ''} classes = "marginRight cursor-click" src = {HamburguerMenu} alt = "Hamburguer menu"  width = '1.6em' height = '1.6em' />
            {isOpen &&
            <DropdownContent>
                <DropdownLink href = {process.env.REACT_APP_STORE_URL} target = "_blank">
                    <Icon src = {MarketIcon} classes = "marginRight" />
                    <span className='dark-text text-size'>Ooppah &nbsp;</span> 
                    <span className='text-size'>Store</span>
                </DropdownLink>
                <DropdownLink href = {process.env.REACT_APP_OOPPAH_PLAY_URL} target = "_blank">
                    <Icon src = {PlayIcon} classes = "marginRight"/>
                    <span className='dark-text text-size'>Ooppah &nbsp;</span>
                    <span className='text-size'> Play</span>
                </DropdownLink>
                <DropdownLink href = {process.env.REACT_APP_HOME_URL} target = "_blank" className = "marginTop-md marginLeft-sm marginBottom-md">
                    <span className='dark-text text-size'>Home</span>
                </DropdownLink>
            </DropdownContent>
            }
        </DropdownWrapper>
        
    );
}

export default DropdowHamburguer;