import { Content, NotificationContent, Container, ButtonArea, Button, TextArea, Title, PaginationContent, ToggleText, BlankSpace } from "./style";
import axios from 'axios';
import Paginator from "../Paginator";
import { useEffect, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import NotificationItem from "./NotificationItem";
import Modal from "../Modal";
import Spinner from "../Spinner";
import { hasJsonStructure } from "../../Support/Util";
import Friendship from "../Friendship";

const NotificationArea = () =>{

    const [notifications, setNotifications] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showSpiner, setShowSpiner] = useState(false);
    const [showMessageBackend, setShowMessageBackend ] = useState(false);
    const backendMessage = useState("Ocorreu alguma erro! Entre em contato com o suporte!");
    const [toggleSelect , setToggleSelect] = useState(1);
    const {getToken} = useAuth();

    const handleCloseErrorBack = () =>{
        setShowMessageBackend(false);
    }

    const getNotifications = async (page=1) => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/notification?page=${page}`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(
                apiUrl,
                {
                    headers:headers
                }
            );

            setNotifications(changeNotifications(response.data.data));
            delete response.data.data;
            setPagination(response.data);     
            
        }catch(error){
            console.log(error.response);
        }
    }

    const changeNotificationItem = (index) => {
        const tempNotification = [];
        for(let i=0; i < notifications.length; i++){
            if(i == index){
                let oldNotification = notifications[i];
                oldNotification.selected = !oldNotification.selected;
                tempNotification.push(oldNotification);
            } else{

                tempNotification.push(notifications[i]);
            }
        }
        setNotifications(tempNotification);
    }

    const changeNotifications = (notification) => {
        const tempNotification = [];
        for(let i=0; i < notification.length; i++){
            let oldNotification = notification[i];
            oldNotification.selected = false;
            tempNotification.push(oldNotification);
        }
        return tempNotification;
    }

    const selectedAll = () => {
        const tempNotificationList = [...notifications];
        const newNotificationStatus = tempNotificationList.map(function(notification) {
                                        if(notification.is_read == 1){
                                            return {...notification}

                                        }
                                        return {...notification, selected: toggleSelect}
                                    });
        setNotifications(newNotificationStatus);
        const tempToggle = toggleSelect;
        setToggleSelect(!tempToggle);
    }

    const setWithRead = async ()  =>{
        const tempNotifications = [];
        const sendNotifications = [];
        for(let i=0; i < notifications.length; i++){
            let oldNotification = notifications[i];
            if(oldNotification.selected && oldNotification.is_read == 0){
                oldNotification.is_read = 1;
                sendNotifications.push(oldNotification);
            }
            tempNotifications.push(oldNotification); 
        }
        setNotifications(tempNotifications);        
        
        let apiUrl = `${process.env.REACT_APP_BASE_API}/notification`;
        let token = getToken();
        setShowSpiner(true);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }
    
        try {
            const response = await axios.post(
                apiUrl,{notifications: sendNotifications},{headers:headers}
            );
            setShowSpiner(false);
            
        }catch(error){
            setShowSpiner(false);
            setShowMessageBackend(true);
        }
    }

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <Container>
            <Content>
                <Title>
                    Notificações
                </Title>
                <ButtonArea>

                    <TextArea>
                        Selecione uma ação para os itens marcados:
                    </TextArea>

                    <Button onClick={setWithRead}>
                        Lido
                    </Button>

                    <Button onClick={selectedAll}>
                        {toggleSelect  == 1 &&
                            <ToggleText>
                                Selecionar todos
                            </ToggleText>
                        }
                        {toggleSelect  == 0 &&
                            <ToggleText>
                                Desmarcar selecionados todos
                            </ToggleText>
                        }
                        
                    </Button>
                </ButtonArea>
                <NotificationContent>
                    {notifications.map((notification, index) => {

                            if(!notification.message){
                                return <BlankSpace key={index} />;
                            }
                            if(notification.message.includes('profile_requester')){
                                notification.message.replace('\\','');
                                
                                const friendship = JSON.parse(notification.message);
                                
                                if(friendship.type === "friendship"){
                                    return <Friendship friendship={friendship} notification_id = {notification.id}/>
                                }
                            }
                            return (<NotificationItem notification={notification} 
                                key={index} changeSelected={changeNotificationItem} 
                                index={index} ></NotificationItem>
                            );
                        })
                    }
                    <PaginationContent>
                        <Paginator pagination={pagination} getResources={getNotifications}/>
                    </PaginationContent>
                </NotificationContent>

                <Modal isOpen={showSpiner}>
                    <Spinner dimension={'40px'} />
                </Modal>

                <Modal isOpen={showMessageBackend} handleClose={handleCloseErrorBack}>
                    <p>{backendMessage}</p>
                </Modal>
            </Content>
        </Container>
    );
}

export default NotificationArea;
