import { Container, Content, Line, Title, Necessities, Necessity } from "./style";
import Characteristic from "./Characteristic";
import { useAuth } from "../../Hooks/useAuth";

import CakeIcon from '../../Assets/icons/D7.svg';
import BalanceIcon from '../../Assets/icons/E7.svg';
import DoubleArrowIcon from '../../Assets/icons/F7.svg';
import FeetIcon from '../../Assets/icons/G7.svg';
import TShirtIcon from '../../Assets/icons/H7.svg';
import HairIcon from '../../Assets/icons/I7.svg';
import EyeIcon from '../../Assets/icons/J7.svg';
import PersonIcon from '../../Assets/icons/K7.svg';
import ScissorIcon from '../../Assets/icons/A8.svg';
import FaceIcon from '../../Assets/icons/B8.svg';
import CombIcon from '../../Assets/icons/C8.svg';
import TattooIcon from '../../Assets/icons/D8.svg';
import LipsIcon from '../../Assets/icons/E8.svg';
import ArmIcon from '../../Assets/icons/F8.svg';
import SocialIcon from '../../Assets/icons/G8.svg';
import { getAge, transformFeature } from "../../Support/Util";




const CharacteristicsGallery = () => {

    const {currentUser}  = useAuth();

    const characteristics = currentUser.profile_features_characteristics?currentUser.profile_features_characteristics: [] ;
    const ageCharacteristic = transformFeature(getAge(currentUser.characteristic.birth_date) + " anos", "Idade");
    const weightCharacteristic = transformFeature(currentUser.characteristic.weight? (Math.round((parseFloat(currentUser.characteristic.weight) + Number.EPSILON) * 100) / 100) + "kg": currentUser.characteristic.weight, "Peso");
    const heightCharacteristic = transformFeature(currentUser.characteristic.height? (Math.round((parseFloat(currentUser.characteristic.height) + Number.EPSILON) * 100) / 100) + "m": currentUser.characteristic.height, "Altura");
    const shoeSizeCharacteristic = transformFeature(currentUser.characteristic.shoe_size, "Calçado");
    const mannequinsCharacteristic = transformFeature(currentUser.characteristic.mannequins, "Manequim");

    const getCharacteristics = (featureCategoryId) => {

        for(let i = 0; i < characteristics.length; i++){
            const characteristic = characteristics[i];
            if(characteristic.feature.feature_category.id === featureCategoryId){
                return characteristic;
            }
        }
        return null;
    }

    return (
        <Container >
            <Title>Características Físicas</Title>
            <Content>
                <Characteristic iconFile = {CakeIcon} characteristic = {ageCharacteristic}/>
                <Characteristic iconFile = {BalanceIcon} characteristic = {weightCharacteristic} />
                <Characteristic iconFile = {DoubleArrowIcon} characteristic = {heightCharacteristic} />
                <Characteristic iconFile = {FeetIcon} characteristic = {shoeSizeCharacteristic}/>
                <Characteristic iconFile = {TShirtIcon} characteristic = {mannequinsCharacteristic} />
                <Characteristic iconFile = {HairIcon} characteristic = {getCharacteristics(2)}/>
                <Characteristic iconFile = {EyeIcon} characteristic = {getCharacteristics(3)}/>
                <Characteristic iconFile = {PersonIcon} characteristic = {getCharacteristics(4)}/>
            </Content>
            <Line />
            <Title>Aparência/estilo</Title>
            <Content>
                <Characteristic iconFile = {ScissorIcon} characteristic = {getCharacteristics(26)}/>
                <Characteristic iconFile = {FaceIcon} characteristic = {getCharacteristics(27)} />
                <Characteristic iconFile = {CombIcon} characteristic = {getCharacteristics(11)} />
                <Characteristic iconFile = {TattooIcon} characteristic = {getCharacteristics(25)}/>
                <Characteristic iconFile = {LipsIcon} characteristic = {getCharacteristics(10)} />
                <Characteristic iconFile = {ArmIcon} characteristic = {getCharacteristics(31)}/>
            </Content>  
            <Line />

            <Title>Tatuagens</Title>
            <Necessities>
                {
                    characteristics.filter((characteristic) => {
                        return (characteristic.feature.feature_category.id == 25 );
                    }).map((characteristic, index) => {
                        return (
                            <Necessity key = {index} >{characteristic.feature.name}</Necessity>
                        );
                    })
                }
            </Necessities>

            <Title>Tipos Étnicos</Title>
            <Necessities>
                {
                    characteristics.filter((characteristic) => {
                        return (characteristic.feature.feature_category.id == 28 );
                    }).map((characteristic, index) => {
                        return (
                            <Necessity key = {index} >{characteristic.feature.name}</Necessity>
                        );
                    })
                }
            </Necessities>

            <Title>Característica especiais</Title>
            <Necessities>
                {
                    characteristics.filter((characteristic) => {
                        return (characteristic.feature.feature_category.id == 16 || characteristic.feature.feature_category.id == 29);
                    }).map((characteristic, index) => {
                        return (
                            <Necessity key = {index} >{characteristic.feature.name}</Necessity>
                        );
                    })
                }
            </Necessities>
        </Container>
    );
}

export default CharacteristicsGallery