import { Container, EditProfilePhotoButton, IconContainer, IconGroup,
    ProfileData, ProfileDetail, ProfileName, IconDescription,
    EndGroup, EndButtons, DeclineButton, ConfirmButton, FinishedImage, Message} from "./style";

import RoundImage from '../../RoundImage';
import Icon from '../../Icon';
import {useAuth} from '../../../Hooks/useAuth';
import Modal from '../../Modal';
import axios from 'axios';

import HouseIcon from '../../../Assets/icons/D3.svg';
import GeneralInactive from '../../../Assets/icons/general.svg';
import GeneralActive from '../../../Assets/icons/generalActive.svg';
import { useState, useEffect } from "react";
import ImageCropper from "../../ImageCropper";
import Spinner from "../../Spinner";
import useWindowSize from "../../../Hooks/useWindowSize";
import { Link } from "react-router-dom";

const Header = ({isGeralOpen, setIsGeralOpen}) => {

    const [windowWidth, windowHeight] = useWindowSize();

    const {currentUser, getToken, getUser} = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const [croppedImage, setCroppedImage] = useState();
    const [finishedCropping, setFinishedCropping] = useState(false);
    const [blobFile, setBlobFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadConcluded, setUploadConcluded] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [croppedImageDimensions, setcroppedImageDimensions] = useState({});
    const [uploadMessage, setUploadMessage] = useState('');

    const concludeCropping = () => {
        setFinishedCropping(true);
    }

    const insertCroppedImage =(croppedImage) => {
        setCroppedImage(croppedImage);
    }


    const handleClose = () => {
        setIsOpen(false);
        setFinishedCropping(false);
        setUploadConcluded(false);
    }

    const handleOpenModal = (event) => {
        event.preventDefault();
        setIsOpen(true);
    }

    const backButton = () => {
        setFinishedCropping(false);
    }

    const confirmImageSend = async () => {
        if(!croppedImage) return;
        setIsLoading(true);

        let url = `${process.env.REACT_APP_BASE_API}/profile/photo`;
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        const formData = new FormData();
        formData.append('photo', blobFile);

        try {
            const response = await axios.post(url, formData, {headers:headers});
            setUploadMessage("Foto de perfil alterada com sucesso. Estamos preparando sua imagem. Em instantes ela estará no seu perfil.");
            setIsUploadSuccess(true);
            setTimeout(function () {
                getUser();
            }, 5000);
            
            
        }catch(error){
            let outputMessage = "";
            setIsUploadSuccess(false);

            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setUploadMessage(outputMessage);
        }
        setIsLoading(false);
        setUploadConcluded(true);
        
    }

    const defineImageStyle = () => {
        if(!croppedImageDimensions) return ({width: '100%'});

        let srcWidth = croppedImageDimensions.width;
        let srcHeight = croppedImageDimensions.height;
        
        let maxWidth = window.innerWidth * 0.8;
        let maxHeight = window.innerHeight * 0.7;

        let ratio = Math.min(maxWidth/srcWidth, maxHeight/srcHeight);

        return ({width: srcWidth*ratio, height: srcHeight*ratio});
    }

    const profileImage = currentUser.profile_photo_medium;
    const artisticName = currentUser.artistic_name;

    useEffect(() => {
        if(!croppedImage) return;
        const img = new Image();
        img.src = croppedImage;
        img.onload = () => {
            setcroppedImageDimensions({width:img.width, height: img.height});
        }
    }, [croppedImage]);

    return (
        <Container>
            <ProfileDetail>
               <RoundImage src={profileImage} sizes = "3em" />
               <ProfileData>
                   <ProfileName>
                       {artisticName}
                   </ProfileName>
                   <EditProfilePhotoButton onClick = {handleOpenModal}>Alterar foto de perfil</EditProfilePhotoButton>
               </ProfileData>
            </ProfileDetail>
            <IconGroup>
                {windowWidth < 700 &&
                    <IconContainer>
                        <Icon onclick={() => {setIsGeralOpen(true)}} src = {isGeralOpen? GeneralActive: GeneralInactive} alt = "Icone de disquete" width = "2em" height="2em"/>
                        <IconDescription>Geral</IconDescription>
                    </IconContainer>
                }
                <Link to="/profile">
                    <IconContainer>
                        <Icon src = {HouseIcon} alt = "Icone de casa" width = "2em" height="2em"  />
                        <IconDescription>Voltar</IconDescription>
                    </IconContainer>
                </Link>
                
            </IconGroup>
            <Modal isOpen = {isOpen} handleClose = {handleClose}>
                <ImageCropper
                    cropperType = {5}
                    setCroppedImage = {insertCroppedImage}
                    finishedCropping = {finishedCropping}
                    concludeCropping = {concludeCropping}
                    setBlobFile = {setBlobFile}
                />
                {(finishedCropping && croppedImage && (!uploadConcluded) && (!isLoading)) &&
                    <EndGroup>
                        <EndButtons>
                            <DeclineButton onClick={backButton}>
                                Voltar
                            </DeclineButton>
                            <ConfirmButton onClick={confirmImageSend}>
                                Confirmar
                            </ConfirmButton>
                        </EndButtons>
                        <FinishedImage style={defineImageStyle()} src={croppedImage}  />
                    </EndGroup>
                }
                { isLoading &&
                    <Spinner />

                }

                { uploadConcluded &&
                    <Message state = {isUploadSuccess}>{uploadMessage}</Message>
                }
            </Modal>

        </Container>
    );
}

export default Header;