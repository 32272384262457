import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DefaultArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-itens: center;
`;

export const DefaultText = styled.span`
    display:inline-table;
    color: #EF742C;
    text-align: center;
    font-size: 0.8em;
    margin-right: 3px;
    margin-top: 2px;

    @media(max-width: 420px){
        font-size: 0.7em;
        margin-top: 0px;
    }
`;

export const CopyArea = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UrlText = styled.span`
    font-size: 0.8em;
    margin-right: 3px;
    text-decoration: underline;
    
    @media(max-width: 700px){
        font-size: 0.7em;
        text-align: center;
    }
`;
export const JustifiedText = styled.p`
    text-align: justify;
`;
