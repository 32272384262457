import { ArtisticName, Container, AgeAndCity } from './style';
import {useAuth} from '../../../Hooks/useAuth';
import { getAge, capitalizeFirstLetter } from '../../../Support/Util';
import Icon from '../../Icon';
import { SealIcons } from '../../../Support/Contants';

const ProfileShortInfo = () => {

    const {currentUser} = useAuth();

    const getProfileLevelICon = (profileLevel) => {

        return SealIcons[profileLevel];
    }

    const profileLevel = currentUser.access_rule.rule.profile_level;
    const birthDate = currentUser.characteristic.birth_date;
    const state = currentUser.address.state ? currentUser.address.state.abbreviation : "";
    const district = currentUser.address.district ? ", " + currentUser.address.district.name : "";

    return (
        <Container>
            <ArtisticName>
                <span>{currentUser.artistic_name}</span>
                {
                    getProfileLevelICon(profileLevel) &&
                    <Icon width='1.5em' height='1.5em' src = {getProfileLevelICon(profileLevel)}/>
                }
            </ArtisticName>
            <AgeAndCity>
                <span className='bold'>{getAge(birthDate)} anos</span>
                <span>|</span>
                <span>{state}{district}</span>
            </AgeAndCity>
        </Container>
    );
}

export default ProfileShortInfo;