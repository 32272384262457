import styled from "styled-components";

export const Content = styled.div`
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-bottom: 10px;
    width: 100%;
`;

export const Button = styled.button`

    border: none;
    border-radius: 7px;

    background: none;
    background-color: #385799;
    color: white;
    cursor: pointer;

    padding: 4px 15px;
    margin-left: 5px;
`;

export const ButtonArea = styled.div`
    width: 85%;
    margin: 0 auto;
    align-items: center;
    padding: 5px 0px;
`;

export const NotificationContent = styled.div`

`;

export const PaginationContent = styled.div`
    display: flex;
    justify-content: center;

`;

export const TextArea = styled.div`
    font-size: 0.8em;
    padding: 5px 5px;
`;

export const Title = styled.div`
    padding: 10px 10px;
`;

export const ToggleText = styled.div`

`;

export const BlankSpace = styled.div``;
