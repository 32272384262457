import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    @media(min-width: 500px){
        display:flex;
        flex-direction: column;
        align-items: center;

    }
`;

export const TabsList = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;

    @media(min-width: 500px) {
        width: 400px;
        margin-top: 15px;
    }

    @media(min-width: 700px) {
        width: 700px;
    }
    
`;

export const TabsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    width: 100%;

    @media(min-width: 500px){
        width: 450px;
        margin-top: 15px;
    }

    @media(min-width: 600px) {
        width: 500px;
        margin-top: 15px;
    }
`;

export const TabsDesktop = styled.div`
    display: flex;
    
`;

export const Info = styled.p`
    text-align: center;
`;