import { useEffect, useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import { Container, Title, TextAreaGroup, AboutTextArea, GroupSave, ButtonSave, UploadMessage  } from "./style";
import Icon from "../../../Icon";
import DisketIcon from '../../../../Assets/icons/E3.svg';
import axios from 'axios';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
const AboutTab = () => {

    const {currentUser, getToken, getUser} = useAuth();
    const [about, setAbout] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");
    

    useEffect(() => {
        setAbout(currentUser.characteristic.about ? currentUser.characteristic.about : "");
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(true);
        setIsLoading(true);

        const data = {
            about: about
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/desktop/about`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers});
            getUser();
            setServerMessage("Dados salvos com sucesso!");
            setIsLoading(false);
            setIsUploadSuccess(true)
        }catch(error){
            //TODO add flash error message;
            let outputMessage = "";
            setIsLoading(false);
            setIsUploadSuccess(false);

            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }


    return(
        <Container>
            <Title>Sobre você</Title>
            <form onSubmit = {handleFormSubmit}>
                <TextAreaGroup>
                    <label>
                        Sobre você (Texto do perfil)
                    </label>
                    <AboutTextArea value={about} onChange = {(event) => { setAbout(event.target.value)}} />
                </TextAreaGroup>
                <GroupSave>
                    <ButtonSave>
                        <Icon src = {DisketIcon} width = "3em" height = "3em"/>
                        Salvar
                    </ButtonSave>
                </GroupSave>
            </form>

            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
                {!isLoading &&
                    <UploadMessage state = {isUploadSuccess}>{serverMessage}</UploadMessage>
                }

            </Modal>
        </Container>
    );
}

export default AboutTab