import { Container, Row, Title, UploadMessage, SaveButton, ButtonWrapper  } from "./style";
import Dropdown from "../../../Dropdown";
import { useAuth } from "../../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import InputV2 from "../../../InputV2";
import axios from 'axios';
import Modal from "../../../Modal";
import Spinner from "../../../Spinner";
import SpecialConditionSelector from "./SpecialConditionSelector";
import { floatFilter } from "../../../../Support/Util";
import { useToast } from "../../../../Hooks/useToast";

const CharacteristicForm = ({features}) => {
    const {addToast} = useToast();
    const {currentUser, getToken, getUser} = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLogading] = useState(true);

    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [shoeSize, setShoeSize] = useState("");
    const [mannequins, setMannequins] = useState("");
    const [hairColor, setHairColor] = useState({});
    const [eyeColor, setEyeColor] = useState({});
    const [skinColor, setSkinColor] = useState({});
    const [hairSize, setHairSize] = useState({});
    const [typeOfHair, setTypeOfHair] = useState({});
    const [hairType, setHairType] = useState({});
    const [accent, setAccent] = useState({});
    const [physicalType, setPhysicalType] = useState({});
    const [tattoos, setTattoos] = useState([]);
    const [ethnicTypes, setEthnicTypes] = useState([]);
    const [usersSelection, setUsersSelection] = useState([]);

    const getRawFeaturesByCategoryId = (categoryId) =>{
        if(features.length == 0) return [];
        const result = features.filter((element)=>{
            return element.id == categoryId;
        });
       return result[0];
    }

    const getFeaturesByCategoryId = (categoryId) =>{
        if(features.length == 0) return [];
        const result = features.filter((element)=>{
            return element.id == categoryId;
        });

        const RefactoredResult = result[0].features.map( (feature) => {
            return ({
                value: feature,
                description: feature.name
            });
        });

        return RefactoredResult;
    }

    const getFeatureFromUser = (categoryId) =>{
        const feature = currentUser.profile_features_characteristics.find(( element )=>{
            return (element.feature.feature_category.id == categoryId);
        });

        let featureSelected = {};
        if(feature){
            featureSelected = {id: feature.feature.id, name: feature.feature.name};
        }
        return featureSelected;
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(true);
        setIsLogading(true);
        const data = {
            height: height,
            weight: weight,
            shoe_size: shoeSize,
            mannequins: mannequins,
            hairColor: hairColor,
            eyeColor: eyeColor,
            skinColor: skinColor,
            hairSize: hairSize,
            typeOfHair: typeOfHair,
            hairType: hairType,
            accent: accent,
            physicalType: physicalType,
            tattoos: tattoos,
            ethnicTypes: ethnicTypes,
            usersSelection: usersSelection
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/characteristic`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try{
            const response = await axios.post(url, data, {headers:headers});
            addToast("Adição de aspectos", "Aspecto(s) adicionado(s) com sucesso!", 1);
            getUser();
        } catch(error) {
            addToast("Erro de sistema", error.response.data.message, 1);
        }
        setIsLogading(false);
    }

    useEffect(() => {
        setHeight(currentUser.characteristic.height ? Math.round((parseFloat(currentUser.characteristic.height) + Number.EPSILON) * 100) / 100 : "");
        setWeight(currentUser.characteristic.weight ? Math.round((parseFloat(currentUser.characteristic.weight) + Number.EPSILON) * 100) / 100 : "");
        setShoeSize(currentUser.characteristic.shoe_size ? currentUser.characteristic.shoe_size : "");
        setMannequins(currentUser.characteristic.mannequins ? currentUser.characteristic.mannequins : "");
        setHairColor(getFeatureFromUser(2));
        setEyeColor(getFeatureFromUser(3));
        setSkinColor(getFeatureFromUser(4));
        setHairSize(getFeatureFromUser(26));
        setTypeOfHair(getFeatureFromUser(27));
        setHairType(getFeatureFromUser(11));
        setAccent(getFeatureFromUser(10));
        setPhysicalType(getFeatureFromUser(31));
    }, []);

    const specialFeatures = features.length > 0 ? [...getRawFeaturesByCategoryId(16).features, ...getRawFeaturesByCategoryId(29).features]: [];
    const ethnicTypeList = features.length > 0 ? getRawFeaturesByCategoryId(28).features: [];
    const tattoosList = features.length > 0 ? getRawFeaturesByCategoryId(25).features: [];

    return (
        <Container>
            <form onSubmit={handleOnSubmit}  autoComplete = "off">
                <Title>Caracteristicas físicas</Title>
                <Row>
                    <InputV2 
                        label = {"Altura"} 
                        name = {'height'} 
                        placeholder = {"Ex: 1.75"}
                        className = {"third-size marginRight"}
                        value = {height}
                        onChange = {(event) => {setHeight(floatFilter(event.target.value))}}
                    />
                    
                    <InputV2
                        label = {"Peso"}
                        name = {"weight"}
                        placeholder = {"Ex: 72"}
                        className = {"third-size marginRight"}
                        value = {weight}
                        onChange = {(event) => {setWeight( floatFilter(event.target.value))}}
                    />

                    <InputV2
                        label = {"Calçado"}
                        name = {"shoeSize"}
                        placeholder = {"Ex: 41"}
                        className = {"third-size"}
                        value = {shoeSize}
                        onChange = {(event) => {setShoeSize( floatFilter( event.target.value ))}}
                    />
                    
                </Row>
                
                <Row>
                    <InputV2
                        label = {"Manequim"}
                        name = {"Mannequins"}
                        placeholder = {"Ex: 38"}
                        className = {"third-size marginRight"}
                        value = {mannequins}
                        onChange = {(event) => {setMannequins( floatFilter(event.target.value))}}
                    />

                    <Dropdown 
                        name ={"hairColor"} 
                        label={"Cor do cabelo"} 
                        title = {"Cor do cabelo"} 
                        options = {getFeaturesByCategoryId(2)}
                        valueDescription = {hairColor.name}
                        value = {hairColor}
                        onChange = {(event) => {setHairColor(event.target.value)}}
                        
                    />
                </Row>
                
                <Row>
                    <Dropdown 
                        label = {"Cor dos olhos"} 
                        name = {"eyeColor"} 
                        title = {"Cor dos olhos"} 
                        options = {getFeaturesByCategoryId(3)}
                        valueDescription = {eyeColor.name}
                        className = {"marginRight"}
                        value = {eyeColor}
                        onChange = {(event) => {setEyeColor(event.target.value)}}
                    />

                    <Dropdown 
                        label = {"Cor da pele"} 
                        name = {"skinColor"} 
                        title = {"Cor da pele"} 
                        options = {getFeaturesByCategoryId(4)}
                        valueDescription = {skinColor.name}
                        value = {skinColor}
                        onChange = {(event) => {setSkinColor(event.target.value)}}
                    
                    />
                </Row>
                
                <Title>Aparência/estilo</Title>

                <Row>
                    <Dropdown 
                        name ={"hairSize"} 
                        label = {"Tamanho do cabelo"} 
                        title = {"Tamanho do cabelo"} 
                        options = {getFeaturesByCategoryId(26)}
                        valueDescription = {hairSize.name}
                        className = {"marginRight"}
                        value = {hairSize}
                        onChange = {(event) => {setHairSize(event.target.value)}}
                    />
                    
                    <Dropdown 
                        name ={"typeOfHair"} 
                        label = {"Tipo de cabeleira"} 
                        title = {"Tipo de cabeleira"} 
                        options = {getFeaturesByCategoryId(27)}
                        valueDescription = {typeOfHair.name}
                        value = {typeOfHair}
                        onChange = {(event) => {setTypeOfHair(event.target.value)}} 
                    />

                </Row>
                
                <Row>
                    <Dropdown 
                        name ={"hairType"} 
                        label = {"Tipo de cabelo"} 
                        title = {"Tipo de cabelo"} 
                        options = {getFeaturesByCategoryId(11)}
                        valueDescription = {hairType.name}
                        className = {"marginRight"}
                        value = {hairType}
                        onChange ={(event) => {setHairType(event.target.value)}}
                    />

                    <Dropdown 
                        name ={"accent"} 
                        label = {"Sotaque natural"} 
                        title = {"Sotaque natural"} 
                        options = {getFeaturesByCategoryId(10)}
                        valueDescription = {accent.name}
                        className = {"marginRight"}
                        value = {accent}
                        onChange = {(event) => {setAccent(event.target.value)}}
                    
                    />

                </Row>

                <Row>
                    

                    <Dropdown 
                        name = {"physicalType"} 
                        label = {"Tipo físico"} 
                        title = {"Tipo físico"} 
                        options = {getFeaturesByCategoryId(31)}
                        valueDescription = {physicalType.name}
                        value = {physicalType}
                        onChange = {(event) => {setPhysicalType(event.target.value)}}
                    />

                </Row>
                <Title>Tatuagens</Title>

                <SpecialConditionSelector
                    options={tattoosList}
                    usersSelection={tattoos}
                    setUsersSelection={setTattoos}
                    featureCategoryIds={[25]}
                />

                <Title>Tipo étnico</Title>

                <SpecialConditionSelector
                    options={ethnicTypeList}
                    usersSelection={ethnicTypes}
                    setUsersSelection={setEthnicTypes}
                    featureCategoryIds={[28]}
                />

                <Title>Características especiais</Title>

                <SpecialConditionSelector 
                    options = {specialFeatures} 
                    usersSelection = {usersSelection}
                    setUsersSelection = {setUsersSelection}
                    featureCategoryIds = {[16, 29]}
                />
                <ButtonWrapper>
                    <SaveButton>Salvar</SaveButton>
                </ButtonWrapper>
                
            </form>

        </Container>
    );
}

export default CharacteristicForm;