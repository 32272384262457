import { useRef, useState } from "react";
import { useClickedOutside } from "../../Hooks/useClickedOutside";
import { AlignIcon, Container, Header, JustifiedText, Label, LabelWrapper, List, Option, SearchableWrapper, SpinnerContainer, TextGray, Title } from "./style";
import ArrowDown from '../../Assets/icons/C5.svg';
import ArrowUp from '../../Assets/icons/D5.svg';
import QuestionIcon from '../../Assets/icons/F3.svg';
import Icon from '../Icon';
import Spinner from "../Spinner";
import { useEffect } from "react";
import Modal from "../Modal";


const Dropdown = ({onChange, name, label = null, title, options, valueDescription, className = null, searchable = false, modalDescription = ""}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const [propagandaModal, setPropagandaModal] = useState(false);

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchField, setSearchField] = useState("");

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const close = () => {
        setIsOpen(false);
    }

    const handleCloseModal = () => {
        setIsOpenModal(false);
    }

    const handleOpenModal = () => {
        setIsOpenModal(true);
    }

    const toggleDropdown = () =>{
        const newState = !isOpen;
        setIsOpen(newState);
    }

    const handleSearchField = (event) => {
        setSearchField(event.target.value);
    }

    const makeSelection = (value) => {
        setSelectedItem(value);
        close()
        if(onChange){
            onChange({target:{name:name, value: value }});
        }
    }

    useClickedOutside(dropdownRef, close, isOpen);

    useEffect(() => {
        if(isOpen && searchable){
            inputRef.current.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        const filtered = options.filter((item) => {
            return item.description.toLowerCase().indexOf(searchField.toLowerCase()) > -1
        })
        setFilteredOptions(filtered);
    }, [searchField]);



    return (
        <Container ref={dropdownRef} className = {className}>
            <LabelWrapper>
                {
                    label &&
                    <Label>{label}</Label>
                }

                {
                    modalDescription.length > 0 &&
                    <AlignIcon>
                        <Icon src = {QuestionIcon} onclick = {handleOpenModal} width = {"1.0em"} height = {'1.0em'}/>
                    </AlignIcon>
                }
            </LabelWrapper>
            <Header type = "button" onClick = {toggleDropdown}>
                <Title isPlaceholder = {!valueDescription}>{valueDescription ? valueDescription: title}</Title>
                {
                    isOpen ?
                    <Icon src = {ArrowUp} />:
                    <Icon src = {ArrowDown}/> 
                }
            </Header>
            {
                isOpen &&
                <List>
                    { !filteredOptions &&
                        <SpinnerContainer>
                            <Spinner dimension={"15px"}/>
                        </SpinnerContainer>
                        
                    }
                    {filteredOptions && searchable &&
                        <SearchableWrapper>
                            <input
                                className="search-input"
                                ref = {inputRef}
                                type = {"text"}
                                onChange = {handleSearchField}
                                value = {searchField}
                                placeholder = "pesquise aqui"
                            />
                        </SearchableWrapper>
                    }
                    { filteredOptions && filteredOptions.map((item, index) => {
                        return(
                            <Option
                                canSelect = {item.canSelect} 
                                type = "button" 
                                key = {index}
                                onClick = { item.canSelect == false ? ()  => {setPropagandaModal(true)}:() => {makeSelection(item.value)}}>{item.description}</Option>
                        );
                    })}
                </List>
            }

            <Modal isOpen={isOpenModal} handleClose ={handleCloseModal} >
                <JustifiedText>
                    {modalDescription}
                </JustifiedText>
            </Modal>

            <Modal isOpen={propagandaModal} handleClose ={() => {setPropagandaModal(false)}} >
                <JustifiedText>
                    Para tornar o seus contatos visíveis apenas a produtores e/ou pesquisadores, é preciso realizar 
                    um <a href={process.env.REACT_APP_HOME_URL + "/plans"}>upgrade de assinatura.</a>
                </JustifiedText>
            </Modal>
            
            
        </Container>
    );
}

export default Dropdown