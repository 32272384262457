import styled from "styled-components";

export const CVText = styled.div`
    display: flex;
    text-align: left;
    width: 100%;
    color: #3A4659;
    box-sizing: boder-box;
    padding-top: 8px;
    font-size: 0.9em;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const Container = styled.div`

`;