import { useEffect, useState } from "react";
import ImageCropper from "../../ImageCropper";
import RoundImage from "../../RoundImage";
import Modal from "../../Modal";
import { Button, ButtonArea, ConfirmButton, Container, Content, DeclineButton,
    EndButtons, EndGroup, FinishedImage, Message, PhotoArea, Title } from "./style";
import axios from 'axios';
import Spinner from "../../Spinner";
import { useAuth } from "../../../Hooks/useAuth";
import DefaultImageProfile from "../../../Assets/icons/K7.svg";

const PhotoTab = ({onChange, setDoneStep}) =>{

    const {currentUser, getToken, getUser} = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState();
    const [finishedCropping, setFinishedCropping] = useState(false);
    const [uploadConcluded, setUploadConcluded] = useState(false);
    const [blobFile, setBlobFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [croppedImageDimensions, setcroppedImageDimensions] = useState({});
    const [srcProfileImage, setSrcProfileImage ] = useState(DefaultImageProfile);
    const [nextStepMessage, setNextStepMessage] = useState("");
    const [showNextStepMessage, setShowNextStepMessage] = useState(false);
    const [showErrorMensage, setShowErrorMensage] = useState(false);
    const profileImage = currentUser.profile_photo_medium;
    const nextState = 2;
 
    const setImageProfile = () =>{
        if(!profileImage) return;
        if(profileImage.includes(".jpg") || profileImage.includes(".jpeg") || profileImage.includes(".png")){
            setSrcProfileImage(profileImage);
        }
    }

    const handleOpenModal = (event) => {
        event.preventDefault();
        setIsOpen(true);
    }

    const insertCroppedImage =(croppedImage) => {
        setCroppedImage(croppedImage);
    }

    const concludeCropping = () => {
        setFinishedCropping(true);
    }

    const backButton = () => {
        setFinishedCropping(false);
    }

    const handleClose = () => {
        setIsOpen(false);
        setFinishedCropping(false);
        setUploadConcluded(false);
        setNextStepMessage("");
        setShowNextStepMessage(false);
        setShowErrorMensage(false);
        setIsLoading(false);
    }

    const confirmImageSend = async () => {
        if(!croppedImage) return;
        setIsLoading(true);

        let url = `${process.env.REACT_APP_BASE_API}/profile/photo`;
        let token = getToken();

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token.type} ${token.token}`
        }

        const formData = new FormData();
        formData.append('photo', blobFile);

        try {
            const response = await axios.post(url, formData, {headers:headers});
            setUploadMessage("Foto de perfil alterada com sucesso. Estamos preparando sua imagem. Em instantes ela estará no seu perfil.");
            setIsUploadSuccess(true);
            setDoneStep(true);
            setTimeout(function () {
                getUser();
            }, 5000);
            
            
        }catch(error){
            let outputMessage = "";
            setIsLoading(false);
            
            setIsUploadSuccess(false);
            setShowErrorMensage(true);
            setDoneStep(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = error.response.data.message;
                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setUploadMessage(outputMessage);
        }
        setIsLoading(false);
        setUploadConcluded(true);
        
    }

    const defineImageStyle = () => {
        if(!croppedImageDimensions) return ({width: '100%'});

        let srcWidth = croppedImageDimensions.width;
        let srcHeight = croppedImageDimensions.height;
        
        let maxWidth = window.innerWidth * 0.8;
        let maxHeight = window.innerHeight * 0.7;

        let ratio = Math.min(maxWidth/srcWidth, maxHeight/srcHeight);

        return ({width: srcWidth*ratio, height: srcHeight*ratio});
    }

    const checkNextStep = () => {
        if(isUploadSuccess || profileImage){
            setDoneStep(true);
            onChange(nextState);
            return ;
        }
        setDoneStep(false);
        setNextStepMessage("Adicione uma imagem para completar essa etapa do seu perfil.");
        setShowNextStepMessage(true);
        setIsOpen(true);

    }

    useEffect(() => {
        if(!croppedImage) return;
        const img = new Image();
        img.src = croppedImage;
        img.onload = () => {
            setcroppedImageDimensions({width:img.width, height: img.height});
        }
    }, [croppedImage]);

    useEffect(()=>{
        setImageProfile();
    }, []);

    return(
        <Container>
            <Content>
                <Title>
                    <h3>Escolha uma foto para o seu perfil.</h3>
                    <span className="sub-title">Mostre para todos como você está atualmente.</span>
                </Title>
                <PhotoArea>
                    <RoundImage src={isUploadSuccess ? croppedImage: srcProfileImage} sizes = "5em" onClick = {handleOpenModal} />
                </PhotoArea>
                <ButtonArea >
                    
                    <Button className="photo-button" onClick = {handleOpenModal}>
                        Clique aqui para adicionar uma foto.
                    </Button>
                </ButtonArea>

                <Modal isOpen = {isOpen} handleClose = {handleClose}>

                    {!showNextStepMessage &&
                        <ImageCropper
                            cropperType = {5}
                            setCroppedImage = {insertCroppedImage}
                            finishedCropping = {finishedCropping}
                            concludeCropping = {concludeCropping}
                            setBlobFile = {setBlobFile}
                        />
                    }
                    {(finishedCropping && croppedImage && (!uploadConcluded) && (!isLoading)) &&
                        <EndGroup>
                            <EndButtons>
                                <DeclineButton onClick={backButton}>
                                    Voltar
                                </DeclineButton>
                                <ConfirmButton onClick={confirmImageSend}>
                                    Confirmar
                                </ConfirmButton>
                            </EndButtons>
                            <FinishedImage style={defineImageStyle()} src={croppedImage}  />
                        </EndGroup>
                    }
                    { isLoading &&
                        <Spinner />

                    }

                    { (uploadConcluded && !showErrorMensage) &&
                        <Message state = {isUploadSuccess}>{uploadMessage}</Message>
                    }

                    { showNextStepMessage &&
                        <Message state={false}>{nextStepMessage}</Message>
                    }

                    { showErrorMensage && 
                        <Message state={false}>{uploadMessage}</Message>
                    }
                </Modal>

                <Button onClick={checkNextStep} disabled={!isUploadSuccess && !profileImage}>
                    Próximo passo
                </Button>
            </Content>
        </Container>
    );

};

export default PhotoTab;