import { useAuth } from "../../Hooks/useAuth";
import { useToast } from "../../Hooks/useToast";
import Modal from "../Modal";
import RoundImage from "../RoundImage";
import { AgencyDisplayCase, AgencyInfo, BackButton, Button, ButtonUnbind, ConfirmButton, Container, ContentWrapper, Info, MessageText, Title, TitleAgency } from "./style";

import axios from 'axios';
import { useState, useEffect } from 'react';

const AgencyDisplay = ({ clickHandle}) => {

    const {currentUser, getToken} = useAuth();
    const {addToast} = useToast();

    const [agency, setAgency] = useState({});

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleClick = () => {
        
        clickHandle('agencies');
        
    }

    const handleCloseConfirmationModal = () => {
        setIsModalConfirmationOpen(false);
        setIsButtonDisabled(false);
        
    }

    const unbindAgency = async () => {
        setIsModalConfirmationOpen(false);
        setIsButtonDisabled(true);

        let apiUrl = `${process.env.REACT_APP_BASE_API}/agency/unbind`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(apiUrl,{},{headers:headers});
            console.log(response);
            addToast('Sucesso', 'Usuário desvinculado da agência', 1);
        }catch(error){
            addToast("Erro", 'Entre em contato com o suporte.');

        }

    }

    const handleGetSingleAgency = async (agency_id) => {
        let apiUrl = `${process.env.REACT_APP_BASE_API}/agency/${agency_id}/get`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.get(apiUrl,{headers:headers});
            setAgency(response.data.agency);
            console.log(response);
        }catch(error){
            

        }
    }

    useEffect(() => {
        if(currentUser.agency_id){
            handleGetSingleAgency(currentUser.agency_id);
        }
    }, [])
    
    return(
        <Container>
            <Title>Agência/Agentes</Title>
            { !currentUser.agency_id &&
                <ContentWrapper>
                    <Info>Você possui uma agência ou agente ? </Info>
                    <Button type= "button" onClick ={handleClick} >Clique aqui e procure</Button>
                </ContentWrapper>
            }
            { currentUser.agency_id &&
                <AgencyDisplayCase>
                    <RoundImage src={agency.avatar_url} sizes = "7em"/>
                    <TitleAgency>
                        {agency.name}
                    </TitleAgency>
                    <AgencyInfo>
                        {agency.about}
                    </AgencyInfo>
                    <ButtonUnbind type = "button" onClick={() => {setIsModalConfirmationOpen(true)}} disabled = {isButtonDisabled}>
                        Desvincular agência
                    </ButtonUnbind>
                </AgencyDisplayCase>
            }
            <Modal isOpen = {isModalConfirmationOpen} handleClose = {handleCloseConfirmationModal}>
                <MessageText>Tem certeza que deseja se desvincular da agência ? </MessageText>
                <BackButton onClick={handleCloseConfirmationModal}>Voltar</BackButton>
                <ConfirmButton onClick={unbindAgency}>Desvincular</ConfirmButton>
            </Modal>
        </Container>
    );
}

export default AgencyDisplay;