import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
`;

export const TopHeader = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
`;

export const TopHeaderDesktop = styled.div`
    display: flex;
    justify-content: space-between;
    width: 700px;
    margin-top: 20px;
    
    margin-left:auto;
    margin-right: auto;
`;

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    
    @media(min-width: 700px){
        width: 35%;
    }
`;

export const RightSide = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #8B8B8B;

    @media(min-width: 700px){
        width: 65%;
    }
`;

export const About = styled.span`
    text-align: center;
    font-size: 0.8em;
    @media(min-width: 700px) {
        text-align: left;
    }
`;

export const SeeMore = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    outline: none;

    cursor: pointer;
    display: inline-block;

    text-decoration: underline;

    color: #385799;
    font-size: 0.8em;
`;


export const Line = styled.div`
    border-top-width: 1px;
    border-bottom: 0;

    border-color: #B3B3B3;
    border-style: solid;
    
    width: 95%;

    margin-top: 15px;
    margin-bottom: 10px;
`;

export const AboutTitle = styled.h1`
    font-size: 1.3em;
    font-weight: bold;
    text-align: left;
`;

export const OkButton = styled.button`
    border: none;
    background: none;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    border-radius: 4px;

    display: block;
    background-color: #385799;

    margin-top: 10px;

    padding: 10px 40px;
    color: white;
    font-weight: bold;
`;