import { useEffect, useState } from "react";
import { useAuth } from "../../../../Hooks/useAuth";
import { prepareDistrictsDropdown, prepareStateDropdown } from "../../../../Support/Util";
import { ButtonSave, Container, GroupSave, Row, UploadMessage } from "./style";
import axios from 'axios';
import Dropdown from "../../../Dropdown";
import { Info } from "../../GeneralTab/style";
import Icon from "../../../Icon";
import DisketIcon from '../../../../Assets/icons/E3.svg';
import Modal from "../../../Modal";
import Spinner from "../../../Spinner";

const AddressTab = () => {

    const {currentUser, getToken, getUser} = useAuth();

    const [states, setStates] = useState([]);
    const [state, setState] = useState({});

    const [districts, setDistricts] = useState([]);
    const [filteredDistrict, setFilteredDistrict] = useState([]);
    const [district, setDistrict] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [serverMessage, setServerMessage] = useState("");

    const getStates = async () => {
        let url = `${process.env.REACT_APP_BASE_API}/state`;

        try {
            const response = await axios.get(url)
            setStates(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }
    }

    const getDistrict = async (id) => {
        let url = `${process.env.REACT_APP_BASE_API}/district/${id}}`;

        try {
            const response = await axios.get(url)
            setDistricts(response.data);
        }catch(error){
            //TODO add flash error message;
            console.log(error);
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsModalOpen(true);
        setIsLoading(true);
        const data = {
            state: state.id,
            district: district.id,
        }

        let url = `${process.env.REACT_APP_BASE_API}/profile/desktop/address`;
        let token = getToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${token.type} ${token.token}`
        }

        try {
            const response = await axios.post(url, data, {headers:headers})
            getUser();
            setServerMessage("Dados salvos com sucesso!");
            setIsLoading(false);
            setIsUploadSuccess(true)
        }catch(error){
            //TODO add flash error message;
            let outputMessage = "";
            setIsLoading(false);
            setIsUploadSuccess(false);
            if(error.response.data){
                if(error.response.data.message){
                    outputMessage = "Problema ao tentar salvar os dados, tente novamente mais tarde.";
                }
    
                if(typeof error.response.data === "object"){
                    const errorMessages = Object.values(error.response.data);
                    if(Array.isArray(errorMessages) && (!error.response.data.message)){
                        errorMessages.map((message) => {
                            outputMessage = outputMessage + message[0] + "\n";
                        });
                    }
                }
                
            }
            setServerMessage(outputMessage);
        }
    }

    useEffect(() => {
        setState(currentUser.address.state);
        setDistrict(currentUser.address.district);
    }, []);

    useEffect(() => {
        getStates();
       
    }, []);

    useEffect(() => {
        getDistrict(state.id);
    }, [state])

    return(
        <Container>
            <form onSubmit = {handleFormSubmit}>
                <Row>
                    <Dropdown
                        searchable
                        name = {"UF"}
                        label = {"UF"}
                        title = {"UF"}
                        options = {prepareStateDropdown(states)}
                        valueDescription = {state.name}
                        onChange = {(event) => {setState(event.target.value); setDistrict("")}}
                        className = {"full-size"}
                    />
                </Row>
                <Row>
                    <Dropdown
                        searchable
                        name = {"district"}
                        label = {"Município"}
                        title = {"Município"}
                        options = {prepareDistrictsDropdown(districts)}
                        valueDescription = {district.name}
                        onChange = {(event) => {setDistrict(event.target.value)}}
                        className = {"full-size"}
                    />

                </Row>

                <Row>
                    <Info>
                        Os dados de Cidade e Estado informados aqui serão vistos pelos produtores e visitantes.
                    </Info>
                
                </Row>

                <GroupSave>
                    <ButtonSave>
                        <Icon src = {DisketIcon} width = "3em" height = "3em"/>
                        Salvar
                    </ButtonSave>
                </GroupSave>
            </form>
            <Modal isOpen={isModalOpen} handleClose={ () => {setIsModalOpen(false)}}>
                {isLoading &&
                    <Spinner dimension={"40px"}/>
                }
                {!isLoading &&
                    <UploadMessage state = {isUploadSuccess}>{serverMessage}</UploadMessage>
                }

            </Modal>
        </Container>
    );
}

export default AddressTab