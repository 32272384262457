const Circle = ({colour, percentage}) => {

    const radius = 30;
    const circunference = 2 * Math.PI * radius;
    const startPercentage = ((100 - percentage) * circunference) / 100;


    return (
        <circle
            r = {radius}
            cx = {50}
            cy = {50}
            fill = {"transparent"}
            stroke = {startPercentage !== circunference ? colour : ""}
            strokeWidth = {"0.2em"}
            strokeDasharray = {circunference}
            strokeDashoffset = {percentage ?  startPercentage : 0}
        ></circle>
    ); 
}

export default Circle;