import StarCard from "../StarCard";
import Stars from "../../Stars"
import { CategoryList, Card,  Container, Title, SubTitle, StarContent } from "./style";

const CategoryFilter = ({categories, selectedCategories, addCategoryItem, removeCategoryItem, rating, changeRatingfilter}) => {

    const onClickForGetValue = (category) => {
        addCategoryItem(category);
    }

    const onClickForRemoveValue = (category) => {
        removeCategoryItem(category);
    }

    return (
        <Container>
            <Title>
                Filtro
            </Title>

            <CategoryList>
                <SubTitle>Categorias selecionadas:</SubTitle>
                {
                    selectedCategories.map((category, key) => {
                        return (<Card onClick={() =>{onClickForRemoveValue(category)}} key={key}> {category}</Card>);

                    })  
                }
            </CategoryList>

            <CategoryList>
                <SubTitle>Nível de habilidade selecionado:</SubTitle>

                <StarContent >
                        <Stars  skill={rating}/>
                </StarContent>
            </CategoryList>

            <CategoryList>
                <SubTitle>Categorias disponíveis:</SubTitle>
                {
                categories.filter((item)=> {return !selectedCategories.includes(item); } ).map((category, key) => {
                    return (<Card onClick={() =>{onClickForGetValue(category)}} key={key}> {category}</Card>);

                })  
            }
            </CategoryList>

            <CategoryList>
                <SubTitle>Filtro por nível de Habilidade ou acima:</SubTitle>
                {   
                    [1,2,3,4,5].map((skill, key)=> {
                        return ( 
                            <StarContent onClick={() => {changeRatingfilter(skill)}} key={key}>
                                <Stars  skill={skill}/>
                            </StarContent>
                        );

                    })
                }
            </CategoryList>
        </Container>
    );

}


export default CategoryFilter;